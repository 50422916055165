import React, {useEffect, useState} from 'react';

const LoaderMessage = props => {

    const [progress, setProgress] = useState({
        percent: 5,
        width: "5%"
    })

    const progressProcess = () => {
        setTimeout(()=>{

            let percent = 5

            if (progress.percent > 80)
                percent = 2

            else if (progress.percent > 50)
                percent = 15

            let newPercent = progress.percent + percent

            if (newPercent > 95)
                return

            progress.percent = progress.percent + percent
            progress.width = `${progress.percent}%`
            setProgress(Object.assign({}, progress))
            progressProcess()
        }, 500)
    }

    useEffect(()=>{
        progressProcess()
    })

    return (
        <div className={props.loading === true ? "is-loading" : "is-loading"}>
            <div id="preloader" style={{
                backgroundColor: "rgb(255 255 255 / 0.72)"
            }}>
                <div id="status" style={{
                    margin: 0,
                    width: 400,
                    right: 0,
                    left: 0,
                    marginLeft: 40
                }}>
                    <h5 className="text-primary">{props.message}</h5>
                    <div className="progress mb-4">
                        <div
                            className="progress-bar progress-bar-animated bg-primary"
                            role="progressbar"
                            style={{ width: progress.width }}
                            aria-valuenow="5"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoaderMessage;