import React, {useEffect, useState} from 'react'
import {Col, Modal, Row} from "reactstrap"

import {withTranslation} from "react-i18next"

function SelectTextAreaModal(props)  {

    const [isOpen, setIsOpen] = useState(props.isOpen)

    const [entity, setEntity] = useState({
        message: props.defaultValue
    })

    const [event, setEvent] = useState('OPENED')

    const afterClosed = () => {
        props.callback({isOpen: false, entity: null})
    }

    const close = () => {
        props.callback({isOpen: false, entity: entity, event: event})
    }

    const toggle = () => {
        setIsOpen(false)
    }

    const handleFieldEntity = (name, value) => {
        entity[name] = value
        setEntity(entity)
        setEvent('CHANGE')
    }

    return (
            <React.Fragment>
                <Modal
                    isOpen={isOpen}
                    toggle={() => {
                        toggle()
                    }}
                    onClosed={() => {
                        afterClosed()
                    }}
                    modalClassName="drawer right-align"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            {props.title}
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                toggle()
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <Row>
                            <Col lg={12}>
                                <p className="card-title-desc mt-2 mb-0">{props.detail}</p>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col lg={12}>
                               <textarea
                                   className="form-control "
                                   defaultValue={entity.message}
                                   style={{height: 100}}
                                   placeholder="Escreva aqui sua mensagem..."
                                   onChange={(event => handleFieldEntity('message', event.target.value))}
                                   rows={5}>
                               </textarea>
                            </Col>

                        </Row>

                        <Row className="justify-content-center text-center mt-5">
                            <Col> <button className="btn btn-primary w-sm waves-effect waves-light w-100"
                                          onClick={close}
                            >
                                Salvar mensagem
                            </button>
                            </Col>
                        </Row>
                    </div>

                </Modal>

            </React.Fragment>
        )
}


export default withTranslation()(SelectTextAreaModal)