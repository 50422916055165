import PropTypes from "prop-types"
import React, {useCallback, useEffect, useRef} from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import {useHistory, withRouter} from "react-router-dom"
import {Link} from "react-router-dom"

//i18n
import {withTranslation} from "react-i18next"

import {getUserSession, hasRole} from "../../helpers/api_helper";

import logoBs2 from "../../assets/images/financial-agent/logo-sm-71027866000134.png"
import logoCashTF from "../../assets/images/financial-agent/logo-sm-35262759000127.png"
import logoCash2Pay from "../../assets/images/financial-agent/logo-sm-44353163000123.png"
import SidebarItemMenu from "./SidebarItemMenu";

const SidebarContent = props => {

    const loggedUser = getUserSession()

    const getLogo = () => {
        if (logoBs2.includes(loggedUser.financialAgent.legalId))
            return {logo: logoBs2, exists: true}
        if (logoCashTF.includes(loggedUser.financialAgent.legalId))
            return {logo: logoCashTF, exists: true}
        if (logoCash2Pay.includes(loggedUser.financialAgent.legalId))
            return {logo: logoCashTF, exists: true}

        return  {logo: logoCashTF, exists: false}
    }

    const logo = getLogo()

    const logoSm = {
        img: logo.logo,
        class: logo.exists ? `logo-sm-${loggedUser.financialAgent.legalId}` : 'logo-sm-default'
    }

    const ref = useRef()
    const activateParentDropdown = useCallback((item) => {
        item.classList.add("active")
        const parent = item.parentElement
        const parent2El = parent.childNodes[1]
        if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.add("mm-show")
        }

        if (parent) {
            parent.classList.add("mm-active")
            const parent2 = parent.parentElement

            if (parent2) {
                parent2.classList.add("mm-show") // ul tag

                const parent3 = parent2.parentElement // li tag

                if (parent3) {
                    parent3.classList.add("mm-active") // li
                    parent3.childNodes[0].classList.add("mm-active") //a
                    const parent4 = parent3.parentElement // ul
                    if (parent4) {
                        parent4.classList.add("mm-show") // ul
                        const parent5 = parent4.parentElement
                        if (parent5) {
                            parent5.classList.add("mm-show") // li
                            parent5.childNodes[0].classList.add("mm-active") // a tag
                        }
                    }
                }
            }
            scrollElement(item);
            return false
        }
        scrollElement(item);
        return false
    }, []);

    // Use ComponentDidMount and ComponentDidUpdate method symultaniously
    useEffect(() => {
        const pathName = props.location.pathname

        const initMenu = () => {
            new MetisMenu("#side-menu")
            let matchingMenuItem = null
            const ul = document.getElementById("side-menu")
            const items = ul.getElementsByTagName("a")
            for (let i = 0; i < items.length; ++i) {
                if (pathName === items[i].pathname) {
                    matchingMenuItem = items[i]
                    break
                }
            }
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem)
            }
        }
        initMenu()
    }, [props.location.pathname, activateParentDropdown])

    useEffect(() => {
        ref.current.recalculate()
    }, []);

    const scrollElement = (item) => {
        if (item) {
            const currentPosition = item.offsetTop
            if (currentPosition > window.innerHeight) {
                ref.current.getScrollElement().scrollTop = currentPosition - 300
            }
        }
    }

    const history = useHistory()
    const logoff = () => {
        localStorage.removeItem("authUser")
        history.push('/login')
    }

    return (
        <React.Fragment>
            <SimpleBar style={{maxHeight: "100%"}} ref={ref}>
                <div id="sidebar-menu">
                    <span className="logo-sm">
                       <img src={logoSm.img} alt="" height="30"
                            className={logoSm.class}/>
                    </span>

                    <ul className="metismenu list-unstyled" id="side-menu">

                        <SidebarItemMenu
                            icon="bx bxs-grid"
                            label={props.t("Menu")}
                            style={{fontSize: "1.8rem", margin: 0}}
                            to='/home-menu'
                        />

                        <hr/>

                        <SidebarItemMenu
                            icon="bx bxs-bar-chart-alt-2"
                            role="portfolio.read"
                            label={props.t("Dashboard")}
                            style={{fontSize: "1.7rem", margin: 0}}
                            to='/dashboard'
                        />

                        <SidebarItemMenu
                            icon="bx bx-log-in"
                            role="optin.read"
                            label={props.t("Opt-in")}
                            style={{fontSize: "1.99rem", margin: "0 0 0 -7px"}}
                            to='/opt-in'
                        />

                        {
                            loggedUser.financialAgent.appProfile === 'SELLER' ?
                                <SidebarItemMenu
                                    icon="bx bx-group"
                                    role="receivable-schedule.read"
                                    label={props.t("Customers")}
                                    style={{fontSize: "1.78rem", margin: 0}}
                                    to='/customers'
                                />
                                :
                                <SidebarItemMenu
                                    icon="bx bx-calendar"
                                    role="receivable-schedule.read"
                                    label={props.t("Receivables")}
                                    style={{fontSize: "1.78rem", margin: 0}}
                                    to='/receivables'
                                />
                        }

                        <SidebarItemMenu
                            icon="fas fa-file-signature"
                            role="contract.read"
                            label={props.t("Contracts")}
                            style={{fontSize: "1.5rem", margin: "0 0 0 4px"}}
                            to='/contracts/manage-contracts'
                        />

                        {
                            loggedUser.financialAgent.appProfile !== 'REGISTER_AGENT' ?
                                <SidebarItemMenu
                                    icon="fas fa-file-invoice-dollar"
                                    role="order.read"
                                    label={props.t("Billing")}
                                    style={{fontSize: "1.5rem", margin: "0 0 0 4px"}}
                                    to='/billing'
                                />
                                : ""
                        }

                        <hr/>

                        <li>
                            <Link to="#!" onClick={logoff} className="has-arrow waves-effect">
                                <i className='bx bx-log-out-circle' style={{fontSize: "1.8rem", margin: 0}}></i>
                                <span>{props.t('Exit')}</span>
                            </Link>
                        </li>

                    </ul>
                </div>
            </SimpleBar>
        </React.Fragment>
    )
}

SidebarContent.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
