const PaymentCompanyDomain = {

    options: [
        {
            "name": "ACCREDITO SCD S.A.",
            "comp": "406",
            "ispb": "37715993"
        },
        {
            "name": "ACESSO SOLUÇÕES DE PAGAMENTO S.A.",
            "comp": "332",
            "ispb": "13140088"
        },
        {
            "name": "ADVANCED CORRETORA DE CÂMBIO LTDA",
            "comp": "117",
            "ispb": "92856905"
        },
        {
            "name": "AGK CORRETORA DE CAMBIO S.A.",
            "comp": "272",
            "ispb": "00250699"
        },
        {
            "name": "AL5 S.A. CFI",
            "comp": "349",
            "ispb": "27214112"
        },
        {
            "name": "AMAZÔNIA CORRETORA DE CÂMBIO LTDA.",
            "comp": "313",
            "ispb": "16927221"
        },
        {
            "name": "ASAAS IP S.A.",
            "comp": "461",
            "ispb": "19540550"
        },
        {
            "name": "ATIVA S.A. INVESTIMENTOS CCTVM",
            "comp": "188",
            "ispb": "33775974"
        },
        {
            "name": "AVENUE SECURITIES DTVM LTDA.",
            "comp": "508",
            "ispb": "61384004"
        },
        {
            "name": "AZUMI DTVM",
            "comp": "463",
            "ispb": "40434681"
        },
        {
            "name": "BANCO ABC BRASIL S.A.",
            "comp": "246",
            "ispb": "28195667"
        },
        {
            "name": "BANCO ABN AMRO S.A.",
            "comp": "075",
            "ispb": "03532415"
        },
        {
            "name": "BANCO AGIBANK S.A.",
            "comp": "121",
            "ispb": "10664513"
        },
        {
            "name": "BANCO ALFA S.A.",
            "comp": "025",
            "ispb": "03323840"
        },
        {
            "name": "BANCO ANDBANK (BRASIL) S.A.",
            "comp": "065",
            "ispb": "48795256"
        },
        {
            "name": "BANCO ARBI S.A.",
            "comp": "213",
            "ispb": "54403563"
        },
        {
            "name": "BANCO B3 S.A.",
            "comp": "096",
            "ispb": "00997185"
        },
        {
            "name": "BANCO BANDEPE S.A.",
            "comp": "024",
            "ispb": "10866788"
        },
        {
            "name": "BANCO BARI S.A.",
            "comp": "330",
            "ispb": "00556603"
        },
        {
            "name": "BANCO BMG S.A.",
            "comp": "318",
            "ispb": "61186680"
        },
        {
            "name": "BANCO BNP PARIBAS BRASIL S.A.",
            "comp": "752",
            "ispb": "01522368"
        },
        {
            "name": "BANCO BOCOM BBM S.A.",
            "comp": "107",
            "ispb": "15114366"
        },
        {
            "name": "BANCO BRADESCARD S.A.",
            "comp": "063",
            "ispb": "04184779"
        },
        {
            "name": "BANCO BRADESCO BBI S.A.",
            "comp": "036",
            "ispb": "06271464"
        },
        {
            "name": "BANCO BRADESCO BERJ S.A.",
            "comp": "122",
            "ispb": "33147315"
        },
        {
            "name": "BANCO BRADESCO FINANCIAMENTOS S.A.",
            "comp": "394",
            "ispb": "07207996"
        },
        {
            "name": "BANCO BRADESCO S.A.",
            "comp": "237",
            "ispb": "60746948"
        },
        {
            "name": "BANCO BS2 S.A.",
            "comp": "218",
            "ispb": "71027866"
        },
        {
            "name": "BANCO BTG PACTUAL S.A.",
            "comp": "208",
            "ispb": "30306294"
        },
        {
            "name": "BANCO BV S.A.",
            "comp": "413",
            "ispb": "01858774"
        },
        {
            "name": "BANCO C6 CONSIGNADO S.A.",
            "comp": "626",
            "ispb": "61348538"
        },
        {
            "name": "BANCO C6 S.A.",
            "comp": "336",
            "ispb": "31872495"
        },
        {
            "name": "BANCO CAIXA GERAL - BRASIL S.A.",
            "comp": "473",
            "ispb": "33466988"
        },
        {
            "name": "BANCO CARGILL S.A.",
            "comp": "040",
            "ispb": "03609817"
        },
        {
            "name": "BANCO CEDULA S.A.",
            "comp": "266",
            "ispb": "33132044"
        },
        {
            "name": "BANCO CETELEM S.A.",
            "comp": "739",
            "ispb": "00558456"
        },
        {
            "name": "BANCO CIFRA S.A.",
            "comp": "233",
            "ispb": "62421979"
        },
        {
            "name": "BANCO CITIBANK S.A.",
            "comp": "745",
            "ispb": "33479023"
        },
        {
            "name": "BANCO CLASSICO S.A.",
            "comp": "241",
            "ispb": "31597552"
        },
        {
            "name": "BANCO COOPERATIVO SICREDI S.A.",
            "comp": "748",
            "ispb": "01181521"
        },
        {
            "name": "BANCO CRÉDIT AGRICOLE BRASIL S.A.",
            "comp": "222",
            "ispb": "75647891"
        },
        {
            "name": "BANCO CREDIT SUISSE (BRASIL) S.A.",
            "comp": "505",
            "ispb": "32062580"
        },
        {
            "name": "BANCO CREFISA S.A.",
            "comp": "069",
            "ispb": "61033106"
        },
        {
            "name": "BANCO CSF S.A.",
            "comp": "368",
            "ispb": "08357240"
        },
        {
            "name": "BANCO DA AMAZÔNIA S.A.",
            "comp": "003",
            "ispb": "04902979"
        },
        {
            "name": "BANCO DA CHINA BRASIL S.A.",
            "comp": "083",
            "ispb": "10690848"
        },
        {
            "name": "BANCO DAYCOVAL S.A.",
            "comp": "707",
            "ispb": "62232889"
        },
        {
            "name": "BANCO DE LA NACION ARGENTINA",
            "comp": "300",
            "ispb": "33042151"
        },
        {
            "name": "BANCO DE LA PROVINCIA DE BUENOS AIRES",
            "comp": "495",
            "ispb": "44189447"
        },
        {
            "name": "BANCO DIGIMAIS S.A.",
            "comp": "654",
            "ispb": "92874270"
        },
        {
            "name": "BANCO DIGIO S.A.",
            "comp": "335",
            "ispb": "27098060"
        },
        {
            "name": "BANCO DO BRASIL S.A.",
            "comp": "001",
            "ispb": "00000000"
        },
        {
            "name": "BANCO DO ESTADO DE SERGIPE S.A.",
            "comp": "047",
            "ispb": "13009717"
        },
        {
            "name": "BANCO DO ESTADO DO PARÁ S.A.",
            "comp": "037",
            "ispb": "04913711"
        },
        {
            "name": "BANCO DO NORDESTE DO BRASIL S.A.",
            "comp": "004",
            "ispb": "07237373"
        },
        {
            "name": "BANCO FATOR S.A.",
            "comp": "265",
            "ispb": "33644196"
        },
        {
            "name": "BANCO FIBRA S.A.",
            "comp": "224",
            "ispb": "58616418"
        },
        {
            "name": "BANCO FINAXIS S.A.",
            "comp": "094",
            "ispb": "11758741"
        },
        {
            "name": "BANCO GENIAL S.A.",
            "comp": "125",
            "ispb": "45246410"
        },
        {
            "name": "BANCO GM S.A.",
            "comp": "390",
            "ispb": "59274605"
        },
        {
            "name": "BANCO GUANABARA S.A.",
            "comp": "612",
            "ispb": "31880826"
        },
        {
            "name": "BANCO HSBC S.A.",
            "comp": "269",
            "ispb": "53518684"
        },
        {
            "name": "BANCO INBURSA S.A.",
            "comp": "012",
            "ispb": "04866275"
        },
        {
            "name": "BANCO INDUSTRIAL DO BRASIL S.A.",
            "comp": "604",
            "ispb": "31895683"
        },
        {
            "name": "BANCO INTER S.A.",
            "comp": "077",
            "ispb": "00416968"
        },
        {
            "name": "BANCO INVESTCRED UNIBANCO S.A.",
            "comp": "249",
            "ispb": "61182408"
        },
        {
            "name": "BANCO ITAUBANK S.A.",
            "comp": "479",
            "ispb": "60394079"
        },
        {
            "name": "BANCO ITAÚ BBA S.A.",
            "comp": "184",
            "ispb": "17298092"
        },
        {
            "name": "BANCO ITAÚ CONSIGNADO S.A.",
            "comp": "029",
            "ispb": "33885724"
        },
        {
            "name": "BANCO JOHN DEERE S.A.",
            "comp": "217",
            "ispb": "91884981"
        },
        {
            "name": "BANCO J.P. MORGAN S.A.",
            "comp": "376",
            "ispb": "33172537"
        },
        {
            "name": "BANCO J. SAFRA S.A.",
            "comp": "074",
            "ispb": "03017677"
        },
        {
            "name": "BANCO KDB DO BRASIL S.A.",
            "comp": "076",
            "ispb": "07656500"
        },
        {
            "name": "BANCO KEB HANA DO BRASIL S.A.",
            "comp": "757",
            "ispb": "02318507"
        },
        {
            "name": "BANCO LETSBANK S.A.",
            "comp": "630",
            "ispb": "58497702"
        },
        {
            "name": "BANCO LUSO BRASILEIRO S.A.",
            "comp": "600",
            "ispb": "59118133"
        },
        {
            "name": "BANCO MASTER S/A",
            "comp": "243",
            "ispb": "33923798"
        },
        {
            "name": "BANCO MERCANTIL DO BRASIL S.A.",
            "comp": "389",
            "ispb": "17184037"
        },
        {
            "name": "BANCO MERCEDES-BENZ DO BRASIL S.A.",
            "comp": "381",
            "ispb": "60814191"
        },
        {
            "name": "BANCO MIZUHO DO BRASIL S.A.",
            "comp": "370",
            "ispb": "61088183"
        },
        {
            "name": "BANCO MODAL S.A.",
            "comp": "746",
            "ispb": "30723886"
        },
        {
            "name": "BANCO MORGAN STANLEY S.A.",
            "comp": "066",
            "ispb": "02801938"
        },
        {
            "name": "BANCO MUFG BRASIL S.A.",
            "comp": "456",
            "ispb": "60498557"
        },
        {
            "name": "BANCO ORIGINAL S.A.",
            "comp": "212",
            "ispb": "92894922"
        },
        {
            "name": "BANCO OURINVEST S.A.",
            "comp": "712",
            "ispb": "78632767"
        },
        {
            "name": "BANCO PAN S.A.",
            "comp": "623",
            "ispb": "59285411"
        },
        {
            "name": "BANCO PAULISTA S.A.",
            "comp": "611",
            "ispb": "61820817"
        },
        {
            "name": "BANCO PINE S.A.",
            "comp": "643",
            "ispb": "62144175"
        },
        {
            "name": "BANCO RABOBANK INTERNATIONAL BRASIL S.A.",
            "comp": "747",
            "ispb": "01023570"
        },
        {
            "name": "BANCO RANDON S.A.",
            "comp": "088",
            "ispb": "11476673"
        },
        {
            "name": "BANCO RENDIMENTO S.A.",
            "comp": "633",
            "ispb": "68900810"
        },
        {
            "name": "BANCO RIBEIRAO PRETO S.A.",
            "comp": "741",
            "ispb": "00517645"
        },
        {
            "name": "BANCO RNX S.A.",
            "comp": "720",
            "ispb": "80271455"
        },
        {
            "name": "BANCO RODOBENS S.A.",
            "comp": "120",
            "ispb": "33603457"
        },
        {
            "name": "BANCO SAFRA S.A.",
            "comp": "422",
            "ispb": "58160789"
        },
        {
            "name": "BANCO SANTANDER (BRASIL) S.A.",
            "comp": "033",
            "ispb": "90400888"
        },
        {
            "name": "BANCOSEGURO S.A.",
            "comp": "081",
            "ispb": "10264663"
        },
        {
            "name": "BANCO SEMEAR S.A.",
            "comp": "743",
            "ispb": "00795423"
        },
        {
            "name": "BANCO SENFF S.A.",
            "comp": "276",
            "ispb": "11970623"
        },
        {
            "name": "BANCO SICOOB S.A.",
            "comp": "756",
            "ispb": "02038232"
        },
        {
            "name": "BANCO SISTEMA S.A.",
            "comp": "754",
            "ispb": "76543115"
        },
        {
            "name": "BANCO SOCIETE GENERALE BRASIL S.A.",
            "comp": "366",
            "ispb": "61533584"
        },
        {
            "name": "BANCO SOFISA S.A.",
            "comp": "637",
            "ispb": "60889128"
        },
        {
            "name": "BANCO SOROCRED S.A. - BANCO MÚLTIPLO",
            "comp": "299",
            "ispb": "04814563"
        },
        {
            "name": "BANCO SUMITOMO MITSUI BRASILEIRO S.A.",
            "comp": "464",
            "ispb": "60518222"
        },
        {
            "name": "BANCO TOPÁZIO S.A.",
            "comp": "082",
            "ispb": "07679404"
        },
        {
            "name": "BANCO TOYOTA DO BRASIL S.A.",
            "comp": "387",
            "ispb": "03215790"
        },
        {
            "name": "BANCO TRIANGULO S.A.",
            "comp": "634",
            "ispb": "17351180"
        },
        {
            "name": "BANCO TRICURY S.A.",
            "comp": "018",
            "ispb": "57839805"
        },
        {
            "name": "BANCO VOITER S.A.",
            "comp": "653",
            "ispb": "61024352"
        },
        {
            "name": "BANCO VOLKSWAGEN S.A.",
            "comp": "393",
            "ispb": "59109165"
        },
        {
            "name": "BANCO VOTORANTIM S.A.",
            "comp": "655",
            "ispb": "59588111"
        },
        {
            "name": "BANCO VR S.A.",
            "comp": "610",
            "ispb": "78626983"
        },
        {
            "name": "BANCO WESTERN UNION DO BRASIL S.A.",
            "comp": "119",
            "ispb": "13720915"
        },
        {
            "name": "BANCO WOORI BANK DO BRASIL S.A.",
            "comp": "124",
            "ispb": "15357060"
        },
        {
            "name": "BANCO XP S.A.",
            "comp": "348",
            "ispb": "33264668"
        },
        {
            "name": "BANCO YAMAHA MOTOR DO BRASIL S.A.",
            "comp": "475",
            "ispb": "10371492"
        },
        {
            "name": "BARI COMPANHIA HIPOTECÁRIA",
            "comp": "268",
            "ispb": "14511781"
        },
        {
            "name": "BCO BANESTES S.A.",
            "comp": "021",
            "ispb": "28127603"
        },
        {
            "name": "BCO BRASILEIRO DE CRÉDITO S.A.",
            "comp": "378",
            "ispb": "01852137"
        },
        {
            "name": "BCO CCB BRASIL S.A.",
            "comp": "320",
            "ispb": "07450604"
        },
        {
            "name": "BCO DO ESTADO DO RS S.A. (BANRISUL)",
            "comp": "041",
            "ispb": "92702067"
        },
        {
            "name": "BCV - BANCO DE CRÉDITO E VAREJO S.A.",
            "comp": "250",
            "ispb": "50585090"
        },
        {
            "name": "BEXS BANCO DE CÂMBIO S/A",
            "comp": "144",
            "ispb": "13059145"
        },
        {
            "name": "BEXS CORRETORA DE CÂMBIO S/A",
            "comp": "253",
            "ispb": "52937216"
        },
        {
            "name": "BGC LIQUIDEZ DTVM LTDA",
            "comp": "134",
            "ispb": "33862244"
        },
        {
            "name": "BIORC FINANCEIRA - CFI S.A.",
            "comp": "426",
            "ispb": "11285104"
        },
        {
            "name": "BMS SOCIEDADE DE CRÉDITO DIRETO S.A.",
            "comp": "377",
            "ispb": "17826860"
        },
        {
            "name": "BNDES",
            "comp": "007",
            "ispb": "33657248"
        },
        {
            "name": "BNY MELLON BANCO S.A.",
            "comp": "017",
            "ispb": "42272526"
        },
        {
            "name": "BOFA MERRILL LYNCH BM S.A.",
            "comp": "755",
            "ispb": "62073200"
        },
        {
            "name": "BONUSPAGO SCD S.A.",
            "comp": "408",
            "ispb": "36586946"
        },
        {
            "name": "BPP INSTITUIÇÃO DE PAGAMENTO S.A.",
            "comp": "301",
            "ispb": "13370835"
        },
        {
            "name": "BRB - BANCO DE BRASÍLIA S.A.",
            "comp": "070",
            "ispb": "00000208"
        },
        {
            "name": "BR-CAPITAL DTVM S.A.",
            "comp": "433",
            "ispb": "44077014"
        },
        {
            "name": "BRK S.A. CFI",
            "comp": "092",
            "ispb": "12865507"
        },
        {
            "name": "BRL TRUST DTVM SA",
            "comp": "173",
            "ispb": "13486793"
        },
        {
            "name": "BROKER BRASIL CORRETORA DE CÂMBIO LTDA.",
            "comp": "142",
            "ispb": "16944141"
        },
        {
            "name": "BR PARTNERS BANCO DE INVESTIMENTO S.A.",
            "comp": "126",
            "ispb": "13220493"
        },
        {
            "name": "BS2 DTVM S.A.",
            "comp": "292",
            "ispb": "28650236"
        },
        {
            "name": "B&T CORRETORA DE CAMBIO LTDA.",
            "comp": "080",
            "ispb": "73622748"
        },
        {
            "name": "CAIXA ECONÔMICA FEDERAL",
            "comp": "104",
            "ispb": "00360305"
        },
        {
            "name": "CAMBIONET CORRETORA DE CÂMBIO LTDA.",
            "comp": "309",
            "ispb": "14190547"
        },
        {
            "name": "CAPITAL CONSIG SCD S.A.",
            "comp": "465",
            "ispb": "40083667"
        },
        {
            "name": "CAPTALYS DTVM LTDA.",
            "comp": "512",
            "ispb": "36266751"
        },
        {
            "name": "CAROL DTVM LTDA.",
            "comp": "288",
            "ispb": "62237649"
        },
        {
            "name": "CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A.",
            "comp": "324",
            "ispb": "21332862"
        },
        {
            "name": "CARUANA SCFI",
            "comp": "130",
            "ispb": "09313766"
        },
        {
            "name": "CASA CREDITO S.A. SCM",
            "comp": "159",
            "ispb": "05442029"
        },
        {
            "name": "CCCM UNICRED CENTRAL RS",
            "comp": "091",
            "ispb": "01634601"
        },
        {
            "name": "CCM DESP TRÂNS SC E RS",
            "comp": "016",
            "ispb": "04715685"
        },
        {
            "name": "CCM SERV. PÚBLICOS SP",
            "comp": "459",
            "ispb": "04546162"
        },
        {
            "name": "CCR DE ABELARDO LUZ",
            "comp": "322",
            "ispb": "01073966"
        },
        {
            "name": "CCR DE IBIAM",
            "comp": "391",
            "ispb": "08240446"
        },
        {
            "name": "CCR DE OURO",
            "comp": "286",
            "ispb": "07853842"
        },
        {
            "name": "CCR DE SÃO MIGUEL DO OESTE",
            "comp": "273",
            "ispb": "08253539"
        },
        {
            "name": "CCR SEARA",
            "comp": "430",
            "ispb": "00204963"
        },
        {
            "name": "CDC SOCIEDADE DE CRÉDITO",
            "comp": "470",
            "ispb": "18394228"
        },
        {
            "name": "CECM COOPERFORTE",
            "comp": "379",
            "ispb": "01658426"
        },
        {
            "name": "CECM DOS TRAB.PORT. DA G.VITOR",
            "comp": "385",
            "ispb": "03844699"
        },
        {
            "name": "CECM FABRIC CALÇADOS SAPIRANGA",
            "comp": "328",
            "ispb": "05841967"
        },
        {
            "name": "CECM SERV PUBL PINHÃO",
            "comp": "471",
            "ispb": "04831810"
        },
        {
            "name": "CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO",
            "comp": "114",
            "ispb": "05790149"
        },
        {
            "name": "CIELO S.A.",
            "comp": "362",
            "ispb": "01027058"
        },
        {
            "name": "CITIBANK N.A.",
            "comp": "477",
            "ispb": "33042953"
        },
        {
            "name": "CM CAPITAL MARKETS CCTVM LTDA",
            "comp": "180",
            "ispb": "02685483"
        },
        {
            "name": "COBUCCIO SCD S.A.",
            "comp": "402",
            "ispb": "36947229"
        },
        {
            "name": "CODEPE CVC S.A.",
            "comp": "127",
            "ispb": "09512542"
        },
        {
            "name": "COLUNA S.A. DTVM",
            "comp": "423",
            "ispb": "00460065"
        },
        {
            "name": "COMMERZBANK BRASIL S.A. - BANCO MÚLTIPLO",
            "comp": "163",
            "ispb": "23522214"
        },
        {
            "name": "CONFIDENCE CORRETORA DE CÂMBIO S.A.",
            "comp": "060",
            "ispb": "04913129"
        },
        {
            "name": "CONF NAC COOP CENTRAIS UNICRED",
            "comp": "136",
            "ispb": "00315557"
        },
        {
            "name": "COOP CREDITAG",
            "comp": "400",
            "ispb": "05491616"
        },
        {
            "name": "COOP DE PRIMAVERA DO LESTE",
            "comp": "279",
            "ispb": "26563270"
        },
        {
            "name": "COOPERATIVA CENTRAL DE CRÉDITO - AILOS",
            "comp": "085",
            "ispb": "05463212"
        },
        {
            "name": "COOPERATIVA DE CRÉDITO RURAL COOPAVEL",
            "comp": "281",
            "ispb": "76461557"
        },
        {
            "name": "CORA SOCIEDADE DE CRÉDITO DIRETO S.A.",
            "comp": "403",
            "ispb": "37880206"
        },
        {
            "name": "CREDIALIANÇA CCR",
            "comp": "098",
            "ispb": "78157146"
        },
        {
            "name": "CREDIARE CFI S.A.",
            "comp": "429",
            "ispb": "05676026"
        },
        {
            "name": "CREDIBRF - COOPERATIVA DE CRÉDITO",
            "comp": "440",
            "ispb": "82096447"
        },
        {
            "name": "CREDICOAMO CREDITO RURAL COOPERATIVA",
            "comp": "010",
            "ispb": "81723108"
        },
        {
            "name": "CREDIFIT SCD S.A.",
            "comp": "452",
            "ispb": "39676772"
        },
        {
            "name": "CREDIHOME SCD",
            "comp": "443",
            "ispb": "39416705"
        },
        {
            "name": "CREDISAN COOPERATIVA DE CRÉDITO",
            "comp": "089",
            "ispb": "62109566"
        },
        {
            "name": "CREDISIS CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.",
            "comp": "097",
            "ispb": "04632856"
        },
        {
            "name": "CREDITAS SCD",
            "comp": "342",
            "ispb": "32997490"
        },
        {
            "name": "CREDSYSTEM SCD S.A.",
            "comp": "428",
            "ispb": "39664698"
        },
        {
            "name": "CRED-UFES",
            "comp": "427",
            "ispb": "27302181"
        },
        {
            "name": "CREFAZ SCMEPP LTDA",
            "comp": "321",
            "ispb": "18188384"
        },
        {
            "name": "CREHNOR LARANJEIRAS",
            "comp": "350",
            "ispb": "01330387"
        },
        {
            "name": "CRESOL CONFEDERAÇÃO",
            "comp": "133",
            "ispb": "10398952"
        },
        {
            "name": "C.SUISSE HEDGING-GRIFFO CV S/A",
            "comp": "011",
            "ispb": "61809182"
        },
        {
            "name": "DELCRED SOCIEDADE DE CRÉDITO DIRETO S.A.",
            "comp": "435",
            "ispb": "38224857"
        },
        {
            "name": "DEUTSCHE BANK S.A. - BANCO ALEMAO",
            "comp": "487",
            "ispb": "62331228"
        },
        {
            "name": "DMCARD SOCIEDADE DE CRÉDITO DIRETO S.A.",
            "comp": "449",
            "ispb": "37555231"
        },
        {
            "name": "DOURADA CORRETORA DE CÂMBIO LTDA.",
            "comp": "311",
            "ispb": "76641497"
        },
        {
            "name": "EBANX INSTITUICAO DE PAGAMENTOS LTDA.",
            "comp": "383",
            "ispb": "21018182"
        },
        {
            "name": "EFX CORRETORA DE CÂMBIO LTDA.",
            "comp": "289",
            "ispb": "94968518"
        },
        {
            "name": "FACTA S.A. CFI",
            "comp": "149",
            "ispb": "15581638"
        },
        {
            "name": "FAIR CORRETORA DE CAMBIO S.A.",
            "comp": "196",
            "ispb": "32648370"
        },
        {
            "name": "F D GOLD DTVM LTDA",
            "comp": "395",
            "ispb": "08673569"
        },
        {
            "name": "FFA SCMEPP LTDA.",
            "comp": "343",
            "ispb": "24537861"
        },
        {
            "name": "FIDUCIA SCMEPP LTDA",
            "comp": "382",
            "ispb": "04307598"
        },
        {
            "name": "FITBANK PAGAMENTOS ELETRONICOS S.A.",
            "comp": "450",
            "ispb": "13203354"
        },
        {
            "name": "FRAM CAPITAL DTVM S.A.",
            "comp": "331",
            "ispb": "13673855"
        },
        {
            "name": "FRENTE CORRETORA DE CÂMBIO LTDA.",
            "comp": "285",
            "ispb": "71677850"
        },
        {
            "name": "GAZINCRED S.A. SCFI",
            "comp": "478",
            "ispb": "11760553"
        },
        {
            "name": "GENIAL INVESTIMENTOS CVM S.A.",
            "comp": "278",
            "ispb": "27652684"
        },
        {
            "name": "GERENCIANET S.A.",
            "comp": "364",
            "ispb": "09089356"
        },
        {
            "name": "GET MONEY CORRETORA DE CÂMBIO S.A.",
            "comp": "138",
            "ispb": "10853017"
        },
        {
            "name": "GLOBAL SCM LTDA",
            "comp": "384",
            "ispb": "11165756"
        },
        {
            "name": "GOLDMAN SACHS DO BRASIL BM S.A",
            "comp": "064",
            "ispb": "04332281"
        },
        {
            "name": "GUIDE",
            "comp": "177",
            "ispb": "65913436"
        },
        {
            "name": "GUITTA CORRETORA DE CAMBIO LTDA.",
            "comp": "146",
            "ispb": "24074692"
        },
        {
            "name": "HAITONG BI DO BRASIL S.A.",
            "comp": "078",
            "ispb": "34111187"
        },
        {
            "name": "HEDGE INVESTMENTS DTVM LTDA.",
            "comp": "458",
            "ispb": "07253654"
        },
        {
            "name": "HEMERA DTVM LTDA.",
            "comp": "448",
            "ispb": "39669186"
        },
        {
            "name": "HIPERCARD BANCO MÚLTIPLO S.A.",
            "comp": "062",
            "ispb": "03012230"
        },
        {
            "name": "HSCM SCMEPP LTDA.",
            "comp": "312",
            "ispb": "07693858"
        },
        {
            "name": "HS FINANCEIRA",
            "comp": "189",
            "ispb": "07512441"
        },
        {
            "name": "HUB PAGAMENTOS S.A",
            "comp": "396",
            "ispb": "13884775"
        },
        {
            "name": "IB CCTVM S.A.",
            "comp": "271",
            "ispb": "27842177"
        },
        {
            "name": "ICAP DO BRASIL CTVM LTDA.",
            "comp": "157",
            "ispb": "09105360"
        },
        {
            "name": "ICBC DO BRASIL BANCO MÚLTIPLO S.A.",
            "comp": "132",
            "ispb": "17453575"
        },
        {
            "name": "ID CTVM",
            "comp": "439",
            "ispb": "16695922"
        },
        {
            "name": "IDEAL CTVM S.A.",
            "comp": "398",
            "ispb": "31749596"
        },
        {
            "name": "ÍNDIGO INVESTIMENTOS DTVM LTDA.",
            "comp": "407",
            "ispb": "00329598"
        },
        {
            "name": "ING BANK N.V.",
            "comp": "492",
            "ispb": "49336860"
        },
        {
            "name": "INTESA SANPAOLO BRASIL S.A. BM",
            "comp": "139",
            "ispb": "55230916"
        },
        {
            "name": "ITAÚ UNIBANCO HOLDING S.A.",
            "comp": "652",
            "ispb": "60872504"
        },
        {
            "name": "ITAÚ UNIBANCO S.A.",
            "comp": "341",
            "ispb": "60701190"
        },
        {
            "name": "IUGU INSTITUIÇÃO DE PAGAMENTO S.A.",
            "comp": "401",
            "ispb": "15111975"
        },
        {
            "name": "J17 - SOCIEDADE DE CRÉDITO DIRETO S/A",
            "comp": "451",
            "ispb": "40475846"
        },
        {
            "name": "JPMORGAN CHASE BANK NATIONAL ASSOCIATION",
            "comp": "488",
            "ispb": "46518205"
        },
        {
            "name": "KIRTON BANK S.A. - BANCO MÚLTIPLO",
            "comp": "399",
            "ispb": "01701201"
        },
        {
            "name": "LAMARA SOCIEDADE DE CRÉDITO DIRETO S.A.",
            "comp": "416",
            "ispb": "19324634"
        },
        {
            "name": "LAR COOPERATIVA DE CRÉDITO - LAR CREDI",
            "comp": "421",
            "ispb": "39343350"
        },
        {
            "name": "LASTRO RDV DTVM LTDA",
            "comp": "293",
            "ispb": "71590442"
        },
        {
            "name": "LECCA CFI S.A.",
            "comp": "105",
            "ispb": "07652226"
        },
        {
            "name": "LEVYCAM CCV LTDA",
            "comp": "145",
            "ispb": "50579044"
        },
        {
            "name": "LIGA INVEST DTVM LTDA.",
            "comp": "469",
            "ispb": "07138049"
        },
        {
            "name": "LISTO SOCIEDADE DE CREDITO DIRETO S.A.",
            "comp": "397",
            "ispb": "34088029"
        },
        {
            "name": "MAF DTVM SA",
            "comp": "484",
            "ispb": "36864992"
        },
        {
            "name": "MAGNETIS - DTVM",
            "comp": "442",
            "ispb": "87963450"
        },
        {
            "name": "MAGNUM SOCIEDADE DE CRÉDITO DIRETO S.A.",
            "comp": "511",
            "ispb": "44683140"
        },
        {
            "name": "MASTER S/A CCTVM",
            "comp": "467",
            "ispb": "33886862"
        },
        {
            "name": "MERCADO PAGO IP LTDA.",
            "comp": "323",
            "ispb": "10573521"
        },
        {
            "name": "MÉRITO DTVM LTDA.",
            "comp": "454",
            "ispb": "41592532"
        },
        {
            "name": "MIDWAY S.A. - SCFI",
            "comp": "358",
            "ispb": "09464032"
        },
        {
            "name": "MIRAE ASSET CCTVM LTDA",
            "comp": "447",
            "ispb": "12392983"
        },
        {
            "name": "MONEYCORP BANCO DE CÂMBIO S.A.",
            "comp": "259",
            "ispb": "08609934"
        },
        {
            "name": "MONEY PLUS SCMEPP LTDA",
            "comp": "274",
            "ispb": "11581339"
        },
        {
            "name": "MS BANK S.A. BANCO DE CÂMBIO",
            "comp": "128",
            "ispb": "19307785"
        },
        {
            "name": "NECTON INVESTIMENTOS S.A CVM",
            "comp": "354",
            "ispb": "52904364"
        },
        {
            "name": "NEON CTVM S.A.",
            "comp": "113",
            "ispb": "61723847"
        },
        {
            "name": "NOVA FUTURA CTVM LTDA.",
            "comp": "191",
            "ispb": "04257795"
        },
        {
            "name": "NOVO BCO CONTINENTAL S.A. - BM",
            "comp": "753",
            "ispb": "74828799"
        },
        {
            "name": "NU FINANCEIRA S.A. CFI",
            "comp": "386",
            "ispb": "30680829"
        },
        {
            "name": "NU INVEST CORRETORA DE VALORES S.A.",
            "comp": "140",
            "ispb": "62169875"
        },
        {
            "name": "NUMBRS SOCIEDADE DE CRÉDITO DIRETO S.A.",
            "comp": "419",
            "ispb": "38129006"
        },
        {
            "name": "NU PAGAMENTOS - IP",
            "comp": "260",
            "ispb": "18236120"
        },
        {
            "name": "OLIVEIRA TRUST DTVM S.A.",
            "comp": "111",
            "ispb": "36113876"
        },
        {
            "name": "OM DTVM LTDA",
            "comp": "319",
            "ispb": "11495073"
        },
        {
            "name": "OMNI BANCO S.A.",
            "comp": "613",
            "ispb": "60850229"
        },
        {
            "name": "ÓRAMA DTVM S.A.",
            "comp": "325",
            "ispb": "13293225"
        },
        {
            "name": "ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A.",
            "comp": "355",
            "ispb": "34335592"
        },
        {
            "name": "OZ CORRETORA DE CÂMBIO S.A.",
            "comp": "296",
            "ispb": "04062902"
        },
        {
            "name": "PAGSEGURO INTERNET IP S.A.",
            "comp": "290",
            "ispb": "08561701"
        },
        {
            "name": "PARANÁ BANCO S.A.",
            "comp": "254",
            "ispb": "14388334"
        },
        {
            "name": "PARATI - CFI S.A.",
            "comp": "326",
            "ispb": "03311443"
        },
        {
            "name": "PARMETAL DTVM LTDA",
            "comp": "194",
            "ispb": "20155248"
        },
        {
            "name": "PEFISA S.A. - C.F.I.",
            "comp": "174",
            "ispb": "43180355"
        },
        {
            "name": "PICPAY BANK - BANCO MÚLTIPLO S.A",
            "comp": "079",
            "ispb": "09516419"
        },
        {
            "name": "PICPAY INSTITUIÇÃO DE PAGAMENTO S.A.",
            "comp": "380",
            "ispb": "22896431"
        },
        {
            "name": "PI DTVM S.A.",
            "comp": "315",
            "ispb": "03502968"
        },
        {
            "name": "PLANNER CORRETORA DE VALORES S.A.",
            "comp": "100",
            "ispb": "00806535"
        },
        {
            "name": "PLANNER SOCIEDADE DE CRÉDITO DIRETO S.A.",
            "comp": "410",
            "ispb": "05684234"
        },
        {
            "name": "PLANTAE CFI",
            "comp": "445",
            "ispb": "35551187"
        },
        {
            "name": "POLOCRED SCMEPP LTDA.",
            "comp": "093",
            "ispb": "07945233"
        },
        {
            "name": "PORTOCRED S.A. - CFI",
            "comp": "108",
            "ispb": "01800019"
        },
        {
            "name": "PORTOPAR DTVM LTDA",
            "comp": "306",
            "ispb": "40303299"
        },
        {
            "name": "PORTOSEG S.A. CFI",
            "comp": "468",
            "ispb": "04862600"
        },
        {
            "name": "QI SOCIEDADE DE CRÉDITO DIRETO S.A.",
            "comp": "329",
            "ispb": "32402502"
        },
        {
            "name": "RB INVESTIMENTOS DTVM LTDA.",
            "comp": "283",
            "ispb": "89960090"
        },
        {
            "name": "REALIZE CFI S.A.",
            "comp": "374",
            "ispb": "27351731"
        },
        {
            "name": "RENASCENCA DTVM LTDA",
            "comp": "101",
            "ispb": "62287735"
        },
        {
            "name": "RJI",
            "comp": "506",
            "ispb": "42066258"
        },
        {
            "name": "SAGITUR CORRETORA DE CÂMBIO S.A.",
            "comp": "270",
            "ispb": "61444949"
        },
        {
            "name": "SCOTIABANK BRASIL S.A. BANCO MÚLTIPLO",
            "comp": "751",
            "ispb": "29030467"
        },
        {
            "name": "SENSO CCVM S.A.",
            "comp": "545",
            "ispb": "17352220"
        },
        {
            "name": "SERVICOOP",
            "comp": "190",
            "ispb": "03973814"
        },
        {
            "name": "SIMPAUL",
            "comp": "365",
            "ispb": "68757681"
        },
        {
            "name": "SINGULARE CTVM S.A.",
            "comp": "363",
            "ispb": "62285390"
        },
        {
            "name": "SOCIAL BANK BANCO MÚLTIPLO S/A",
            "comp": "412",
            "ispb": "15173776"
        },
        {
            "name": "SOCINAL S.A. CFI",
            "comp": "425",
            "ispb": "03881423"
        },
        {
            "name": "SOCRED SA - SCMEPP",
            "comp": "183",
            "ispb": "09210106"
        },
        {
            "name": "STARK SOCIEDADE DE CRÉDITO DIRETO S.A.",
            "comp": "462",
            "ispb": "39908427"
        },
        {
            "name": "STATE STREET BR S.A. BCO COMERCIAL",
            "comp": "014",
            "ispb": "09274232"
        },
        {
            "name": "STONE INSTITUIÇÃO DE PAGAMENTO S.A.",
            "comp": "197",
            "ispb": "16501555"
        },
        {
            "name": "SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A.",
            "comp": "404",
            "ispb": "37241230"
        },
        {
            "name": "SUPERDIGITAL I.P. S.A.",
            "comp": "340",
            "ispb": "09554480"
        },
        {
            "name": "SUPERLÓGICA SCD S.A.",
            "comp": "481",
            "ispb": "43599047"
        },
        {
            "name": "TERRA INVESTIMENTOS DTVM",
            "comp": "307",
            "ispb": "03751794"
        },
        {
            "name": "TORO CTVM S.A.",
            "comp": "352",
            "ispb": "29162769"
        },
        {
            "name": "TRAVELEX BANCO DE CÂMBIO S.A.",
            "comp": "095",
            "ispb": "11703662"
        },
        {
            "name": "TREVISO CORRETORA DE CÂMBIO S.A.",
            "comp": "143",
            "ispb": "02992317"
        },
        {
            "name": "TRINUS CAPITAL DTVM",
            "comp": "360",
            "ispb": "02276653"
        },
        {
            "name": "TRINUS SOCIEDADE DE CRÉDITO DIRETO S.A.",
            "comp": "444",
            "ispb": "40654622"
        },
        {
            "name": "TRUSTEE DTVM LTDA.",
            "comp": "438",
            "ispb": "67030395"
        },
        {
            "name": "TULLETT PREBON BRASIL CVC LTDA",
            "comp": "131",
            "ispb": "61747085"
        },
        {
            "name": "UBS BRASIL BANCO DE INVESTIMENTO S.A.",
            "comp": "129",
            "ispb": "18520834"
        },
        {
            "name": "UBS BRASIL CCTVM S.A.",
            "comp": "015",
            "ispb": "02819125"
        },
        {
            "name": "UNAVANTI SOCIEDADE DE CRÉDITO DIRETO S/A",
            "comp": "460",
            "ispb": "42047025"
        },
        {
            "name": "UNIPRIME COOPCENTRAL LTDA.",
            "comp": "099",
            "ispb": "03046391"
        },
        {
            "name": "UNIPRIME DO BRASIL - COOP",
            "comp": "084",
            "ispb": "02398976"
        },
        {
            "name": "UP.P SEP S.A.",
            "comp": "373",
            "ispb": "35977097"
        },
        {
            "name": "UY3 SOCIEDADE DE CRÉDITO DIRETO S/A",
            "comp": "457",
            "ispb": "39587424"
        },
        {
            "name": "VALOR SOCIEDADE DE CRÉDITO DIRETO S.A.",
            "comp": "195",
            "ispb": "07799277"
        },
        {
            "name": "VIA CERTA FINANCIADORA S.A. - CFI",
            "comp": "411",
            "ispb": "05192316"
        },
        {
            "name": "VIP'S CORRETORA DE CÂMBIO LTDA.",
            "comp": "298",
            "ispb": "17772370"
        },
        {
            "name": "VITREO DTVM S.A.",
            "comp": "367",
            "ispb": "34711571"
        },
        {
            "name": "VORTX DTVM LTDA.",
            "comp": "310",
            "ispb": "22610500"
        },
        {
            "name": "WARREN CVMC LTDA",
            "comp": "371",
            "ispb": "92875780"
        },
        {
            "name": "WILL FINANCEIRA S.A.CFI",
            "comp": "280",
            "ispb": "23862762"
        },
        {
            "name": "WORK SOCIEDADE DE CRÉDITO DIRETO S.A.",
            "comp": "414",
            "ispb": "37526080"
        },
        {
            "name": "XP INVESTIMENTOS CCTVM S/A",
            "comp": "102",
            "ispb": "02332886"
        },
        {
            "name": "ZEMA CFI S/A",
            "comp": "359",
            "ispb": "05351887"
        },
        {
            "name": "ZIPDIN SCD S.A.",
            "comp": "418",
            "ispb": "37414009"
        }
    ],

    getLabelByCode(ispb) {
        for (const i in PaymentCompanyDomain.options) {
            if (PaymentCompanyDomain.options[i].ispb === ispb) {
                return this.options[i].name
            }
        }
        return null
    }
}

export default PaymentCompanyDomain