import React from "react";
import { Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { hasRole } from "../../helpers/api_helper";

function MenuItem(props) {
  const handleOnClick = () => {
    if (props.onClick) props.onClick();
  };

  return (
    <React.Fragment>
      {hasRole(props.role) ? (
        <Col
          lg={2}
          xs={6}
          sm={4}
          className="col-auto item-menu-group p-2 justify-content-center text-center"
        >
          <div
            className="justify-content-center text-center"
            onClick={handleOnClick}
          >
            <div className="menu-item-img p-1 bg-light" >
              <img className={props.imgClassName} src={props.img} alt="" />
            </div>
            <h5 className="mt-3">{props.label}</h5>
          </div>
        </Col>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
}

export default withRouter(withTranslation()(MenuItem));
