import React, {useEffect, useState} from "react"
import PropTypes from 'prop-types'
import {
    Row,
    Col,
    BreadcrumbItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem, Modal, Container,
} from "reactstrap"
import {withTranslation} from "react-i18next";

import {createNewSession, getUserSession, post} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import Loader from "./Loader";
import ErrorDialog from "./ErrorDialog";
import StringUtils from "../../common/utils/StringUtils";
import ManageOptinNew from "../../pages/Opt-in/ManageOptinNew";
import SelectChangeSessionOrganizationModal from "./SelectChangeSessionOrganizationModal";

function Breadcrumb(props) {

    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState(getUserSession())
    const [error, setError] = useState(null)
    const [modalChangeCompany, setModalChangeCompany] = useState(false)

    const closeModalChangeCompany = (resultModal) => {
        setModalChangeCompany(false)
    }

    return (
        <Row>
            <Col xs="12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="mb-0 font-size-18">{props.breadcrumbItem}</h4>
                    <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                            <BreadcrumbItem active>
                                <UncontrolledDropdown className="dropdown-user-logged">
                                    <DropdownToggle onClick={()=>{setModalChangeCompany(true)}} >
                                        <strong className="mb-0 font-size-15">
                                            {props.t("Hello")},&nbsp;{StringUtils.capitalizeFirstLetter(user['user']['shortName'].split(' ')[0])}!&nbsp;&nbsp;<small>({user['financialAgent']['name']})&nbsp;&nbsp;</small>
                                        </strong>

                                        <small>Trocar</small>&nbsp;<i className="mdi mdi-chevron-right"></i>
                                    </DropdownToggle>
                                </UncontrolledDropdown>
                            </BreadcrumbItem>
                        </ol>
                    </div>
                </div>
            </Col>

            {
                error ? (
                    <ErrorDialog
                        title={error['title']}
                        onClose={() => {
                            setError(null)
                        }}
                    >
                        <p>{error['detail']} </p>
                    </ErrorDialog>
                ) : ("")
            }

            {
                loading ?
                    <Loader loading={loading}/>
                    :
                    (" ")
            }

            {
                modalChangeCompany ?
                    (
                        <SelectChangeSessionOrganizationModal
                            isOpen={modalChangeCompany}
                            callback={closeModalChangeCompany}
                        />
                    ) : ("")
            }

        </Row>
    )
}

Breadcrumb.propTypes = {
    breadcrumbItem: PropTypes.string,
    title: PropTypes.string
}

export default withTranslation()(Breadcrumb)
