import React, {Component} from "react"
import ReactApexChart from "react-apexcharts"
import ChartLocale from "../../domains/ChartLocale"
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import $filter from "../../common/utils/$filter";

const SettlementsAnalyticsChart = props => {

    const liqEfetiva = []
    const liqPrevista = []
    const saldoDescoberto = []
    const labels = []

    for (const i in props.settlements) {
        const set = props.settlements[i]
        liqEfetiva.push(
            set['valueAppliedEffect']
        )
        liqPrevista.push(
            set['amountCharged']
        )
        saldoDescoberto.push(
            set['unconstitutedValue']
        )
        labels.push(
            set['settlementDate']
        )
    }

    var series = [
        {
            name: 'Valor a receber constituído',
            type: 'column',
            data: liqEfetiva
        },
        {
            name: 'Saldo não constituído',
            type: 'column',
            data: saldoDescoberto
        },
        {
            name: 'Valor esperado',
            type: 'area',
            data: liqPrevista
        },
    ];
    var options = {
        chart: {
            height: 338,
            type: 'line',
            stacked: !1,
            offsetY: -5,
            toolbar: {
                show: !1
            },
            defaultLocale: ChartLocale.pt.name,
            locales: [
                ChartLocale.pt
            ],
        },
        stroke: {
            width: 1,
            curve: 'smooth'
        },
        plotOptions: {
            bar: {
                columnWidth: '50%',
                borderRadius: 8
            }
        },
        colors: ['rgb(33, 203, 201)', 'rgb(245,110,80)', '#dfe2e6', '#f1b44c'],
        fill: {
            opacity: [0.85, 1, 0.25, 1],
            gradient: {
                inverseColors: !1,
                shade: 'light',
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100]
            }
        },
        labels: labels,
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0
        },

        xaxis: {
            type: 'datetime'
        },
        yaxis: {
            title: {
                text: 'Valores',
            },
            labels: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return $filter(y, 'MONEY')
                    }
                    return y;
                }
            }
        },
        tooltip: {
            shared: !0,
            intersect: !1,
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return $filter(y, 'MONEY')
                    }
                    return y;
                }
            }
        },
        grid: {
            borderColor: '#f1f1f1',
            padding: {
                bottom: 15
            }
        }
    }
    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="bar"
                height="250"
                dir="ltr"
                className="apex-charts"
            />
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(SettlementsAnalyticsChart))