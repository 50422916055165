import React from 'react';
import {Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';


import {withTranslation} from "react-i18next";
import $filter from "../../common/utils/$filter";
import {Link, useHistory} from "react-router-dom";

function NextSettlements(props) {
    const settlements = props.settlements || [];

    const history = useHistory()

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="float-end">
                        <UncontrolledDropdown>
                            <DropdownToggle className="text-reset" tag="a" id="dropdownMenuButton4">
                                <span className="text-muted">Relevância:</span> <span className="fw-semibold">Todos<i
                                className="mdi mdi-chevron-down ms-1"></i></span>
                            </DropdownToggle>

                            <DropdownMenu className="dropdown-menu-end" aria-labelledby="dropdownMenuButton4">
                                <DropdownItem>Maior atraso</DropdownItem>
                                <DropdownItem>Maior valor</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>

                    <h4 className="card-title mb-4">Liquidações previstas</h4>

                    <table className="table align-middle table-striped table-nowrap mb-0">
                        <tbody>

                        {settlements.map((set, key) => (
                            <tr key={key}>
                                <td><small>Previsão</small><br/>{$filter(set.startDate, 'date')}</td>
                                <td>
                                    {set.nameAcquirer} <br/>
                                    <small>{set.modlity}</small>
                                </td>
                                <td>
                                    <small>Valor esperado</small> <br/>
                                    {$filter(set.amountCharged, 'money')}
                                </td>
                                <td>
                                    <small>Valor a receber</small> <br/>
                                    {
                                        set.valueAppliedEffect < set.amountCharged ? (
                                            <span className="text-danger fw-semibold">{$filter(set.valueAppliedEffect, 'money')}</span>
                                        ) : (
                                        <span className="fw-semibold">{$filter(set.valueAppliedEffect, 'money')}</span>
                                        )
                                    }

                                </td>
                                <td className="text-center">
                                    <i className='fa fa-search me-1'></i>
                                </td>
                            </tr>
                        ))}

                        </tbody>
                    </table>

                    <div className="text-center">
                        <Link to="#!" onClick={()=>{
                            history.push('/liquidations')
                        }}>Ver mais</Link>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default withTranslation()(NextSettlements)


