const PaymentSchemaTypeDomain = {

    options: [
        {id: "DEBITO", value: "Débito"},
        {id: "CREDITO", value: "Crédito"},
    ],

    getValueById(id){
        for (const i in PaymentSchemaTypeDomain.options){
            if (PaymentSchemaTypeDomain.options[i].id === id){
                return this.options[i].value
            }
        }
        return null
    }
}


export default PaymentSchemaTypeDomain