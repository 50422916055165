class InvalidOperation extends Error {
  constructor(title, detail) {
    super();

    this.title = title;
    this.detail = detail;
    this.status = "400";
  }
}

export default InvalidOperation;
