class LiquidationStatusDomain {

    OPENED = {value: 'OPENED', name: 'Em Aberto'};
    OVERDUE = {value: 'OVERDUE', name: 'Em Atraso'};
    PARTIAL_PAID = {value: 'PARTIAL_PAID', name: 'Pago Parcial'};
    TOTAL_PAID = {value: 'TOTAL_PAID', name: 'Pago Total'};

    static getNameFromValue(value) {
        const obj = new LiquidationStatusDomain()

        for (const fieldName in obj) {
            if (obj.hasOwnProperty(fieldName)) {
                if (value === obj[fieldName]['value']) {
                    return obj[fieldName]['name'];
                }
            }
        }
        return null;
    }

    static getClassFromValue(value) {
        if (value === 'OPENED') return "badge rounded-pill bg-info bg-sof badge-md"
        if (value === 'OVERDUE') return "badge rounded-pill bg-danger bg-soft badge-md text-danger"
        if (value === 'PARTIAL_PAID') return "badge rounded-pill bg-warning bg-soft text-warning badge-md"
        if (value === 'TOTAL_PAID') return "badge rounded-pill bg-success bg-soft text-success badge-md"

        return ""
    }

    static options = [
        {value: 'OPENED', label: 'Em Aberto'},
        {value: 'OVERDUE', label: 'Em Atraso'},
        {value: 'PARTIAL_PAID', label: 'Pago Parcial'},
        {value: 'TOTAL_PAID', label: 'Pago Total'},
    ]
}

export default LiquidationStatusDomain