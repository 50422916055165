import React from 'react';
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import { hasRole} from "../../helpers/api_helper";

function SidebarItemMenu(props) {

    const validRole = () => {
        if (props.role)
            return hasRole(props.role)
        return true
    }

    return (
        <React.Fragment>
            {
                validRole() ?
                    <li>
                        <Link to={props.to}>
                            <i className={props.icon} style={props.style}></i>
                            <span>{props.label}</span>
                        </Link>
                    </li>:
                    ''
            }
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(SidebarItemMenu))
