import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
  Badge,
} from "reactstrap";

import $filter from "../../../common/utils/$filter";

function TypesOfEstablishment({ tipoEstabelecimento }) {
  const types = {
    1: "Matriz",
    2: "Filial",
  };

  return <span>{types[tipoEstabelecimento]}</span>;
}

function PorteEmpresa({ porte }) {
  const portes = {
    0: "Não informado",
    1: "Micro Empresa",
    3: "Empresa de Pequeno Porte",
    5: "Demais",
  };

  return <span>{portes[parseInt(porte)]}</span>;
}

// Tipos de situação cadastral
// 1	Nula
// 2	Ativa
// 3	Suspensa
// 4	Inapta
// 5	Ativa Não Regular
// 8	Baixada
function LegalStatusSafes({ situacaoCadastral }) {
  const statuses = {
    1: "Nula",
    2: "Ativa",
    3: "Suspensa",
    4: "Inapta",
    8: "Baixada",
  };

  const colors = {
    1: "success",
    2: "success",
    3: "warning",
    4: "danger",
    8: "danger",
  };

  return (
    <td>
      <strong>Tipos de situação cadastral: </strong>
      <Badge
        className={`badge bg-${
          colors[situacaoCadastral.codigo]
        } rounded-pill font-size-12`}
        color={colors[situacaoCadastral.codigo]}
        pill={true}
      >
        {statuses[situacaoCadastral.codigo]}
      </Badge>
      <br />
      {situacaoCadastral?.data && (
        <strong>Data da situação cadastral: {situacaoCadastral?.data}</strong>
      )}
    </td>
  );
}

function EntityCard({ entity }) {
  const openGoogleMaps = () => {
    const address = `${entity?.endereco.tipoLogradouro} ${entity?.endereco.logradouro}, ${entity?.endereco.numero}, ${entity?.endereco.bairro}, ${entity?.endereco.municipio.descricao} - ${entity?.endereco.uf}, ${entity?.endereco.cep}`;
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`;
    window.open(googleMapsUrl, "_blank");
  };
  return (
    <Card className="mb-3">
      <CardBody>
        <CardTitle tag="h2">Dados detalhados do Cliente final SERPRO</CardTitle>
        <td className="col-md-8">
          <p className="card-title-desc mb-2">Razão Social</p>
        </td>
        <CardTitle tag="h6">{entity?.nomeEmpresarial}</CardTitle>
        <td className="col-md-4">
          <p className="card-title-desc mb-2">Nome Fantasia</p>
        </td>
        <CardSubtitle
          tag="h5"
          title={entity?.nomeFantasia}
          className="mb-2 text-muted"
        >
          {entity?.nomeFantasia}
        </CardSubtitle>
        <CardText>
          <strong>CNPJ:</strong> {entity?.ni}
          <br />
          <strong>Data de Abertura:</strong> {entity?.dataAbertura}
        </CardText>
        <CardText>
          <LegalStatusSafes situacaoCadastral={entity?.situacaoCadastral} />
          <br />
          <strong>CNAE Principal:</strong> {entity?.cnaePrincipal?.descricao}
          <br />
          <strong>CNAE Código:</strong> {entity?.cnaePrincipal?.codigo}
          <br />
          <strong>Natureza Jurídica: </strong>
          {entity?.naturezaJuridica?.descricao} <br />
          <strong>Porte: </strong> <PorteEmpresa porte={entity.porte} />
          <br />
          <strong>Tipo de Estabelecimento: </strong>
          <TypesOfEstablishment
            tipoEstabelecimento={entity.tipoEstabelecimento}
          />
          <br />
        </CardText>
        <CardText>
          <td className="col-md-6">
            <strong>Endereço:</strong>
            <br />
            {`${entity?.endereco?.tipoLogradouro} ${entity?.endereco?.logradouro}, ${entity?.endereco?.numero}, ${entity?.endereco?.bairro}, ${entity?.endereco?.municipio?.descricao} - ${entity?.endereco?.uf}, ${entity?.endereco?.cep}`}
          </td>
          <td className="col-md-4">
            <br />
            <Button color="primary" onClick={openGoogleMaps} className="ml-2">
              Abrir no Google Maps
            </Button>
          </td>
        </CardText>
        {entity?.cnaeSecundarias && (
          <CardText>
            <strong>CNAEs Secundários:</strong>
            <table className="table table-striped mt-2">
              <thead>
                <tr>
                  <th scope="col">Código</th>
                  <th scope="col">Descrição</th>
                </tr>
              </thead>
              <tbody>
                {entity?.cnaeSecundarias?.map((cnae, index) => (
                  <tr key={index}>
                    <td>{cnae.codigo}</td>
                    <td>{cnae.descricao}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardText>
        )}

        <CardText>
          <table className="table table-striped mt-2">
            <thead>
              <tr>
                <th scope="col">Telefones</th>
              </tr>
            </thead>
            <tbody>
              {entity?.telefones?.map((telefone, index) => (
                <tr key={index}>
                  <td>
                    ({telefone.ddd}) {telefone.numero}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </CardText>
        <CardText>
          <strong>Email: </strong> {entity?.correioEletronico}
        </CardText>
        <CardText>
          <strong>Capital Social: </strong>
          {$filter(entity?.capitalSocial, "MONEY")}
          {entity?.situacaoEspecial && (
            <strong>Situação Especial: {entity?.situacaoEspecial}</strong>
          )}
          {entity?.dataSituacaoEspecial && (
            <strong>
              Data Situação Especial: {entity?.dataSituacaoEspecial}
            </strong>
          )}
        </CardText>
      </CardBody>
    </Card>
  );
}

function SerproLegalIdDetails(props) {
  return (
    <div className="container">
      {props?.entity?.receivingEndUserDetails?.ni?.length === 14 ? (
        <EntityCard entity={props.entity.receivingEndUserDetails} />
      ) : (
        <Card>
          <CardBody>
            <CardTitle tag="h2">
              {$filter(props?.entity?.receivingEndUser?.legalId, "CPF_CNPJ")}
            </CardTitle>
            <CardText>{props?.entity?.receivingEndUser?.name}</CardText>
            <CardText>{props?.entity?.receivingEndUser?.legalId}</CardText>
            <CardText>{props?.entity?.receivingEndUser?.tradeName}</CardText>
          </CardBody>
        </Card>
      )}
    </div>
  );
}

export default withRouter(withTranslation()(SerproLegalIdDetails));
