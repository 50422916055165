import React from 'react'

import {withTranslation} from "react-i18next"
import InputText from "./InputText";

const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

function EmailInput (props) {

    const validation = (value) => {
        return value && value.toString().match(validRegex)
    }

    const onChangeValidation = (isValid) => {
        if (props.onChangeValidation)
            props.onChangeValidation(props.id, isValid)
    }

    return (
        <InputText
            label={props.label}
            id={props.id}
            autoComplete={props.autoComplete}
            onChange={props.onChange}
            required={props.required}
            onChangeValidation={onChangeValidation}
            validation={validation}
        />
    )
}

export default withTranslation()(EmailInput)