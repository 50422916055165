class SignStatusDomain {

    NAO_ASSINADO = {value: null, name: 'Solicitar Assinatura'};
    PENDENTE = {value: 'P', name: 'Aguardando Assinatura'};
    ASSINADO = {value: 'S', name: 'Assinado'};


    static getNameFromValue(value) {
        const obj = new SignStatusDomain()

        for (const fieldName in obj) {
            if (obj.hasOwnProperty(fieldName)) {
                if (value === obj[fieldName]['value']) {
                    return obj[fieldName]['name'];
                }
            }
        }
        return null;
    }

    static getClassFromValue(value) {
        if (value === 'P') return "badge rounded-pill bg-warning bg-soft text-warning badge-md"
        if (value === null) return "badge rounded-pill bg-danger bg-soft badge-md text-danger"
        if (value === 'S') return "badge rounded-pill bg-success bg-soft text-success badge-md"

        return ""
    }
}

export default SignStatusDomain