class FormUtils {
  static isValid = (form) => {
    let isValid = true;
    let invalidField = null;
    const fields = Object.keys(form);
    fields.forEach((field, index) => {
      if (form[field].isValid === false) {
        isValid = false;
        invalidField = field;
      }
    });
    if (!isValid) document.getElementById(invalidField).focus();

    return isValid;
  };
}

export default FormUtils;
