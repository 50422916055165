import React from 'react';

const Loader = props => {

    return (
        <div className={props.loading === true ? "is-loading" : "is-loading"}>
            <div id="preloader">
                <div id="status">
                    <div
                        className="spinner-border text-primary m-1 z-1000"
                        style={{zIndex: 1000}}
                        role="status"
                    >
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Loader;