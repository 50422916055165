const ProductTypeDomain = {

  options: [
    {label: "Antecipação de Recebíveis", value: "AR", allowDiscountRate: true, allowFine: false, allowFee: false},
    {label: "Cobrança", value: "CB", allowDiscountRate: false, allowFine: true, allowFee: true},
    {label: "Capital de Giro", value: "CG", allowDiscountRate: false, allowFine: false, allowFee: true, allowPP: true},
  ],

  getInstance(value) {
    for (const i in ProductTypeDomain.options) {
      if (ProductTypeDomain.options[i].value === value) {
        return this.options[i]
      }
    }
    return null
  },

  getLabelByValue(value) {
    for (const i in ProductTypeDomain.options) {
      if (ProductTypeDomain.options[i].value === value) {
        return this.options[i].label
      }
    }
    return null
  },

  getOptByValue(value) {
    for (const i in ProductTypeDomain.options) {
      if (ProductTypeDomain.options[i].value === value) {
        return this.options[i]
      }
    }
    return null
  }
}


export default ProductTypeDomain