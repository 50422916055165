import React, { useState} from "react"
import {withTranslation} from "react-i18next";

import {Row, Col, Nav, NavItem, NavLink, CardBody, TabContent, TabPane,} from "reactstrap"
import { withRouter,} from "react-router-dom"
import LegalIdInput from "../../components/Common/LegalIdInput";
import InputText from "../../components/Common/InputText";
import PhoneInput from "../../components/Common/PhoneInput";
import classnames from "classnames";
import {AvField, AvForm} from "availity-reactstrap-validation";
import EmailInput from "../../components/Common/EmailInput";

function CompleteFieldsToStartCheckout (props) {

    const [currentStatusTab, setCurrentStatusTab] = useState("New")


    const [statusTabs] = useState([
        {id: "New", title: "Comprar sem cadastro"}
    ])

    const handleBuyerField = (name, value) => {
        props.handleBuyerField(name, value)
    }

    const handleBuyerFormField = (fieldId, isValid) => {
        props.handleBuyerFormField(fieldId, isValid)
    }

    const isValidString = (v) => {
        return v && v.toString().length > 0;
    }

    const handleValidSubmit = () => {

    }

    return (
        <React.Fragment>
            <div className={props.isMobile ? "d-lg-block": "p-lg-5 p-4 d-lg-block"}>
                <Row>
                    <h3 className="checkout-buyer-title">
                        {props.t("Complete the fields")}
                    </h3>
                    <br/>
                    <h3 className="checkout-buyer-title">
                        {props.t("to start Checkout")}
                    </h3>
                </Row>

                <TabContent activeTab={currentStatusTab}>
                    <TabPane tabId="Login">
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={handleValidSubmit}
                        >
                            <Row className="mt-5 mb-4">
                                <div>
                                    <AvField
                                        name="userName"
                                        label="Email"
                                        className="form-control"
                                        placeholder={props.t("Enter your email here")}
                                        type="email"
                                        required
                                        errorMessage={props.t("Invalid email")}
                                        style={{height: 48}}
                                    />
                                </div>
                            </Row>

                            <Row className="mb-4">
                                <div>
                                    <AvField
                                        style={{height: 48}}
                                        name="secret"
                                        label={props.t("Secret")}
                                        type="password"
                                        errorMessage={props.t("Required field")}
                                        required
                                        placeholder={props.t("Enter your password here")}
                                    />
                                </div>
                            </Row>

                        </AvForm>
                    </TabPane>

                    <TabPane tabId="New">
                        <Row className="mt-5 mb-4">
                            <Col lg={12} sm={12}>
                                <LegalIdInput
                                    id="legalIdBuyer"
                                    required={true}
                                    autoComplete="off"
                                    onChangeValidation={handleBuyerFormField}
                                    onChange={(event) => {
                                        handleBuyerField(
                                            'legalId', event.target.value
                                        )
                                    }}
                                    label={props.t("Legal Id Buyer")}/>
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Col lg={12} sm={12}>
                                <PhoneInput
                                    id="phoneBuyer"
                                    required={true}
                                    autoComplete="off"
                                    onChangeValidation={handleBuyerFormField}
                                    onChange={(event) => {
                                        handleBuyerField(
                                            'phone', event.target.value
                                        )
                                    }}
                                    label={props.t("Phone Buyer")}/>
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Col lg={12} sm={12}>
                                <EmailInput
                                    id="emailBuyer"
                                    required={true}
                                    autoComplete="off"
                                    onChangeValidation={handleBuyerFormField}
                                    onChange={(event) => {
                                        handleBuyerField(
                                            'email', event.target.value
                                        )
                                    }}
                                    label={props.t("E-mail")}/>
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Col lg={12} sm={12}>
                                <LegalIdInput
                                    id="personLegalId"
                                    required={true}
                                    autoComplete="off"
                                    onChangeValidation={handleBuyerFormField}
                                    onChange={(event) => {
                                        handleBuyerField(
                                            'personLegalId', event.target.value
                                        )
                                    }}
                                    label={props.t("Responsable Legal Id")}/>
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Col lg={12} sm={12}>
                                <InputText
                                    id="personName"
                                    required={true}
                                    autoComplete="off"
                                    onChange={(event) => {
                                        handleBuyerFormField('personName', isValidString(event.target.value))
                                        handleBuyerField(
                                            'personName', event.target.value
                                        )
                                    }}
                                    label={props.t("Responsable Name")}/>
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Col lg={12} sm={12}>
                                <InputText
                                    id="personCompanyPlacement"
                                    required={true}
                                    autoComplete="off"
                                    onChange={(event) => {
                                        handleBuyerFormField('personCompanyPlacement', isValidString(event.target.value))
                                        handleBuyerField(
                                            'personCompanyPlacement', event.target.value
                                        )
                                    }}
                                    label={props.t("Responsable Company Placement")}/>
                            </Col>
                        </Row>

                    </TabPane>
                </TabContent>

                <Row className="mb-4">
                    <div>
                        <div className="form-check form-check-active mb-0">
                            <input className="form-check-input"
                                   type="checkbox"
                                   defaultChecked={true}
                                   onChange={(event)=>{
                                       handleBuyerFormField(
                                           "checkAcceptedTerms",
                                           event.target.checked
                                       )
                                   }}
                                   name="requestSigning"
                                   id="requestSigning"
                            />
                            <label htmlFor="requestSigning"
                                   className="form-check-label mb-0 ms-1">
                                <p className="font-size-12 mb-0">
                                    Li e aceito todos os termos e condições
                                </p>
                            </label>
                        </div>
                    </div>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(CompleteFieldsToStartCheckout))

