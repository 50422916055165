import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { Col, Container, Row } from "reactstrap";

import { withRouter, useHistory, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import contractImg from "../../assets/images/features/contract-menu.png";
import dashboardImg from "../../assets/images/features/dashboard.png";
import receivablesImg from "../../assets/images/features/receivables.png";
import optinImg from "../../assets/images/features/optin.png";
import newLogo from "../../assets/images/logo/agreement.png";
import newLogo2 from "../../assets/images/logo/recipt.png";
import newLogo3 from "../../assets/images/logo/invoice.png";
import newLogo4 from "../../assets/images/logo/commitment.png";
import newLogo5 from "../../assets/images/logo/gravame.png";
import usersIms from "../../assets/images/features/users.png";
import organizationsImg from "../../assets/images/features/organizations.png";
import signersImg from "../../assets/images/features/signers.png";
import segmentsImg from "../../assets/images/features/segments.png";
import customersImg from "../../assets/images/features/customers.png";
import orderImg from "../../assets/images/features/order.png";
import globalParamImg from "../../assets/images/features/params.png";
import liquidationsImg from "../../assets/images/features/liquidations.png";

import {
  getUserSession,
  getSessionUserProfile,
} from "../../helpers/api_helper";
import OrganizationSigners from "../Organization/OrganizationSigners";
import StringUtils from "../../common/utils/StringUtils";
import SelectChangeSessionOrganizationModal from "../../components/Common/SelectChangeSessionOrganizationModal";
import MenuItem from "./MenuItem";
import { AppFields } from "../../common/data/AppFields";

function HomeMenu(props) {
  const history = useHistory();
  const [user] = useState(getUserSession());
  const [userProfile] = useState(getSessionUserProfile());
  const [modalChangeCompany, setModalChangeCompany] = useState(false);

  const closeModalChangeCompany = (resultModal) => {
    setModalChangeCompany(false);
  };

  const [signerModal, setSignerModal] = useState({
    isOpen: false,
  });

  const openSignersModal = () => {
    setSignerModal({
      ...signerModal,
      isOpen: true,
    });
  };

  return (
    <React.Fragment>
      <div className="page-content" id="home-menu">
        <MetaTags>
          <title>{props.t("COMPANY | HOME MENU")}</title>
        </MetaTags>
        <Container fluid={true}>
          <Row className="mt-5 text-start offset-sm-1">
            <Col lg={1} md={4} sm={3} xs={12}>
              <div className="avatar-md mx-auto">
                <span
                  className="avatar-title rounded-circle bg-light font-size-30"
                  style={{ color: "black" }}
                >
                  <i className="fa fa-user"></i>
                </span>
              </div>
            </Col>
            <Col
              lg={8}
              md={7}
              sm={8}
              xs={12}
              onClick={() => {
                setModalChangeCompany(true);
              }}
              style={{ cursor: "pointer" }}
              className="ps-5"
            >
              <h2>
                {props.t("Hello")},&nbsp;
                {StringUtils.capitalizeFirstLetter(
                  user["user"]["shortName"].split(" ")[0]
                )}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Link to="#!" className="font-size-18 fw-medium">
                  <span>Trocar de conta</span>&nbsp;&nbsp;
                  <i className="mdi mdi-chevron-right"></i>
                </Link>
              </h2>

              <h5>{user["financialAgent"]["name"]}</h5>
            </Col>
          </Row>

          <Row className="offset-lg-1">
            <Col xl={11} lg={11} md={12} sm={12} xs={12}>
              <Row md={10} xs={12} sm={12} xl={11}>
                <MenuItem
                  label={props.t("Dashboard")}
                  role="portfolio.read"
                  img={dashboardImg}
                  onClick={() => {
                    history.push("/dashboard");
                  }}
                />
                <MenuItem
                  label={props.t("Contract")}
                  role="contract.read"
                  img={contractImg}
                  onClick={() => {
                    history.push("/contracts/manage-contracts");
                  }}
                />

                {userProfile === "SELLER" ? (
                  <MenuItem
                    label={props.t("Customers")}
                    role="receivable-schedule.read"
                    img={customersImg}
                    onClick={() => {
                      history.push("/customers");
                    }}
                  />
                ) : (
                  <MenuItem
                    label={props.t("Schedule")}
                    role="receivable-schedule.read"
                    img={receivablesImg}
                    onClick={() => {
                      history.push("/receivables");
                    }}
                  />
                )}

                <MenuItem
                  label={props.t("Opt-In")}
                  role="optin.read"
                  img={optinImg}
                  onClick={() => {
                    history.push("/opt-in");
                  }}
                />

                <MenuItem
                  label={props.t("Billing")}
                  role="order.read"
                  img={orderImg}
                  onClick={() => {
                    history.push("/billing");
                  }}
                />

                {userProfile === "FINANCIAL" ? (
                  <MenuItem
                    label={props.t("Liquidations")}
                    role="contract.read"
                    img={liquidationsImg}
                    onClick={() => {
                      history.push("/liquidations");
                    }}
                  />
                ) : (
                  <></>
                )}
              </Row>
              <Row md={10} xs={12} sm={12} xl={11}>
                <MenuItem
                  label={props.t("Users")}
                  role="user.read"
                  img={usersIms}
                  onClick={() => {
                    history.push("/users");
                  }}
                />
                <MenuItem
                  label={props.t("Organizations")}
                  role="organization.read"
                  img={organizationsImg}
                  onClick={() => {
                    history.push("/organizations");
                  }}
                />
                <MenuItem
                  label={props.t("Formalization")}
                  role="formalization.read"
                  img={signersImg}
                  onClick={() => {
                    openSignersModal();
                  }}
                />
                <MenuItem
                  label={props.t("Segment")}
                  role="segment.read"
                  img={segmentsImg}
                  onClick={() => {
                    history.push("/segments");
                  }}
                />
                <MenuItem
                  label={props.t("Configs")}
                  role="global-param.read"
                  img={globalParamImg}
                  onClick={() => {
                    history.push("/global-params");
                  }}
                />
              </Row>
              <Row md={10} xs={12} sm={12} xl={11}>
                <MenuItem
                  label={"Adm Optin"}
                  role="global-param.read"
                  imgClassName="p-2 background-light"
                  img={newLogo}
                  onClick={() => {
                    history.push("/admin/opt-in");
                  }}
                />

                <MenuItem
                  label={"Adm Cobranças"}
                  role="global-param.read"
                  imgClassName="p-2 background-light"
                  img={newLogo2}
                  onClick={() => {
                    history.push("/admin/order");
                  }}
                />

                <MenuItem
                  label={"Adm Boletos"}
                  role="global-param.read"
                  imgClassName="p-2 background-light"
                  img={newLogo3}
                  onClick={() => {
                    history.push("/admin/billing");
                  }}
                />

                <MenuItem
                  label={"Adm Gravame"}
                  role="global-param.read"
                  imgClassName="p-2 background-light"
                  img={newLogo4}
                  onClick={() => {
                    history.push("/admin/gravame");
                  }}
                />

                <MenuItem
                  label={"Novo Gravame"}
                  role="global-param.read"
                  imgClassName="p-2 background-light"
                  img={newLogo5}
                  onClick={() => {
                    history.push("/admin/gravame/new");
                  }}
                />
              </Row>
            </Col>
          </Row>

          <Row className="d-none d-lg-flex ms-5 mt-5">
            <Col lg={11} xl={8} xs={12} md={12} sm={12}>
              <div>
                <hr />
                <p> {AppFields.copyright}</p>
              </div>
            </Col>
          </Row>
          {signerModal.isOpen ? (
            <OrganizationSigners
              isOpen={signerModal.isOpen}
              callback={() =>
                setSignerModal({
                  ...signerModal,
                  isOpen: false,
                })
              }
            />
          ) : (
            ""
          )}
          {modalChangeCompany ? (
            <SelectChangeSessionOrganizationModal
              isOpen={modalChangeCompany}
              callback={closeModalChangeCompany}
            />
          ) : (
            ""
          )}
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withRouter(withTranslation()(HomeMenu));
