import PropTypes from 'prop-types'
import React, {useEffect, useState} from "react"
import MetaTags from 'react-meta-tags'
import jwt from 'jsonwebtoken'
import {withTranslation} from "react-i18next";

import {Row, Col, Alert, Label, Form} from "reactstrap"
import {useHistory, withRouter, Link} from "react-router-dom"

// Redux
import {connect} from "react-redux"

// availity-reactstrap-validation
import {AvForm, AvField} from "availity-reactstrap-validation"

// actions
import Loader from "../../components/Common/Loader";
import {post, setAccessToken} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import ErrorDialog from "../../components/Common/ErrorDialog";
import AuthCode from "react-auth-code-input";

const PasswordAltered = (props) => {
    const history = useHistory()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    document.body.classList.add("login_page")

    return (
        <React.Fragment>
            {
                error ? (
                    <ErrorDialog
                        title={error['title']}
                        code={error['status']}
                        onClose={() => {
                            setError(null)
                        }}
                    >
                        <p>{error['detail']} </p>
                    </ErrorDialog>
                ) : ("")
            }

            <div className="p-2">
                <div className="text-center">

                    <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                            <i className="bx bx-mail-send h1 mb-0 text-primary"></i>
                        </div>
                    </div>

                    <div className="p-2 mt-4">
                        <h3 className="fw-semibold mb-4">Sucesso !</h3>
                        <p className="text-muted"><strong>Pronto!</strong> Sua senha foi alterada. Tudo pronto para
                            acessar a
                            Cash2Pay.</p>
                        <div className="mt-4">
                            <Link to="/login" className="btn btn-primary">Ir para login</Link>
                        </div>
                    </div>
                </div>
            </div>

            {
                loading ?
                    <Loader loading={loading}/>
                    :
                    (" ")
            }

        </React.Fragment>
    )
}


export default withRouter(withTranslation()(PasswordAltered))

