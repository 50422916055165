const DistributionTypeDomain = {

    EMPILHAMENTO: "1",

    options: [
        {label: "Empilhamento", value: "1"},
        // {label: "Empilhamento com Fumaça", value: "2"},
        // {label: "Fumaça Valor Fixo", value: "3"},
        {label: "Pro-rata", value: "4"},
        // {label: "Pro-rata com Fumaça", value: "5"},
        // {label: "Pro-rata proporcional", value: "6"},
        // {label: "Empilhamento Percentual", value: "7"},
        // {label: "Empilhamento Percentual com Fumaça", value: "8"},
        // {label: "Fumaça Percentual", value: "9"},
        // {label: "Pro-Rata Percentual", value: "10"},
        // {label: "Pro-Rata Percentual com Fumaça", value: "11"},
        // {label: "Percentual limitado ao VPG", value: "12"},
    ],

    getLabelByValue(value){
        for (const i in DistributionTypeDomain.options){
            if (DistributionTypeDomain.options[i].value === value){
                return DistributionTypeDomain.options[i].label
            }
        }
        return null
    }
}


export default DistributionTypeDomain