import React, {useEffect, useState} from 'react'
import {Card, CardBody, Col, Modal, Row, Table} from "reactstrap"

import {withTranslation} from "react-i18next"
import ErrorDialog from "../../components/Common/ErrorDialog";
import Loader from "../../components/Common/Loader";
import SelectUserModal from "../../components/Common/SelectUserModal";
import {get, put} from "../../helpers/api_helper";
import {GET_SIGNERS} from "../../helpers/url_helper";
import * as url from "../../helpers/url_helper";
import {Scrollbars} from "react-custom-scrollbars-2";


function OrganizationSigners(props) {

    const [state, setState] = useState({
        isOpen: props.isOpen || false,
        init: false
    })

    const [innerHeight] = useState(window.innerHeight)

    const [optinSigners, setOptinSigners] = useState([])
    const [contractSigners, setContractSigners] = useState([])
    const [orderSigners, setOrderSigners] = useState([])

    const [loading, setLoading] = useState(false)

    const [showModalOptinParticipant, setShowModalOptinParticipant] = useState(false)
    const [showModalContractParticipant, setShowModalContractParticipant] = useState(false)
    const [showModalOrderParticipant, setShowModalOrderParticipant] = useState(false)

    const [error, setError] = useState(null)

    useEffect(() => {
        loadInitialData().then();
    }, [state.init])

    const loadInitialData = async () => {
        setState({...state, loading: true});
        try {
            const signersResult = await get(GET_SIGNERS, null, null, setLoading)

            const optin = !signersResult.signers
            || !signersResult.signers.optin
            || !signersResult.signers.optin.length ? [] : signersResult.signers.optin

            const contract = !signersResult.signers
            || !signersResult.signers.contract
            || !signersResult.signers.contract.length ? [] : signersResult.signers.contract

            const order = !signersResult.signers
            || !signersResult.signers.order
            || !signersResult.signers.order.length ? [] : signersResult.signers.order

            setOptinSigners(optin)
            setContractSigners(contract)
            setOrderSigners(order)

        } catch (e) {
            if (e.status !== '200' && e.status !== '403')
                setError(e)
        }
    }

    const toggle = () => {
        setState({...state, isOpen: false})
    }

    const afterClosed = () => {
        if (props.callback) {
            props.callback({isOpen: false, entity: null, event: 'CLOSE'})
        }
    }

    const successClose = () => {
        if (!props.callback) {
            setState({
                ...state,
                isOpen: false
            })
        }

        props.callback({
            isOpen: false,
            event: 'CHANGED'
        })
    }

    const addContractParticipant = () => {
        setShowModalContractParticipant(true)
    }

    const onCloseContractParticipant = (result) => {
        if (result && result.entity) {
            if (contractSigners.filter(s => s.email === result.entity.userName).length === 0) {
                contractSigners.push({
                    userName: result.entity.userName,
                    name: result.entity.name,
                    legalId: result.entity.legalId,
                    email: result.entity.userName
                })

                setContractSigners(contractSigners)
            }
        }

        setShowModalContractParticipant(false)
    }

    const removeContractSigner = (signer) => {
        setContractSigners(
            contractSigners.filter(s => s.email !== signer.email)
        )
    }

    const addOptinParticipant = () => {
        setShowModalOptinParticipant(true)
    }

    const onCloseOptinParticipant = (result) => {
        if (result && result.entity) {
            if (optinSigners.filter(s => s.email === result.entity.userName).length === 0) {
                optinSigners.push({
                    userName: result.entity.userName,
                    name: result.entity.name,
                    legalId: result.entity.legalId,
                    email: result.entity.userName
                })

                setOptinSigners(optinSigners)
            }
        }

        setShowModalOptinParticipant(false)
    }

    const removeOptinSigner = (signer) => {
        setOptinSigners(
            optinSigners.filter(s => s.email !== signer.email)
        )
    }

    const addOrderParticipant = () => {
        setShowModalOrderParticipant(true);
    }

    const onCloseOrderParticipant = (result) => {
        if (result && result.entity) {
            if (orderSigners.filter(s => s.email === result.entity.userName).length === 0) {
                orderSigners.push({
                    userName: result.entity.userName,
                    name: result.entity.name,
                    legalId: result.entity.legalId,
                    email: result.entity.userName
                })

                setOrderSigners(orderSigners)
            }
        }

        setShowModalOrderParticipant(false)
    }

    const removeOrderSigner = (signer) => {
        setOrderSigners(
            orderSigners.filter(s => s.email !== signer.email)
        )
    }

    const save = async () => {
        if (loading) return

        try {
            let response = await put(url.PUT_SIGNERS, {
                    contract: contractSigners,
                    optin: optinSigners,
                    order: orderSigners,
                }, null, setLoading
            )

            successClose()
        } catch (e) {
            if (e['status'] !== '200' && e['status'] !== '403')
                setError(e)
        }
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={state.isOpen}
                toggle={() => {
                    toggle()
                }}

                onClosed={() => {
                    afterClosed()
                }}
                modalClassName="drawer right-align"
                contentClassName="modal-content-scroll"
            >

                <Scrollbars
                    style={{ height: innerHeight-80}}>

                <div className="modal-header">
                    <h5 className="modal-title mt-0">
                        {props.t("Configure singning/formalization")}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            toggle()
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>

                </div>
                <div className="modal-body">
                    <p className="card-title-desc mt-3 mb-0">{props.t("Contract signing participants")}</p>
                    <hr/>
                    <Row>
                        <Col lg={12} className="mb-3">
                            <button
                                className="btn mt-3 w-100 btn-select"
                                onClick={addContractParticipant}
                            >
                                {props.t("Add participant")}
                            </button>
                        </Col>
                    </Row>
                    {!contractSigners || !contractSigners.length ? '' :
                        (<Row>
                            <Col lg={12} className="mb-3">
                                <Card>
                                    <CardBody>
                                        <Table className="table table-striped mb-0">
                                            <tbody>
                                            {(contractSigners || []).map((s, index) => (
                                                <tr key={s.legalId}
                                                >
                                                    <td>
                                                        <p className="mb-0">
                                                            {s.name}
                                                        </p>
                                                        <p className="mb-0">
                                                            <small>{s.email}</small>
                                                        </p>
                                                    </td>
                                                    <td className="col-md-1 text-center" style={{cursor: "pointer"}}
                                                        onClick={() => {
                                                            removeContractSigner(s)
                                                        }}>
                                                        <i className="fa fa-trash"></i>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>)
                    }

                    <p className="card-title-desc mt-3 mb-0">{props.t("Opt-in signing participants")}</p>
                    <hr/>
                    <Row>
                        <Col lg={12} className="mb-3">
                            <button
                                className="btn mt-3 w-100 btn-select"
                                onClick={addOptinParticipant}
                            >
                                {props.t("Add participant")}
                            </button>
                        </Col>
                    </Row>
                    {!optinSigners || !optinSigners.length ? '' :
                        (<Row>
                            <Col lg={12} className="mb-3">
                                <Card>
                                    <CardBody>
                                        <Table className="table table-striped mb-0">
                                            <tbody>
                                            {(optinSigners || []).map(s => (
                                                <tr key={s.legalId}
                                                >
                                                    <td>
                                                        <p className="mb-0">
                                                            {s.name}
                                                        </p>
                                                        <p className="mb-0">
                                                            <small>{s.email}</small>
                                                        </p>
                                                    </td>

                                                    <td className="col-md-1 text-center" style={{cursor: "pointer"}}
                                                        onClick={() => {
                                                            removeOptinSigner(s)
                                                        }}>
                                                        <i className="fa fa-trash"></i>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>)
                    }

                    <p className="card-title-desc mt-3 mb-0">{props.t("Billing signing participants")}</p>
                    <hr/>
                    <Row>
                        <Col lg={12} className="mb-3">
                            <button
                                className="btn mt-3 w-100 btn-select"
                                onClick={addOrderParticipant}
                            >
                                {props.t("Add participant")}
                            </button>
                        </Col>
                    </Row>
                    {!orderSigners || !orderSigners.length ? '' :
                        (<Row>
                            <Col lg={12} className="mb-3">
                                <Card>
                                    <CardBody>
                                        <Table className="table table-striped mb-0">
                                            <tbody>
                                            {(orderSigners || []).map(s => (
                                                <tr key={s.legalId}
                                                >
                                                    <td>
                                                        <p className="mb-0">
                                                            {s.name}
                                                        </p>
                                                        <p className="mb-0">
                                                            <small>{s.email}</small>
                                                        </p>
                                                    </td>

                                                    <td className="col-md-1 text-center" style={{cursor: "pointer"}}
                                                        onClick={() => {
                                                            removeOrderSigner(s)
                                                        }}>
                                                        <i className="fa fa-trash"></i>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>)
                    }
                </div>

                </Scrollbars>

                <div className="modal-footer fixed-bottom">
                    <div className="text-end">
                        <button
                            className="btn btn-primary w-sm waves-effect waves-light"
                            onClick={save}
                        >
                            {props.t("Save")}
                        </button>
                    </div>
                </div>
                {
                    error ? (
                        <ErrorDialog
                            title={error['title']}
                            onClose={() => {
                                setError(null)
                            }}
                        >
                            <p>{error['detail']} </p>
                        </ErrorDialog>
                    ) : ("")
                }

                {
                    loading ?
                        <Loader loading={loading}/>
                        :
                        (" ")
                }

                {
                    showModalOptinParticipant ?
                        (
                            <SelectUserModal
                                isOpen={showModalOptinParticipant}
                                callback={onCloseOptinParticipant}
                            />
                        ) : ""
                }

                {
                    showModalContractParticipant ?
                        (
                            <SelectUserModal
                                isOpen={showModalContractParticipant}
                                callback={onCloseContractParticipant}
                            />
                        ) : ""
                }

                {
                    showModalOrderParticipant ?
                        (
                            <SelectUserModal
                                isOpen={showModalOrderParticipant}
                                callback={onCloseOrderParticipant}
                            />
                        ) : ""
                }

            </Modal>
        </React.Fragment>
    )

}

export default withTranslation()(OrganizationSigners)