import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Card, CardBody, CardTitle, CardText, Row, Col } from "reactstrap";

import $filter from "../../../common/utils/$filter";

function CardReceivablesDetails({ receivable }) {
  return (
    <Card className="mb-3">
      <CardBody>
        <CardTitle tag="h2">Detalhes da Agenda de Recebiveis</CardTitle>
        <Row>
          <Col md="6">
            <CardText>
              <strong>Saldo Disponível:</strong>{" "}
              {$filter(receivable?.balanceAvailable, "MONEY")}
            </CardText>
          </Col>
          <Col md="6">
            <CardText>
              <strong>Valor Comprometido:</strong>{" "}
              {$filter(receivable?.compromisedValue, "MONEY")}
            </CardText>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <CardText>
              <strong>Volume dos Últimos 30 Dias:</strong>{" "}
              {$filter(receivable?.volumeLast30days, "MONEY")}
            </CardText>
          </Col>
          <Col md="6">
            <CardText>
              <strong>Volume Médio Mensal:</strong>{" "}
              {$filter(receivable?.averageMonthlyVolume, "MONEY")}
            </CardText>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <CardText>
              <strong>Prazo Médio:</strong> {receivable?.averageTerm}
            </CardText>
          </Col>
          <Col md="6">
            <CardText>
              <strong>Valor Total Constituído:</strong>{" "}
              {$filter(receivable?.totalConstitutedValue, "MONEY")}
            </CardText>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <CardText>
              <strong>Intervalo Total Constituído (Meses):</strong>{" "}
              {receivable?.totalConstitutedIntervalMonths}
            </CardText>
          </Col>
          <Col md="6">
            <CardText>
              <strong>Limite de Crédito:</strong>{" "}
              {$filter(receivable?.creditLimit, "MONEY")}
            </CardText>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <CardText>
              <strong>Taxa de Desconto:</strong> {receivable?.discountRate}%
            </CardText>
          </Col>
          <Col md="6">
            <CardText>
              <strong>Dias Bloqueados para Agendamento:</strong>{" "}
              {receivable?.daysBlockedSchedule}
            </CardText>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <CardText>
              <strong>Data da Próxima Liquidação:</strong>{" "}
              {receivable?.nextSettlementDate}
            </CardText>
          </Col>
          <Col md="6">
            <CardText>
              <strong>Data da Última Liquidação:</strong>{" "}
              {receivable?.lastSettlementDate}
            </CardText>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <CardText>
              <strong>Data da Última Atualização:</strong>{" "}
              {receivable?.lastUpdateDate}
            </CardText>
          </Col>
          <Col md="6">
            <CardText>
              <strong>Hora da Última Atualização:</strong>{" "}
              {receivable?.lastUpdateTime}
            </CardText>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <CardText>
              <strong>Limite de Crédito Parcelado:</strong>{" "}
              {$filter(receivable?.installmentCreditLimit, "MONEY")}
            </CardText>
          </Col>
          <Col md="6">
            <CardText>
              <strong>Crédito Bloqueado:</strong> {receivable?.blockedCredit}
            </CardText>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <CardText>
              <strong>Limite de Crédito Utilizado:</strong>{" "}
              {$filter(receivable?.usedCreditLimit, "MONEY")}
            </CardText>
          </Col>
          <Col md="6">
            <CardText>
              <strong>Limite Disponível:</strong>{" "}
              {$filter(receivable?.availableLimit, "MONEY")}
            </CardText>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <CardText>
              <strong>Tipo de Crédito Bloqueado:</strong>{" "}
              {receivable?.tpBlockedCredit}
            </CardText>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <CardText>
              <strong>Valor Constituído de Antecipação Pré:</strong>{" "}
              {$filter(receivable?.valueConstitutedAnticipationPre, "MONEY")}
            </CardText>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default withRouter(withTranslation()(CardReceivablesDetails));
