import React, {useState} from 'react';
import {withTranslation} from "react-i18next";
import {Table} from "reactstrap";
import $filter from "../../../common/utils/$filter";
import StringUtils from "../../../common/utils/StringUtils";

function EndUserObligationsView(props) {

    const [endUserObligations] = useState(props.endUserObligations)

    return (
        <React.Fragment>

            <Table
                className="table table-striped table-bordered ">
                <thead className="table-light">
                <tr>
                    <th>Beneficiário/Títular</th>
                    <th>Valor <br/>onerado</th>
                    <th>Valor <br/>efeito de contrato</th>
                    <th>URs Oneradas</th>
                    <th>Próximo<br/>vencimento</th>
                    <th>Último<br/>vencimento</th>
                    <th>Credenciadores/Sub<br/>com bloqueio</th>
                    <th>Arranjos<br/>com bloqueio</th>
                    <th>Bloqueando URs<br/>não constituidas</th>

                </tr>
                </thead>
                <tbody>

                {endUserObligations.map((ur, index) => {

                    return (
                        <React.Fragment>
                            <tr key={index}>

                                <td scope="row">
                                    {ur.nameRecipientOrHolder !== null &&
                                    ur.nameRecipientOrHolder !== undefined  ?
                                        (
                                            <React.Fragment>
                                                {
                                                    StringUtils.abbreviateName(
                                                        ur.nameRecipientOrHolder, 30
                                                    )
                                                }
                                                <br/>
                                            </React.Fragment>
                                        )
                                        :
                                        ""
                                    }
                                    {
                                        $filter(ur['legalIdRecipientOrHolder'], 'CPF_CNPJ')
                                    }
                                </td>

                                <td>
                                    {$filter(ur.encumberedAmount, 'MONEY')}
                                </td>

                                <td>
                                    {$filter(ur.valueConstitutedEffect, 'MONEY')}
                                </td>

                                <td>
                                    {ur.ursEncumbered}
                                </td>

                                <td>
                                    {$filter(ur.nextSettlementDate, 'DATE')}
                                </td>
                                <td>
                                    {$filter(ur.lasSettlementDate, 'DATE')}
                                </td>
                                <td>
                                    {ur.qtAcquirers}
                                </td>
                                <td>
                                    {ur.qtArrangements}
                                </td>
                                <td>
                                    {ur.isBlockUrNotConstituted > 0 ? "Sim" : "Não"}
                                </td>

                            </tr>

                        </React.Fragment>
                    )
                })}
                </tbody>
            </Table>


        </React.Fragment>
    );
}

export default withTranslation()(EndUserObligationsView)