import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useHistory, useParams, withRouter } from "react-router-dom";
import { Container, Row } from "reactstrap";

import { withTranslation } from "react-i18next";
import UserIdentifierPanel from "./ReceivableDetails/UserIdentifierPanel";
import GeneralBalanceReport from "./ReceivableDetails/GeneralBalanceReport";
import ReceivablesScheduleGraphic from "./ReceivableDetails/ReceivablesScheduleGraphic";
import ReceivablesUnitsAnalytcs from "./ReceivableDetails/ReceivablesUnitsAnalytcs";
import * as url from "../../helpers/url_helper";
import {
  get,
  getSessionUserProfile,
  getUserSession,
} from "../../helpers/api_helper";
import Loader from "../../components/Common/Loader";
import ErrorDialog from "../../components/Common/ErrorDialog";

function ReceivablesScheduleDetail(props) {
  let { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [entity, setEntity] = useState({
    receivingEndUser: {},
    hasLoadedStorage: false,
    hasLoadedApi: false,
    hasLoadedRecAvailable: false,
    receivableUnitsAvailable: null,
  });

  const history = useHistory();

  function loadFromStorage() {
    if (entity.hasLoadedStorage) return;

    const prof = getSessionUserProfile();
    if (prof !== "END_USER") {
      const storageId = id;
      const newEntity = JSON.parse(localStorage.getItem(storageId));
      if (newEntity && newEntity["oid"] === storageId) {
        localStorage.removeItem(storageId);

        setEntity(newEntity);
        newEntity["hasLoadedStorage"] = true;
      } else {
        history.push(`/receivables`);
      }
    } else {
      const usr = getUserSession();
      setEntity({
        receivingEndUser: {
          legalId: usr.financialAgent.legalId,
          name: usr.financialAgent.name,
        },
        hasLoadedStorage: true,
      });
    }
  }

  const getReceivable = async () => {
    if (!entity || !entity.receivingEndUser || !entity.receivingEndUser.legalId)
      return;
    if (entity.hasLoadedApi) return;

    setLoading(true);
    try {
      const apiUrl = `${url.GET_RECEIVABLES}/${entity.receivingEndUser.legalId}`;
      const response = await get(apiUrl, null, {}, setLoading);

      const newEntity = Object.assign(response["receivable"], entity);

      newEntity["hasLoadedApi"] = true;

      newEntity["hasLoadedRecAvailable"] =
        newEntity["receivableUnitsAvailable"] !== null &&
        newEntity["receivableUnitsAvailable"] !== undefined;

      setEntity(newEntity);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadFromStorage();
  });

  useEffect(() => {
    getReceivable();
  }, [entity]);

  return (
    <React.Fragment>
      <div className="page-content" style={{ padding: "0 10px 0" }}>
        <MetaTags>
          <title>{props.t("COMPANY | Receivables")}</title>
        </MetaTags>

        <UserIdentifierPanel entity={entity} loading={loading} />

        <Container fluid>
          <Row>
            {entity.hasLoadedStorage ? (
              <ReceivablesScheduleGraphic entity={entity} />
            ) : (
              ""
            )}
            <GeneralBalanceReport entity={entity} loading={loading} />
          </Row>

          {entity.hasLoadedApi ? (
            <ReceivablesUnitsAnalytcs entity={entity} setEntity={setEntity} />
          ) : (
            <></>
          )}
        </Container>
      </div>

      {loading ? <Loader loading={loading} /> : " "}
    </React.Fragment>
  );
}

export default withRouter(withTranslation()(ReceivablesScheduleDetail));
