import React from 'react';
import MetaTags from 'react-meta-tags';
import {Card, CardBody, Col, Container, Label, Row, Table} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import $filter from "../../common/utils/$filter";

function SearchParticipants(props) {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Search participants")}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title={props.t("Searches")} breadcrumbItem={props.t("Search participants")}/>
                    {/* Search */}
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">{props.t("Filters")}</h4>
                                    <Row>
                                        <Col lg={3}>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control"
                                                       id="inputTypeOfInstitution"
                                                       placeholder="."/>
                                                <Label
                                                    for="inputTypeOfInstitution">{props.t("Type of institution")}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control"
                                                       id="inputLegalName"
                                                       placeholder="."/>
                                                <Label
                                                    for="inputLegalName">{props.t("Legal name")}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control"
                                                       id="inputDocument"
                                                       placeholder="."/>
                                                <Label for="inputDocument">{props.t("Document")}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control"
                                                       id="inputSystemRegister"
                                                       placeholder="."/>
                                                <Label for="inputSystemRegister">{props.t("System register")}</Label>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={12}>
                                            <div className="text-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-light w-sm waves-effect waves-light"
                                                >
                                                    {props.t("Clean filters")}
                                                </button>
                                                {" "}
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-light w-sm waves-effect waves-light"
                                                >
                                                    {props.t("Apply Search")}
                                                </button>
                                                {" "}
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Search results */}
                    <Row>
                        <Table className="table table-striped table-bordered mb-0 table-hover font-size-12">
                            <thead className="table-light">
                            <tr>
                                <th>{props.t("Type of institution")}</th>
                                <th>{props.t("Participant")}</th>
                                <th>{props.t("System register")}</th>
                                <th>{props.t("Inclusion date")}</th>
                            </tr>
                            </thead>
                            <tbody>

                            {/*{"LINHA 1"}*/}
                            <tr>
                                <td>
                                    Credenciador/sub
                                </td>
                                <td>
                                    WOW SOLUÇÕES CONSULTORIA DE CRÉDITO...<br/>
                                    {$filter('30144977000143', 'CPF_CNPJ')}
                                </td>
                                <td>
                                    CERC
                                </td>
                                <td>
                                    10/06/2021
                                </td>
                            </tr>
                            </tbody>
                        </Table>

                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default withRouter(withTranslation()(SearchParticipants))