import React from "react";
import { Redirect } from "react-router-dom";
import { getSessionUserProfile, getUserSession } from "../helpers/api_helper";

// Authentication related pages
import AccessManagement from "../pages/Authentication/AccessManagement";

import Error403 from "../pages/Utility/Error403";
import Error404 from "../pages/Utility/Error404";

// Opt-In
import ManageOptIn from "../pages/Opt-in/ManageOptIn";

// Receivables
import SearchReceivablesSchedule from "../pages/Receivables/SearchReceivablesSchedule";
import ReceivablesScheduleDetail from "../pages/Receivables/ReceivablesScheduleDetail";

// Contracts
import ManageContracts from "../pages/Contracts/ManageContracts";
import ManageContractsEdit from "../pages/Contracts/ManageContractsEdit";
import ManageContractsView from "../pages/Contracts/ManageContractsView";

// Searches
import SearchParticipants from "../pages/Searches/SearchParticipants";
import SearchPaymentArrangement from "../pages/Searches/SearchPaymentArrangement";

// Users
import Users from "../pages/User/Users";

// Conciliation
import PerformConciliation from "../pages/Conciliation/PerformConciliation";
import PerformConciliationEdit from "../pages/Conciliation/PerformConciliationEdit";

// Disputes
import ManageDisputesReceived from "../pages/Disputes/ManageDisputesReceived";
import ManageSubmittedDisputes from "../pages/Disputes/ManageSubmittedDisputes";
import ManageSubmittedDisputesEdit from "../pages/Disputes/ManageSubmittedDisputesEdit";
import ManageDisputesReceivedEdit from "../pages/Disputes/ManageDisputesReceivedEdit";

// Files
import ImportFile from "../pages/Files/ImportFile";
import SearchFile from "../pages/Files/SearchFile";
import ShippingFile from "../pages/Files/ShippingFile";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import HomeMenu from "../pages/HomeMenu/HomeMenu";

// Organization
import Organization from "../pages/Organization/Organization";
import Order from "../pages/Order/Order";

import Checkout from "../pages/Checkout/Checkout";
import Segments from "../pages/Segment/Segments";
import GlobalParams from "../pages/GlobalParams/GlobalParams";

// Liquidations
import ManageLiquidations from "../pages/Liquidations/ManageLiquidations";
import GravameV2 from "../pages/Admin/gravame/Gravame";
import BillingV2 from "../pages/Admin/billing/Billing";
import OptinV2 from "../pages/Admin/optin/Optin";
import OrderV2 from "../pages/Admin/order/Order";
import GravameNewV2 from "../pages/Admin/gravame/GravameNewV2";

const endUserRoutes = [
  { path: "/receivables/user", component: ReceivablesScheduleDetail },
  { path: "/contracts/manage-contracts", component: ManageContracts },
  { path: "/contracts/edit/:id", component: ManageContractsView },
  { path: "/home-menu", component: HomeMenu },

  // Users
  { path: "/users", component: Users },

  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/receivables/user" />,
  },
  { path: "*", component: () => <Redirect to="/receivables/user" /> },

  { path: "/login", component: AccessManagement },
];

const financialRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/home-menu", component: HomeMenu },

  // Opt-in
  { path: "/opt-in", component: ManageOptIn },

  // Receivable
  { path: "/receivables", component: SearchReceivablesSchedule },
  { path: "/receivables/:id", component: ReceivablesScheduleDetail },

  // Contracts
  { path: "/contracts/manage-contracts", component: ManageContracts },
  { path: "/contracts/simulate-contracts", component: ManageContracts },
  { path: "/contracts/new", component: ManageContractsEdit },
  { path: "/contracts/edit/:id", component: ManageContractsView },

  // Searches
  { path: "/searches/search-participants", component: SearchParticipants },
  {
    path: "/searches/search-payment-arrangement",
    component: SearchPaymentArrangement,
  },

  // Conciliation
  { path: "/perform-conciliation", component: PerformConciliation },
  { path: "/perform-conciliation/new", component: PerformConciliationEdit },
  {
    path: "/perform-conciliation/edit/:id",
    component: PerformConciliationEdit,
  },

  // Files
  { path: "/files/import-file", component: ImportFile },
  { path: "/files/search-file", component: SearchFile },
  { path: "/files/shipping-file", component: ShippingFile },

  // Disputes
  {
    path: "/disputes/manage-disputes-received",
    component: ManageDisputesReceived,
  },
  {
    path: "/disputes/manage-submitted-disputes",
    component: ManageSubmittedDisputes,
  },
  { path: "/disputes/new", component: ManageSubmittedDisputesEdit },
  { path: "/disputes/edit/:id", component: ManageSubmittedDisputesEdit },
  {
    path: "/disputes/received-disputes/edit/:id",
    component: ManageDisputesReceivedEdit,
  },

  // Users
  { path: "/users", component: Users },

  // Organization
  { path: "/organizations", component: Organization },

  // Global Params
  { path: "/global-params", component: GlobalParams },

  // Segment
  { path: "/segments", component: Segments },

  { path: "/billing", component: Order },

  // Liquidations
  { path: "/liquidations", component: ManageLiquidations },
  { path: "/admin/gravame/new", component: GravameNewV2 },
  { path: "/admin/gravame", component: GravameV2 },
  { path: "/admin/opt-in", component: OptinV2 },
  { path: "/admin/billing", component: BillingV2 },
  { path: "/admin/order", component: OrderV2 },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  { path: "*", component: () => <Redirect to="/dashboard" /> },

  { path: "/login", component: AccessManagement },
];

const sellerRoutes = [
  { path: "/home-menu", component: HomeMenu },
  { path: "/billing", component: Order },

  // Receivable
  { path: "/customers", component: SearchReceivablesSchedule },
  { path: "/receivables/:id", component: ReceivablesScheduleDetail },

  // Users
  { path: "/users", component: Users },

  { path: "/", exact: true, component: () => <Redirect to="/customers" /> },
  { path: "*", component: () => <Redirect to="/customers" /> },
  { path: "/login", component: AccessManagement },
];

const PROFILE_ROUTE_DOMAIN = {
  END_USER: endUserRoutes,
  FINANCIAL: financialRoutes,
  SELLER: sellerRoutes,
  REGISTER_AGENT: financialRoutes,
};

const userProfile = getSessionUserProfile();
const userProfileRoute = PROFILE_ROUTE_DOMAIN[userProfile];

export const authRoutes = [
  { path: "/login", component: AccessManagement },
  { path: "/access-management", component: AccessManagement },
  { path: "/checkout/:orderReference", component: Checkout },

  { path: "/pages-404", component: Error404 },
  { path: "/access-denied", component: Error403 },
];

export const defaultUserRoutes = [
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
  { path: "*", component: () => <Redirect to="/login" /> },
];

export const userRoutes = userProfileRoute
  ? userProfileRoute
  : defaultUserRoutes;

export const getHome = () => {
  const userProfileRoute = getSessionUserProfile();

  switch (userProfileRoute) {
    case "END_USER": {
      return "/receivables/user";
    }
    case "FINANCIAL": {
      return "/home-menu";
    }
    case "SELLER": {
      return "/customers";
    }
    case "REGISTER_AGENT": {
      return "/home-menu";
    }
    default: {
      return "/login";
    }
  }
};
