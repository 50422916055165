import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Badge } from "reactstrap";

const BillingStatusBadge = ({ status, key }) => {
  const orderStatus = [
    { value: "W", label: "Aguardando Pagamento" },
    { value: "P", label: "Parcialmente Pago" },
    { value: "T", label: "Pago" },
    { value: "C", label: "Cancelado" },
    { value: "R", label: "Rejeitado" },
    { value: "O", label: "Atrasado" },
  ];

  let label = "Error";
  let color = "primary";
  switch (status) {
    case "W":
      color = "primary";
      label = orderStatus[0].label;
      break;
    case "P":
      color = "info";
      label = orderStatus[1].label;
      break;
    case "T":
      color = "success";
      label = orderStatus[2].label;
      break;
    case "C":
      color = "danger";
      label = orderStatus[3].label;
      break;
    case "R":
      color = "secondary";
      label = orderStatus[4].label;
      break;
    case "O":
      color = "info";
      label = orderStatus[5].label;
      break;
    default:
      color = "dark";
      break;
  }

  return (
    <Badge
      className={`badge bg-${color} rounded-pill font-size-12`}
      color={color}
      pill={true}
      key={key}
    >
      {label}
    </Badge>
  );
};

export default withRouter(withTranslation()(BillingStatusBadge));
