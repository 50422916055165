import { SHOW_NOTIFICATION } from "../actions";

const notificationMiddleware = (store) => (next) => (action) => {
  if (action.type === SHOW_NOTIFICATION) {
    const { message, type } = action.payload;
    // Lógica para exibir a notificação
    console.log(`Notification: ${message} - Type: ${type}`);
    // Aqui você pode adicionar a lógica para exibir a notificação na UI
  }

  return next(action);
};

export default notificationMiddleware;
