import React, {useEffect, useState} from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import {
    Col,
    Container, Row,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import {withTranslation} from "react-i18next";

import PortfolioSummary from './PortfolioSummary'

import SettlementsAnalytics from './SettlementsAnalytics';
import * as url from "../../helpers/url_helper";
import {get} from "../../helpers/api_helper";
import Loader from "../../components/Common/Loader";
import {withRouter} from "react-router-dom";
import ContractsActives from "./ContractsActives";
import NextSettlements from "./NextSettlements";
import ErrorDialog from "../../components/Common/ErrorDialog";

function Dashboard(props) {

    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({
        portfolioSummary: {}
    })
    const [error, setError] = useState(null)

    const getReceivable = async () => {
        try{
            const apiUrl = `${url.GET_PORTFOLIO_REPORT}`
            const response = await get(apiUrl, null, {}, setLoading)

            const newState = response['view']
            if (!newState['portfolioSummary'] ){
                newState['portfolioSummary'] = {}
            }
            setState(newState)
        }catch(e){
            if (e['status'] !== '200' && e['status'] !== '403')
                setError(e)
        }
    }

    useEffect(() => {
        getReceivable()
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Dashboard")}</title>
                </MetaTags>

                <Container fluid>
                    <Breadcrumbs
                        title={props.t("Dashboard")}
                        breadcrumbItem={props.t("Dashboard")}
                    />

                    <PortfolioSummary view={state.portfolioSummary}/>

                    <Row>
                        <SettlementsAnalytics
                            settlements={state.settlementsByDay}
                            view={state.portfolioSummary}
                        />
                    </Row>
                    <Row>
                        <Col xl={6}>
                            <ContractsActives contracts={state.contractsActives} />
                        </Col>
                        <Col xl={6}>
                            <NextSettlements settlements={state.nextSettlements}/>
                        </Col>
                    </Row>
                </Container>
            </div>

            {
                loading ?
                    <Loader loading={loading}/>
                    :
                    (" ")
            }

            {
                error ? (
                    <ErrorDialog
                        title={error['title']}
                        code={error['status']}
                        onClose={() => {
                            setError(null)
                        }}
                    >
                        <p>{!!error['detail'] ? error['detail'] : 'Erro de rede, verifique sua conexão com a internet.'} </p>
                    </ErrorDialog>
                ) : ("")
            }

        </React.Fragment>
    );
}

export default withRouter(withTranslation()(Dashboard))