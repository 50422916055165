import React, { useState} from "react"
import {withTranslation} from "react-i18next";

import {Row, Col} from "reactstrap"
import { withRouter,} from "react-router-dom"
import CompleteFieldsToStartCheckout from "./CompleteFieldsToStartCheckout";
import CheckoutFinished from "./CheckoutFinished";
import CheckoutTicketSeller from "./CheckoutTicketSeller";

function CheckoutDesktopContent (props) {

    return (
        <React.Fragment>
            <Row className="justify-content-center">

                {
                    !props.documentAlreadySigned ?
                        <Col xl={4} lg={6} md={6} sm={12} xs={12} >
                            <CompleteFieldsToStartCheckout
                                handleBuyerField={props.handleBuyerField}
                                handleBuyerFormField={props.handleBuyerFormField}
                            />
                        </Col>
                        :
                        <Col xl={4} lg={6} md={6} sm={12} xs={12} className="text-center">
                            <CheckoutFinished
                                entity={props.entity}
                            />
                        </Col>
                }

                <Col xl={8} lg={6} md={6} sm={12} xs={12}  style={{paddingRight: 0}}>
                    <CheckoutTicketSeller
                        entity={props.entity}
                        firstLoad={props.firstLoad}
                        handlePaymentPeriod={props.handlePaymentPeriod}
                        documentAlreadySigned={props.documentAlreadySigned}
                        paymentPeriodSelected={props.paymentPeriodSelected}
                    />
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(CheckoutDesktopContent))