import $filter from "../../common/utils/$filter";
import $unmasked from "../../common/utils/$unmasked";

const filterEl = (event, type, callback) => {
  let valueWithMask = event.target.value;
  let unmaskedValue = event.target.value;

  if (
    event &&
    event["target"] &&
    event["target"]["value"] !== null &&
    event["target"]["value"] !== undefined
  ) {
    const value = event.target.value;
    let superType = "";
    if (type === "CPF_CNPJ" || type === "MONEY" || type === "NUMBER") {
      superType = "number";
    }

    if (superType === "number") {
      const lastChar = value.substr(value.length - 1, value.length);
      if (isNaN(lastChar)) {
        const oldValue = value.substr(0, value.length - 1);
        document.getElementById(event.target.id).value = oldValue;
        unmaskedValue = oldValue;
        return { valueWithMask, unmaskedValue };
      }
    }
    valueWithMask = $filter(event.target.value, type);
    unmaskedValue = $unmasked(valueWithMask, type);
    document.getElementById(event.target.id).value = valueWithMask;

    if (callback instanceof Function) {
      callback(valueWithMask, unmaskedValue);
    }
    return { valueWithMask, unmaskedValue };
  }
};

export default filterEl;
