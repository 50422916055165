import React from 'react';
import MetaTags from 'react-meta-tags';
import {Card, CardBody, Col, Container, Label, Row, Table} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Select from "react-select";

const optionFileType = [
    {
        options: [
            {label: "OPT-IN", value: "1"},
            {label: "CONTRATO", value: "2"},
            {label: "CONTESTAÇÃO", value: "3"},
            {label: "USUÁRIOS", value: "4"},
        ],
    },
];

function ImportFile(props) {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Consulta de arquivo remessa</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Arquivos" breadcrumbItem="Consulta de arquivo remessa"/>

                    {/* Search */}
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">{props.t("Filters")}</h4>
                                    <Row>
                                        <Col lg={3}>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control"
                                                       id="inputExternalReference"
                                                       placeholder="."/>
                                                <Label
                                                    for="inputExternalReference">Data base</Label>
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="form-floating mb-3">
                                                <div className="form-floating mb-2">
                                                    <div
                                                        className="templating-select select2-container mb-2 ">
                                                        <Select
                                                            isMulti={false}
                                                            className="select2-floating"
                                                            required
                                                            options={optionFileType}
                                                            classNamePrefix="select2-selection"
                                                            closeMenuOnSelect={true}
                                                            placeholder="Tipo de arquivo"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control"
                                                       id="inputReceivingEndUser"
                                                       placeholder="."/>
                                                <Label
                                                    for="inputReceivingEndUser">Status</Label>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={12}>
                                            <div className="text-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-light w-sm waves-effect waves-light"
                                                >
                                                    {props.t("Clean filters")}
                                                </button>
                                                {" "}
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-light w-sm waves-effect waves-light"
                                                >
                                                    {props.t("Apply Search")}
                                                </button>
                                                {" "}
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Search results */}
                    <Row>
                        <div className="table-responsive">
                            <Table className="table table-striped table-bordered mb-0 table-hover font-size-12">
                                <thead className="table-light">
                                <tr>
                                    <th>{props.t("Status")}</th>
                                    <th>Tipo de arquivo</th>
                                    <th>Data base</th>
                                    <th>Arquivo</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>

                                {/*{"LINHA 1"}*/}
                                <tr>
                                    <td scope="row">
                                                         <span className="badge bg-success me-1 w-sm">
                                      Concluído
                                    </span>
                                    </td>
                                    <td>
                                        Opt-in
                                    </td>
                                    <td>
                                        19/06/2021
                                    </td>
                                    <td>
                                        4EX-OPTIN-20210620-0001-ENV.csv
                                    </td>
                                    <td>
                                        <div className="button-items text-end">
                                            <button type="button"
                                                    className="btn btn-light btn-sm waves-effect"
                                            >
                                                <i className='mdi mdi-download-box-outline me-1'></i> Download
                                            </button>
                                        </div>
                                    </td>
                                </tr>

                                </tbody>
                            </Table>
                        </div>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
}
export default withRouter(withTranslation()(ImportFile))