import React from 'react';
import {withTranslation} from "react-i18next";

const SuccessDialog = props => {

    setTimeout(() => {
        if (props.onClose)
            props.onClose()
    }, 3000)

    const title = props.title ? props.title : props.t("Operation performed successfully")

    return (
        <div className="toast-container position-absolute top-1 end-0 p-2 p-lg-3">

            <div className="toast fade show"
                 role="alert"
                 aria-live="assertive"
                 aria-atomic="true"
                 id="toastSuccessDialog">
                <div className="toast-header">

                    <i className="bx bx-check-circle text-success me-2 font-size-30"></i>
                    <strong className="me-auto mt-1 ">{title}</strong>
                    <button type="button"
                            className="btn-close"
                            data-bs-dismiss="toast"
                            aria-label="Close"
                            onClick={() => {
                                if (props.onClose)
                                    props.onClose()
                            }}
                    ></button>
                </div>
                <div className="toast-body  ">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(SuccessDialog)