import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { Card, CardBody, Col, Container, Form, Modal, Row } from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import InputText from "../../components/Common/InputText";
import TablePagination from "../../components/Common/TablePagination";
import PaginationService from "../../components/Common/PaginationService";
import * as url from "../../helpers/url_helper";
import SuccessDialog from "../../components/Common/SuccessDialog";
import ErrorDialog from "../../components/Common/ErrorDialog";
import ParamValueView from "./ParamValueView";
import GlobalParamEdit from "./GlobalParamEdit";

function GlobalParams(props) {
  const [pager, setPager] = useState(PaginationService.defaultPager());
  const [filter, setFilter] = useState(PaginationService.defaultFilter());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [modalEditEntity, setModalEditEntity] = useState({
    isOpen: false,
    entity: null,
  });
  const [successMessage, setSuccessMessage] = useState(null);

  const applySearch = async (currentPage, itemsPerPage) => {
    try {
      const { pagerResult, filterResult } = await PaginationService.applySearch(
        url.GET_GLOBAL_PARAMS,
        "globalParams",
        filter,
        currentPage,
        itemsPerPage,
        setLoading
      );
      setPager(pagerResult);
      setFilter(filterResult);
    } catch (e) {
      if (e["status"] !== "200" && e["status"] !== "403") setError(e);
    }
  };

  const handleChangeFilter = (event, field) => {
    filter[field] = event.target.value;
    setFilterField("filter", filter);
  };

  const setFilterField = (name, value) => {
    filter[name] = value;
    setFilter(filter);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFilter(PaginationService.resetPagination(filter));
    applySearch();
  };

  function setResultFromNewEntity(newEntity) {
    setFilter(PaginationService.defaultFilter());

    const newPager = PaginationService.defaultPager();
    newPager.results = [newEntity];
    setPager(newPager);
  }

  const openModalEditEntity = (entity) => {
    setModalEditEntity({
      isOpen: true,
      entity: Object.assign({}, entity),
    });
  };

  const closeModalEditEntity = (resultModal) => {
    setModalEditEntity({
      isOpen: false,
      entity: null,
    });

    if (resultModal["event"] === "UPDATED") {
      setResultFromNewEntity(resultModal["entity"]);

      setSuccessMessage({
        detail: props.t("Organization successfully updated"),
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Global Parameters")}</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs
            title={props.t("Global Parameters")}
            breadcrumbItem={props.t("Manage Global Parameters")}
          />

          <Form
            id="searchForm"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <p>{props.t("Filters parameters")}</p>
                    <Row>
                      <Col lg={4} className="mb-3">
                        <InputText
                          id="filterName"
                          onChange={(event) =>
                            handleChangeFilter(event, "keyName")
                          }
                          placeholder={props.t("Key Name")}
                        />
                      </Col>
                      <Col lg={4} className="mb-3">
                        <InputText
                          id="filterName"
                          onChange={(event) =>
                            handleChangeFilter(event, "detail")
                          }
                          placeholder={props.t("Detail")}
                        />
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <hr />
                    </Row>

                    <Row>
                      <Col lg={12}>
                        <div className="text-end">
                          <button
                            type="submit"
                            className="btn btn-sm btn-light w-sm waves-effect waves-light"
                          >
                            {props.t("Apply Search")}
                          </button>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col lg={12}>
                        <TablePagination
                          pager={pager}
                          applySearch={applySearch}
                          loading={loading}
                        >
                          <thead className="table-light">
                            <tr>
                              <th className="col-md-4 text-start">
                                {props.t("Key Name")}
                              </th>
                              <th className="col-md-4 text-start">
                                {props.t("Detail")}
                              </th>
                              <th className="col-md-2 text-start">
                                {props.t("Key Value")}
                              </th>
                              <th className="col-md-1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {(pager.results || []).map((entity, key) => (
                              <tr
                                key={entity["legalId"]}
                                className="align-middle"
                              >
                                <td
                                  scope="row"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {}}
                                >
                                  {entity.keyName}
                                </td>
                                <td>{entity.detail}</td>
                                <td>
                                  <ParamValueView
                                    keyValue={entity.keyValue}
                                    paramType={entity.paramType}
                                  />
                                </td>
                                <td
                                  className="text-center"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    openModalEditEntity(entity);
                                  }}
                                >
                                  <i className="mdi mdi-pencil font-size-16" />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </TablePagination>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>

          {modalEditEntity.isOpen ? (
            <GlobalParamEdit
              isOpen={modalEditEntity.isOpen}
              entity={modalEditEntity.entity}
              callback={closeModalEditEntity}
            />
          ) : (
            ""
          )}
        </Container>
      </div>

      {error ? (
        <ErrorDialog
          title={error["title"]}
          onClose={() => {
            setError(null);
          }}
        >
          <p>{error["detail"]} </p>
        </ErrorDialog>
      ) : (
        ""
      )}

      {successMessage ? (
        <SuccessDialog
          title={successMessage["title"]}
          onClose={() => {
            setSuccessMessage(null);
          }}
        >
          <p>{successMessage["detail"]} </p>
        </SuccessDialog>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}

export default withRouter(withTranslation()(GlobalParams));
