import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";
import { Label } from "reactstrap";
import AccountTypeDomain from "../../domains/AccountTypeDomain";

const animatedComponents = makeAnimated();

function Select2AccountType(props) {
  const [options, setOptions] = useState(AccountTypeDomain.options);

  const [defaultValue] = useState(
    props.defaultValue !== null && props.defaultValue !== undefined
      ? options.filter((bank) => {
          return bank.value === props.defaultValue;
        })[0]
      : null
  );

  function onChange(options) {
    if (props.onChange) props.onChange(options);
  }

  return (
    <div className="mb-3 templating-select select2-container">
      {props.label !== null && props.label !== undefined ? (
        <Label for={props.id} className="form-label">
          {`${props.required === true ? "*" : ""}${props.label}`}
        </Label>
      ) : (
        <></>
      )}
      <Select
        isMulti={
          props.isMulti !== null && props.isMulti !== undefined
            ? props.isMulti
            : false
        }
        classNamePrefix="select2-selection"
        closeMenuOnSelect={true}
        isSearchable={false}
        defaultValue={defaultValue}
        menuPlacement={props.menuPlacement}
        placeholder={
          props.placeholder !== null && props.placeholder !== undefined
            ? props.placeholder
            : ""
        }
        options={options}
        components={animatedComponents}
        onChange={(options) => {
          onChange(options);
        }}
      />
    </div>
  );
}

export default withTranslation()(Select2AccountType);
