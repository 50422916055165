import React, { useState } from "react";
import { Card, CardBody, Col, Label, Row, Table } from "reactstrap";

import { withTranslation } from "react-i18next";
import InputMoney from "../../components/Common/InputMoney";
import InputNumber from "../../components/Common/InputNumber";

function OrganizationEditLimit(props) {
  return (
    <React.Fragment>
      {props.entity.limitInformation ? (
        <Card>
          <CardBody>
            <Row className="mt-3">
              <Col lg={12}>
                <div className="form-check form-switch form-switch-lg mb-lg-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked={
                      props.entity.limitInformation.limitEngine === "MANUAL"
                    }
                    onClick={(event) => {
                      props.entity.limitInformation.limitEngine = event.target
                        .checked
                        ? "MANUAL"
                        : "CREDIT_ENGINE";
                      props.handleChange(props.entity);
                    }}
                    id="toogleEngine"
                  />
                  <Label className="form-check-label" for="cashNowActive">
                    {props.t("Manual limit")}
                  </Label>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={6} className="mb-3">
                <InputMoney
                  id={"creditLimit"}
                  value={props.entity.limitInformation.creditLimit}
                  onChangeValidation={(val) => {
                    props.entity.limitInformation.creditLimit = val;
                    props.handleChange(props.entity);
                  }}
                  readOnly={
                    props.entity.limitInformation.limitEngine !== "MANUAL"
                  }
                  label={props.t("Credit limit")}
                />
              </Col>
              <Col lg={6} className="mb-3">
                <InputMoney
                  id={"installmentCreditLimit"}
                  value={props.entity.limitInformation.installmentCreditLimit}
                  onChangeValidation={(val) => {
                    props.entity.limitInformation.installmentCreditLimit = val;
                    props.handleChange(props.entity);
                  }}
                  readOnly={
                    props.entity.limitInformation.limitEngine !== "MANUAL"
                  }
                  label={props.t("Installment credit limit")}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={6} className="mb-3">
                <InputMoney
                  id={"usedCreditLimit"}
                  value={props.entity.limitInformation.usedCreditLimit}
                  onChange={() => {}}
                  readOnly={true}
                  label={props.t("Used credit limit")}
                />
              </Col>
              <Col lg={6} className="mb-3">
                <InputMoney
                  id={"availableLimit"}
                  value={props.entity.limitInformation.availableLimit}
                  onChange={() => {}}
                  readOnly={true}
                  label={props.t("Available limit")}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}

export default withTranslation()(OrganizationEditLimit);
