import React from 'react'
import {Label} from "reactstrap"
import {withTranslation} from "react-i18next"
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

class InputNumber extends React.Component {

    constructor(props) {
        super(props)

        const defaultMaskOptions = {
            prefix: '',
            suffix: '',
            includeThousandsSeparator: false,
            thousandsSeparatorSymbol: '',
            allowDecimal: true,
            decimalSymbol: '',
            decimalLimit: 0,
            integerLimit: this.props.maxLength && this.props.maxLength > 0 ? this.props.maxLength : 40,
            allowNegative: false,
            allowLeadingZeroes: true,
        }

        const currencyMask = createNumberMask({
            ...defaultMaskOptions,
        })

        this.state = {
            isValid: true,
            "$error": null,
            currencyMask: currencyMask
        }
    }

    setValue = (event) => {
        const newState = {value: event.target.value}

        this.setState(newState)

        const valueWithoutMask = event.target.value.replace(/\D/g, "").trim()

        if (this.props.onChange) {
            this.props.onChange({
                target: {value: valueWithoutMask, name: event.target.name}
            })
        }
    }

    onChangeValidation = (event) => {
        const valueWithoutMask = event.target.value.replace(/\D/g, "").trim()

        if (this.props.onChangeValidation) {
            this.props.onChangeValidation(valueWithoutMask)
        }
    }

    render() {
        return (<React.Fragment>
            {
                this.props.label !== null && this.props.label !== undefined ?

                    <Label for={this.props.id} className="form-label">
                        {`${this.props.required === true ? "*" : ""}${this.props.label}`}
                    </Label>
                    : ("")
            }

            <MaskedInput className={this.state.isValid ? "form-control" : "form-control is-invalid"}
                         mask={this.state.currencyMask}
                         disabled={
                             this.props.readOnly !== null && this.props.readOnly !== undefined ?
                                 this.props.readOnly : false
                         }
                         value={this.props['value'] !== undefined && this.props['value'] !== null ? this.props['value'] : null}
                         placeholder={this.props.placeholder}
                         type="text"
                         autoComplete="off"
                         onBlur={this.onChangeValidation}
                         onChange={this.setValue}
                         id={this.props.id}
                         name={this.props.id}
            />

        </React.Fragment>)
    }
}

export default withTranslation()(InputNumber)