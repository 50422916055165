import PropTypes from "prop-types";
import React from "react";
import AlertContainer from "./components/AlertContainer";

import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

class DebugRouter extends Router {
  constructor(props) {
    super(props);
    // console.log("initial history is: ", JSON.stringify(this.history, null, 2));
    // this.history.listen((location, action) => {
    //   console.log(
    //     `The current URL is ${location.pathname}${location.search}${location.hash}`
    //   );
    //   console.log(
    //     `The last navigation action was ${action}`,
    //     JSON.stringify(this.history, null, 2)
    //   );
    // });
  }
}

const App = (props) => {
  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();
  return (
    <React.Fragment>
      <DebugRouter>
        <AlertContainer />
        <Switch>
          {authRoutes.map((route, idx) => {
            return (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            );
          })}

          {userRoutes.map((route, idx) => {
            return (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            );
          })}
        </Switch>
      </DebugRouter>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
