import React, {useState} from "react"
import MetaTags from 'react-meta-tags'
import {withTranslation} from "react-i18next";

import {Row, Col, TabPane, TabContent,} from "reactstrap"
import {Link, withRouter,} from "react-router-dom"

import Login from "./Login";
import ConfirmEmail from "./ConfirmEmail";
import TwoFactorConfirm from "./TwoFactorConfirm";
import PasswordAltered from "./PasswordAltered";
import NewPassword from "./NewPassword";
import ConfirmEmailToNewPwd from "./ConfirmEmailToNewPwd";
import ErrorDialog from "../../components/Common/ErrorDialog";
import {AppFields} from "../../common/data/AppFields";

const AccessManagement = (props) => {

    const [error, setError] = useState(null)

    const queryParams = new URLSearchParams(window.location.search)

    const [innerHeight] = useState(window.innerHeight)

    const [activeTab, setActiveTab] = useState(
        window.location.pathname === '/access-management' && !!queryParams.get("q") ? 2 : 1
    )

    const [entity, setEntity] = useState({
        token: queryParams.get("q")
    })

    document.body.classList.add("login_page")

    const onSuccess = (newEntity) => {
        setActiveTab(activeTab + 1)
        setEntity(newEntity)
    }

    const onFailure = (error) => {
        setError(error)
    }

    const requestNewPwd = () => {
        setActiveTab(6)
    }

    return (
        <React.Fragment>
            <MetaTags>
                <title>Login | Cash2Pay</title>
            </MetaTags>
            <div className="login-page">
                <Row className="justify-content-center">
                    <Col xl={4} lg={5} md={5} sm={12} xs={12}>
                        <div className="p-lg-5 p-4 my-5">

                            {
                                error ? (
                                    <ErrorDialog
                                        title={error['title']}
                                        code={error['status']}
                                        onClose={() => {
                                            setError(null)
                                        }}
                                    >
                                        <p>{error['detail']} </p>
                                    </ErrorDialog>
                                ) : ("")
                            }

                            <TabContent activeTab={activeTab}>
                                <TabPane tabId={1}>
                                    <Login
                                        requestNewPwd={requestNewPwd}
                                        onFailure={onFailure}
                                    />
                                </TabPane>

                                <TabPane tabId={2}>
                                    <ConfirmEmail
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                        entity={entity}/>
                                </TabPane>

                                <TabPane tabId={3}>
                                    <TwoFactorConfirm
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                        entity={entity}/>
                                </TabPane>
                                <TabPane tabId={4}>
                                    <NewPassword
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                        entity={entity}/>
                                </TabPane>
                                <TabPane tabId={5}>
                                    <PasswordAltered/>
                                </TabPane>
                                <TabPane tabId={6}>
                                    <ConfirmEmailToNewPwd
                                        onFailure={onFailure}
                                    />
                                </TabPane>
                            </TabContent>
                        </div>
                    </Col>
                    <Col xl={8} lg={7} md={7} sm={12} xs={12} style={{paddingRight: 0}}>
                        <div className="p-lg-5 p-4 bg-auth h-900 d-none d-lg-block text-center">
                            <p className="bg-auth-title">Cash2Pay</p>
                            <p className="bg-auth-detail">A conexão certa entre indústria e varejo</p>
                        </div>
                    </Col>
                </Row>

                {/*{*/}
                {/*    innerHeight > 640 ?*/}

                {/*        <Row className=" fixed-bottom">*/}
                {/*            <Col xl={4} lg={5} md={5} sm={12} xs={12} className="justify-content-center">*/}
                {/*                {" "}*/}
                {/*            </Col>*/}
                {/*            <Col xl={8} lg={7} md={7} sm={12} xs={12}>*/}
                {/*                <div className="p-lg-5 p-4" style={{color: "#ffffff9e"}}>*/}
                {/*                    <Link*/}
                {/*                        style={{color: "#ffffff9e"}}*/}
                {/*                        to={{ pathname: AppFields.webSiteUrl }} target="_blank">*/}
                {/*                        {props.t("Cash2Pay to the right industry and retail connection, learn more at")}:&nbsp;{AppFields.webSite}*/}
                {/*                    </Link>*/}
                {/*                    <hr/>*/}
                {/*                    <p> {AppFields.copyright}</p>*/}
                {/*                </div>*/}

                {/*            </Col>*/}
                {/*        </Row> : ""*/}
                {/*}*/}


            </div>

        </React.Fragment>
    )
}


export default withRouter(withTranslation()(AccessManagement))

