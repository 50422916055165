import React, {useState} from 'react';
import {
    Card,
    CardBody,
    Col,
    NavItem,
    NavLink,
    Nav, TabContent, TabPane, Row
} from 'reactstrap';
import {withTranslation} from "react-i18next";
import classnames from "classnames";
import SummarizedVisionUnits from "./SummarizedVisionUnits";
import AnalyticalVisionUnits from "./AnalyticalVisionUnits";
import * as url from "../../../helpers/url_helper";
import {get} from "../../../helpers/api_helper";
import Loader from "../../../components/Common/Loader";
import EndUserObligationsView from "./EndUserObligationsView";

function ReceivablesUnitsAnalytcs(props) {

    const [activeTab, setActiveTab] = useState(0)
    const [loading, setLoading] = useState(false)

    const [tabs, setTabs] = useState([
        {title: props.t("Summary"), onclick: (index) => {setActiveTab(index)}},

        {title: props.t("Receivable Units Available"), onclick: handleGetReceivableAvailable},
        {title: props.t("Unavailable receivables"), onclick: handleGetReceivableCompromised},
        {title: props.t("Not constituted receivables"), onclick: handleGetFutureCommitments},
        {title: props.t("Obligations"), onclick: handleGetEndUserObligations},

        // {title: props.t("Download full report"), icon: "bx-download", onclick: (index) => {
        //     printReport()
        // }},
    ])

    function handleGetReceivableAvailable () {
        getReceivableUnitsAvailable()
    }

    function handleGetFutureCommitments () {
        getFutureCommitments()
    }

    function handleGetReceivableCompromised () {
        getReceivableCompromised()
    }

    function handleGetEndUserObligations () {
        getEndUserObligations()
    }

    function printReport() {
    }

    const getReceivableUnitsAvailable = async ()=> {
        if (!props.entity['hasLoadedRecAvailable']){
            const apiUrl = `${url.GET_RECEIVABLES_AVAILABLE}/${props.entity.receivingEndUser.legalId}`
            const response = await get(apiUrl, null, {}, setLoading)

            props.entity['receivableUnitsAvailable'] = response['receivableUnitsAvailable']
            props.entity['hasLoadedRecAvailable'] = true

            props.setEntity(props.entity)
        }
        setActiveTab(1)
    }

    const getReceivableCompromised = async ()=> {
        if (!props.entity['hasLoadedRecCompromised']){
            const apiUrl = `${url.GET_RECEIVABLES_COMPROMISED}/${props.entity.receivingEndUser.legalId}`
            const response = await get(apiUrl, null, {}, setLoading)

            props.entity['receivableUnitsCompromised'] = response['receivableUnitsCompromised']
            props.entity['hasLoadedRecCompromised'] = true

            props.setEntity(props.entity)
        }
        setActiveTab(2)
    }

    const getFutureCommitments = async ()=> {
        if (!props.entity['hasLoadedFutureCommitments']){
            const apiUrl = `${url.GET_FUTURE_COMMITMENTS}/${props.entity.receivingEndUser.legalId}`
            const response = await get(apiUrl, null, {}, setLoading)

            props.entity['receivableFutureCommitments'] = response['receivableFutureCommitments']
            props.entity['hasLoadedFutureCommitments'] = true

            props.setEntity(props.entity)
        }
        setActiveTab(3)
    }

    const getEndUserObligations = async ()=> {
        if (!props.entity['hasLoadedEndUserObligations']){
            const apiUrl = `${url.GET_END_USER_OBLIGATIONS}/${props.entity.receivingEndUser.legalId}`
            const response = await get(apiUrl, null, {}, setLoading)

            props.entity['endUserObligations'] = response['endUserObligations']
            props.entity['hasLoadedEndUserObligations'] = true

            props.setEntity(props.entity)
        }
        setActiveTab(4)
    }

    return (
        <React.Fragment>
            <Card>
                <CardBody>

                    <Row className="mb-3">
                        <Col xl={12}>
                            <Nav
                                className="nav-tabs-custom nav-justified"
                            >
                                {tabs.map((tab, index) => {
                                    return (
                                        <NavItem key={index}>
                                            <NavLink
                                                className={classnames({
                                                    active: activeTab === index,
                                                })}
                                                onClick={() => {
                                                    tab.onclick(index)
                                                }}
                                                data-bs-toggle="tab"
                                                role="tab"
                                            >

                                                {
                                                    tab.icon ?
                                                        <i className={`bx ${tab.icon} font-size-20 mr-5`}/>
                                                        : ("")
                                                }
                                                <span>{tab.title}</span>
                                            </NavLink>
                                        </NavItem>
                                    )
                                })}
                            </Nav>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={12}>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId={0}>
                                    <SummarizedVisionUnits entity={props.entity}/>
                                </TabPane>
                                <TabPane tabId={1}>
                                    {
                                        activeTab === 1 ?
                                            <AnalyticalVisionUnits
                                                receivableUnitsAvailable={props.entity.receivableUnitsAvailable}/> : ""
                                    }
                                </TabPane>
                                <TabPane tabId={2}>
                                    {
                                        activeTab === 2 ?
                                            <AnalyticalVisionUnits
                                                receivableUnitsAvailable={props.entity.receivableUnitsCompromised}/> : ""
                                    }
                                </TabPane>
                                <TabPane tabId={3}>
                                    {
                                        activeTab === 3 ?
                                            <AnalyticalVisionUnits
                                                receivableUnitsAvailable={props.entity.receivableFutureCommitments}/> : ""
                                    }
                                </TabPane>
                                <TabPane tabId={4}>
                                    {
                                        activeTab === 4 ?
                                            <EndUserObligationsView
                                                endUserObligations={props.entity.endUserObligations}/> : ""
                                    }
                                </TabPane>
                            </TabContent>
                            {
                                loading ?
                                    <Loader loading={loading}/>
                                    :
                                    (" ")
                            }
                        </Col>
                    </Row>

                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default withTranslation()(ReceivablesUnitsAnalytcs);