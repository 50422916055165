import CpfCnpjUtils from "../../common/utils/CpfCnpjUtils";
import DateTimeUtils from "../../common/utils/DateTimeUtils";
import MoneyUtils from "../../common/utils/MoneyUtils";
import BooleanUtils from "../../common/utils/BooleanUtils";

const filter = (value, type, noprefixo) => {
  if (type.toUpperCase() === "CPF_CNPJ") {
    if (!value) return value;
    return CpfCnpjUtils.getMask(value);
  }

  if (type.toUpperCase() === "DATE") {
    if (!value) return value;
    return DateTimeUtils.filter(value);
  }

  if (type.toUpperCase() === "MONEY")
    return MoneyUtils.getMask(value, noprefixo);

  if (type.toUpperCase() === "PRECENT")
    return MoneyUtils.getMaskPercent(value, noprefixo);

  if (type.toUpperCase() === "BOOLEAN") return BooleanUtils.fromNative(value);

  return value;
};

export default filter;
