import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardBody, Table } from "reactstrap";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Loader from "../../components/Common/Loader";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

class TablePagination extends React.Component {
  constructor(props) {
    super(props);
  }

  goLast = () => {
    this.props.applySearch(this.props.pager["pagination"]["numberOfPages"]);
  };
  goFirst = () => {
    this.props.applySearch(0);
  };
  goNext = () => {
    this.props.applySearch(
      Number(this.props.pager["pagination"]["currentPage"]) + 1
    );
  };
  goPrior = () => {
    this.props.applySearch(
      Number(this.props.pager["pagination"]["currentPage"]) - 1
    );
  };
  goPage = (currentPage) => {
    if (currentPage === Number(this.props.pager["pagination"]["currentPage"]))
      return;
    this.props.applySearch(currentPage);
  };

  range = (from, to, step = 1) => {
    let i = from;
    const range = [];
    while (i <= to) {
      range.push(i);
      i += step;
    }
    return range;
  };

  fetchPageNumbers = () => {
    let totalPages = Number(this.props.pager["pagination"]["numberOfPages"]);
    const currentPage =
      Number(this.props.pager["pagination"]["currentPage"]) + 1;
    let pageNeighbours = 0;
    // if (Number(this.props.pager['pagination']['currentPage']) < 3) {
    //     pageNeighbours = Number(this.props.pager['pagination']['currentPage'])
    // }

    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
      let pages = this.range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = this.range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = this.range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return this.range(1, totalPages);
  };

  render() {
    const pages = this.fetchPageNumbers();
    return (
      <Row>
        {this.props.loading ? <Loader loading={this.props.loading} /> : " "}
        {/* Search results */}
        <Col xs={12}>
          {this.props.pager["results"] &&
          this.props.pager["results"].length > 0 ? (
            <div className={this.props.noClass ? "" : "table-responsive"}>
              <Table
                className={
                  this.props.noClass
                    ? ""
                    : "table table-striped table-bordered mb-0 table-hover font-size-default"
                }
              >
                {this.props.children}
              </Table>
            </div>
          ) : this.props.pager["pagination"] &&
            this.props.pager["pagination"]["totaOfItems"] === 0 ? (
            <blockquote className="blockquote font-size-16 mb-0">
              <p>{this.props.t("No results found")}.</p>
              <footer className="blockquote-footer">
                {this.props.t("Modify filter parameters and search again")}.
              </footer>
            </blockquote>
          ) : (
            <blockquote className="blockquote font-size-16 mb-0">
              <p>{this.props.t("Inform the filters")}.</p>
              <footer className="blockquote-footer">
                {this.props.t(
                  "Enter the filter parameters to start the search"
                )}
                .
              </footer>
            </blockquote>
          )}
        </Col>

        {/* Search Pagination */}
        <Col xs={12}>
          {this.props.pager["results"] &&
          this.props.pager["results"].length > 0 ? (
            <nav className="text-end" aria-label="Page navigation example">
              <ul className="pagination pagination-rounded mb-4 mt-3">
                {pages.map((page, index) => {
                  if (page === LEFT_PAGE)
                    return (
                      <li className="page-item" key={index}>
                        <Link
                          className="page-link"
                          to="#"
                          aria-label="Previous"
                          onClick={() => {
                            this.goPrior();
                          }}
                        >
                          <i className="bx bx-chevron-left"></i>
                        </Link>
                      </li>
                    );

                  if (page === RIGHT_PAGE)
                    return (
                      <li className="page-item" key={index}>
                        <Link
                          className="page-link"
                          to="#"
                          aria-label="Previous"
                          onClick={() => {
                            this.goNext();
                          }}
                        >
                          <i className="bx bx-chevron-right"></i>
                        </Link>
                      </li>
                    );

                  return (
                    <li
                      key={index}
                      className={
                        Number(this.props.pager["pagination"]["currentPage"]) +
                          1 ===
                        page
                          ? "page-item active"
                          : "page-item"
                      }
                    >
                      <Link
                        className="page-link"
                        to="#"
                        aria-label="Previous"
                        onClick={() => {
                          this.goPage(page - 1);
                        }}
                      >
                        {page}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </nav>
          ) : (
            " "
          )}
        </Col>
      </Row>
    );
  }
}

TablePagination.propTypes = {
  pager: PropTypes.object,
};

export default withTranslation()(TablePagination);
