import React from "react"
import {Card, CardBody, CardTitle, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap"


import {withTranslation} from "react-i18next";
import $filter from "../../common/utils/$filter";

const ContractsActives = (props) => {

    const contracts = props.contracts || []

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="float-end">
                        <UncontrolledDropdown>
                            <DropdownToggle className="text-reset" tag="a" id="dropdownMenuButton3">
                                <span className="fw-semibold">Relevância:</span> <span
                                className="text-muted">Recentes<i className="mdi mdi-chevron-down ms-1"></i></span>
                            </DropdownToggle>

                            <DropdownMenu className="dropdown-menu-end" aria-labelledby="dropdownMenuButton3">
                                <DropdownItem>Maior valor</DropdownItem>
                                <DropdownItem>Saldo não constituído</DropdownItem>
                                <DropdownItem>Próxima liquidação</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                    <CardTitle className="mb-4">Contratos em aberto</CardTitle>
                    <div className="table-responsive">
                        <table className="table align-middle table-striped table-nowrap mb-0">
                            <tbody>

                            {contracts.map((ctr, key) => (
                                <tr key={key}>
                                    <td>
                                        {ctr.tradingName} <br/>
                                        <small>{ctr.externalReference}</small>
                                    </td>
                                    <td>
                                        <small>Data de assinatura</small><br/>
                                        {$filter(ctr.signatureDate, 'date')}
                                    </td>
                                    <td>
                                        <small>Saldo devedor</small><br/>
                                        {$filter(ctr.debitBalance, 'money')}
                                    </td>
                                    <td>
                                        <small>Valor liquidado</small><br/>
                                        {$filter(ctr.settledAmount, 'money')}
                                    </td>
                                    <td className="text-center">
                                        <i className='fa fa-search me-1'></i>
                                    </td>
                                </tr>
                            ))}

                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default withTranslation()(ContractsActives)

