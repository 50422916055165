import { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

import "react-datepicker/dist/react-datepicker.css";
function InputDateRange(props) {
  const [dateRange, setDateRange] = useState([props?.init, props?.end]);

  // const [startDate, endDate] = dateRange;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    setStartDate(dateRange[0] || null);
    setEndDate(dateRange[1] || null);
  }, [dateRange]);

  return (
    <>
      <DatePicker
        name={props.name}
        id={props.id}
        // className={props.isValid ? "form-control" : "form-control is-invalid"}
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setDateRange(update);
        }}
        isClearable={true}
      />
    </>
  );
}

export default withRouter(withTranslation()(InputDateRange));
