const StatusPaymentDomain = {

    PENDENTE_PAGAMENTO: "PP",
    LIQUIDADO_PARCIAL: "LP",
    LIQUIDADO_TOTAL: "LT",

    options: [
        {label: "Pendente de pagamento", value: "PP"},
        {label: "Liquidado parcial", value: "LP"},
        {label: "Liquidado total", value: "LT"},
    ],

    getLabelByValue(value){
        for (const i in StatusPaymentDomain.options){
            if (StatusPaymentDomain.options[i].value === value){
                return StatusPaymentDomain.options[i].label
            }
        }
        return null
    }
}

export default StatusPaymentDomain
