class OrderStatusDomain {

  VALIDACAO = {value: 'V', name: 'Em validação'};
  PENDENTE = {value: 'P', name: 'Pendente'};
  APROVADO = {value: 'A', name: 'Aprovado'};
  REJEITADO = {value: 'R', name: 'Rejeitado'};
  CANCELADO = {value: 'C', name: 'Cancelado'};
  EXPIRADO = {value: 'E', name: 'Expirado'};

  static getNameFromValue(value) {
    const obj = new OrderStatusDomain()

    for (const fieldName in obj) {
      if (obj.hasOwnProperty(fieldName)) {
        if (value === obj[fieldName]['value']) {
          return obj[fieldName]['name'];
        }
      }
    }
    return null;
  }

  static getClassFromValue(value) {
    if (value === 'P') return "badge rounded-pill bg-warning bg-soft text-warning badge-md"
    if (value === 'V') return "badge rounded-pill bg-info bg-sof badge-md"
    if (value === 'A') return "badge rounded-pill bg-success bg-soft text-success badge-md"
    if (value === 'R') return "badge rounded-pill bg-danger bg-soft badge-md text-danger"
    if (value === 'C') return "badge rounded-pill bg-light bg-soft text-light badge-md"
    if (value === 'E') return "badge rounded-pill bg-light bg-soft text-light badge-md"

    return ""
  }
}

export default OrderStatusDomain