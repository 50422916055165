import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import InputMoney from "../../components/Common/InputMoney";
import React from "react";
import InputDate from "../../components/Common/InputDate";
import InputNumber from "../../components/Common/InputNumber";
import InputPercent from "../../components/Common/InputPercent";
import Select2YesOrNo from "../../components/Common/Select2YesOrNo";


const ParamValueEdit = ({label, keyName, keyValue, paramType, onChange}) => {
    const withType = () => {

        switch (paramType) {
            case 'date':
                return handleDate();
            case 'real':
                return handleReal();
            case 'number':
                return handleNumber();
            case 'percent':
                return handlePercent();
            case 'boolean':
                return handleBoolean();
            default:
                return keyValue;
        }
    }

    const handleBoolean = () => {
        return (
            <Select2YesOrNo id={keyName}
                            label={label}
                            defaultValue={
                                keyValue.toUpperCase() === 'TRUE' ? '1' : '0'
                            }
                            onChange={(option)=>{
                                const value = option.value === '1' ? 'true' : 'false'

                                onChange({
                                    target: {value: value, name: keyName}
                                })
                            }}
                            placeholder={label}/>
        )
    }

    const handlePercent = () => {
        return (
            <InputPercent id={keyName}
                          onChange={onChange}
                          value={keyValue}
                          label={label}
                          placeholder={label}/>
        )
    }

    const handleDate = () => {
        return (
            <InputDate id={keyName}
                       onChange={onChange}
                       value={keyValue}
                       label={label}
                       placeholder={label}/>
        )
    }

    const handleReal = () => {
        return (
            <InputMoney
                id={keyName}
                value={keyValue}
                onChange={onChange}
                label={label}/>
        )
    }

    const handleNumber = () => {
        return (
            <InputNumber
                id={keyName}
                value={keyValue}
                maxLength={4}
                onChange={onChange}
                label={label}/>
        )
    }

    return (
        <>{withType()}</>
    )
}
export default withRouter(withTranslation()(ParamValueEdit));