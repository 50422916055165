import React from 'react';
import MetaTags from 'react-meta-tags';
import {Card, CardBody, Col, Container, Label, Row, Table} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";

function SearchPaymentArrangement(props) {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Search participants")}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title={props.t("Searches")} breadcrumbItem={props.t("Search payment arrangement")}/>
                    {/* Search */}
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">{props.t("Filters")}</h4>
                                    <Row>
                                        <Col lg={4}>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control"
                                                       id="inputPaymentArrangementCode"
                                                       placeholder="."/>
                                                <Label
                                                    for="inputPaymentArrangementCode">{props.t("Payment arrangement code")}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control"
                                                       id="inputArrangementType"
                                                       placeholder="."/>
                                                <Label
                                                    for="inputArrangementType">{props.t("Arrangement type")}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control"
                                                       id="inputDescription"
                                                       placeholder="."/>
                                                <Label for="inputDescription">{props.t("Description")}</Label>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={12}>
                                            <div className="text-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-light w-sm waves-effect waves-light"
                                                >
                                                    {props.t("Clean filters")}
                                                </button>
                                                {" "}
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-light w-sm waves-effect waves-light"
                                                >
                                                    {props.t("Apply Search")}
                                                </button>
                                                {" "}
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Search results */}
                    <Row>
                        <div className="table-responsive">
                            <Table className="table table-striped table-bordered mb-0 table-hover font-size-12">
                                <thead className="table-light">
                                <tr>
                                    <th>{props.t("Payment arrangement code")}</th>
                                    <th>{props.t("Arrangement type")}</th>
                                    <th>{props.t("Description")}</th>
                                </tr>
                                </thead>
                                <tbody>

                                {/*{"LINHA 1"}*/}
                                <tr>
                                    <td>
                                        AGC
                                    </td>
                                    <td>
                                        Crédito
                                    </td>
                                    <td>
                                        Agiplan
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        ACC
                                    </td>
                                    <td>
                                        Crédito
                                    </td>
                                    <td>
                                        Amex Cartão de Crédito
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        BCC
                                    </td>
                                    <td>
                                        Crédito
                                    </td>
                                    <td>
                                        Banescard Cartão de Crédito
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default withRouter(withTranslation()(SearchPaymentArrangement))