import React, {useEffect, useState} from "react"
import {withTranslation} from "react-i18next";

import { Modal,} from "reactstrap"
import { withRouter,} from "react-router-dom"
import {get} from "../../helpers/api_helper";
import {GET_CHECKOUT} from "../../helpers/url_helper";

const ModalCheckoutSign = (props) => {

    const [state, setState] = useState({
        isOpen: props.isOpen,
    })

    const [onMessageConfigured, setOnMessageConfigured] = useState(false)

    const toggle = () => {
        setState({isOpen: false})
    }

    const afterClosed = () => {
        props.callback({isOpen: false, entity: null})
    }

    const closeAfterSign = (entity) => {
        props.callback({isOpen: false, event: 'DOC_SIGNED', entity: entity})
    }

    useEffect(()=>{
        if (!onMessageConfigured){
            setOnMessageConfigured(true)

            window.onmessage = function(e){
                if(e.data === 'zs-doc-signed'){
                    closeAfterSign()
                }
            }

            verifySign()
        }
    })

    const verifySign = async () => {
        setTimeout(async () => {
            const response = await get(
                `${GET_CHECKOUT}/${props.entity.orderReference}`, null, null, null
            )
            if (response['order'] && response['order']['isSigned'] === true){
                closeAfterSign(response['order'])
            }else {
                await verifySign()
            }
        }, 4000)
    }

    return (
        <React.Fragment>
            <Modal
                modalClassName="drawer right-align"
                size="lg"
                isOpen={state.isOpen}
                toggle={() => {
                    toggle()
                }}

                onClosed={() => {
                    afterClosed()
                }}
            >
                <div className="modal-body">
                    <iframe
                        src={props.entity.buyer.linkSign[0]}
                        width="100%"
                        height="700px"
                    />
                </div>
            </Modal>

        </React.Fragment>
    )
}


export default withRouter(withTranslation()(ModalCheckoutSign))

