import React, {useState} from 'react'

import {Col, Label, Row, UncontrolledAlert} from "reactstrap"
import {withTranslation} from "react-i18next"
import $filter from "../../../common/utils/$filter";
import InputMoney from "../../../components/Common/InputMoney";
import Select2DiscountRates from "../../../components/Common/Select2DiscountRates";
import SelectEndUserReceivables from "../../../components/Common/SelectEndUserReceivables";
import GenericModal from "../../../components/Common/GenericModal";
import AnticipationContractReceivables from "../AnticipationContract/AnticipationContractReceivables";
import {Link} from "react-router-dom";
import SelectAddAccountModal from "../../../components/Common/SelectAddAccountModal";
import PaymentCompanyDomain from "../../../domains/PaymentCompanyDomain";

function AnticipationSelectBankAccount(props) {

    const [openModalAccountToAcquirer, setOpenModalAccountToAcquirer] = useState(false)
    const [openModalEndUserAccount, setOpenModalEndUserAccount] = useState(false)

    const [enableAccountToAcquirerEdit, setEnableAccountToAcquirerEdit] = useState(false)
    const [enableEndUserAccountEdit, setEnableEndUserAccountEdit] = useState(false)

    const [contract, setContract] = useState(props.contract)

    const addAccountToAcquirer = (result) => {
        if (result.event === "SAVED"){
            props.contract.creditorAccount['ispb'] = result.entity['ispb']
            props.contract.creditorAccount['accountNumber'] = result.entity['accountNumber']
            props.contract.creditorAccount['accountDigit'] = result.entity['accountDigit']
            props.contract.creditorAccount['branch'] = result.entity['branch']
            props.contract.creditorAccount['accountType'] = result.entity['accountType']

            setContract(
                props.setContract(props.contract, true)
            )
            setEnableAccountToAcquirerEdit(true)
        }
        setOpenModalAccountToAcquirer(false)
    }

    const addAccountEndUser = (result) => {
        if (result.event === "SAVED"){
            props.contract.contractor.bankAccount['ispb'] = result.entity['ispb']
            props.contract.contractor.bankAccount['accountNumber'] = result.entity['accountNumber']
            props.contract.contractor.bankAccount['accountDigit'] = result.entity['accountDigit']
            props.contract.contractor.bankAccount['branch'] = result.entity['branch']
            props.contract.contractor.bankAccount['accountType'] = result.entity['accountType']

            setContract(
                props.setContract(props.contract, true)
            )
            setEnableEndUserAccountEdit(true)
        }
        setOpenModalEndUserAccount(false)
    }

    const handleCreditorAccount = (taxIdOwner,ownerName ) => {
        props.contract.creditorAccount['taxIdOwner'] = taxIdOwner
        props.contract.creditorAccount['ownerName'] = ownerName

        setContract(
            props.setContract(props.contract, true)
        )
    }

    return (
        <React.Fragment>

            <Row className="mb-2">
                <Col lg={12}>
                    <p className="card-title-desc mb-0">
                        Conta de <strong>{contract.contractor.name}</strong> para liberação do crédito.
                    </p>
                </Col>
            </Row>

            {
                props.contract.contractor.bankAccount.ispb ?
                    <Row className="mb-3">
                        <Col lg={12}>
                            <strong>{PaymentCompanyDomain.getLabelByCode(
                                props.contract.contractor.bankAccount.ispb
                            )}</strong>
                            <br/>
                            <span>Agência&nbsp;{props.contract.contractor.bankAccount.branch}&nbsp;
                                Conta&nbsp;{props.contract.contractor.bankAccount.accountNumber}
                                {
                                    props.contract.contractor.bankAccount.accountDigit ?
                                        `-${props.contract.contractor.bankAccount.accountDigit}` : ""
                                }
                                                                            </span>
                        </Col>
                    </Row> : ""
            }

            <Row className="mt-2">
                <Col lg={12}>
                    {
                        props.contract.contractor.bankAccount.ispb ?

                            <Link to="#!"
                                  onClick={() => {
                                      setOpenModalEndUserAccount(true)
                                  }}>
                                {props.t("Change Creditor Account")}
                            </Link> :
                            <button
                                className="btn w-100 btn-select"
                                onClick={() => {
                                    setOpenModalEndUserAccount(true)
                                }}
                            >
                                Informar conta para liberação do crédito
                            </button>
                    }
                </Col>
            </Row>

            <hr style={{borderTop: "1px solid rgb(187 187 187)"}}/>

            <Row className="mt-4">
                <Col lg={12}>
                    <UncontrolledAlert
                        color="info"
                        className="mb-3"
                        role="alert"
                    >
                        <i className="mdi mdi-alert-circle-outline me-2 font-size-20"></i>
                        Conta para mudança de titularidade no credenciador.
                    </UncontrolledAlert>

                </Col>
            </Row>

            <Row className="mt-2">
                <Col lg={12} >
                    <Label  className="form-label">
                        { props.t("Legal ID bank owner account")}
                    </Label>

                    <div className={
                        contract.creditorAccount['taxIdOwner'] === contract.financialAgent.legalId ?
                            "form-check form-check-active" : "form-check form-check-inactive"
                    }>
                        <input className="form-check-input"
                               type="radio"
                               name="creditorAccount"
                               id="creditorAccount1"
                               onClick={()=>{
                                   handleCreditorAccount(
                                       props.contract.financialAgent.legalId,
                                       props.contract.financialAgent.name
                                   )
                               }}
                               defaultChecked />
                        <Label className="form-check-label"
                               for="creditorAccount1">
                            {contract.financialAgent.name}
                            &nbsp;&nbsp;
                            ({$filter(contract.financialAgent.legalId, 'CPF_CNPJ')})
                        </Label>
                    </div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col lg={12}>
                    <div className={
                        contract.creditorAccount['taxIdOwner'] === contract.contractor.legalId ?
                            "form-check form-check-active" : "form-check form-check-inactive"
                    }>
                        <input className="form-check-input"
                               type="radio"
                               name="creditorAccount"
                               onClick={()=>{
                                   handleCreditorAccount(
                                       props.contract.contractor.legalId,
                                       props.contract.contractor.name
                                   )
                               }}
                               id="creditorAccount2" />
                        <Label className="form-check-label"
                               for="creditorAccount2">
                            {contract.contractor.name}
                            &nbsp;&nbsp;
                            ({$filter(contract.contractor.legalId, 'CPF_CNPJ')})
                        </Label>
                    </div>
                </Col>
            </Row>

            {
                props.contract.creditorAccount.ispb ?
                    <Row className="mb-3">
                        <Col lg={12}>
                            <strong>{PaymentCompanyDomain.getLabelByCode(props.contract.creditorAccount.ispb)}</strong>
                            <br/>
                            <span>Agência&nbsp;{props.contract.creditorAccount.branch}&nbsp;
                                Conta&nbsp;{props.contract.creditorAccount.accountNumber}
                                {
                                    props.contract.creditorAccount.accountDigit ?
                                        `-${props.contract.creditorAccount.accountDigit}` : ""
                                }
                                                                            </span>
                        </Col>
                    </Row> : ""
            }

            <Row className="mb-3" >
                <Col lg={12} >
                    {
                        props.contract.creditorAccount.ispb ?

                            <Link to="#!"
                                  onClick={() => {
                                      setOpenModalAccountToAcquirer(true)
                                  }}>
                                Modificar conta para liquidação no credenciador
                            </Link> :
                            <button
                                className="btn w-100 btn-select"
                                onClick={() => {
                                    setOpenModalAccountToAcquirer(true)
                                }}
                            >
                                Informar conta para liquidação no credenciador
                            </button>
                    }
                </Col>
            </Row>

            <hr style={{borderTop: "1px solid rgb(187 187 187)"}}/>

            {
                openModalAccountToAcquirer ?
                    <SelectAddAccountModal
                        title={(<span>Conta para mudança de titularidade <br/>no credenciador</span>)}
                        callback={addAccountToAcquirer}
                    /> : ""
            }
            {
                openModalEndUserAccount ?
                    <SelectAddAccountModal
                        title="Conta para liberação do crédito"
                        callback={addAccountEndUser}
                    /> : ""
            }

        </React.Fragment>
    )
}

export default withTranslation()(AnticipationSelectBankAccount)