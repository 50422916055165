const BillingIntegrationStatus = {

    PENDING: "P",
    SUCCESS: "S",
    ERROR: "E",

    options: [
        {label: "Registrando...", value: "P"},
        {label: "Registrado", value: "S"},
        {label: "Erro", value: "E"},
    ],

    getLabelByValue(value){
        for (const i in BillingIntegrationStatus.options){
            if (BillingIntegrationStatus.options[i].value === value){
                return BillingIntegrationStatus.options[i].label.padStart(30, " ")
            }
        }
        return "Sem registro ".padStart(30, " ")
    },

    getClassFromValue(value) {
        if (value === 'P') return "badge rounded-pill bg-success bg-soft text-success badge-md"
        if (value === 'S') return "badge rounded-pill bg-success bg-soft text-success badge-md"
        if (value === 'E') return "badge rounded-pill bg-danger bg-soft text-danger badge-md"

        return "badge rounded-pill bg-secondary bg-soft text-secondary badge-md"
    }
}

export default BillingIntegrationStatus
