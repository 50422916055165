import React from "react";
import { connect } from "react-redux";
import Alert from "./Common/AlertInterceptor";

const AlertContainer = ({ notifications }) => {
  if (!Array.isArray(notifications)) {
    console.error("Notifications is not an array:", notifications);
    return null;
  }

  return notifications.map((notification, index) => (
    <Alert
      key={index}
      message={notification.message}
      type={notification.type}
    />
  ));
};

const mapStateToProps = (state) => ({
  notifications: state.notifications,
});

export default connect(mapStateToProps)(AlertContainer);
