import React, {useEffect, useState} from 'react'
import {Col, Row, Modal, Form, Container} from "reactstrap"

import {withTranslation} from "react-i18next"
import * as url from "../../helpers/url_helper"
import PaginationService from "./PaginationService";
import InputText from "./InputText";
import TablePagination from "./TablePagination";
import $filter from "../../common/utils/$filter";
import StringUtils from "../../common/utils/StringUtils";
import ErrorDialog from "./ErrorDialog";

function SelectOrganizationModal(props)  {

    const [pager, setPager] = useState(PaginationService.defaultPager())
    const [filter, setFilter] = useState(PaginationService.defaultFilter(7))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [firstSearch, setFirstSearch] = useState(false)
    const [isOpen, setIsOpen] = useState(props.isOpen)

    const afterClosed = () => {
        props.callback({isOpen: false, entity: null, event: 'CLOSE'})
    }

    const close = (selectedEntity) => {
        props.callback({isOpen: false, entity: selectedEntity, event: 'SELECTED'})
    }

    const toggle = () => {
        setIsOpen(false)
    }

    const applySearch = async (currentPage, itemsPerPage) => {
        try{
            const {pagerResult, filterResult} = await PaginationService.applySearch(
                url.GET_ORGANIZATION, 'organizations', filter, currentPage, itemsPerPage, setLoading
            )
            setPager(pagerResult)
            setFilter(filterResult)
        }catch(e){
            if (e['status'] !== '200' && e['status'] !== '403')
                setError(e)
        }
    }

    useEffect(() => {
        if (!firstSearch) {
            setFirstSearch(true)
            applySearch()
        }
    })

    const handleChangeFilter = (event) => {
        filter[event.target.name] = event.target.value
        setFilterField('filter', filter)
    }

    const setFilterField = (name, value) => {
        filter[name] = value
        setFilter(filter);
    }

    return (
            <React.Fragment>
                <Modal
                    isOpen={isOpen}
                    toggle={() => {
                        toggle()
                    }}
                    onClosed={() => {
                        afterClosed()
                    }}
                    modalClassName="drawer right-align"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            {props.t("Select Organization")}
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                toggle()
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">

                            <Row>
                                <Col lg={9} className="mb-3">
                                    <InputText id="name"
                                               onChange={event => handleChangeFilter(event)}
                                               placeholder={props.t("Name")}/>
                                </Col>
                                <Col lg={3} className="mb-3 text-end">
                                    <button className="btn btn-search-icon"
                                        onClick={() => applySearch()}>
                                        <i className="fa fa-search"></i>
                                    </button>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col lg={12}>
                                    <TablePagination
                                        noClass={true}
                                        pager={pager}
                                        applySearch={applySearch}
                                        loading={loading}>
                                        <tbody>
                                        {(pager.results || []).map((entity, key) => (
                                            <tr key={entity['legalId']}
                                            >
                                                <td style={{padding: "0 0 10px 0"}}>
                                                    <button
                                                        className="btn w-100 btn-select"
                                                        style={{
                                                            padding: 20,
                                                            height: 65
                                                        }}
                                                        onClick={()=> {
                                                            close(entity)
                                                        }}
                                                    >
                                                        <span>
                                                            {StringUtils.abbreviateName(entity.name, 45)} <br />
                                                            {$filter(entity.legalId, 'CPF_CNPJ')}
                                                        </span>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </TablePagination>
                                </Col>
                            </Row>
                    </div>

                    {
                        error ? (
                            <ErrorDialog
                                title={error['title']}
                                onClose={() => {
                                    setError(null)
                                }}
                            >
                                <p>{error['detail']} </p>
                            </ErrorDialog>
                        ) : ("")
                    }

                </Modal>

            </React.Fragment>
        )
}


export default withTranslation()(SelectOrganizationModal)