import React, {useState} from 'react'
import {Col, Row, Modal, Card, CardBody, Table} from "reactstrap"

import {withTranslation} from "react-i18next"
import $filter from "../../common/utils/$filter";
import StatusDomain from "../../domains/StatusDomain";
import PaymentSchemaTypeDomain from "../../domains/PaymentSchemaTypeDomain";
import {downloadFile, post} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import Swal from "sweetalert2";
import ErrorDialog from "../../components/Common/ErrorDialog";
import Loader from "../../components/Common/Loader";
import SuccessDialog from "../../components/Common/SuccessDialog";

class ManageOptinView extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            isOpen: this.props.isOpen,
            entity: props.entity,
            error: null,
            successMessage: null,
            optoutLoading: false,
            termDownloading: false,
        }
    }

    toggle = () => {
        this.setState({isOpen: false})
    }

    afterClosed = () => {
        this.props.callback(null)
    }

    close = (newEntity) => {
        this.props.callback(newEntity)
    }

    setOptoutLoading = (flag) => {
        this.setState(Object.assign(this.state, {optoutLoading: flag}))
    }

    setSuccessMessage = (message) => {
        this.setState(Object.assign(this.state, {successMessage: message}))
    }

    setTermDownloading = (flag) => {
        this.setState(Object.assign(this.state, {termDownloading: flag}))
    }

    setError = (error) => {
        this.setState(Object.assign(this.state, {error: error}))
    }

    setOptOut = async (entity) => {
        try {
            const response = await post(
                url.POST_OPTOUT, {
                    externalReference: entity['externalReference']
                }, null, this.setOptoutLoading
            )
            this.setState(Object.assign(this.state, {entity: response['optin']}))

            this.setSuccessMessage({
                detail: this.props.t("Opt-in successfully deactivated")
            })

        } catch (e) {
            if (e['status'] !== '200'){
                this.setError(e)
            }
        }
    }

    openOptoutConfirm = (entity) => {
        const html = `<div>
                <p>${this.props.t("Suspend access permission to")}<br/>
                    <strong>${entity.receivingEndUser.name}</strong><br/>
                    ${this.props.t("calendar through opt-out")}.
                </p>
            </div>`

        Swal.fire({
            title: this.props.t("Confirm o opt-out?"),
            icon: 'question',
            html: html,
            showCloseButton: false,
            showCancelButton: true,
            confirmButtonText: this.props.t("Confirm"),
            cancelButtonText: this.props.t("Cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                this.setOptOut(entity)
            }
        })
    }

     downloadTerm = async(entity) => {
        const response = await downloadFile(
            `${url.GET_OPTIN_TERMS}/${entity.externalReference}`,  null, null, this.setTermDownloading
        )

        const newUrl = window.URL.createObjectURL(new Blob([response]));

        const link = document.createElement('a');
        link.href = newUrl;
        link.setAttribute('download', `${ entity.receivingEndUser['name']} - ${entity.externalReference}.pdf`);
        document.body.appendChild(link);
        link.click();
    }

    render() {
        return (
            <React.Fragment>
                <Modal
                    isOpen={this.state.isOpen}
                    size="lg"
                    toggle={() => {
                        this.toggle()
                    }}

                    onClosed={() => {
                        this.afterClosed()
                    }}
                    modalClassName="drawer right-align"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            <p className="card-title-desc mb-1" >
                               <small> {this.props.t("Opt-in")}</small>
                                <small>{" / "}  {this.state.entity.externalReference}</small>
                            </p>
                            {this.state.entity.receivingEndUser.name}
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                this.toggle()
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">

                        <Card>
                            <CardBody>
                            <Table className="table table-striped mb-0 font-size-14">
                                <tbody>
                                <tr>
                                    <td className="col-md-8">
                                        <p className="card-title-desc mb-2" >
                                            {this.props.t("Participant")}
                                        </p>
                                        {this.state.entity.registerAgent.name}
                                    </td>
                                    <td className="col-md-4">
                                        <p className="card-title-desc mb-2" >
                                            {this.props.t("CNPJ")}
                                        </p>
                                        {$filter(this.state.entity.registerAgent.legalId, 'CPF_CNPJ')}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="col-md-8">
                                        <p className="card-title-desc mb-2" >
                                            {this.props.t("Requester")}
                                        </p>
                                        {this.state.entity.financialAgent.name}
                                    </td>
                                    <td className="col-md-4">
                                        <p className="card-title-desc mb-2" >
                                            {this.props.t("CNPJ")}
                                        </p>
                                        {$filter(this.state.entity.financialAgent.legalId, 'CPF_CNPJ')}
                                    </td>
                                </tr>

                                <tr>
                                    <td className="col-md-8">
                                        <p className="card-title-desc mb-2" >
                                            {this.props.t("Receiving end user")}
                                        </p>
                                        {this.state.entity.receivingEndUser.name}
                                    </td>
                                    <td className="col-md-4">
                                        <p className="card-title-desc mb-2" >
                                            {this.props.t("CPF/CNPJ")}
                                        </p>
                                        {$filter(this.state.entity.receivingEndUser.legalId, 'CPF_CNPJ')}
                                    </td>
                                </tr>

                                <tr>
                                    <td className="col-md-8">
                                        <p className="card-title-desc mb-2" >
                                            {this.props.t("Titular")}
                                        </p>
                                        {this.state.entity.holder.name}
                                    </td>
                                    <td className="col-md-4">
                                        <p className="card-title-desc mb-2" >
                                            {this.props.t("CPF/CNPJ")}
                                        </p>
                                        {$filter(this.state.entity.holder.legalId, 'CPF_CNPJ')}
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Table className="table table-striped mb-0 font-size-14">
                                    <tbody>
                                    <tr>
                                        <td className="col-md-8">
                                            <p className="card-title-desc mb-2" >
                                                {this.props.t("Signature date")}
                                            </p>
                                            {$filter(this.state.entity.signatureDate, 'DATE')}
                                        </td>
                                        <td className="col-md-4">
                                            <p className="card-title-desc mb-2" >
                                                {this.props.t("Status")}
                                            </p>
                                            <span
                                                      className={StatusDomain.getClassFromValue(this.state.entity.status)}>
                                                        {StatusDomain.getNameFromValue(this.state.entity.status)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="col-md-8">
                                            <p className="card-title-desc mb-2" >
                                                {this.props.t("Start date")}
                                            </p>
                                            {$filter(this.state.entity.startDate, 'DATE')}
                                        </td>
                                        <td className="col-md-4">
                                            <p className="card-title-desc mb-2" >
                                                {this.props.t("Opt-in expiration date")}
                                            </p>
                                            {$filter(this.state.entity.endDate, 'DATE')}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="col-md-8">
                                            <p className="card-title-desc mb-2" >
                                                {this.props.t("Reference Code")}
                                            </p>
                                            {this.state.entity.externalReference}
                                        </td>
                                        <td className="col-md-4">
                                            <p className="card-title-desc mb-2" >
                                                {this.props.t("Opt-out date")}
                                            </p>
                                            {$filter(this.state.entity.optoutDate, 'DATE')}
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Table className="table table-striped mb-0 font-size-14">
                                    <tbody>
                                    <tr>
                                        <td className="col-md-8">
                                            <p className="card-title-desc mb-2" >
                                                {this.props.t("Acquirer")}
                                            </p>
                                        </td>
                                        <td className="col-md-8">
                                            <p className="card-title-desc mb-2" >
                                                {this.props.t("Legal ID Acquirer")}
                                            </p>
                                        </td>
                                    </tr>

                                    {(this.state.entity.acquirers || []).map((ac, index) => (
                                        <tr>
                                            <td className="col-md-8">
                                                {ac.name}
                                            </td>
                                            <td className="col-md-4">
                                                {$filter(ac.legalId, 'CPF_CNPJ')}
                                            </td>
                                        </tr>
                                    ))}
                                    {
                                        this.state.entity['acquirers'] === null ||
                                        this.state.entity['acquirers'] === undefined ||
                                        this.state.entity['acquirers'].length === 0?
                                            <tr>
                                                <td className="col-md-8">
                                                    { this.props.t("All") }
                                                </td>
                                                <td className="col-md-4">

                                                </td>
                                            </tr> : ""
                                    }
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Table className="table table-striped mb-0 font-size-14">
                                    <tbody>
                                    <tr>
                                        <td className="col-md-8">
                                            <p className="card-title-desc mb-2" >
                                                {this.props.t("Payment arrangement")}
                                            </p>
                                        </td>
                                        <td className="col-md-4">

                                        </td>
                                    </tr>

                                    {(this.state.entity.arrangements || []).map((ar, index) => (
                                        <tr>
                                            <td className="col-md-8">
                                                {ar.code === 'ALL' ?  this.props.t("All") : ''}
                                                {ar.code === 'ALL_CREDIT' ?  this.props.t("All Credits") : ''}
                                                {ar.code === 'ALL_DEBIT' ?   this.props.t("All Debits") : ''}

                                                {ar.code !== 'ALL_DEBIT' &&
                                                  ar.code !== 'ALL_CREDIT' &&
                                                  ar.code !== 'ALL' ?   ar.label : ''}
                                            </td>
                                            <td className="col-md-4">
                                                {ar.code !== 'ALL_DEBIT' &&
                                                ar.code !== 'ALL_CREDIT' &&
                                                ar.code !== 'ALL' ?  PaymentSchemaTypeDomain.getValueById(ar.type) : ''}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </div>

                    <div className="modal-footer" style={{
                        position: "fixed",
                        left: 0,
                        bottom: 0,
                        width: "100%",
                        display: "none"
                    }}>
                        <Row>
                            <Col lg={12}>
                                <div className="text-end">

                                    {
                                        this.state.entity.status === 'A' ? (
                                            <button
                                                type="submit"
                                                className="btn btn-sm btn-light w-sm waves-effect waves-light"
                                                disabled={this.state.optoutLoading}
                                                onClick={() => {
                                                    this.openOptoutConfirm(this.state.entity);
                                                }}
                                            >
                                                {
                                                    this.state.optoutLoading ?
                                                        <div
                                                            className="spinner-border text-primary m-1"
                                                            style={{
                                                                fontSize: 5,
                                                                height: 15,
                                                                width: 15
                                                            }}
                                                            role="status"
                                                        >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                                        </div>
                                                        :
                                                        <i className="bx bx-log-out font-size-20" />
                                                }
                                                Opt-out
                                            </button>
                                        ) : ("")
                                    }
                                    {" "}
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-light w-sm waves-effect waves-light"
                                        disabled={this.state.termDownloading}
                                        onClick={() => {
                                            this.downloadTerm(this.state.entity);
                                        }}
                                    >
                                        {
                                            this.state.termDownloading ?
                                                <div
                                                    className="spinner-border text-primary m-1"
                                                    style={{
                                                        fontSize: 5,
                                                        height: 15,
                                                        width: 15
                                                    }}
                                                    role="status"
                                                >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                                </div>
                                                :
                                                <i className="mdi mdi-download font-size-20" />

                                        }
                                        Baixar Termo
                                    </button>
                                    {" "}
                                </div>
                            </Col>
                        </Row>
                    </div>

                    {
                        this.state.error ? (
                            <ErrorDialog
                                title={this.state.error['title']}
                                onClose={() => {
                                    this.setError(null)
                                }}
                            >
                                <p>{this.state.error['detail']} </p>
                            </ErrorDialog>
                        ) : ("")
                    }

                    {
                        this.state.successMessage ? (
                            <SuccessDialog
                                title={this.state.successMessage['title']}
                                onClose={() => {
                                    this.setSuccessMessage(null)
                                }}
                            >
                                <p>{this.state.successMessage['detail']} </p>
                            </SuccessDialog>
                        ) : ("")
                    }

                </Modal>

            </React.Fragment>
        )
    }
}

export default withTranslation()(ManageOptinView)