import React, { useState } from "react";
import { Popover, PopoverBody } from "reactstrap";

const PopoverInfo = ({ isOpen, target, color, toggle }) => {
  let info;

  switch (color) {
    case "#8FFE09":
      info = "Saldo disponível e sem bloqueios";
      break;
    case "#FF7F00":
      info = "Crédito bloqueado";
      break;
    case "red":
      info = "Credito indisponível";
      break;
    default:
      info = "Informação não disponível";
  }

  return (
    <Popover isOpen={isOpen} target={target} toggle={toggle}>
      <PopoverBody>{info}</PopoverBody>
    </Popover>
  );
};

const SvgStatus = ({ color, index }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const statusId = `Popover${index}`;

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const handleMouseEnter = () => {
    setPopoverOpen(true);
  };

  const handleMouseLeave = () => {
    setPopoverOpen(false);
  };

  return (
    <>
      <svg
        id={statusId}
        width="30"
        height="30"
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <circle cx="20" cy="20" r="10" fill={color} />
      </svg>
      <PopoverInfo
        isOpen={popoverOpen}
        target={statusId}
        color={color}
        toggle={togglePopover}
      />
    </>
  );
};

export default SvgStatus;
