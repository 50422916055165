import React, {useState} from 'react'

import {withTranslation} from "react-i18next"
import {Col, Modal, Row} from "reactstrap";
import {Link} from "react-router-dom";
import $filter from "../../../common/utils/$filter";
import checkSuccess from "../../../assets/images/check-success.svg"

function AnticipationContractFinish(props) {

    const [contract] = useState(props.contract)
    const [additionalInfo] = useState(JSON.parse(
        props.contract['additionalInfo']
    ))

    return (
        <React.Fragment>
             <div className="text-center" style={{padding: 50}}>>
                 <Row className="mt-0">
                     <Col>
                         <img src={checkSuccess} alt="" height="160"  />
                     </Col>
                 </Row>
                 <Row className="text-center mb-5 mt-3">
                     <Col lg={12}>
                         <h5>{props.t("Successfully requested advance")}!</h5>
                     </Col>
                 </Row>

                 <Row>
                     <Col lg={5} className="text-start">
                         <h5>{props.t("Amount to receive")}</h5>
                     </Col>
                     <Col lg={7} className="text-end mb-0">
                         <h5>{$filter(additionalInfo['mainValue'], 'MONEY')}</h5>
                     </Col>

                 </Row>

                 <hr style={{borderTop: "1px solid rgb(187 187 187)"}}/>


                 <Row>
                     <Col lg={5} className="text-start">
                         <label className="form-label">{props.t("Total Advance")}</label>
                     </Col>
                     <Col lg={7} className="text-end mb-0">
                         <label className="form-label">{$filter(contract['debitBalance'], 'MONEY')}</label>
                     </Col>
                 </Row>

                 <hr style={{borderTop: "1px solid rgb(187 187 187)"}}/>

                 <Row>
                     <Col lg={5} className="text-start">
                         <label className="form-label">{props.t("Discount")}</label>
                     </Col>
                     <Col lg={7} className="text-end mb-0">
                         <label className="form-label">{$filter(additionalInfo['discountValue'], 'MONEY')}</label>
                     </Col>

                 </Row>

                 <hr style={{borderTop: "1px solid rgb(187 187 187)"}}/>

                 <Row>
                     <Col lg={5} className="text-start">
                         <label className="form-label">{props.t("Reference Code")}</label>
                     </Col>
                 </Row>
                 <Row>
                     <Col lg={7} className="text-end">
                         <label className="form-label">{contract['externalReference']}</label>
                     </Col>
                 </Row>

                 <Row className="mt-5">
                     <Col lg={12} className="text-center">
                         <Link
                             style={{fontWeight: "600"}}
                             className="w-sm waves-effect waves-light"
                             to={"/contracts/manage-contracts?codeRef=" + contract['externalReference']}
                         >
                             {props.t("Follow Request")}
                         </Link>
                         <br/>
                         <label className="form-label">{props.t("Processing")}</label>
                     </Col>
                 </Row>
            </div>

        </React.Fragment>
    )
}

export default withTranslation()(AnticipationContractFinish)