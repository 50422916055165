import React from 'react'
import { Card, CardBody, Table} from "reactstrap"

import {withTranslation} from "react-i18next"
import $filter from "../../common/utils/$filter";

const OrderViewReceivables = (props) => {

    return (
        <React.Fragment>
            <Card>
                <CardBody>

                    <div className="table-responsive">
                        <Table
                            className="table table-striped table-bordered font-size-default">
                            <thead className="table-light">
                            <tr>
                                <th className="text-start">#</th>
                                <th>Credenciador/sub</th>
                                <th>Data de vencimento</th>
                                <th>Valor onerado</th>
                                <th>Saldo em chargeback</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                (props.receivables || []).map((rec, index)=>{
                                    return (
                                        <tr key={index}>
                                            <td className="text-start">{index+1}</td>
                                            <td>
                                                {rec.acquirer.name} <br/>
                                                <p className="card-title-desc mb-0">
                                                    {rec.arrangement.name}
                                                </p>
                                            </td>
                                            <td>
                                                {$filter(rec.dueDate, 'DATE')}
                                            </td>
                                            <td>
                                                {$filter(rec.amountCharged, 'MONEY')}
                                            </td>
                                            <td>
                                                {$filter(rec.chargebackBalance, 'MONEY')}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                    </div>

                </CardBody>
            </Card>

        </React.Fragment>
    )

}

export default withTranslation()(OrderViewReceivables)