import React, {useEffect, useState} from "react"
import {withTranslation} from "react-i18next";

import {Row, Alert, Col} from "reactstrap"
import {Link, useHistory, withRouter} from "react-router-dom"

import {AvForm, AvField} from "availity-reactstrap-validation"

import Loader from "../../components/Common/Loader";
import {post} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";

const ConfirmEmail = (props) => {
    const [loading, setLoading] = useState(false)

    const history = useHistory()

    document.body.classList.add("login_page")

    const handleValidSubmit = async (event, values) => {
        event.preventDefault()
        try {
            await post(
                url.POST_CONFIRM_EMAIL, {
                    token: props.entity.token,
                    email: values.email
                }, null, setLoading
            )
            props.onSuccess(Object.assign({email: values.email}, props.entity))

        } catch (e) {
            props.onFailure(e)
        }
    }

    const backPage = () => {
        history.push('/login')
        window.location.reload(true)
    }

    return (
        <React.Fragment>
            <div>
                <h3>{props.t("Password recovery")}</h3>
            </div>

            <div className="mt-4 pt-3">

                <Alert color="success" className="text-center mb-4 mt-2">
                    {props.t("Enter your email and instructions will be sent to you")}
                </Alert>

                <AvForm className="form-horizontal"
                        onValidSubmit={handleValidSubmit}
                >
                    <Row className="mb-4">
                        <div>
                            <AvField
                                name="email"
                                label="Email"
                                className="form-control"
                                placeholder={props.t("Enter your email here")}
                                type="email"
                                required
                                errorMessage={props.t("Invalid email")}
                                style={{height: 48}}
                            />
                        </div>
                    </Row>

                    <Row>
                        <Col lg={12} className="mt-4 text-center">
                            <button
                                className="btn btn-primary w-100 waves-effect waves-light"
                                type="submit"
                                style={{height: 42}}
                            >
                                {props.t("Send")}
                            </button>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12} className="mt-4 text-center">
                            <Link
                                to="#!"
                                onClick={backPage}
                                style={{height: 42}}
                            >
                                {props.t("Back")}
                            </Link>
                        </Col>
                    </Row>
                </AvForm>
            </div>

            {
                loading ?
                    <Loader loading={loading}/>
                    :
                    (" ")
            }

        </React.Fragment>
    )
}


export default withRouter(withTranslation()(ConfirmEmail))

