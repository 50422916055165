import React, {useState} from "react"
import {withTranslation} from "react-i18next";

import {Row, Col} from "reactstrap"
import { withRouter,} from "react-router-dom"
import $filter from "../../common/utils/$filter";
import GenericModal from "../../components/Common/GenericModal";

function CheckoutSelectInstallments (props) {

    const [modalInstallments, setModalInstallments] = useState(false)
    const [paymentOptionSelected, setPaymentOptionSelected] = useState(null)

    const [paymentOptions] = useState(
        props.paymentOptions.sort((a,b)=>{
            return a.numberInstallments - b.numberInstallments
        })
    )

    const closeModalInstallments = () => {
        setModalInstallments(false)
    }

    const handleSelectlInstallment = (paymentOption) => {
        if (props.onChange){
            props.onChange(paymentOption)
        }
        setPaymentOptionSelected(paymentOption)
        setModalInstallments(false)
    }

    return (
        <React.Fragment>
            <button
                className="btn w-100 btn-select"
                onClick={()=>{
                    setModalInstallments(true)
                }}
            >
                {paymentOptionSelected ?
                    <strong>{props.t("Change installment")}</strong>
                    :
                    <strong>{props.t("How often would you like to pay?")}</strong>
                }
            </button>

            { modalInstallments ?
                <GenericModal
                    isOpen={modalInstallments}
                    callback={()=>{
                        closeModalInstallments()
                    }}
                >
                    <React.Fragment>

                        <Row className="mb-5">
                            <h4>
                                {props.t("How often would you like to pay?")}
                            </h4>
                        </Row>

                        {
                            paymentOptions.map((option)=>{
                                let parcelaLabel = 'parcela de'
                                if (option.numberInstallments > 1)
                                    parcelaLabel = 'parcelas de'

                                return (
                                    <React.Fragment>
                                        <div className="pt-2 ps-3 pe-5 pb-2 mb-3"
                                             style={{cursor: "pointer"}}
                                             onClick={()=>{
                                                 handleSelectlInstallment(option)
                                             }}>

                                            <Row>
                                                <Col lg={7}>
                                                    <strong className="font-size-16">
                                                        {option.numberInstallments}&nbsp;{parcelaLabel}&nbsp;{$filter(option.installmentMainAmount, 'MONEY')}
                                                    </strong>
                                                </Col>

                                                <Col lg={5}>
                                                    <span className="badge rounded-pill bg-light badge-md">Sem juros</span>
                                                </Col>

                                            </Row>

                                            <Row className="mt-2">
                                                <Col lg={7}>
                                                    <p className="static-label">Total a pagar: {$filter(option.mainAmount, 'MONEY')}</p>
                                                </Col>
                                                <Col lg={5}>
                                                    <p className="static-label">prazo: {option.paymentPeriod}</p>
                                                </Col>
                                            </Row>
                                        </div>
                                        <hr/>
                                    </React.Fragment>
                                )
                            })
                        }


                    </React.Fragment>
                </GenericModal>
                : ""
            }
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(CheckoutSelectInstallments))
