import {uuid} from "uuidv4";

class ExternalRefUtils {

    static CONTRACT = 'C';
    static OPTIN = 'O';
    static GUARANTEE = 'G';

    static oid(prefix) {
        return `CTF${prefix}${uuid().split('-').join('')}`
    }
}

export default ExternalRefUtils