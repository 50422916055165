import React, {useEffect, useState, useRef} from 'react';
import MetaTags from 'react-meta-tags';
import {
    Alert,
    Card,
    CardBody,
    Col,
    Container, DropdownItem, DropdownMenu, DropdownToggle,
    Form, Input,
    Label, Modal,
    NavItem,
    NavLink,
    Row,
    TabContent,
    Table,
    TabPane, UncontrolledAlert,
    UncontrolledDropdown,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {Link, useHistory, useParams, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import classnames from "classnames";

import * as url from "../../helpers/url_helper"
import {post} from "../../helpers/api_helper"
import Loader from "../../components/Common/Loader";

import $filterEl from "../../common/utils/$filterEl";
import $filter from "../../common/utils/$filter";
import ExternalRefUtils from "../../common/utils/ExternalRefUtils";
import ArrangementsDomain from "../../domains/ArrangementsDomain";
import AcquiresDomain from "../../domains/AcquiresDomain";
import DivisionRuleDomain from "../../domains/DivisionRuleDomain";
import DistributionTypeDomain from "../../domains/DistributionTypeDomain";
import ArrangementsTypeDomain from "../../domains/ArrangementsTypeDomain";
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";
import CpfCnpjUtils from "../../common/utils/CpfCnpjUtils";
import SweetAlert from "react-bootstrap-sweetalert";
import EffetiveTypeDomain from "../../domains/EffetiveTypeDomain";
import ServiceTypeDomain from "../../domains/ServiceTypeDomain";
import ModalityDomain from "../../domains/ModalityDomain";
import YesOrNoDomain from "../../domains/YesOrNoDomain";
import PaymentCompanyDomain from "../../domains/PaymentCompanyDomain"
import AccountTypeDomain from "../../domains/AccountTypeDomain"

const animatedComponents = makeAnimated();

const optionEffectType = EffetiveTypeDomain.options;

const optionServiceType = [
    {
        options: [
            {label: "Registro simples", value: "1"},
            {label: "Gestão automática de garantias", value: "2"},
            {label: "Monitoramento", value: "3"}
        ],
    },
];
const optionUnconditionalAcceptance = [
    {
        options: [
            {label: "Aceitar", value: "true"},
            {label: "Recusar", value: "false"}
        ],
    },
];
const optionEvaluationType = [
    {
        options: [
            {label: "Avaliação simples de agenda", value: "1"},
            {label: "Avaliação completa de agenda", value: "2"},
            {label: "Avaliação simples de contrato", value: "3"},
            {label: "Avaliação completa de contrato", value: "4"},
        ],
    },
];
const optionModality = [
    {
        options: [
            {label: "Rotativo", value: "1"},
            {label: "Parcelado", value: "2"},
            {label: "Cessão", value: "3"},
        ],
    },
];
const optionYesOrNo = [
    {
        options: [
            {label: "Sim", value: true},
            {label: "Não", value: false},
        ],
    },
];
const optionAccountType = [
    {
        options: AccountTypeDomain.options
    },
];
const optionFinancialInstitution = [
    {
        options: PaymentCompanyDomain.options
    },
];

const optionArranjements = ArrangementsDomain.options
const optionAcquires = AcquiresDomain.options

const optionDivisionRule = [
    {
        options: DivisionRuleDomain.options
    },
];

const optionDistibuitionType = [
    {
        options: DistributionTypeDomain.options
    },
];

function ManageContractsEdit(props) {

    const arrangementsRef = useRef()
    const acquirersRef = useRef()
    const allAcquirersRef = useRef()

    const userEffectType = useRef()

    const [activeTab, setactiveTab] = useState(1);
    const [passedSteps, setPassedSteps] = useState([1]);
    const [modalGuarante, setModalGuaranteCore] = useState(false);
    const [confirmGuaranteeDeleteAlert, setConfirmGuaranteeDeleteAlert] = useState(false);
    const [guaranteeToDelete, setGuaranteeToDelete] = useState({});

    const history = useHistory();
    const cancel = () => history.push('/contracts/manage-contracts');
    const navigateToHome = (contractId) => history.push(`/contracts/manage-contracts?contractId=${contractId}`);

    const [loading, setLoading] = useState(false);
    const [invalidFields, setInvalidFields] = useState({});
    const [hasInstallments, setHasInstallments] = useState(false);
    const [hasPriorContracts, setHasPriorContracts] = useState(false);

    const [state, setState] = useState({
        registerAgent: {legalId: "17773640000137"},
        financialAgent: {legalId: "17773640000137"},
        externalReference: ExternalRefUtils.oid(ExternalRefUtils.CONTRACT),
        creditorAccount: {
            taxIdOwner: ""
        },
        contractor: {
            legalId: "17773640000137"
        },
        guarantees: [],
        installments: []
    });

    const [newGuarantee, setNewGuaranteeCore] = useState({
        externalReference: '',
        acquirers: [],
        allAcquirers: 'ALL',
        allArrangements: 'ALL_CREDITORS'
    })

    const setNewGuarantee = (newValue) => {
        setNewGuaranteeCore(newValue)
        setNewGuaranteeAcquirers(newValue['acquirers'])
        setNewGuaranteeArrangements(newValue['arrangements'])
    }
    const [newGuaranteeAcquirers, setNewGuaranteeAcquirers] = useState([])
    const [newGuaranteeArrangements, setNewGuaranteeArrangements] = useState([])

    const handleNewGuarantee = (event) => {
        newGuarantee[event.target.name] = event.target.value;
        setNewGuarantee(newGuarantee);
    }

    const handleNewGuaranteeWithMask = (event, maskType) => {
        const {valueWithMask, unmaskedValue} = $filterEl(event, maskType)
        newGuarantee[event.target.name] = unmaskedValue;
        setNewGuarantee(newGuarantee);
    }

    const handleNewGuaranteeReceivingEndUser = (event) => {
        const {valueWithMask, unmaskedValue} = $filterEl(event, 'CPF_CNPJ')
        newGuarantee['receivingEndUser'] = {
            legalId: unmaskedValue
        }
        setNewGuarantee(newGuarantee)
    }

    const handleNewGuaranteeHolder = (event) => {
        const {valueWithMask, unmaskedValue} = $filterEl(event, 'CPF_CNPJ')
        newGuarantee['holder'] = {
            legalId: unmaskedValue
        }
        setNewGuarantee(newGuarantee);
    }

    const handleNewGuaranteeDivisionRule = (option) => {
        newGuarantee['divisionRule'] = option.value
        setNewGuarantee(newGuarantee);
    }
    const handleNewGuaranteeDistribuitionType = (option) => {
        newGuarantee['typeDistribution'] = option.value
        setNewGuarantee(newGuarantee);
    }

    const setStateField = (name, value) => {
        state[name] = value
        setState(state);
    }
    const handleChange = (event) => {
        setStateField(event.target.name, event.target.value)
    }
    const handleChangeFilterWithMask = (event, maskType) => {
        // apply mask in input field
        const {valueWithMask, unmaskedValue} = $filterEl(event, maskType)
        setStateField(event.target.name, unmaskedValue)
    }

    const handleLegalIdFinancialAgent = (event) => {
        // apply mask in input field
        const {valueWithMask, unmaskedValue} = $filterEl(event, 'CPF_CNPJ')

        const financialAgent = state['financialAgent'] || {legalId: null}
        financialAgent['legalId'] = unmaskedValue
        setStateField('financialAgent', financialAgent)

        const creditorAccount = state['creditorAccount']
        creditorAccount['taxIdOwner'] = financialAgent['legalId']
        setStateField('creditorAccount', creditorAccount)
        setElementValue(
            'inputCreditorAccountTaxIdNumber',
            $filter(financialAgent['legalId'], 'CPF_CNPJ')
        )

        // rebuild external ref based in financial agent tax id
        let extRef = state['externalReference'].substr(0, 36)
        const newExtRef = `${extRef}${financialAgent['legalId'].substr(0, 8)}`
        setStateField('externalReference', newExtRef)
        setElementValue(
            'inputExternalReference', newExtRef
        )
        copyExternalTefToContractId(newExtRef)
        setCreditorAccount(
            'taxIdOwner', financialAgent.legalId
        )
    }

    const copyExternalTefToContractId = (externalRef) => {
        let newValue = externalRef.substr(4, externalRef.length)
        setStateField('contractId', newValue)
        setElementValue(
            'inputContractId', newValue
        )
    }

    const handleLegalIdContractor = (event) => {
        // apply mask in input field
        const {valueWithMask, unmaskedValue} = $filterEl(event, 'CPF_CNPJ')
        const contractor = state['contractor'] || {legalId: null}
        contractor['legalId'] = unmaskedValue
        setStateField('contractor', contractor)
    }

    const handleSelect = (fieldName, option) => {
        setStateField(fieldName, option['value'])
    }

    const handleRenegociation = (option) => {
        setStateField('renegotiation', option['value'])
        if (option['value']) {
            setHasPriorContracts(true)
        } else {
            setHasPriorContracts(false)
        }
    }

    const setCreditorAccount = (fieldName, value) => {
        const creditorAccount = state.creditorAccount;
        creditorAccount[fieldName] = value
        setStateField('creditorAccount', creditorAccount)
    }

    const handleCreditorAccountWithMask = (event, type) => {
        const {valueWithMask, unmaskedValue} = $filterEl(event, type)
        const creditorAccount = state.creditorAccount;
        creditorAccount[event.target.name] = unmaskedValue
        setStateField('creditorAccount', creditorAccount)
    }

    const handleModality = (option) => {
        setStateField('modality', option['value'])
        if (option['value'] === '2') {
            setHasInstallments(true)
        } else {
            setHasInstallments(false)
        }
    }

    const handleSubmiNextStep = (event) => {
        event.preventDefault();
        toggleTab(activeTab + 1)
    }

    const [entitySupport, setEntitySupportCore] = useState({
        creditorAccount: {
            accountType: null
        }
    });

    useEffect(() => {
        const newContractByUr = localStorage.getItem("newContractByUr")
        if (newContractByUr !== null) {
            const newContract = JSON.parse(newContractByUr)

            localStorage.removeItem("newContractByUr")
            setState(newContract)
            setElementValue(
                'inputContractId', newContract['contractId']
            )
            setElementValue(
                'inputLegalIdHolder', $filter(newContract['financialAgent']['legalId'], 'CPF_CNPJ')
            )
            setElementValue(
                'inputLegalIdContractor', $filter(newContract['contractor']['legalId'], 'CPF_CNPJ')
            )
            setElementValue(
                'inputDebitBalance', $filter(newContract['debitBalance'], 'MONEY')
            )
            setElementValue(
                'inputGuaranteedLimit', $filter(newContract['debitBalance'], 'MONEY')
            )
            setElementValue(
                'inputMinimumValueMaintained', $filter(newContract['debitBalance'], 'MONEY')
            )
        }

        setElementValue(
            'inputExternalReference', state['externalReference']
        )
        setElementValue(
            'inputLegalIdParticipant', $filter(state['registerAgent']['legalId'], 'CPF_CNPJ')
        )
    })

    const handleNewGuaranteeAcquirers = (acquirers) => {
        const allAcquirers = (acquirers || []).length > 0 ? null : 'ALL'

        newGuarantee['acquirers'] = (acquirers || []).length > 0 ? acquirers : null
        newGuarantee['allAcquirers'] = allAcquirers
        setNewGuarantee(newGuarantee)

        allAcquirersRef.current.checked = allAcquirers === 'ALL'
    }

    const handleNewGuaranteeAllAcquirers = (event) => {
        newGuarantee['acquirers'] = null
        newGuarantee['allAcquirers'] = event.target.checked ? 'ALL' : null
        setNewGuarantee(newGuarantee)

        acquirersRef.current.select.clearValue();
    }

    const setElementValue = (elName, value) => {
        document.getElementById(elName).value = value
    }

    const setEntitySupport = (fieldName, value) => {
        entitySupport[fieldName] = value
        setEntitySupportCore(entitySupport)
    }

    let {id} = useParams();
    const [pageParams] = useState(() => {
        if (id) {
            return {
                id: id,
                title: props.t("Edit contract"),
                editMode: true
            }
        }
        return {
            id: null,
            title: props.t("Register contract"),
            editMode: false
        }
    });

    const handleAddGuarantee = (event) => {
        let edit = false
        for (const i in state['guarantees']) {
            if (state['guarantees'][i]['externalReference'] === newGuarantee['externalReference']) {
                state['guarantees'][i] = newGuarantee
                edit = true
            }
        }
        if (!edit) {
            state['guarantees'].push(newGuarantee)
        }
        setState(state)
        closeModalGuarante()
    }

    const handleNewGuaranteeArrangements = (arrangements) => {
        const arrangementsMap = (arrangements || [])
        newGuarantee['arrangements'] = arrangementsMap.length > 0 ? arrangementsMap : null

        if (arrangementsMap.length > 0) {
            newGuarantee['allArrangements'] = null

            document.getElementById('checkAllArrangements').checked = false
            document.getElementById('checkAllDebitorArrangements').checked = false
            document.getElementById('checkAllCreditorArrangements').checked = false
        } else {
            if (newGuarantee['allArrangements'] == null) {
                newGuarantee['allArrangements'] = 'ALL_CREDITORS'
                document.getElementById('checkAllCreditorArrangements').checked = true

            } else if (newGuarantee['allArrangements'] === 'ALL_CREDITORS') {
                document.getElementById('checkAllCreditorArrangements').checked = true

            } else if (newGuarantee['allArrangements'] === 'ALL_DEBITORS') {
                document.getElementById('checkAllDebitorArrangements').checked = true

            } else if (newGuarantee['allArrangements'] === 'ALL') {
                document.getElementById('checkAllArrangements').checked = true
            }
        }
        setNewGuarantee(newGuarantee)
    }

    const handleAllArrangements = (event, allArrangementsType) => {
        newGuarantee['allArrangements'] = allArrangementsType
        newGuarantee['arrangements'] = null
        setNewGuarantee(newGuarantee)
        arrangementsRef.current.select.clearValue()
    }

    const addInstallment = () => {
        state.installments.push({
            installmentNumber: (state.installments + 1),
            dueDate: null,
            amount: 0
        })
    }

    const addInstallments = () => {
        state.installments = []
        const installmentNumbers = Number(entitySupport['installmentNumbers'])
        const firstDueDate = Number(entitySupport['firstDueDate'])
        const debitBalance = Number(state['debitBalance'])

        for (let i = 0; i < installmentNumbers; i++) {
            state.installments.push({
                installmentNumber: i + 1,
                dueDate: firstDueDate,
                amount: (debitBalance / installmentNumbers)
            })
        }
        setState(state)
    }

    const closeModalGuarante = () => {
        setNewGuarantee({})
        setModalGuaranteCore(false)
    }

    const save = async () => {
        try {
            const response = await post(url.POST_CONTRACT, state, null, setLoading)
            navigateToHome(response['contract']['contractId']);
        } catch (e) {
        }
    }

    const openModalDeleteGuarantee = (extRef) => {
        setGuaranteeToDelete({
            externalReference: extRef
        })
        setConfirmGuaranteeDeleteAlert(true)
    }

    const deleteGuarantee = () => {
        const extRef = guaranteeToDelete['externalReference']
        const newGuarantee = []
        for (const i in state.guarantees) {
            if (state.guarantees[i].externalReference !== extRef) {
                newGuarantee.push(
                    state.guarantees[i]
                )
            }
        }
        state['guarantees'] = newGuarantee
        setState(state)
        setConfirmGuaranteeDeleteAlert(false)
    }

    const openModalNewGuarantee = (editMode, extRefEdit) => {
        let guaranteeEdit = newGuarantee

        if (editMode) {
            guaranteeEdit = state.guarantees.filter((gua) => {
                return gua['externalReference'] === extRefEdit
            })[0]

            guaranteeEdit['acquirers'] = AcquiresDomain.getInstancesByValues(
                (guaranteeEdit['acquirers'] || []).map((ac) => {
                    return ac['legalId']
                })
            )
            guaranteeEdit['arrangements'] = ArrangementsDomain.getInstancesByValues(
                (guaranteeEdit['arrangements'] || []).map((ac) => {
                    return ac['code']
                })
            )
        } else {
            let extRef = ExternalRefUtils.oid(ExternalRefUtils.GUARANTEE)
            const financialAgent = state['financialAgent']
            extRef = `${extRef}${financialAgent['legalId'].substr(0, 8)}`

            guaranteeEdit = {
                externalReference: extRef,
                acquirers: [],
                allAcquirers: 'ALL',
                allArrangements: 'ALL_CREDITORS'
            }
        }
        setNewGuarantee(guaranteeEdit)
        togModalGuarante(guaranteeEdit)
    }

    const togModalGuarante = (guaranteeEdit) => {
        setModalGuaranteCore(!modalGuarante);
        removeBodyCss();
    }

    const printContract = () => {
        window.print()
    }

    const setModalGuaranteValues = () => {
        if (newGuarantee['receivingEndUser'] && newGuarantee['receivingEndUser']['legalId']) {
            setElementValue(
                'inputGuaranteeLegalIdReceiving',
                $filter(newGuarantee['receivingEndUser']['legalId'], 'CPF_CNPJ')
            )
        }
        if (newGuarantee['holder'] && newGuarantee['holder']['legalId']) {
            setElementValue(
                'inputGuaranteeLegalIdHolder',
                $filter(newGuarantee['holder']['legalId'], 'CPF_CNPJ')
            )
        }
        handleNewGuaranteeArrangements(
            newGuarantee['arrangements']
        )
        handleNewGuaranteeAcquirers(
            newGuarantee['acquirers']
        )
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function toggleTab(tab) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 5) {
                var modifiedSteps = [...passedSteps, tab];
                setactiveTab(tab);
                setPassedSteps(modifiedSteps);
            }
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{pageParams.title}</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title={props.t("Contracts")} breadcrumbItem={pageParams.title}/>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <div className="wizard clearfix">
                                        <div className="steps clearfix d-print-none">
                                            <ul>
                                                <NavItem
                                                    className={classnames({current: activeTab === 1})}
                                                >
                                                    <NavLink
                                                        className={classnames({current: activeTab === 1})}
                                                        onClick={() => {
                                                            setactiveTab(1);
                                                        }}
                                                    >
                                                        <span className="number">01</span> {props.t("Contract")}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem
                                                    className={classnames({current: activeTab === 2})}
                                                >
                                                    <NavLink
                                                        disabled={!(passedSteps || []).includes(2)}
                                                        className={classnames({active: activeTab === 2})}
                                                        onClick={() => {
                                                            setactiveTab(2);
                                                        }}
                                                    >
                                                        <span
                                                            className="number ms-2">02</span> {props.t("Credit information")}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem
                                                    className={classnames({current: activeTab === 3})}
                                                >
                                                    <NavLink
                                                        disabled={!(passedSteps || []).includes(3)}
                                                        className={classnames({active: activeTab === 3})}
                                                        onClick={() => {
                                                            setactiveTab(3);
                                                        }}
                                                    >
                                                        <span
                                                            className="number">03</span> {props.t("Payment data")}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem
                                                    className={classnames({current: activeTab === 4})}
                                                >
                                                    <NavLink
                                                        disabled={!(passedSteps || []).includes(4)}
                                                        className={classnames({active: activeTab === 4})}
                                                        onClick={() => {
                                                            setactiveTab(4);
                                                        }}
                                                    >
                                                        <span className="number">04</span> {props.t("Guarantees")}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem
                                                    className={classnames({current: activeTab === 5})}
                                                >
                                                    <NavLink
                                                        disabled={!(passedSteps || []).includes(5)}
                                                        className={classnames({active: activeTab === 5})}
                                                        onClick={() => {
                                                            setactiveTab(5);
                                                        }}
                                                    >
                                                        <span className="number">05</span> {props.t("Confirm Detail")}
                                                    </NavLink>
                                                </NavItem>
                                            </ul>
                                        </div>
                                        <div className="content clearfix mt-4">
                                            <TabContent activeTab={activeTab}>
                                                {/*Contract information*/}
                                                <TabPane tabId={1}>
                                                    <Form
                                                        className="needs-validation"
                                                        id="formContractInfo"
                                                        onSubmit={(e) => {
                                                            handleSubmiNextStep(e);
                                                        }}
                                                    >
                                                        {/*Control information"*/}
                                                        <Row className="mb-3">
                                                            <p className="card-title-desc">{props.t("Control information")}</p>

                                                            <Col lg={4}>
                                                                <div className="form-floating mb-2">
                                                                    <input type="text"
                                                                           className="form-control"
                                                                           id="inputExternalReference"
                                                                           disabled
                                                                           placeholder="."/>
                                                                    <Label
                                                                        for="inputExternalReference">{props.t("External Reference")}</Label>
                                                                </div>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <div className="form-floating mb-2">
                                                                    <input type="text"
                                                                           className="form-control"
                                                                           id="inputContractId"
                                                                           disabled
                                                                           placeholder="."/>
                                                                    <Label
                                                                        for="inputContractId">{props.t("Contract ID")}</Label>
                                                                </div>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <div className="form-floating mb-2">
                                                                    <input type="date"
                                                                           className="form-control"
                                                                           id="inputSignatureDate"
                                                                           name="signatureDate"
                                                                           required
                                                                           onChange={event => handleChange(event)}
                                                                           placeholder="."/>
                                                                    <Label
                                                                        for="inputSignatureDate">{props.t("Signature date")}</Label>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        {/*Contract information*/}
                                                        <Row>
                                                            <p className="card-title-desc">{props.t("Contract information")}</p>
                                                            <Col lg={4}>
                                                                <div className="form-floating mb-2">
                                                                    <input type="text"
                                                                           className="form-control"
                                                                           id="inputLegalIdParticipant"
                                                                           disabled
                                                                           placeholder="."/>
                                                                    <Label
                                                                        for="inputLegalIdParticipant">{props.t("Legal ID participant")}</Label>
                                                                </div>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <div className="form-floating mb-2">
                                                                    <input type="text"
                                                                           className="form-control"
                                                                           id="inputLegalIdHolder"
                                                                           required
                                                                           pattern={CpfCnpjUtils.CPF_CNPJ_PATTERN}
                                                                           title={props.t("Invalid legal ID")}
                                                                           autoComplete="off"
                                                                           maxLength={18}
                                                                           minLength={14}
                                                                           onChange={event => handleLegalIdFinancialAgent(event)}
                                                                           placeholder="."/>
                                                                    <Label
                                                                        for="inputLegalIdHolder">{props.t("Legal ID Financial Agent")}</Label>
                                                                </div>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <div className="form-floating mb-2">
                                                                    <input type="text"
                                                                           className="form-control"
                                                                           id="inputLegalIdContractor"
                                                                           required
                                                                           pattern={CpfCnpjUtils.CPF_CNPJ_PATTERN}
                                                                           title={props.t("Invalid legal ID")}
                                                                           autoComplete="off"
                                                                           maxLength={18}
                                                                           minLength={14}
                                                                           onChange={event => handleLegalIdContractor(event)}
                                                                           placeholder="."/>
                                                                    <Label
                                                                        for="inputLegalIdContractor">{props.t("Legal ID contractor")}</Label>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col lg={4}>
                                                                <div className="form-floating mb-2">
                                                                    <div
                                                                        className="templating-select select2-container mb-2 ">
                                                                        <Select
                                                                            isMulti={false}
                                                                            required
                                                                            className={
                                                                                "select2-floating" +
                                                                                (invalidFields['evaluationType'] ? "-invalid" : "")
                                                                            }
                                                                            onChange={option => handleSelect(
                                                                                'effectType', option
                                                                            )}
                                                                            options={optionEffectType}
                                                                            classNamePrefix="select2-selection"
                                                                            closeMenuOnSelect={true}
                                                                            components={animatedComponents}
                                                                            placeholder={props.t("Effect type")}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <div className="form-floating mb-2">
                                                                    <div
                                                                        className="templating-select select2-container mb-2 ">
                                                                        <Select
                                                                            isMulti={false}
                                                                            className={
                                                                                "select2-floating" +
                                                                                (invalidFields['serviceType'] ? "-invalid" : "")
                                                                            }
                                                                            required
                                                                            onChange={option => handleSelect(
                                                                                'serviceType', option
                                                                            )}
                                                                            options={optionServiceType}
                                                                            classNamePrefix="select2-selection"
                                                                            closeMenuOnSelect={true}
                                                                            components={animatedComponents}
                                                                            placeholder={props.t("Service type")}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <div className="form-floating mb-2">
                                                                    <div
                                                                        className="templating-select select2-container mb-2 ">
                                                                        <Select
                                                                            isMulti={false}
                                                                            className={
                                                                                "select2-floating" +
                                                                                (invalidFields['unconditionalAccept'] ? "-invalid" : "")
                                                                            }
                                                                            required
                                                                            onChange={option => handleSelect(
                                                                                'unconditionalAccept', option
                                                                            )}
                                                                            options={optionUnconditionalAcceptance}
                                                                            classNamePrefix="select2-selection"
                                                                            closeMenuOnSelect={true}
                                                                            components={animatedComponents}
                                                                            placeholder={props.t("Unconditional acceptance of the transaction")}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col lg={4}>
                                                                <div className="form-floating mb-2">
                                                                    <div
                                                                        className="templating-select select2-container mb-2 ">
                                                                        <Select
                                                                            isMulti={false}
                                                                            className={
                                                                                "select2-floating" +
                                                                                (invalidFields['evaluationType'] ? "-invalid" : "")
                                                                            }
                                                                            required
                                                                            onChange={option => handleSelect(
                                                                                'evaluationType', option
                                                                            )}
                                                                            menuPlacement="top"
                                                                            options={optionEvaluationType}
                                                                            classNamePrefix="select2-selection"
                                                                            closeMenuOnSelect={true}
                                                                            components={animatedComponents}
                                                                            placeholder={props.t("Evaluation type")}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <div className="form-floating mb-2">
                                                                    <input type="date"
                                                                           className="form-control"
                                                                           id="inputDueDate"
                                                                           name="dueDate"
                                                                           required
                                                                           onChange={event => handleChange(event)}
                                                                           placeholder="."/>
                                                                    <Label
                                                                        for="inputDueDate">{props.t("Due date")}</Label>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        {/*Actions*/}
                                                        <Row className="mt-4">
                                                            <Col lg={12}>
                                                                <div className="text-end">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-sm btn-light w-sm waves-effect waves-light"
                                                                        onClick={() => {
                                                                            cancel();
                                                                        }}
                                                                    >
                                                                        {props.t("Cancel")}
                                                                    </button>
                                                                    {" "}
                                                                    <button
                                                                        type="button"
                                                                        className={
                                                                            activeTab === 1 ? "disabled btn btn-sm btn-primary w-sm waves-effect waves-light" :
                                                                                " btn btn-sm btn-primary w-sm waves-effect waves-light"
                                                                        }
                                                                        onClick={() => {
                                                                            toggleTab(activeTab - 1);
                                                                        }}
                                                                    >
                                                                        {props.t("Previous")}
                                                                    </button>
                                                                    {" "}
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-sm btn-primary w-sm waves-effect waves-light"
                                                                    >
                                                                        {props.t("Next")}
                                                                    </button>
                                                                    {" "}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </TabPane>

                                                {/*Credit information*/}
                                                <TabPane tabId={2}>
                                                    <Form
                                                        className="needs-validation"
                                                        id="formCrediInformation"
                                                        onSubmit={(e) => {
                                                            handleSubmiNextStep(e);
                                                        }}
                                                    >
                                                        <Row>
                                                            <Col lg={4}>
                                                                <div className="form-floating mb-2">
                                                                    <div
                                                                        className="templating-select select2-container mb-2 ">
                                                                        <Select
                                                                            isMulti={false}
                                                                            className="select2-floating"
                                                                            onChange={(option) => {
                                                                                handleModality(option);
                                                                            }}
                                                                            options={optionModality}
                                                                            classNamePrefix="select2-selection"
                                                                            closeMenuOnSelect={true}
                                                                            components={animatedComponents}
                                                                            placeholder={props.t("Modality")}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <div className="form-floating mb-2">
                                                                    <div
                                                                        className="templating-select select2-container mb-2 ">
                                                                        <Select
                                                                            isMulti={false}
                                                                            className="select2-floating"
                                                                            onChange={(option) => {
                                                                                handleRenegociation(option);
                                                                            }}
                                                                            options={optionYesOrNo}
                                                                            classNamePrefix="select2-selection"
                                                                            closeMenuOnSelect={true}
                                                                            components={animatedComponents}
                                                                            placeholder={props.t("Renegotiation")}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <div className="form-floating mb-2">
                                                                    <input type="text"
                                                                           className="form-control"
                                                                           id="inputIdPreviousContract"
                                                                           name="previousContractId"
                                                                           autoComplete="off"
                                                                           disabled={!hasPriorContracts}
                                                                           onChange={event => handleChange(event)}
                                                                           placeholder="."/>
                                                                    <Label
                                                                        for="inputIdPreviousContract">{props.t("ID(s) of previous contract(s)")}</Label>
                                                                </div>
                                                            </Col>

                                                        </Row>

                                                        <Row className="mb-3">

                                                            <Col lg={4}>
                                                                <div className="form-floating mb-2">
                                                                    <input type="text"
                                                                           className="form-control"
                                                                           id="inputDebitBalance"
                                                                           name="debitBalance"
                                                                           autoComplete="off"
                                                                           onChange={event => handleChangeFilterWithMask(
                                                                               event, 'MONEY'
                                                                           )}
                                                                           placeholder="."/>
                                                                    <Label
                                                                        for="inputDebitBalance">{props.t("Debit balance")}</Label>
                                                                </div>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <div className="form-floating mb-2">
                                                                    <input type="text"
                                                                           className="form-control"
                                                                           id="inputGuaranteedLimit"
                                                                           autoComplete="off"
                                                                           name="guaranteedOperationLimit"
                                                                           onChange={event => handleChangeFilterWithMask(
                                                                               event, 'MONEY'
                                                                           )}
                                                                           placeholder="."/>
                                                                    <Label
                                                                        for="inputGuaranteedLimit">{props.t("Guaranteed operation limit")}</Label>
                                                                </div>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <div className="form-floating mb-2">
                                                                    <input type="text"
                                                                           className="form-control"
                                                                           id="inputMinimumValueMaintained"
                                                                           name="valueMaintained"
                                                                           autoComplete="off"
                                                                           onChange={event => handleChangeFilterWithMask(
                                                                               event, 'MONEY'
                                                                           )}
                                                                           placeholder="."/>
                                                                    <Label
                                                                        for="inputMinimumValueMaintained">{props.t("Minimum value to be maintained")}</Label>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        {
                                                            hasInstallments ? (
                                                                <div>
                                                                    <Row className="mb-3">
                                                                        <p className="card-title-desc">{props.t("Installment information")}</p>

                                                                        <Col lg={2}>
                                                                            <div className="mb-2 form-floating">
                                                                                <input type="text"
                                                                                       className="form-control"
                                                                                       id="inputInstallmentNumbers"
                                                                                       onChange={event =>
                                                                                           setEntitySupport(
                                                                                               'installmentNumbers', event.target.value
                                                                                           )}
                                                                                       placeholder="."/>
                                                                                <Label
                                                                                    for="inputInstallmentNumbers">{props.t("Installment numbers")}</Label>
                                                                            </div>
                                                                        </Col>

                                                                        <Col lg={3}>
                                                                            <div className="form-floating mb-2">
                                                                                <input type="date"
                                                                                       className="form-control"
                                                                                       id="inputInstallmenFirstDueDay"
                                                                                       onChange={event =>
                                                                                           setEntitySupport(
                                                                                               'installmenFirstDueDay', event.target.value
                                                                                           )}
                                                                                       placeholder="."/>
                                                                                <Label
                                                                                    for="inputInstallmenFirstDueDay">{props.t("First due date")}</Label>
                                                                            </div>
                                                                        </Col>

                                                                        <Col lg={7}>
                                                                            <div>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary w-sm waves-effect waves-light"
                                                                                    onClick={() => {
                                                                                        addInstallments();
                                                                                    }}
                                                                                >
                                                                                    {props.t("Build installments")}
                                                                                </button>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    {
                                                                        state.installments.length > 0 ? (
                                                                            <Row>
                                                                                <Col lg={8}>
                                                                                    <div
                                                                                        className="table-responsive">
                                                                                        <Table
                                                                                            className="table table-striped mb-2 font-size-12">
                                                                                            <thead
                                                                                                className="table-light">
                                                                                            <tr>
                                                                                                <th>{props.t("Installment number")}</th>
                                                                                                <th>{props.t("Due date")}</th>
                                                                                                <th>{props.t("Amount")}</th>
                                                                                                <th></th>
                                                                                            </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                            {state.installments.map((installment, key) => (
                                                                                                <tr key={key}>
                                                                                                    <td scope="row">
                                                                                                        <strong>{installment.installmentNumber}</strong>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <input
                                                                                                            type="date"
                                                                                                            className="form-control"
                                                                                                            value={installment.dueDate}/>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            className="form-control"
                                                                                                            value={installment.amount}/>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                            </tbody>
                                                                                        </Table>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        ) : ("")
                                                                    }
                                                                </div>

                                                            ) : ("")
                                                        }

                                                        {/*Actions*/}
                                                        <Row className="mt-4">
                                                            <Col lg={12}>
                                                                <div className="text-end">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-sm btn-light w-sm waves-effect waves-light"
                                                                        onClick={() => {
                                                                            cancel();
                                                                        }}
                                                                    >
                                                                        {props.t("Cancel")}
                                                                    </button>
                                                                    {" "}
                                                                    <button
                                                                        type="button"
                                                                        className={
                                                                            activeTab === 1 ? "disabled btn btn-sm btn-primary w-sm waves-effect waves-light" :
                                                                                " btn btn-sm btn-primary w-sm waves-effect waves-light"
                                                                        }
                                                                        onClick={() => {
                                                                            toggleTab(activeTab - 1);
                                                                        }}
                                                                    >
                                                                        {props.t("Previous")}
                                                                    </button>
                                                                    {" "}
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-sm btn-primary w-sm waves-effect waves-light"
                                                                    >
                                                                        {props.t("Next")}
                                                                    </button>
                                                                    {" "}
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                    </Form>
                                                </TabPane>

                                                {/*Payment detail*/}
                                                <TabPane tabId={3}>
                                                    <Form
                                                        className="needs-validation"
                                                        id="formPaymentDetail"
                                                        onSubmit={(e) => {
                                                            handleSubmiNextStep(e);
                                                        }}
                                                    >
                                                        <Row>
                                                            <Col lg={6}>
                                                                <div className="form-floating mb-2">
                                                                    <input type="text"
                                                                           className="form-control"
                                                                           id="inputHolderName"
                                                                           name="ownerName"
                                                                           autoComplete="off"
                                                                           required
                                                                           onChange={event => setCreditorAccount(
                                                                               event.target.name,
                                                                               event.target.value
                                                                           )}
                                                                           placeholder="."/>
                                                                    <Label
                                                                        for="inputHolderName">{props.t("Holder name")}</Label>
                                                                </div>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <div className="form-floating mb-2">
                                                                    <input type="text"
                                                                           className="form-control"
                                                                           id="inputCreditorAccountTaxIdNumber"
                                                                           disabled
                                                                           placeholder="."/>
                                                                    <Label
                                                                        for="inputLegalIdHolder">{props.t("Legal ID bank owner")}</Label>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col lg={6}>
                                                                <div className="form-floating mb-2">
                                                                    <div
                                                                        className="templating-select select2-container mb-2 ">
                                                                        <Select
                                                                            isMulti={false}
                                                                            className="select2-floating"
                                                                            onChange={option => setCreditorAccount(
                                                                                'ispb',
                                                                                option.ispb
                                                                            )}
                                                                            getOptionLabel={(option) => {
                                                                                return `${option.ispb} - ${option.name}`
                                                                            }}
                                                                            getOptionValue={(option) => {
                                                                                return option.ispb
                                                                            }}
                                                                            options={optionFinancialInstitution}
                                                                            classNamePrefix="select2-selection"
                                                                            closeMenuOnSelect={true}
                                                                            components={animatedComponents}
                                                                            placeholder={props.t("ISPB/Payment company")}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3}>
                                                                <div className="form-floating mb-2">
                                                                    <div
                                                                        className="templating-select select2-container mb-2 ">
                                                                        <Select
                                                                            isMulti={false}
                                                                            className="select2-floating"
                                                                            onChange={option => setCreditorAccount(
                                                                                'accountType',
                                                                                option.value
                                                                            )}
                                                                            options={optionAccountType}
                                                                            classNamePrefix="select2-selection"
                                                                            closeMenuOnSelect={true}
                                                                            components={animatedComponents}
                                                                            placeholder={props.t("Account type")}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col lg={3}>
                                                                <div className="form-floating mb-2">
                                                                    <input type="text"
                                                                           className="form-control"
                                                                           id="inputBranch"
                                                                           name="branch"
                                                                           required
                                                                           autoComplete="off"
                                                                           maxLength={6}
                                                                           onChange={event => handleCreditorAccountWithMask(
                                                                               event, 'NUMBER'
                                                                           )}
                                                                           placeholder="."/>
                                                                    <Label
                                                                        for="inputBranch">{props.t("Branch")}</Label>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3}>
                                                                <div className="form-floating mb-2">
                                                                    <input type="text"
                                                                           className="form-control"
                                                                           id="inputAccountNumber"
                                                                           name="accountNumber"
                                                                           required
                                                                           maxLength={20}
                                                                           autoComplete="off"
                                                                           onChange={event => handleCreditorAccountWithMask(
                                                                               event, 'NUMBER'
                                                                           )}
                                                                           placeholder="."/>
                                                                    <Label
                                                                        for="inputAccountNumber">{props.t("Account number")}</Label>
                                                                </div>
                                                            </Col>
                                                            <Col lg={2}>
                                                                <div className="form-floating mb-2">
                                                                    <input type="text"
                                                                           className="form-control"
                                                                           id="inputAccountNumberDigit"
                                                                           name="accountNumberDigit"
                                                                           required
                                                                           maxLength={2}
                                                                           onChange={event => handleCreditorAccountWithMask(
                                                                               event, 'NUMBER'
                                                                           )}
                                                                           placeholder="."/>
                                                                    <Label
                                                                        for="inputAccountNumber">{props.t("Account number digit")}</Label>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        {/*Actions*/}
                                                        <Row className="mt-4">
                                                            <Col lg={12}>
                                                                <div className="text-end">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-sm btn-light w-sm waves-effect waves-light"
                                                                        onClick={() => {
                                                                            cancel();
                                                                        }}
                                                                    >
                                                                        {props.t("Cancel")}
                                                                    </button>
                                                                    {" "}
                                                                    <button
                                                                        type="button"
                                                                        className={
                                                                            activeTab === 1 ? "disabled btn btn-sm btn-primary w-sm waves-effect waves-light" :
                                                                                " btn btn-sm btn-primary w-sm waves-effect waves-light"
                                                                        }
                                                                        onClick={() => {
                                                                            toggleTab(activeTab - 1);
                                                                        }}
                                                                    >
                                                                        {props.t("Previous")}
                                                                    </button>
                                                                    {" "}
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-sm btn-primary w-sm waves-effect waves-light"
                                                                    >
                                                                        {props.t("Next")}
                                                                    </button>
                                                                    {" "}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </TabPane>

                                                {/*Guarantees*/}
                                                <TabPane tabId={4}>
                                                    <Form
                                                        className="needs-validation"
                                                        id="formGuarantees"
                                                        onSubmit={(e) => {
                                                            handleSubmiNextStep(e);
                                                        }}
                                                    >
                                                        <Row>
                                                            <Col lg={12}>
                                                                <div className="text-end mb-1">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-sm btn-primary w-sm waves-effect waves-light"
                                                                        onClick={() => {
                                                                            openModalNewGuarantee(false);
                                                                        }}
                                                                    >
                                                                        {props.t("Add guarante")}
                                                                    </button>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            {state.guarantees.length > 0 ?
                                                                <div className="table-responsive">
                                                                    <Table
                                                                        className="table table-striped table-bordered font-size-12 mb-50">
                                                                        <thead className="table-light">
                                                                        <tr>
                                                                            <th>{props.t("Receiving end user")}</th>
                                                                            <th>{props.t("Holder")}</th>
                                                                            <th>{props.t("Payment arrangement")}</th>
                                                                            <th>{props.t("Acquirers")}</th>
                                                                            <th>{props.t("Division rule")}</th>
                                                                            <th>{props.t("Type of distribution")}</th>
                                                                            <th>{props.t("Amount to be charged")}</th>
                                                                            <th>{props.t("Start date/end date")}</th>
                                                                            <th></th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>

                                                                        {state.guarantees.map((guar, key) => (
                                                                            <tr
                                                                                key={guar['externalReference']}
                                                                            >
                                                                                <td scope="row">
                                                                                    {
                                                                                        guar.receivingEndUser['name'] ?
                                                                                            (
                                                                                                <span>{guar.receivingEndUser['name']}<br/></span>
                                                                                            ) : ("")
                                                                                    }
                                                                                    {$filter(guar.receivingEndUser.legalId, 'CPF_CNPJ')}
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        guar.holder['name'] ?
                                                                                            (
                                                                                                <span>{guar.holder['name']}<br/></span>
                                                                                            ) : ("")
                                                                                    }
                                                                                    {$filter(guar.holder.legalId, 'CPF_CNPJ')}
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        guar['allArrangements'] ?
                                                                                            (
                                                                                                <span>{ArrangementsTypeDomain.getLabelByValue(guar['allArrangements'])}</span>
                                                                                            ) : ("")
                                                                                    }
                                                                                    {
                                                                                        !guar['allArrangements'] &&
                                                                                        guar['arrangements'] &&
                                                                                        guar['arrangements'].length === 1 ?
                                                                                            (
                                                                                                <span>
                                                                                                    {ArrangementsDomain.getLabelByValue(guar['arrangements'][0]['code'])}
                                                                                                </span>
                                                                                            ) : ("")
                                                                                    }
                                                                                    {
                                                                                        !guar['allArrangements'] &&
                                                                                        guar['arrangements'] &&
                                                                                        guar['arrangements'].length > 1 ?
                                                                                            (
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-sm btn-light w-sm waves-effect waves-light"
                                                                                                >
                                                                                                    <i className='mdi mdi-credit-card-settings mdi-18px'></i>
                                                                                                </button>
                                                                                            ) : ("")
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        guar['allAcquirers'] ?
                                                                                            (
                                                                                                <span>{props.t("All acquirers")}</span>
                                                                                            ) : ("")
                                                                                    }
                                                                                    {
                                                                                        !guar['allAcquirers'] &&
                                                                                        guar['acquirers'] &&
                                                                                        guar['acquirers'].length === 1 ?
                                                                                            (
                                                                                                <span>
                                                                                                    {AcquiresDomain.getLabelByValue(guar['acquirers'][0]['legalId'])}
                                                                                                </span>
                                                                                            ) : ("")
                                                                                    }
                                                                                    {
                                                                                        !guar['allAcquirers'] &&
                                                                                        guar['acquirers'] &&
                                                                                        guar['acquirers'].length > 1 ?
                                                                                            (
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-sm btn-light w-sm waves-effect waves-light"
                                                                                                >
                                                                                                    <i className='mdi mdi-bank mdi-18px'></i>

                                                                                                </button>
                                                                                            ) : ("")
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {DivisionRuleDomain.getLabelByValue(guar.divisionRule)}
                                                                                </td>
                                                                                <td>
                                                                                    {DistributionTypeDomain.getLabelByValue(guar.typeDistribution)}
                                                                                </td>
                                                                                <td>
                                                                                    {$filter(guar.amountCharged, 'MONEY')}
                                                                                </td>
                                                                                <td>
                                                                                    {$filter(guar.startDate, 'DATE')}
                                                                                    {" - "}
                                                                                    {$filter(guar.endDate, 'DATE')}
                                                                                </td>
                                                                                <td>
                                                                                    <UncontrolledDropdown>
                                                                                        <DropdownToggle
                                                                                            className="text-muted font-size-16"
                                                                                            tag="a"
                                                                                        >
                                                                                            <i className="mdi mdi-dots-vertical"></i>
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu
                                                                                            className="dropdown-menu-end">
                                                                                            <DropdownItem
                                                                                                onClick={() => {
                                                                                                    openModalNewGuarantee(
                                                                                                        true, guar.externalReference
                                                                                                    )
                                                                                                }}
                                                                                            >
                                                                                                <i className='mdi mdi-square-edit-outline me-1'></i>
                                                                                                {props.t("Edit")}
                                                                                            </DropdownItem>
                                                                                            <DropdownItem
                                                                                                onClick={() => {
                                                                                                    openModalDeleteGuarantee(guar.externalReference);
                                                                                                }}
                                                                                            >
                                                                                                <i className='mdi mdi-trash-can-outline me-1'></i>
                                                                                                {props.t("Delete")}
                                                                                            </DropdownItem>
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                </td>
                                                                            </tr>
                                                                        ))}

                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                                :
                                                                <Alert color="warning" className="mt-4">
                                                                    <i className="mdi mdi-alert me-2"></i>

                                                                    {props.t("Not guarante defined")} {" "} {props.t("Click to")}{":  "}
                                                                    <Link to="#"
                                                                          className="alert-link"
                                                                          onClick={() => {
                                                                              openModalNewGuarantee(false)
                                                                          }}
                                                                    >
                                                                        {props.t("Add guarante")}
                                                                    </Link>
                                                                </Alert>
                                                            }
                                                        </Row>

                                                        {/*Actions*/}
                                                        <Row className="mt-4">
                                                            <Col lg={12}>
                                                                <div className="text-end">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-sm btn-light w-sm waves-effect waves-light"
                                                                        onClick={() => {
                                                                            cancel();
                                                                        }}
                                                                    >
                                                                        {props.t("Cancel")}
                                                                    </button>
                                                                    {" "}
                                                                    <button
                                                                        type="button"
                                                                        className={
                                                                            activeTab === 1 ? "disabled btn btn-sm btn-primary w-sm waves-effect waves-light" :
                                                                                " btn btn-sm btn-primary w-sm waves-effect waves-light"
                                                                        }
                                                                        onClick={() => {
                                                                            toggleTab(activeTab - 1);
                                                                        }}
                                                                    >
                                                                        {props.t("Previous")}
                                                                    </button>
                                                                    {" "}
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-sm btn-primary w-sm waves-effect waves-light"
                                                                    >
                                                                        {props.t("Next")}
                                                                    </button>
                                                                    {" "}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </TabPane>

                                                {/*Finish*/}
                                                <TabPane tabId={5}>

                                                    <p className="card-title-desc">{props.t("Contract information")}</p>
                                                    <hr/>

                                                    <Row className="mb-3">
                                                        <Col lg={6}>
                                                            {props.t("Contract ID")}<br/>
                                                            <strong>{state.contractId}</strong>
                                                        </Col>
                                                        <Col lg={6}>
                                                            {props.t("External Reference")}<br/>
                                                            <strong>{state.externalReference}</strong>
                                                        </Col>
                                                    </Row>

                                                    <Row className="mb-3">
                                                        <Col lg={2}>
                                                            {props.t("Effect type")}<br/>
                                                            <strong>{EffetiveTypeDomain.getLabelByValue(state.effectType)}</strong>
                                                        </Col>
                                                        <Col lg={2}>
                                                            {props.t("Service type")}<br/>
                                                            <strong>{ServiceTypeDomain.getLabelByValue(state.serviceType)}</strong>
                                                        </Col>
                                                        <Col lg={2}>
                                                            {props.t("Modality")}<br/>
                                                            <strong>{ModalityDomain.getLabelByValue(state.modality)}</strong>
                                                        </Col>
                                                        <Col lg={2}>
                                                            {props.t("Renegotiation")}<br/>
                                                            <strong>{YesOrNoDomain.getLabelByValue(state.renegotiation)}</strong>
                                                        </Col>
                                                        <Col lg={2}>
                                                            {props.t("Signature date")}<br/>
                                                            <strong>{$filter(state.signatureDate, 'DATE')}</strong>
                                                        </Col>
                                                        <Col lg={2}>
                                                            {props.t("Due date")}<br/>
                                                            <strong>{$filter(state.dueDate, 'DATE')}</strong>
                                                        </Col>
                                                    </Row>

                                                    <Row className="mb-4">
                                                        <Col lg={2}>
                                                            {props.t("Contractor")}<br/>
                                                            <strong>{$filter(state.contractor['legalId'], 'CPF_CNPJ')}</strong>
                                                        </Col>
                                                        <Col lg={2}>
                                                            {props.t("Register agent")}<br/>
                                                            <strong>{$filter(state.registerAgent.legalId, 'CPF_CNPJ')}</strong>
                                                        </Col>
                                                        <Col lg={2}>
                                                            {props.t("Financial Agent")}<br/>
                                                            <strong>{$filter(state.financialAgent.legalId, 'CPF_CNPJ')}</strong>
                                                        </Col>
                                                        <Col lg={2}>
                                                            {props.t("Debit balance")}<br/>
                                                            <strong>{$filter(state.debitBalance, 'MONEY')}</strong>
                                                        </Col>
                                                        <Col lg={2}>
                                                            {props.t("Guaranteed operation limit")}<br/>
                                                            <strong>{$filter(state.guaranteedOperationLimit, 'MONEY')}</strong>
                                                        </Col>
                                                        <Col lg={2}>
                                                            {props.t("Minimum value to be maintained")}<br/>
                                                            <strong>{$filter(state.valueMaintained, 'MONEY')}</strong>
                                                        </Col>
                                                    </Row>

                                                    <p className="card-title-desc">{props.t("Payment data")}</p>
                                                    <hr/>

                                                    <Row className="mb-3">
                                                        <Col lg={2}>
                                                            {props.t("Legal ID bank owner")}<br/>
                                                            <strong>{$filter(state.creditorAccount.taxIdOwner, 'CPF_CNPJ')}</strong>
                                                        </Col>
                                                        <Col lg={6}>
                                                            {props.t("Holder name")}<br/>
                                                            <strong>{state.creditorAccount.ownerName}</strong>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-4">
                                                        <Col lg={2}>
                                                            {props.t("ISPB")}<br/>
                                                            <strong>{state.creditorAccount.ispb}</strong>
                                                        </Col>
                                                        <Col lg={2}>
                                                            {props.t("Payment company")}<br/>
                                                            <strong>{PaymentCompanyDomain.getLabelByCode(state.creditorAccount.ispb)}</strong>
                                                        </Col>
                                                        <Col lg={2}>
                                                            {props.t("Account type")}<br/>
                                                            <strong>{AccountTypeDomain.getLabelByValue(state.creditorAccount.accountType)}</strong>
                                                        </Col>
                                                        <Col lg={2}>
                                                            {props.t("Branch")}<br/>
                                                            <strong>{state.creditorAccount.branch}</strong>
                                                        </Col>
                                                        <Col lg={2}>
                                                            {props.t("Account number")}<br/>
                                                            <strong>{state.creditorAccount.accountNumber}</strong>
                                                        </Col>
                                                        <Col lg={2}>
                                                            {props.t("Account number digit")}<br/>
                                                            <strong>{state.creditorAccount.accountNumberDigit}</strong>
                                                        </Col>
                                                    </Row>

                                                    <p className="card-title-desc">{props.t("Guarantees")}</p>
                                                    <hr/>

                                                    <Row className="mb-4">
                                                        {state.guarantees.length > 0 ?
                                                            <div className="table-responsive">
                                                                <Table
                                                                    className="table table-striped table-bordered font-size-12">
                                                                    <thead className="table-light">
                                                                    <tr>
                                                                        <th>{props.t("External Reference")}</th>
                                                                        <th>{props.t("Receiving end user")}</th>
                                                                        <th>{props.t("Holder")}</th>
                                                                        <th>{props.t("Payment arrangement")}</th>
                                                                        <th>{props.t("Acquirers")}</th>
                                                                        <th>{props.t("Division rule")}</th>
                                                                        <th>{props.t("Type of distribution")}</th>
                                                                        <th>{props.t("Amount to be charged")}</th>
                                                                        <th>{props.t("Start date/end date")}</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                    {state.guarantees.map((guar, key) => (
                                                                        <tr
                                                                            key={guar['externalReference']}
                                                                        >
                                                                            <td style={{maxWidth: "3px"}}>
                                                                                {guar.externalReference}
                                                                            </td>
                                                                            <td scope="row">
                                                                                {
                                                                                    guar.receivingEndUser['name'] ?
                                                                                        (
                                                                                            <span>{guar.receivingEndUser['name']}<br/></span>
                                                                                        ) : ("")
                                                                                }
                                                                                {$filter(guar.receivingEndUser.legalId, 'CPF_CNPJ')}
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    guar.holder['name'] ?
                                                                                        (
                                                                                            <span>{guar.holder['name']}<br/></span>
                                                                                        ) : ("")
                                                                                }
                                                                                {$filter(guar.holder.legalId, 'CPF_CNPJ')}
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    guar['allArrangements'] ?
                                                                                        (
                                                                                            <span>{ArrangementsTypeDomain.getLabelByValue(guar['allArrangements'])}</span>
                                                                                        ) : ("")
                                                                                }
                                                                                {
                                                                                    !guar['allArrangements'] &&
                                                                                    guar['arrangements'] &&
                                                                                    guar['arrangements'].length === 1 ?
                                                                                        (
                                                                                            <span>
                                                                                                    {ArrangementsDomain.getLabelByValue(guar['arrangements'][0]['code'])}
                                                                                                <br/>
                                                                                                {guar['arrangements'][0]['code']}
                                                                                                </span>
                                                                                        ) : ("")
                                                                                }
                                                                                {
                                                                                    !guar['allArrangements'] &&
                                                                                    guar['arrangements'] &&
                                                                                    guar['arrangements'].length > 1 ?
                                                                                        (
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-sm btn-light w-sm waves-effect waves-light"
                                                                                            >
                                                                                                <i className='mdi mdi-credit-card-settings mdi-18px'></i>
                                                                                            </button>
                                                                                        ) : ("")
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    guar['allAcquirers'] ?
                                                                                        (
                                                                                            <span>{props.t("All acquirers")}</span>
                                                                                        ) : ("")
                                                                                }
                                                                                {
                                                                                    !guar['allAcquirers'] &&
                                                                                    guar['acquirers'] &&
                                                                                    guar['acquirers'].length === 1 ?
                                                                                        (
                                                                                            <span>
                                                                                                    {AcquiresDomain.getLabelByValue(guar['acquirers'][0]['legalId'])} <br/>
                                                                                                {guar['acquirers'][0]['legalId']}
                                                                                                </span>
                                                                                        ) : ("")
                                                                                }
                                                                                {
                                                                                    !guar['allAcquirers'] &&
                                                                                    guar['acquirers'] &&
                                                                                    guar['acquirers'].length > 1 ?
                                                                                        (
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-sm btn-light w-sm waves-effect waves-light"
                                                                                            >
                                                                                                <i className='mdi mdi-bank mdi-18px'></i>

                                                                                            </button>
                                                                                        ) : ("")
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {DivisionRuleDomain.getLabelByValue(guar.divisionRule)}
                                                                            </td>
                                                                            <td>
                                                                                {DistributionTypeDomain.getLabelByValue(guar.typeDistribution)}
                                                                            </td>
                                                                            <td>
                                                                                {$filter(guar.amountCharged, 'MONEY')}
                                                                            </td>
                                                                            <td>
                                                                                {$filter(guar.startDate, 'DATE')}
                                                                                {" - "}
                                                                                {$filter(guar.endDate, 'DATE')}
                                                                            </td>
                                                                        </tr>
                                                                    ))}

                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                            :
                                                            ("")
                                                        }
                                                    </Row>

                                                    {/*Actions*/}
                                                    <Row className="mt-4">
                                                        <div className="d-print-none">
                                                            <div className="float-end">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm btn-light w-sm waves-effect waves-light"
                                                                    onClick={() => {
                                                                        cancel();
                                                                    }}
                                                                >
                                                                    {props.t("Cancel")}
                                                                </button>
                                                                {" "}
                                                                <button
                                                                    type="button"
                                                                    className={
                                                                        activeTab === 1 ? "disabled btn btn-sm btn-primary w-sm waves-effect waves-light" :
                                                                            " btn btn-sm btn-primary w-sm waves-effect waves-light"
                                                                    }
                                                                    onClick={() => {
                                                                        toggleTab(activeTab - 1);
                                                                    }}
                                                                >
                                                                    {props.t("Previous")}
                                                                </button>
                                                                {" "}
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm btn-success waves-effect waves-light"
                                                                    onClick={() => {
                                                                        printContract()
                                                                    }}
                                                                >
                                                                    <i className="fa fa-print"></i>
                                                                </button>
                                                                {" "}
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm btn-primary w-sm waves-effect waves-light"
                                                                    onClick={() => {
                                                                        save()
                                                                    }}
                                                                >
                                                                    {props.t("Confirm")}
                                                                </button>
                                                                {" "}
                                                            </div>
                                                        </div>

                                                    </Row>
                                                </TabPane>
                                            </TabContent>
                                        </div>

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Modal
                        size="xl"
                        isOpen={modalGuarante}
                        toggle={() => {
                            setModalGuaranteCore()
                        }}
                        onOpened={() => {
                            setModalGuaranteValues()
                        }}
                        centered={true}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0">
                                {props.t("Register guarante")}
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    closeModalGuarante();
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <Form
                                className="needs-validation"
                                id="formAddGuarantee"

                            >
                                <p className="card-title-desc mt-3">{props.t("Guarante detail")}</p>
                                <Row>
                                    <Col lg={6}>
                                        <div className="form-floating mb-2">
                                            <input type="text"
                                                   className="form-control"
                                                   id="inputGuaranteeLegalIdReceiving"
                                                   required
                                                   pattern={CpfCnpjUtils.CPF_CNPJ_PATTERN}
                                                   title={props.t("Invalid legal ID")}
                                                   autoComplete="off"
                                                   maxLength={18}
                                                   minLength={14}
                                                   onChange={event => handleNewGuaranteeReceivingEndUser(event)}
                                                   placeholder="."/>
                                            <Label
                                                for="inputLegalIdReceiving">{props.t("Legal Id Receiving end user")}</Label>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="form-floating mb-2">
                                            <input type="text"
                                                   className="form-control"
                                                   id="inputGuaranteeLegalIdHolder"
                                                   required
                                                   pattern={CpfCnpjUtils.CPF_CNPJ_PATTERN}
                                                   title={props.t("Invalid legal ID")}
                                                   autoComplete="off"
                                                   maxLength={18}
                                                   minLength={14}
                                                   onChange={event => handleNewGuaranteeHolder(event)}
                                                   placeholder="."/>
                                            <Label
                                                for="inputHolder">{props.t("Legal ID Guarantee Holder")}</Label>
                                        </div>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col lg={6}>
                                        <div className=" templating-select select2-container mb-2 ">
                                            <Select
                                                isMulti={true}
                                                className="select2-floating"
                                                ref={acquirersRef}
                                                onChange={(options) => {
                                                    handleNewGuaranteeAcquirers(options)
                                                }}
                                                value={newGuaranteeAcquirers}
                                                getOptionLabel={(option) => {
                                                    return option.name
                                                }}
                                                getOptionValue={(option) => {
                                                    return option.legalId
                                                }}
                                                id="selectGuaranteeAcquirers"
                                                options={optionAcquires}
                                                classNamePrefix="select2-selection"
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                placeholder={props.t("Acquirer")}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={4}>
                                        <div className="form-check mt-4">
                                            <input className="form-check-input"
                                                   type="checkbox"
                                                   ref={allAcquirersRef}
                                                   defaultChecked={newGuarantee && newGuarantee['allAcquirers'] === 'ALL'}
                                                   name="allAcquirers"
                                                   onChange={event => handleNewGuaranteeAllAcquirers(event)}
                                                   id="checkAllAcquirer"/>
                                            <Label className="form-check-label" for="checkAllAcquirer">
                                                {props.t("All acquirers")}
                                            </Label>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mb-2">
                                    <Col lg={6}>
                                        <div className="form-floating">
                                            <Select
                                                isMulti={true}
                                                className="select2-floating"
                                                ref={arrangementsRef}
                                                value={newGuaranteeArrangements}
                                                onChange={(options) => {
                                                    handleNewGuaranteeArrangements(options);
                                                }}
                                                getOptionValue={(option) => {
                                                    return option.code
                                                }}
                                                options={optionArranjements}
                                                classNamePrefix="select2-selection"
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                placeholder={props.t("Payment arrangement")}
                                            />
                                        </div>
                                    </Col>

                                    <Col lg={6} className="mt-1">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input"
                                                   type="radio"
                                                   name="checkAllArrangementsType"
                                                   id="checkAllArrangements"
                                                   onChange={event => handleAllArrangements(
                                                       event, 'ALL'
                                                   )}/>
                                            <Label className="form-check-label" for="inlineRadio1">
                                                {props.t("All arrangements")}
                                            </Label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input"
                                                   type="radio"
                                                   name="checkAllArrangementsType"
                                                   id="checkAllDebitorArrangements"
                                                   onChange={event => handleAllArrangements(
                                                       event, 'ALL_DEBITORS'
                                                   )}/>
                                            <Label className="form-check-label" for="inlineRadio2">
                                                {props.t("All debitor arrangements")}
                                            </Label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input"
                                                   type="radio"
                                                   name="checkAllArrangementsType"
                                                   id="checkAllCreditorArrangements"
                                                   onChange={event => handleAllArrangements(
                                                       event, 'ALL_CREDITORS'
                                                   )}/>
                                            <Label className="form-check-label" for="inlineRadio3">
                                                {props.t("All creditor arrangements")}
                                            </Label>
                                        </div>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col lg={3}>
                                        <div className="form-floating mb-2">
                                            <div
                                                className="templating-select select2-container mb-2 ">
                                                <Select
                                                    isMulti={false}
                                                    className="select2-floating"
                                                    options={optionDivisionRule}
                                                    classNamePrefix="select2-selection"
                                                    onChange={(options) => {
                                                        handleNewGuaranteeDivisionRule(
                                                            options
                                                        )
                                                    }}
                                                    closeMenuOnSelect={true}
                                                    components={animatedComponents}
                                                    placeholder={props.t("Division rule")}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="form-floating mb-2">
                                            <div
                                                className="templating-select select2-container mb-2 ">
                                                <Select
                                                    isMulti={false}
                                                    className="select2-floating"
                                                    options={DistributionTypeDomain.options}
                                                    classNamePrefix="select2-selection"
                                                    closeMenuOnSelect={true}
                                                    onChange={(options) => {
                                                        handleNewGuaranteeDistribuitionType(
                                                            options
                                                        )
                                                    }}
                                                    components={animatedComponents}
                                                    placeholder={props.t("Distribuition type")}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="form-floating mb-2">
                                            <input type="date"
                                                   className="form-control"
                                                   id="inputStartDate"
                                                   name="startDate"
                                                   onChange={event => handleNewGuarantee(event)}
                                                   placeholder="."/>
                                            <Label
                                                for="inputStartDate">{props.t("Start date")}</Label>
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="form-floating mb-2">
                                            <input type="date"
                                                   className="form-control"
                                                   id="inputEndDate"
                                                   name="endDate"
                                                   onChange={event => handleNewGuarantee(event)}
                                                   placeholder="."/>
                                            <Label
                                                for="inputEndDate">{props.t("End date")}</Label>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={3}>
                                        <div className="form-floating mb-2">
                                            <input type="text"
                                                   className="form-control"
                                                   id="inputAmountCharged"
                                                   name="amountCharged"
                                                   onChange={event => handleNewGuaranteeWithMask(event, 'money')}
                                                   placeholder="."/>
                                            <Label
                                                for="inputAmountCharged">{props.t("Amount to be charged")}</Label>
                                        </div>
                                    </Col>

                                    <Col lg={6}>
                                        <div className="form-floating mb-2">
                                            <input type="text"
                                                   className="form-control"
                                                   id="inputNewGuaranteeExternalReference"
                                                   name="externalReference"
                                                   value={newGuarantee['externalReference']}
                                                   disabled
                                                   placeholder="."/>
                                            <Label
                                                for="inputExternalReference">
                                                {props.t("External Reference")}
                                            </Label>
                                        </div>
                                    </Col>
                                </Row>

                            </Form>
                        </div>

                        <div className="modal-footer">
                            <Row>
                                <Col lg={12}>
                                    <div className="text-end">
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-light w-sm waves-effect waves-light"
                                            onClick={() => {
                                                closeModalGuarante()
                                            }}
                                        >
                                            {props.t("Cancel")}
                                        </button>
                                        {" "}
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-primary w-sm waves-effect waves-light"
                                            onClick={() => {
                                                handleAddGuarantee()
                                            }}
                                        >
                                            {props.t("Confirm")}
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </Modal>
                    {
                        loading ?
                            <Loader loading={loading}/>
                            :
                            (" ")
                    }

                    {confirmGuaranteeDeleteAlert ? (
                        <SweetAlert
                            title={props.t("Confirms the exclusion of the guarantee")}
                            warning
                            showCancel
                            confirmBtnText={props.t("Yes")}
                            cancelBtnText={props.t("No")}
                            onConfirm={() => {
                                deleteGuarantee()
                            }}
                            onCancel={() => setConfirmGuaranteeDeleteAlert(false)}
                        >
                        </SweetAlert>
                    ) : null}

                </Container>

            </div>
        </React.Fragment>
    );
}

export default withRouter(withTranslation()(ManageContractsEdit))