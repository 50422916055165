import React from 'react';
import MetaTags from 'react-meta-tags';
import {Card, CardBody, Col, Container, Label, Row, Table} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {Link, useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";

function ManageDisputesReceived(props) {
    const history = useHistory();
    const navigateToEdit = () => history.push('/disputes/received-disputes/edit/10');

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Manage incoming disputes")}</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title={props.t("Disputes")} breadcrumbItem={props.t("Manage incoming disputes")}/>

                    {/* Search */}
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">{props.t("Filters")}</h4>
                                    <Row>
                                        <Col lg={4}>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control"
                                                       id="inputExternalReference"
                                                       placeholder="."/>
                                                <Label
                                                    for="inputExternalReference">{props.t("External Reference")}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control"
                                                       id="inputConciliationType"
                                                       placeholder="."/>
                                                <Label
                                                    for="inputConciliationType">{props.t("Dispute type")}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control"
                                                       id="inputContestant"
                                                       placeholder="."/>
                                                <Label for="inputContestant">{props.t("Contestant")}</Label>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={4}>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control"
                                                       id="inputDateOfDispute"
                                                       placeholder="."/>
                                                <Label for="inputDateOfDispute">{props.t("Date of dispute")}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control"
                                                       id="inputReason"
                                                       placeholder="."/>
                                                <Label for="inputReason">{props.t("Reason")}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control"
                                                       id="inputContested"
                                                       placeholder="."/>
                                                <Label for="inputContested">{props.t("Contested")}</Label>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={12}>
                                            <div className="text-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-light w-sm waves-effect waves-light"
                                                >
                                                    {props.t("Clean filters")}
                                                </button>
                                                {" "}
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-light w-sm waves-effect waves-light"
                                                >
                                                    {props.t("Apply Search")}
                                                </button>
                                                {" "}
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Search results */}
                    <Row>
                        <Col xs={12}>
                            <Row>
                                <div className="table-responsive">
                                    <Table className="table table-striped table-bordered mb-0 table-hover font-size-12">
                                        <thead className="table-light">
                                        <tr>
                                            <th>{props.t("Dispute type")}</th>
                                            <th>{props.t("Transaction/contract identifier")}</th>
                                            <th>{props.t("Contestant")}</th>
                                            <th>{props.t("Date of dispute")}</th>
                                            <th>{props.t("Contested")}</th>
                                            <th>{props.t("Reason")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {/*{"LINHA 1"}*/}
                                        <tr onClick={() => {
                                            navigateToEdit();
                                        }}>
                                            <td scope="row">
                                                Contrato
                                            </td>
                                            <td>
                                                8b08a59d4cd74a4e9a3c628505a4c93811111111
                                            </td>
                                            <td>
                                                VERDE ADM CARTõES CRéDITO S.A.<br/>
                                                01.722.480/0001-67
                                            </td>
                                            <td>
                                                10/06/2021
                                            </td>
                                            <td>
                                                4EX INVESTING <br/>
                                                17.773.640/0001-37
                                            </td>
                                            <td>
                                                Títular ou usuário final recebedor não reconhece operação.
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Row>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
}

export default withRouter(withTranslation()(ManageDisputesReceived))