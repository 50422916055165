import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "reactstrap";
import { MetaTags } from "react-meta-tags";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Select2Product from "../../../components/Common/Select2Product";
import ProductTypeDomain from "../../../domains/ProductTypeDomain";
import Select2EffectType from "../../../components/Common/Select2EffectType";
import Select2CreditModality from "../../../components/Common/Select2CreditModality";
import InputText from "../../../components/Common/InputText";
import Select2YesOrNoBool from "../../../components/Common/Select2YesOrNoBool";
import InputDate from "../../../components/Common/InputDate";
import Select2Async from "../../../components/Common/Select2Async";
import GravameSelectContract from "./GravameSelectContract";
import { get, getUserSession } from "../../../helpers/api_helper";
import InputMoney from "../../../components/Common/InputMoney";
import GravameBankAccount from "./GravameBankAccount";

import * as url from "../../../helpers/url_helper";
function GravameNewV2(props) {
  const financialAgent = getUserSession()["financialAgent"];
  const registerAgent = getUserSession()["registerAgent"];
  const [loading, setLoading] = React.useState(false);
  const [bankAccounts, setBankAccounts] = React.useState([]);
  const [guarantees, setGuarantees] = React.useState([
    {
      acquirers: [
        {
          legalId: "45181802000183",
          name: "FISERV DO BRASIL INSTITUICAO DE PAGAMENTO LTDA",
        },
      ],
      arrangements: [
        {
          code: null,
          label: null,
        },
      ],
      receivingEndUser: {
        legalId: "13160941000134",
      },
      holder: {
        legalId: "13160941000134",
      },
      divisionRule: "1",
      typeDistribution: "1",
      startDate: "2024-11-11",
      endDate: "2024-11-11",
      amountCharged: 4547.391333333334,
    },
  ]);
  const [modalGarantias, setModalGarantias] = React.useState(false);

  const [creditorAccount, setCreditorAccount] = React.useState({
    taxIdOwner: "",
    bankCode: null,
    accountType: "",
    branch: "",
    ispb: "",
    accountNumber: "",
    accountDigit: "",
    ownerName: "",
    legalId: "",
  });

  const [entity, setEntity] = React.useState({
    registerAgent: registerAgent,
    financialAgent: financialAgent,
    creditorAccount: creditorAccount,
    guarantees: guarantees,
    signatureDate: "",
    contractor: {
      legalId: "",
    },
    effectType: "",
    serviceType: "",
    unconditionalAccept: true,
    evaluationType: "",
    dueDate: "",
    modality: "",
    renegotiation: false,
    debitBalance: 0,
    guaranteedOperationLimit: 0,
    valueMaintained: 0,
    additionalInfo: {
      product: "",
      productName: "",
      productModality: "",
      averageTerm: 0,
      discountRate: "0",
      admRate: 0,
      effectiveRate: 0,
      discountValue: 0,
      mainValue: 0,
      payments: null,
      fine: 0,
      fee: 0,
      baseDate: null,
    },
    fine: 0,
    fee: 0,
    arrangements: [],
    acquirers: [],
  });

  const getBankAccounts = async () => {
    const response = await get(
      `${url.GET_BANK_ID_FINANCIAL_ADMIN}/${financialAgent?.legalId}`,
      null,
      {},
      setLoading
    );

    return response;
  };
  useEffect(() => {
    getBankAccounts().then((response) => {
      setBankAccounts(response);
    });
  }, []);

  useEffect(() => {
    setEntity((entity) => ({
      ...entity,
      registerAgent: registerAgent,
      financialAgent: financialAgent,
      creditorAccount: creditorAccount,
      guarantees: guarantees,
    }));
  }, [registerAgent, financialAgent, creditorAccount, guarantees]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Gravame</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={"Novo gravame"} breadcrumbItem={"Gravame"} />
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              console.log("submit", e);
            }}
            id="newGravameForm"
          >
            <Container fluid="xl">
              <GravameSelectContract entity={entity} setEntity={setEntity} />
              <Card>
                <CardBody>
                  <CardTitle>Cadastrar Gravame</CardTitle>
                  <Row>
                    <Col md={4} sm={6}>
                      <FormGroup>
                        <Select2Product
                          id="productType"
                          required={true}
                          popover={"Seleciona o tipo do produto do Gravame"}
                          onChange={(event) => {
                            entity.additionalInfo.product = event["value"];
                            entity.additionalInfo.productName =
                              ProductTypeDomain.getLabelByValue(event["value"]);

                            setEntity(entity);
                          }}
                          label={"Produto"}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={6}>
                      <FormGroup>
                        <Select2EffectType
                          id="effectType"
                          required={true}
                          label={props.t("Effect type")}
                          onChange={(option) => {
                            entity.effectType = option["value"];
                            setEntity(entity);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={6}>
                      <FormGroup>
                        <Select2CreditModality
                          id="modality"
                          required={true}
                          label={props.t("Modality")}
                          onChange={(option) => {
                            entity.modality = option["value"];
                            setEntity(entity);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={6}>
                      <FormGroup>
                        <Select2YesOrNoBool
                          id="renegotiation"
                          required={true}
                          defaultValue={false}
                          popover={
                            "Indica se o contrato atual se trata de repactuação"
                          }
                          label={props.t("Renegotiation")}
                          onChange={(option) => {
                            entity.renegotiation = option["value"];
                            setEntity(entity);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={4} className="mb-3">
                      <FormGroup>
                        <InputText
                          disabled={!entity.renegotiation}
                          id="previousContractId"
                          onChange={(event) => {
                            entity.previousContractId = event?.target?.value;
                            setEntity(entity);
                          }}
                          label={props.t("ID(s) of previous contract(s)")}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={6}>
                      <FormGroup>
                        <Select2YesOrNoBool
                          id="unconditionalAccept"
                          required={true}
                          label={"Aceite Incondicional da Operação"}
                          defaultValue={true}
                          onChange={(option) => {
                            entity.unconditionalAccept = option["value"];
                            setEntity(entity);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={6}>
                      <FormGroup>
                        <InputDate
                          id="signatureDate"
                          autoComplete="off"
                          onChange={(e) => {
                            entity.signatureDate = e?.target?.value;
                            setEntity(entity);
                          }}
                          required={true}
                          value={entity.signatureDate}
                          label={"Data de assinatura"}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={6}>
                      <FormGroup>
                        <InputDate
                          id="dueDate"
                          autoComplete="off"
                          onChange={(e) => {
                            entity.dueDate = e?.target?.value;
                            setEntity(entity);
                          }}
                          required={true}
                          value={entity.dueDate}
                          label={"Data de vencimento"}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={4} className="mb-3">
                      <FormGroup>
                        <InputText
                          disabled={true}
                          id="contractorLegalId"
                          required={true}
                          value={entity?.contractor?.legalId}
                          label={props.t("CNPJ do contratante")}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={4} className="mb-3">
                      <FormGroup>
                        <InputText
                          disabled={true}
                          required={true}
                          id="registerAgentLegalId"
                          value={entity?.registerAgent?.legalId}
                          label={props.t("CNPJ do participante")}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={4} className="mb-3">
                      <FormGroup>
                        <InputText
                          disabled={true}
                          required={true}
                          id="financialAgentLegalId"
                          value={entity?.financialAgent?.legalId}
                          label={props.t("CNPJ do detendor")}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={6}>
                      <FormGroup>
                        <InputMoney
                          id="debitBalance"
                          required={true}
                          onChange={(event) => {
                            entity.debitBalance = event?.target?.value;
                            setEntity(entity);
                          }}
                          label={props.t("Debit balance")}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={6}>
                      <FormGroup>
                        <InputMoney
                          id="guaranteedOperationLimit"
                          onChange={(event) => {
                            entity.guaranteedOperationLimit =
                              event?.target?.value;
                            setEntity(entity);
                          }}
                          required={true}
                          label={props.t("Guaranteed operation limit")}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={6}>
                      <FormGroup>
                        <InputMoney
                          id="valueMaintained"
                          onChange={(event) => {
                            entity.valueMaintained = event?.target?.value;
                            setEntity(entity);
                          }}
                          required={true}
                          label={"Valor mínimo a ser mantido"}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <CardTitle>Dados Bancarios</CardTitle>
                      <GravameBankAccount
                        bankAccounts={bankAccounts}
                        reload={getBankAccounts}
                        financialAgent={financialAgent}
                        setCreditorAccount={setCreditorAccount}
                      />
                    </Col>
                    <Col md={12}>
                      <div>
                        <CardTitle>Garantias</CardTitle>
                        <Button
                          color="primary"
                          onClick={() => modalGarantias()}
                        >
                          <i className="bx bx-plus font-size-16 align-middle me-2"></i>
                          Adicionar garantias
                        </Button>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mt-5">
                        <button
                          type="submit"
                          title="Salvar"
                          className="btn btn-sm btn-primary w-sm waves-effect waves-primary col-auto"
                        >
                          {props.t("Save")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                  <Row></Row>
                </CardBody>
              </Card>
            </Container>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withRouter(withTranslation()(GravameNewV2));
