import React, { useEffect, useState } from "react";
import { Col, Row, Modal, Card, CardBody, Table, Button } from "reactstrap";

import * as url from "../../../helpers/url_helper";
import { get, post } from "../../../helpers/api_helper";

import $filter from "../../../common/utils/$filter";
import StatusDomain from "../../../domains/StatusDomain";
import PaymentSchemaTypeDomain from "../../../domains/PaymentSchemaTypeDomain";
import Swal from "sweetalert2";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import SerproLegalIdDetails from "../serpro/SerproLegalIdDetails";
import CardReceivablesDetails from "../serpro/CardReceivablesDetails";

function OptinViewAdmin(props) {
  // const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [optoutLoading, setOptoutLoading] = useState(false);
  const [entity, setEntity] = useState({
    id: null,
    revision: 0,
    status: null,
    externalReference: null,
    signatureDate: null,
    optinDate: null,
    optoutDate: null,
    startDate: null,
    endDate: null,
    registerAgent: { legalId: null, name: null },
    financialAgent: { legalId: null, name: null },
    requester: { legalId: null, name: null },
    receivingEndUser: { legalId: null, name: null },
    holder: { legalId: null, name: null },
    arrangements: [
      { optinId: 7556, code: "ALL", label: null, type: null },
      { optinId: 7556, code: "ALL", label: null, type: null },
    ],
    receivableDetails: null,
  });

  const openOptoutConfirm = (entity, index) => {
    const html = `<div>
                <p>${props.t("Suspend access permission to")}<br/>
                    <strong>${entity.receivingEndUser.name}</strong><br/>
                    ${props.t("calendar through opt-out")}.
                </p>
            </div>`;

    Swal.fire({
      title: props.t("Confirm o opt-out?"),
      icon: "question",
      html: html,
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonText: props.t("Confirm"),
      cancelButtonText: props.t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        setOptOut(entity, index);
      }
    });
  };

  const openSyncnizeDataCerc = (entity, index) => {
    const html = `<div>
                <p>Gostaria de Sinconizar os dados do cliente <br/>
                    <strong>${
                      entity?.receivingEndUser?.name ||
                      entity?.receivingEndUser?.legalId ||
                      entity?.legalIdReceivingEndUser
                    }</strong><br/>
                    via CERC e SERPRO.
                </p>
            </div>`;

    Swal.fire({
      title: "Sincronizar os Dados",
      icon: "question",
      html: html,
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonText: props.t("Confirm"),
      cancelButtonText: props.t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        setSyncDados(entity, index);
      }
    });
  };

  const setOptOut = async (entity, index) => {
    try {
      await post(
        url.POST_OPTOUT,
        {
          externalReference: entity["externalReference"],
        },
        null,
        setOptoutLoading
      );
      props.afterClosed();
    } catch (e) {
      if (e["status"] !== "200") setError(e);
    }
  };

  const setSyncDados = async (entity, index) => {
    try {
      await post(
        url.POST_OPTIN_SYNC_DATA,
        {
          externalReference: entity["externalReference"],
          id: entity["id"],
        },
        null,
        setOptoutLoading
      );
      props.afterClosed();
    } catch (e) {
      if (e["status"] !== "200") setError(e);
    }
  };

  const getByIdOptin = async (id) => {
    if (!id) return;
    const response = await get(
      `${url.GET_OPTIN_ADMIN_BY_ID}/${id}`,
      null,
      {},
      setOptoutLoading
    );

    return response;
  };

  useEffect(() => {
    if (props?.entityId) {
      getByIdOptin(props?.entityId).then((response) => {
        if (response) {
          setEntity(response);
        }
      });
    }
  }, [props?.entityId]);

  return (
    <React.Fragment>
      <Modal
        isOpen={props.isOpen}
        size="lg"
        toggle={() => {
          props.toggle(!props.isOpen);
        }}
        onClosed={() => {
          props.afterClosed();
        }}
        modalClassName="drawer right-align"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            <p className="card-title-desc mb-1">
              <small> {props.t("Opt-in")}</small>
              <small>
                {" / "} {entity.externalReference}
              </small>
            </p>
            {entity?.receivingEndUser?.name}
          </h5>
          <button
            type="button"
            onClick={() => {
              props.toggle(!props.isOpen);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Card>
            <CardBody>
              <Table className="table table-striped mb-0 font-size-14">
                <tbody>
                  <tr>
                    <td className="col-md-8">
                      <p className="card-title-desc mb-2">
                        {props.t("Participant")}
                      </p>
                      {entity?.registerAgent?.name}
                    </td>
                    <td className="col-md-4">
                      <p className="card-title-desc mb-2">{props.t("CNPJ")}</p>
                      {$filter(entity?.registerAgent?.legalId, "CPF_CNPJ")}
                    </td>
                  </tr>
                  <tr>
                    <td className="col-md-8">
                      <p className="card-title-desc mb-2">
                        {props.t("Requester")}
                      </p>
                      {entity?.financialAgent?.name}
                    </td>
                    <td className="col-md-4">
                      <p className="card-title-desc mb-2">{props.t("CNPJ")}</p>
                      {$filter(entity?.financialAgent?.legalId, "CPF_CNPJ")}
                    </td>
                  </tr>

                  <tr>
                    <td className="col-md-8">
                      <p className="card-title-desc mb-2">
                        {props.t("Receiving end user")}
                      </p>
                      {entity?.receivingEndUser?.name}
                    </td>
                    <td className="col-md-4">
                      <p className="card-title-desc mb-2">
                        {props.t("CPF/CNPJ")}
                      </p>
                      {$filter(entity?.receivingEndUser?.legalId, "CPF_CNPJ")}
                    </td>
                  </tr>

                  <tr>
                    <td className="col-md-8">
                      <p className="card-title-desc mb-2">
                        {props.t("Titular")}
                      </p>
                      {entity?.holder?.name}
                    </td>
                    <td className="col-md-4">
                      <p className="card-title-desc mb-2">
                        {props.t("CPF/CNPJ")}
                      </p>
                      {$filter(entity?.holder?.legalId, "CPF_CNPJ")}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Table className="table table-striped mb-0 font-size-14">
                <tbody>
                  <tr>
                    <td className="col-md-8">
                      <p className="card-title-desc mb-2">
                        {props.t("Signature date")}
                      </p>
                      {$filter(entity.signatureDate, "DATE")}
                    </td>
                    <td className="col-md-4">
                      <p className="card-title-desc mb-2">
                        {props.t("Status")}
                      </p>
                      <span
                        className={StatusDomain.getClassFromValue(
                          entity.status
                        )}
                      >
                        {StatusDomain.getNameFromValue(entity.status)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="col-md-8">
                      <p className="card-title-desc mb-2">
                        {props.t("Start date")}
                      </p>
                      {$filter(entity.startDate, "DATE")}
                    </td>
                    <td className="col-md-4">
                      <p className="card-title-desc mb-2">
                        {props.t("Opt-in expiration date")}
                      </p>
                      {$filter(entity.endDate, "DATE")}
                    </td>
                  </tr>

                  <tr>
                    <td className="col-md-8">
                      <p className="card-title-desc mb-2">
                        {props.t("Reference Code")}
                      </p>
                      {entity.externalReference}
                    </td>
                    <td className="col-md-4">
                      <p className="card-title-desc mb-2">
                        {props.t("Opt-out date")}
                      </p>
                      {$filter(entity.optoutDate, "DATE")}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Table className="table table-striped mb-0 font-size-14">
                <tbody>
                  <tr>
                    <td className="col-md-8">
                      <p className="card-title-desc mb-2">
                        {props.t("Acquirer")}
                      </p>
                    </td>
                    <td className="col-md-8">
                      <p className="card-title-desc mb-2">
                        {props.t("Legal ID Acquirer")}
                      </p>
                    </td>
                  </tr>

                  {(entity?.acquirers || []).map((ac, index) => (
                    <tr>
                      <td className="col-md-8">{ac.name}</td>
                      <td className="col-md-4">
                        {$filter(ac.legalId, "CPF_CNPJ")}
                      </td>
                    </tr>
                  ))}
                  {entity["acquirers"] === null ||
                  entity["acquirers"] === undefined ||
                  entity["acquirers"].length === 0 ? (
                    <tr>
                      <td className="col-md-8">{props.t("All")}</td>
                      <td className="col-md-4"></td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Table className="table table-striped mb-0 font-size-14">
                <tbody>
                  <tr>
                    <td className="col-md-8">
                      <p className="card-title-desc mb-2">
                        {props.t("Payment arrangement")}
                      </p>
                    </td>
                    <td className="col-md-4"></td>
                  </tr>

                  {(entity?.arrangements || []).map((ar, index) => (
                    <tr>
                      <td className="col-md-8">
                        {ar.code === "ALL" ? props.t("All") : ""}
                        {ar.code === "ALL_CREDIT" ? props.t("All Credits") : ""}
                        {ar.code === "ALL_DEBIT" ? props.t("All Debits") : ""}

                        {ar.code !== "ALL_DEBIT" &&
                        ar.code !== "ALL_CREDIT" &&
                        ar.code !== "ALL"
                          ? ar.label
                          : ""}
                      </td>
                      <td className="col-md-4">
                        {ar.code !== "ALL_DEBIT" &&
                        ar.code !== "ALL_CREDIT" &&
                        ar.code !== "ALL"
                          ? PaymentSchemaTypeDomain.getValueById(ar.type)
                          : ""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
          <SerproLegalIdDetails entity={entity} />
          <Card>
            <CardBody>
              <Table className="table table-striped mb-0 font-size-14">
                <tbody>
                  <tr>
                    <td className="col-md-8">
                      <p className="card-title-desc mb-2">
                        Sincronizar dados CERC e SERPRO
                      </p>
                    </td>
                    <td className="col-md-4">
                      <p className="card-title-desc mb-2">Opt-Out</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="col-md-8">
                      <Button
                        color="primary"
                        onClick={() => openSyncnizeDataCerc(entity)}
                      >
                        Sincronizar dados
                      </Button>
                    </td>
                    <td className="col-md-4">
                      <Button
                        color="primary"
                        onClick={() => openOptoutConfirm(entity)}
                      >
                        Opt-out
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
          {entity?.receivableDetails?.legalIdReceivingEndUser ? (
            <CardReceivablesDetails receivable={entity.receivableDetails} />
          ) : (
            <></>
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default withRouter(withTranslation()(OptinViewAdmin));
