import React, {useState} from 'react';
import MetaTags from 'react-meta-tags';
import {Card, CardBody, Col, Container, Label, Row, Table} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {useHistory, useParams, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";

function PerformConciliationEdit(props) {
    const history = useHistory();
    const cancel = () => history.push('/perform-conciliation');
    let {id} = useParams();
    const [pageParams] = useState(() => {
        if (id) {
            return {
                id: id,
                title: props.t("Edit conciliation"),
                editMode: true
            }
        }
        return {
            id: null,
            title: props.t("Register conciliation"),
            editMode: false
        }
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{pageParams.title}</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title={pageParams.title} breadcrumbItem={pageParams.title}/>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <p className="card-title-desc">{props.t("Control information")}</p>
                                    <Row>
                                        <Col lg={4}>
                                            <div className="form-floating mb-2">
                                                <input type="text" className="form-control" id="inputExternalReference"
                                                       placeholder="."/>
                                                <Label
                                                    for="inputExternalReference">{props.t("External Reference")}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="form-floating mb-2">
                                                <input type="text" className="form-control" id="inputRequester"
                                                       placeholder="."/>
                                                <Label for="inputRequester">{props.t("Requester")}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="form-floating mb-2">
                                                <input type="text" className="form-control" id="inputFinancialAgent"
                                                       placeholder="."/>
                                                <Label for="inputFinancialAgent">{props.t("Financial Agent")}</Label>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={4}>
                                            <div className="form-floating mb-2">
                                                <input type="text" className="form-control"
                                                       id="inputCompanyRecipientReceivable"
                                                       placeholder="."/>
                                                <Label
                                                    for="inputCompanyRecipientReceivable">{props.t("Company recipient receivable")}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="form-floating mb-2">
                                                <input type="text" className="form-control" id="inputSignatureDate"
                                                       placeholder="."/>
                                                <Label for="inputSignatureDate">{props.t("Signature Date")}</Label>
                                            </div>
                                        </Col>
                                    </Row>


                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <div className="text-end">
                                <button
                                    type="button"
                                    className="btn btn-sm btn-light w-sm waves-effect waves-light"
                                    onClick={() => {
                                        cancel();
                                    }}
                                >
                                    {props.t("Cancel")}
                                </button>
                                {" "}
                                <button
                                    type="button"
                                    className="btn btn-sm btn-primary w-sm waves-effect waves-light"

                                >
                                    {props.t("Confirm")}
                                </button>
                                {" "}
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
}

export default withRouter(withTranslation()(PerformConciliationEdit))