import React from "react";
import { Table } from "reactstrap";

const ReceiptPlanBox = (props) => {
  return (
    <div
      className={
        props.selected ? "card-product-select selected" : "card-product-select"
      }
    >
      <Table className={props.selected ? "selected" : ""}>
        <tbody>
          <tr>
            {props.selected !== null && props.selected !== undefined ? (
              <td className="col-md-1 align-middle">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="onlyTodayCheck"
                  checked={props.selected}
                />
              </td>
            ) : (
              ""
            )}

            <td className="col-md-1 align-middle">
              <div className="avatar-sm mx-auto ">
                <span className="avatar-title rounded-circle bg-light font-size-24">
                  {props.icon ? <i className={props.icon} /> : ""}
                  {props.img ? (
                    <img src={props.img} style={{ height: 35 }} alt="avatar" />
                  ) : (
                    <></>
                  )}
                </span>
              </div>
            </td>
            <td className="col-md-9 align-middle ">
              <strong>{props.title}</strong> <br />
              {props.detail ? (
                <p className="static-label"> {props.detail}</p>
              ) : (
                " "
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default ReceiptPlanBox;
