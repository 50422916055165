import React from 'react';
import {Card, CardBody, Col, Row} from 'reactstrap';
import $filter from "../../common/utils/$filter";

const PortfolioSummary = props => {

    const view = props.view || {
        amountCharged: 0,
        qtReceivablesSchedules: 0,
        freeValue: 0
    }

    const portfolioSummary = [
        {
            id: 1,
            title: 'Saldo em contrato',
            count: $filter(view.debitBalance, 'money'),
            icon: 'fas fa-file-signature text-primary',
        },
        {
            id: 2,
            title: 'Saldo em garantias',
            count: $filter(view.amountCharged, 'money'),
            dollor: false,
            icon: 'fas fa-award text-success',
        },
        {
            id: 3,
            title: 'Agenda de recebíveis',
            count: `${!!view['qtReceivablesSchedules'] ? view['qtReceivablesSchedules'] : 0} Und.`,
            dollor: false,
            icon: 'far fa-calendar-alt text-primary',
        },
        {
            id: 4,
            title: 'Saldo em agendas',
            count: $filter(view.freeValue, 'money'),
            icon: 'far fa-calendar-check text-success',
        },
    ]

    return (
        <React.Fragment>
            <Row>
                {portfolioSummary.map((widget, key) => (
                    <Col md={6} xl={3} key={key}>
                        <Card>
                            <CardBody>
                                <div className="float-end">
                                    <div className="avatar-sm mx-auto mb-4">
                                        <span className="avatar-title rounded-circle bg-light font-size-24">
                                            <i className={widget.icon}></i>
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <p className="text-muted text-uppercase fw-semibold">{widget.title}</p>
                                    <h4 className="mb-1 mt-1">
                                        {widget.dollor === true ? '$' : ''}
                                        <span className="counter-value" data-target="58425">
                                            {widget.count}
                                        </span></h4>
                                </div>
                                <p className="text-muted mt-1 mb-0">
                                    <span className={"badge badge-soft-" + widget.color + " me-1"}>

                                        {widget.upArrow === true ?
                                            <i className="mdi mdi-arrow-up-bold me-1"></i> :
                                            <i className="mdi mdi-arrow-down-bold me-1"></i>
                                        }
                                        {widget.percentage}
                                    </span> {widget.metricLabel}
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
        </React.Fragment>
    );
}

export default PortfolioSummary;