const ArrangementsTypeDomain = {

    options: [
        {label: "Todos arranjos de pagamento", value: "ALL"},
        {label: "Todos arranjos de crédito", value: "ALL_CREDITORS"},
        {label: "Todos arranjos de débito", value: "ALL_DEBITORS"},
    ],

    getLabelByValue(value){
        for (const i in ArrangementsTypeDomain.options){
            if (ArrangementsTypeDomain.options[i].value === value){
                return this.options[i].label
            }
        }
        return null
    }
}


export default ArrangementsTypeDomain