import moment from 'moment-timezone'

class DateTimeUtils {

    static regex() {
        return new RegExp(/^\d{4}([./-])\d{2}\1\d{2}$/);
    }

    static toISO(data) {
        if (data) {
            const dataBR = moment.tz(data, "America/Sao_Paulo");
            return dataBR.toISOString();
        }
        return null;
    }

    static isValid(value) {
        return DateTimeUtils.regex().test(value);
    }

    static format(data, format) {
        if (data) {
            const dataBR = moment.tz(data, "America/Sao_Paulo");
            return moment(dataBR).format(format ? format : 'YYYY-MM-DD');
        }
        return null;
    }

    static filter(data, format) {
        if (data) {
            const date1 = DateTimeUtils.parse(data, format)
            return DateTimeUtils.format(date1, 'DD/MM/YYYY')
        }
        return null;
    }

    static addMillisecond(date, millisecond) {
        if (date && millisecond && millisecond > 0) {
            const dt = moment(date);
            dt.add({millisecond: millisecond});
            return dt.toDate();
        }
        return null;
    }

    static addSeconds(date, seconds) {
        if (date && seconds && seconds > 0) {
            const dt = moment(date);
            dt.add({second: seconds});
            return dt.toDate();
        }
        return null;
    }

    static addMonths(date, months) {
        if (date && months && months > 0) {
            const dt = moment(date);
            dt.add({month: months});
            return dt.toDate();
        }
        return null;
    }

    static addDays(date, days) {
        if (date && days && days > 0) {
            const dt = moment(date);
            dt.add({day: days});
            return dt.toDate();
        }
        return null;
    }

    static subDays(date, days) {
        if (date && days && days > 0) {
            const dt = moment(date);
            dt.subtract({day: days});
            return dt.toDate();
        }
        return null;
    }

    static subMinutes(date, minute) {
        if (date && minute && minute > 0) {
            const dt = moment(date);
            dt.subtract({minute: minute});
            return dt.toDate();
        }
        return null;
    }

    static addMinutes(date, minute) {
        if (date && minute && minute > 0) {
            const dt = moment(date);
            dt.add({minute: minute});
            return dt.toDate();
        }
        return null;
    }

    static parse(date, format) {
        let result = null;
        if (date) {
            if (format) {
                result = moment(date, format).toDate();
            }
            result = moment(date).toDate();
        }

        if (result && result.toString() === 'Invalid Date') {
            throw Error(`Invalid Date: ${date} ${format ? 'expected format: ' + format : ''}`);
        }

        return result;
    }

    static isBefore(dateSource, dateTarget) {
        return moment(dateSource).isBefore(dateTarget)
    }

    static daysBetween(startDate, endDate) {
        return moment(startDate).diff(endDate, 'day')
    }
}

export default DateTimeUtils