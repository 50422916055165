import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import $filter from "../../common/utils/$filter";
import DivisionRuleDomain from "../../domains/DivisionRuleDomain";
import DistributionTypeDomain from "../../domains/DistributionTypeDomain";
import EffetiveTypeDomain from "../../domains/EffetiveTypeDomain";
import ModalityDomain from "../../domains/ModalityDomain";
import PaymentCompanyDomain from "../../domains/PaymentCompanyDomain";
import AccountTypeDomain from "../../domains/AccountTypeDomain";
import ErrorDialog from "../../components/Common/ErrorDialog";
import {
  downloadFile,
  downloadFileComplete,
  get,
  hasRole,
  post,
} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import Loader from "../../components/Common/Loader";
import StatusDomain from "../../domains/StatusDomain";
import StatusPaymentDomain from "../../domains/StatusPaymentDomain";
import SignStatusDomain from "../../domains/SignStatusDomain";
import BillingIntegrationStatus from "../../domains/BillingIntegrationStatus";
import { GET_CNAB444_PAGAMENTO } from "../../helpers/url_helper";
import Swal from "sweetalert2";
import ProductTypeDomain from "../../domains/ProductTypeDomain";

function ManageContractsView(props) {
  const history = useHistory();
  const cancel = () => history.push("/contracts/manage-contracts");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [enableGenerateBilling, setEnableGenerateBillingCore] = useState(false);

  const [state, setState] = useState(
    localStorage.getItem("contractView")
      ? JSON.parse(localStorage.getItem("contractView"))
      : {}
  );

  const [mainVal, setMainVal] = useState(0);
  const [totalVal, setTotalVal] = useState(0);

  let { id } = useParams();
  const [pageParams] = useState(() => {
    if (id) {
      return {
        id: id,
        title: props.t("Edit contract"),
        editMode: true,
      };
    }
    return {
      id: null,
      title: props.t("Register contract"),
      editMode: false,
    };
  });

  useEffect(() => {
    // localStorage.removeItem("contractView")

    if (state["contractId"] === null || state["contractId"] === undefined)
      cancel();

    if (!enableGenerateBilling) {
      setEnableGenerateBilling(state);
    }

    if (state.additionalInfo.product === "CG") {
      setMainVal(
        state.additionalInfo.mainValue > state.totalAmount
          ? state.additionalInfo.mainValue
          : state.totalAmount
      );
      setTotalVal(
        state.additionalInfo.mainValue < state.totalAmount
          ? state.additionalInfo.mainValue
          : state.totalAmount
      );
    } else {
      setMainVal(state.additionalInfo.mainValue);
      setTotalVal(state.totalAmount);
    }
  }, [state]);

  const setEnableGenerateBilling = (contract) => {
    if (contract.installments && contract.installments.length > 0) {
      contract.installments.map((inst) => {
        if (
          inst["statusChargeInt"] === null ||
          inst["statusChargeInt"] === undefined
        ) {
          setEnableGenerateBillingCore(true);
        }
      });
    }
  };

  const printContract = async (entity) => {
    const urlApi = `${url.GET_CONTRACT_TERMS}/${entity.externalReference}`;
    const filename = `${entity.contractor["name"]} - ${entity.externalReference}.pdf`;

    await downloadFileComplete(urlApi, null, null, setLoading, filename, "GET");
  };

  const requestContractSiging = async (entity) => {
    if (!hasRole("signing.write")) {
      return;
    }
    try {
      const response = await post(
        url.REQUEST_CONTRACT_SIGNING,
        {
          entityId: entity.externalReference,
        },
        {},
        setLoading
      );

      setState({ ...state, signingStatus: "P" });
    } catch (e) {
      setError({
        title: "Solicitação de Assinatura",
        status: "500",
        detail:
          "Erro ao realizar a Solicitação de Assinatura. Favor contate o suporte.",
      });
    }
  };

  const handleRefresh = async () => {
    const response = await get(
      url.GET_CONTRACT,
      {},
      {
        "pagination.currentPage": 0,
        "pagination.itemsPerPage": 1,
        externalReference: state["externalReference"],
      },
      setLoading
    );
    setState(response["contracts"][0]);
  };

  const askForConfirmationCNAB = () => {
    if (state.status === "A") {
      return { isConfirmed: true };
    }

    const html = `<div>
                <p><strong>Contrato não está ativo e registrado. Deseja prosseguir com o download do CNAB444 para fins de simulação?</strong>
                </p>
            </div>`;

    return Swal.fire({
      title: "Status Contrato",
      icon: "question",
      html: html,
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonText: props.t("Confirm"),
      cancelButtonText: props.t("Cancel"),
    });
  };

  const handleGetCnabRemessa = async () => {
    try {
      const question = await askForConfirmationCNAB();
      if (!question.isConfirmed) {
        return;
      }

      const headers = {
        "Content-Type": "application/json",
        "x-auth-info": JSON.stringify({
          clientId: props.match["params"]["id"],
          scope: "contract.read",
          registerAgent: {},
          financialAgent: {},
          user: {},
        }),
      };

      const response = await get(
        `${url.GET_CNAB444_REMESSA}/${state.contractId}`,
        { headers },
        {},
        setLoading
      );

      download(response);
    } catch (e) {
      setError(e);
    }
  };

  const handleGetCnabPagamento = async () => {
    try {
      const question = await askForConfirmationCNAB();
      if (!question.isConfirmed) {
        return;
      }

      const headers = {
        "Content-Type": "application/json",
        "x-auth-info": JSON.stringify({
          clientId: props.match["params"]["id"],
          scope: "contract.read",
          registerAgent: {},
          financialAgent: {},
          user: {},
        }),
      };

      const response = await get(
        `${url.GET_CNAB444_PAGAMENTO}/${state.contractId}`,
        { headers },
        {},
        setLoading
      );

      download(response);
    } catch (e) {
      setError(e);
    }
  };

  const download = (f) => {
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(f.content)
    );
    element.setAttribute("download", f.fileName);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const buildInstallmentsToBiling = (onlyWithoutBilling, installmentNumber) => {
    const request = {
      contractId: state["contractId"],
      installments: [],
    };

    for (const i in state["installments"]) {
      if (installmentNumber !== null) {
        request.installments.push({
          installmentNumber: state["installments"][i]["installmentNumber"],
        });
      } else {
        if (onlyWithoutBilling) {
          if (
            state["installments"][i]["statusChargeInt"] === null ||
            state["installments"][i]["statusChargeInt"] === undefined ||
            state["installments"][i]["statusChargeInt"] ===
              BillingIntegrationStatus.ERROR
          ) {
            request.installments.push({
              installmentNumber: state["installments"][i]["installmentNumber"],
            });
          }
        } else {
          request.installments.push({
            installmentNumber: state["installments"][i]["installmentNumber"],
          });
        }
      }
    }

    // console.log(request)

    return request;
  };

  const handleGenerateBilling = async (installmentNumber) => {
    if (loading) return;

    if (state["installments"] && state["installments"].length) {
      try {
        const request = buildInstallmentsToBiling(true, installmentNumber);

        // no installments without billing
        if (request["installments"].length === 0) {
          setError({
            title: props.t("Billing not requested"),
            status: "404",
            detail: props.t("Not installments availables to billing"),
          });
          return;
        }

        const response = await post(
          url.POST_BILLING,
          request,
          null,
          setLoading
        );

        response.map((ins) => {
          state["installments"].map((subIns) => {
            if (
              ins["contractId"] === subIns["contractId"] &&
              ins["installmentNumber"] === subIns["installmentNumber"]
            ) {
              subIns["statusChargeInt"] = ins["statusIntegration"];
            }
          });
        });

        const newState = Object.assign({}, state);

        setState(newState);
        setEnableGenerateBilling(newState);
      } catch (e) {
        if (e["status"] !== "200" && e["status"] !== "403") setError(e);
      }
    }
  };

  const handleDownloadBilling = async () => {
    const request = buildInstallmentsToBiling(false, null);

    const filename = `${state.contractor["name"]} - ${state.externalReference} - BILLING.pdf`;

    await downloadFileComplete(
      url.POST_BILLING_PDF,
      null,
      request,
      setLoading,
      filename
    );
  };

  return (
    <React.Fragment>
      {state["contractId"] !== null && state["contractId"] !== undefined ? (
        <div className="page-content">
          <MetaTags>
            <title>{props.t("View contract")}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
              title={props.t("Contracts")}
              breadcrumbItem={props.t("View contract")}
            />
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <p className="card-title-desc">
                      {props.t("Contract information")}
                    </p>
                    <Row className="mb-3">
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Contract ID")}
                        </p>
                        <strong>{state.contractId}</strong>
                      </Col>
                      <Col lg={4}>
                        <p className="card-title-desc mb-0">
                          {props.t("Reference Code")}
                        </p>
                        <strong>{state.externalReference}</strong>
                      </Col>
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Signature date")}
                        </p>
                        <strong>{$filter(state.signatureDate, "DATE")}</strong>
                      </Col>
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Due date")}
                        </p>
                        <strong>{$filter(state.dueDate, "DATE")}</strong>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Legal ID contractor")}
                        </p>
                        <strong>
                          {$filter(state.contractor["legalId"], "CPF_CNPJ")}
                        </strong>
                      </Col>
                      <Col lg={4}>
                        <p className="card-title-desc mb-0">
                          {props.t("Contractor")}
                        </p>
                        <strong>{state.contractor["name"]}</strong>
                      </Col>
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Legal ID register agent")}
                        </p>
                        <strong>
                          {$filter(state.registerAgent.legalId, "CPF_CNPJ")}
                        </strong>
                      </Col>
                      <Col lg={4}>
                        <p className="card-title-desc mb-0">
                          {props.t("Register agent")}
                        </p>
                        <strong>{state.registerAgent.name}</strong>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Legal ID Financial Agent")}
                        </p>
                        <strong>
                          {$filter(state.financialAgent.legalId, "CPF_CNPJ")}
                        </strong>
                      </Col>
                      <Col lg={4}>
                        <p className="card-title-desc mb-0">
                          {props.t("Financial Agent")}
                        </p>
                        <strong>{state.financialAgent.name}</strong>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Debit balance")}
                        </p>
                        <strong>{$filter(state.debitBalance, "MONEY")}</strong>
                      </Col>
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Guaranteed operation limit")}
                        </p>
                        <strong>
                          {$filter(state.guaranteedOperationLimit, "MONEY")}
                        </strong>
                      </Col>
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Value Applied Effect Total")}
                        </p>
                        <strong>
                          {$filter(state.valueAppliedEffectTotal, "MONEY")}
                        </strong>
                      </Col>
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Balance to be constituted in guarantees")}
                        </p>
                        <strong>
                          {$filter(
                            state.balanceToBeConstitutedInGuarantees,
                            "MONEY"
                          )}
                        </strong>
                      </Col>
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Chargeback balance")}
                        </p>
                        <strong>
                          {$filter(state.chargebackBalance, "MONEY")}
                        </strong>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Status")}
                        </p>
                        <span
                          className={StatusDomain.getClassFromValue(
                            state.status
                          )}
                        >
                          {StatusDomain.getNameFromValue(state.status)}
                        </span>
                        {state.additionalInfo.product !== "CB" ? (
                          <span
                            className={SignStatusDomain.getClassFromValue(
                              state.signingStatus
                            )}
                            role="button"
                            onClick={
                              state.signingStatus
                                ? null
                                : () => requestContractSiging(state)
                            }
                          >
                            {SignStatusDomain.getNameFromValue(
                              state.signingStatus
                            )}
                          </span>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Effect type")}
                        </p>
                        <strong>
                          {EffetiveTypeDomain.getLabelByValue(state.effectType)}
                        </strong>
                      </Col>
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Modality")}
                        </p>
                        <strong>
                          {ModalityDomain.getLabelByValue(state.modality)}
                        </strong>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <p className="card-title-desc">
                      {props.t("Financial Service")}
                    </p>
                    <Row className="mb-3">
                      <Col lg={4}>
                        <p className="card-title-desc mb-0">
                          {props.t("Product")}
                        </p>
                        {state.additionalInfo.productName ? (
                          <strong>{state.additionalInfo.productName}</strong>
                        ) : (
                          <strong>{"Antecipação de Recebíveis"}</strong>
                        )}
                      </Col>
                      <Col lg={4}>
                        <p className="card-title-desc mb-0">
                          {props.t("Modality")}
                        </p>
                        {/*<strong>{state.additionalInfo.product.modality}</strong>*/}

                        {state.additionalInfo.productModalityName ? (
                          <strong>
                            {state.additionalInfo.productModalityName}
                          </strong>
                        ) : (
                          <strong>Recebíveis de Arranjo de Pagamento</strong>
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col lg={1}>
                        <p className="card-title-desc mb-0">
                          {props.t("Average term")}
                        </p>
                        <strong>
                          {state.additionalInfo.averageTerm}&nbsp;dias
                        </strong>
                      </Col>
                      <Col lg={1}>
                        <p className="card-title-desc mb-0">
                          {props.t("Discount rate")}
                        </p>
                        <strong>
                          {$filter(
                            state.additionalInfo.discountRate,
                            "PRECENT"
                          )}
                        </strong>
                      </Col>
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Effective rate")}
                        </p>
                        <strong>
                          {$filter(
                            state.additionalInfo.effectiveRate,
                            "PRECENT"
                          )}
                        </strong>
                      </Col>
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Adm rate")}
                        </p>
                        <strong>
                          {$filter(state.additionalInfo.admRate, "PRECENT")}
                        </strong>
                      </Col>
                      <Col lg={2}>
                        {state.additionalInfo.product === "CG" ? (
                          <>
                            <p className="card-title-desc mb-0">Juros</p>
                            <strong>
                              {$filter(
                                state.additionalInfo.discountValue < 0
                                  ? state.additionalInfo.discountValue * -1
                                  : state.additionalInfo.discountValue,
                                "MONEY"
                              )}
                            </strong>
                          </>
                        ) : (
                          <>
                            <p className="card-title-desc mb-0">
                              {props.t("Discount")}
                            </p>
                            <strong>
                              {$filter(
                                state.additionalInfo.discountValue,
                                "MONEY"
                              )}
                            </strong>
                          </>
                        )}
                      </Col>
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Main Value")}
                        </p>
                        <strong>{$filter(mainVal, "MONEY")}</strong>
                      </Col>
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Total amount")}
                        </p>
                        <strong>{$filter(totalVal, "MONEY")}</strong>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <p className="card-title-desc">
                      Conta para liquidação e provisionamento de garantias,
                      utilizada como domicílio bancário no credenciador.
                    </p>

                    <Row className="mb-3">
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Legal ID bank owner")}
                        </p>
                        <strong>
                          {$filter(
                            state.creditorAccount.taxIdOwner,
                            "CPF_CNPJ"
                          )}
                        </strong>
                      </Col>
                      <Col lg={6}>
                        <p className="card-title-desc mb-0">
                          {props.t("Holder name")}
                        </p>
                        <strong>{state.creditorAccount.ownerName}</strong>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("ISPB")}
                        </p>
                        <strong>{state.creditorAccount.ispb}</strong>
                      </Col>
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Payment company")}
                        </p>
                        <strong>
                          {PaymentCompanyDomain.getLabelByCode(
                            state.creditorAccount.ispb
                          )}
                        </strong>
                      </Col>
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Account type")}
                        </p>
                        <strong>
                          {AccountTypeDomain.getLabelByValue(
                            state.creditorAccount.accountType
                          )}
                        </strong>
                      </Col>
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Branch")}
                        </p>
                        <strong>{state.creditorAccount.branch}</strong>
                      </Col>
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Account number")}
                        </p>
                        <strong>{state.creditorAccount.accountNumber}</strong>
                      </Col>
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Account number digit")}
                        </p>
                        <strong>{state.creditorAccount.accountDigit}</strong>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <p className="card-title-desc">
                      {props.t(
                        "Commercial establishment account for credit release"
                      )}
                    </p>

                    <Row className="mb-3">
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Legal ID bank owner")}
                        </p>
                        <strong>
                          {$filter(state.contractor.legalId, "CPF_CNPJ")}
                        </strong>
                      </Col>
                      <Col lg={6}>
                        <p className="card-title-desc mb-0">
                          {props.t("Holder name")}
                        </p>
                        <strong>{state.contractor.name}</strong>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("ISPB")}
                        </p>
                        <strong>{state.contractor.bankAccount.ispb}</strong>
                      </Col>
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Payment company")}
                        </p>
                        <strong>
                          {PaymentCompanyDomain.getLabelByCode(
                            state.contractor.bankAccount.ispb
                          )}
                        </strong>
                      </Col>
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Account type")}
                        </p>
                        <strong>
                          {AccountTypeDomain.getLabelByValue(
                            state.contractor.bankAccount.accountType
                          )}
                        </strong>
                      </Col>
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Branch")}
                        </p>
                        <strong>{state.contractor.bankAccount.branch}</strong>
                      </Col>
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Account number")}
                        </p>
                        <strong>
                          {state.contractor.bankAccount.accountNumber}
                        </strong>
                      </Col>
                      <Col lg={2}>
                        <p className="card-title-desc mb-0">
                          {props.t("Account number digit")}
                        </p>
                        <strong>
                          {state.contractor.bankAccount.accountDigit}
                        </strong>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {state.guarantees.length > 0 ? (
              <Row>
                <Col xs={12}>
                  <Card>
                    <CardBody>
                      <p className="card-title-desc">{props.t("Guarantees")}</p>
                      <Row>
                        <div className="table-responsive">
                          <Table className="table table-striped table-bordered font-size-default">
                            <thead className="table-light">
                              <tr>
                                <th></th>
                                <th>{props.t("Acquirers")}</th>
                                <th>{props.t("Payment arrangement")}</th>

                                {state.additionalInfo.productName &&
                                state.additionalInfo.productName ===
                                  "Capital.Giro" ? (
                                  <React.Fragment>
                                    <th>{props.t("Start date")}</th>
                                    <th>{props.t("End date")}</th>
                                  </React.Fragment>
                                ) : (
                                  <th>{props.t("Due date")}</th>
                                )}

                                <th>{props.t("Type of distribution")}</th>
                                <th>{props.t("Amount to be charged")}</th>
                                <th>{props.t("Value Applied Effect")}</th>
                                <th>{props.t("Chargeback balance")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {state.guarantees.map((guar, index) => (
                                <tr key={guar["externalReference"]}>
                                  <td className="text-center">{index + 1}</td>
                                  <td>
                                    {" "}
                                    {guar["acquires"] &&
                                    guar["acquires"].length > 0
                                      ? guar["acquires"][0]["name"]
                                      : "Todos"}
                                    {guar["acquires"] &&
                                    guar["acquires"].length > 0 ? (
                                      <React.Fragment>
                                        <br />
                                        {$filter(
                                          guar["acquires"][0]["legalId"],
                                          "CPF_CNPJ"
                                        )}
                                      </React.Fragment>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    {guar["arrangements"] &&
                                    guar["arrangements"].length > 0
                                      ? guar["arrangements"][0]["name"]
                                      : "Todos"}
                                  </td>

                                  {state.additionalInfo.productName &&
                                  state.additionalInfo.productName ===
                                    "Capital.Giro" ? (
                                    <React.Fragment>
                                      <td>{$filter(guar.startDate, "DATE")}</td>
                                      <td>{$filter(guar.endDate, "DATE")}</td>
                                    </React.Fragment>
                                  ) : (
                                    <th>{$filter(guar.startDate, "DATE")}</th>
                                  )}

                                  <td>
                                    {DivisionRuleDomain.getLabelByValue(
                                      guar.divisionRule
                                    )}
                                    <br />
                                    <small>
                                      {DistributionTypeDomain.getLabelByValue(
                                        guar.typeDistribution
                                      )}
                                    </small>
                                  </td>
                                  <td>
                                    {$filter(guar.amountCharged, "MONEY")}
                                  </td>
                                  <td>
                                    {$filter(guar.valueAppliedEffect, "MONEY")}
                                  </td>
                                  <td>
                                    {$filter(guar.chargebackBalance, "MONEY")}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              ""
            )}

            {state.installments.length > 0 ? (
              <Row>
                <Col xs={12}>
                  <Card>
                    <CardBody>
                      <p className="card-title-desc">
                        {props.t("Installments")}
                      </p>
                      <Row>
                        <div className="table-responsive">
                          <Table className="table table-striped table-bordered font-size-default">
                            <thead className="table-light">
                              <tr>
                                <th></th>
                                <th>{props.t("Due date")}</th>
                                <th>{props.t("Deadline")}</th>

                                <th>{props.t("Discount rate")}</th>
                                <th>{props.t("Effective rate")}</th>

                                <th>{props.t("Main Value")}</th>
                                <th>{props.t("Amount")}</th>

                                <th>{props.t("Status")}</th>
                                <th>{props.t("Billing")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {state.installments.map((inst, index) => (
                                <tr key={inst["id"]}>
                                  <td className="text-center">
                                    {inst["installmentNumber"]}
                                  </td>
                                  <td>{$filter(inst["dueDate"], "DATE")}</td>
                                  <td>{inst["deadline"]}</td>
                                  <td>
                                    {$filter(inst["discountRate"], "PRECENT")}
                                  </td>
                                  <td>
                                    {$filter(inst["effectiveRate"], "PRECENT")}
                                  </td>
                                  <td>{$filter(inst["mainValue"], "MONEY")}</td>
                                  <td>{$filter(inst["amount"], "MONEY")}</td>
                                  <td>
                                    {StatusPaymentDomain.getLabelByValue(
                                      inst["status"]
                                    )}
                                  </td>
                                  <td>
                                    <span
                                      className={BillingIntegrationStatus.getClassFromValue(
                                        inst["statusChargeInt"]
                                      )}
                                    >
                                      {BillingIntegrationStatus.getLabelByValue(
                                        inst["statusChargeInt"]
                                      )}
                                    </span>
                                    {inst["statusChargeInt"] === "E" ? (
                                      <React.Fragment>
                                        <br />
                                        <Link
                                          to="#!"
                                          onClick={() => {
                                            handleGenerateBilling(
                                              inst["installmentNumber"]
                                            );
                                          }}
                                        >
                                          Gerar cobrança
                                        </Link>
                                      </React.Fragment>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Row>

                      <Row className="mt-3">
                        <Col lg={12}>
                          <div className="text-end">
                            {state["status"] === "A" ? (
                              <div>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-primary mr-5"
                                  onClick={handleRefresh}
                                >
                                  <i className="mdi mdi-update font-size-16 mr-5" />
                                  {props.t("Update")}
                                </button>

                                <button
                                  type="button"
                                  className="btn btn-sm btn-primary mr-5"
                                  onClick={handleDownloadBilling}
                                >
                                  <i className="mdi mdi-file-download-outline font-size-16 mr-5" />
                                  {props.t("View Billing")}
                                </button>
                                {hasRole("billing.write") ? (
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-primary mr-5"
                                    onClick={() => {
                                      handleGenerateBilling(null);
                                    }}
                                    disabled={!enableGenerateBilling}
                                  >
                                    {props.t("Generate Billing")}
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              ""
            )}

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <p className="card-title-desc">Pagamentos</p>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <p className="card-title-desc">{props.t("KPIs")}</p>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/*<Row>*/}
            {/*    <Col xs={12}>*/}
            {/*        <Card>*/}
            {/*            <CardBody>*/}
            {/*                <p className="card-title-desc">Protocolo de Registro</p>*/}
            {/*            </CardBody>*/}
            {/*        </Card>*/}
            {/*    </Col>*/}
            {/*</Row>*/}

            {/*Actions*/}
            <Row className="mb-4">
              <div className="d-print-none">
                <div className="float-start">
                  <button
                    type="button"
                    className="btn btn-sm btn-light w-sm waves-effect waves-light"
                    onClick={handleGetCnabRemessa}
                  >
                    Download CNAB444 Remessa
                  </button>{" "}
                  <button
                    type="button"
                    className="btn btn-sm btn-light w-sm waves-effect waves-light"
                    onClick={handleGetCnabPagamento}
                  >
                    Download CNAB444 Pagamentos
                  </button>
                </div>
                <div className="float-end">
                  <button
                    type="button"
                    className="btn btn-sm btn-light w-sm waves-effect waves-light"
                    onClick={() => {
                      cancel();
                    }}
                  >
                    {props.t("Back")}
                  </button>{" "}
                  <button
                    type="button"
                    className="btn btn-sm btn-primary waves-effect waves-light"
                    onClick={() => {
                      printContract(state);
                    }}
                  >
                    <i className="fa fa-print"></i>
                  </button>{" "}
                </div>
              </div>
            </Row>
          </Container>
        </div>
      ) : (
        ""
      )}

      {error ? (
        <ErrorDialog
          title={error["title"]}
          code={error["status"]}
          onClose={() => {
            setError(null);
          }}
        >
          <p>
            {!!error["detail"]
              ? error["detail"]
              : "Erro de rede, verifique sua conexão com a internet."}{" "}
          </p>
        </ErrorDialog>
      ) : (
        ""
      )}

      {loading ? <Loader loading={loading} /> : " "}
    </React.Fragment>
  );
}

export default withRouter(withTranslation()(ManageContractsView));
