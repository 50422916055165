import React from "react"
import ReactApexChart from "react-apexcharts"


const SummarizedVisionChart = (props) => {
  const series = props.series
  const options = {
    labels: props.labels,
    colors: props.colors,

    legend: {
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: true,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -100,
      show: false,
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90
      }
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="donut"
      height="300"
      className="apex-charts max-height-155"
      dir="ltr"
    />
  )
}

export default SummarizedVisionChart
