import React, {useState} from "react"
import {withTranslation} from "react-i18next";

import {Row, Col, Label, Form} from "reactstrap"
import {Link, useHistory, withRouter} from "react-router-dom"

import Loader from "../../components/Common/Loader";
import {post} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import AuthCode from "react-auth-code-input";
import {AvForm} from "availity-reactstrap-validation";

const {uuid} = require('uuidv4');

const TwoFactorConfirm = (props) => {

    const history = useHistory()

    const [loading, setLoading] = useState(false)
    const [twoFactor, setTwoFactor] = useState(null)
    const [sessionId] = useState(uuid())

    document.body.classList.add("login_page")

    const handleConfirm = async (autoCode) => {
        try {
            const response = await post(
                url.POST_CONFIRM_TWO_FACTOR, {
                    token: props.entity.token,
                    email: props.entity.email,
                    authCode: autoCode,
                    sessionId: sessionId
                }, null, setLoading
            )
            if (response['success']){
                props.onSuccess(Object.assign({
                    authCode: autoCode, sessionId: sessionId
                }, props.entity))
            }else {
                props.onFailure({
                    title: props.t("Invalid code"),
                    status: '500',
                    detail: props.t("Invalid authorization code")
                })
            }
        } catch (e) {
            props.onFailure(e)
        }
    }

    const handleTwoFactor = (autoCode) => {
        setTwoFactor(autoCode)
        if (autoCode.length === 4){
            handleConfirm(autoCode)
        }
    }

    const backPage = () => {
        history.push('/login')
        window.location.reload(true)
    }


    return (
        <React.Fragment>
            <div className="p-2">
                <div className="text-center">

                    <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                            <i className="bx bxs-envelope h1 mb-0 text-primary" />
                        </div>
                    </div>

                    <div className="p-2 mt-4">

                        <h4>{props.t("Check your email")}</h4>
                        <p className="mb-5 text-muted">{props.t("Enter the 4-digit code sent to")} <span
                            className="font-weight-semibold">{props.entity.email}</span></p>

                        <Form className="form-horizontal">
                            <Row>
                                <Col xs={12}>
                                    <div className="mb-3 verification">
                                        <Label for="digit1-input" className="visually-hidden">Dight 1</Label>
                                        <AuthCode
                                            characters={4}
                                            className="form-control form-control-lg text-center"
                                            allowedCharacters="^[0-9]"
                                            onChange={handleTwoFactor}
                                            inputStyle={{
                                                width: "76px",
                                                height: "42px",
                                                padding: "8px",
                                                borderRadius: "8px",
                                                fontSize: "16px",
                                                textAlign: "center",
                                                marginRight: "15px",
                                                border: "1px solid #ced4da",
                                                textTransform: "uppercase",
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form>

                        <Row>
                            <Col lg={12} className="mt-5 text-center">
                                <Link
                                    to="#!"
                                    style={{height: 42}}
                                    onClick={backPage}
                                >
                                    {props.t("Back")}
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            {
                loading ?
                    <Loader loading={loading}/>
                    :
                    (" ")
            }

        </React.Fragment>
    )
}


export default withRouter(withTranslation()(TwoFactorConfirm))

