import React, {useState} from "react"
import {withTranslation} from "react-i18next"
import CreatableSelect from 'react-select/creatable'
import makeAnimated from "react-select/animated/dist/react-select.esm"
import {Label} from "reactstrap"

const animatedComponents = makeAnimated()

function Select2DiscountRates(props) {

    const [options, setOptions] = useState(props.defaultOptins ? props.defaultOptins : [
        {label: "0,80%", value: "0.80"},
        {label: "1,00%", value: "1.00"},
        {label: "1,30%", value: "1.30"},
        {label: "2,00%", value: "2.00"},
        {label: "2,30%", value: "2.30"},
        {label: "3,00%", value: "3.00"},
        {label: "4,00%", value: "4.00"},
    ])

    function onChange(opt) {
        if (opt.label === opt.value) {
            opt.label = `${opt.label}%`
            opt.value = opt.value.replace(',', '.')

            if (isNaN(opt.value))
                return

            options.push(opt)
        }

        if (props.onChange)
            props.onChange(opt)
    }

    return (
        <div className="templating-select select2-container">
            {
                props.label !== null && props.label !== undefined ?
                    <Label for={props.id} className="form-label">
                        {`${props.required === true ? "*" : ""}${props.label}`}
                    </Label>
                    : ("")
            }
            <CreatableSelect
                isMulti={false}
                classNamePrefix="select2-selection"
                closeMenuOnSelect={true}
                isSearchable={true}
                menuPlacement={props.menuPlacement}
                placeholder={
                    props.placeholder !== null && props.placeholder !== undefined ?
                        props.placeholder : ""
                }
                defaultValue={options[props.defaultValue]}
                options={options}
                components={animatedComponents}
                formatCreateLabel={(inputValue) => {
                    const newValue = inputValue.replace(',', '.')
                    if (isNaN(newValue))
                        return `Valor inválido`

                    return `Definir nova taxa ${inputValue}%`
                }}
                onChange={(options) => {
                    onChange(options)
                }}
            />

        </div>
    )
}

export default withTranslation()(Select2DiscountRates)