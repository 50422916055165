import React from 'react';

const Placeholder = props => {

    return (
        <React.Fragment>
            {
                props.isLoading ?
                <span className="card-title placeholder-glow"
                    style={{color: "#cccc"}}>
                    <span className="placeholder col-6"> </span>
                </span> : props.value
            }
        </React.Fragment>
    );
}

export default Placeholder;