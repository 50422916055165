import React, { useState } from "react";
import { Card, CardBody, Col, Row, Table } from "reactstrap";

import { withTranslation } from "react-i18next";
import PaymentCompanyDomain from "../../domains/PaymentCompanyDomain";
import SelectAddAccountModal from "../../components/Common/SelectAddAccountModal";
import AccountTypeDomain from "../../domains/AccountTypeDomain";

function OrganizationEditBankAccount(props) {
  const [modalAddBankAccount, setModalAddBankAccount] = useState(false);

  const addAccount = (modalResult) => {
    if (modalResult["event"] === "SAVED") {
      if (!props.entity.bankAccounts) props.entity.bankAccounts = [];

      props.entity.bankAccounts.push(modalResult["entity"]);
    }
    setModalAddBankAccount(false);
  };

  const removeAccount = (index) => {
    props.entity.bankAccounts = props.entity.bankAccounts.filter((ac, idx) => {
      return idx !== index;
    });
    props.entity.bankAccounts = Object.assign([], props.entity.bankAccounts);
    props.handleChange(props.entity);
  };

  return (
    <React.Fragment>
      {props.entity.bankAccounts && props.entity.bankAccounts.length ? (
        <Card>
          <CardBody>
            <Table className="table table-striped mb-0 font-size-14">
              <tbody>
                {props.entity.bankAccounts.map((account, index) => {
                  return (
                    <tr>
                      <td className="col-md-5">
                        <p className="card-title-desc mb-2">Instituição</p>
                        <strong>
                          {PaymentCompanyDomain.getLabelByCode(account.ispb)}
                        </strong>
                      </td>
                      <td className="col-md-3">
                        <p className="card-title-desc mb-2">Tipo de conta</p>
                        {AccountTypeDomain.getLabelByValue(account.accountType)}
                      </td>
                      <td className="col-md-2">
                        <p className="card-title-desc mb-2">Agência</p>
                        {account.branch}
                      </td>
                      <td className="col-md-5">
                        <p className="card-title-desc mb-2">Conta</p>
                        {account.accountNumber}
                        {account.accountDigit ? `-${account.accountDigit}` : ""}
                      </td>

                      <td
                        className="col-md-1 text-center font-size-18"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          removeAccount(index);
                        }}
                      >
                        <i className="mdi mdi-trash-can" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      ) : (
        ""
      )}

      <Row className="mt-3">
        <Col lg={12}>
          <button
            className="btn btn-select"
            onClick={() => {
              setModalAddBankAccount(true);
            }}
          >
            Adicionar conta
          </button>
        </Col>
      </Row>

      {modalAddBankAccount ? (
        <SelectAddAccountModal callback={addAccount} />
      ) : (
        ""
      )}
    </React.Fragment>
  );
}

export default withTranslation()(OrganizationEditBankAccount);
