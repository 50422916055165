const DivisionRuleDomain = {

    PERCENTUAL: "2",
    VALOR_DEFINIDO: "1",

    options: [
        {label: "Valor definido", value: "1"},
        {label: "Percentual", value: "2"},
    ],

    getLabelByValue(value){
        for (const i in DivisionRuleDomain.options){
            if (DivisionRuleDomain.options[i].value === value){
                return this.options[i].label
            }
        }
        return null
    }
}


export default DivisionRuleDomain