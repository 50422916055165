import React from "react";
import { Card, CardBody, Table } from "reactstrap";

import { withTranslation } from "react-i18next";

function OrganizationEditContacts(props) {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Table className="table table-striped mb-0 font-size-14">
            <tbody>
              {props.entity.additionalInfo &&
              props.entity.additionalInfo.telefones
                ? props.entity.additionalInfo.telefones.map((tel) => {
                    return (
                      <tr>
                        <td className="col-md-1 text-center">
                          <i className="mdi mdi-phone font-size-20" />
                        </td>
                        <td className="col-md-11">
                          {tel.ddd} {tel.numero}
                        </td>
                      </tr>
                    );
                  })
                : ""}

              {props.entity.additionalInfo &&
              props.entity.additionalInfo.correioEletronico ? (
                <tr>
                  <td className="col-md-1 text-center">
                    <i className="mdi mdi-email font-size-20" />
                  </td>
                  <td className="col-md-11">
                    {props.entity.additionalInfo.correioEletronico}
                  </td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export default withTranslation()(OrganizationEditContacts);
