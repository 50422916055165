import CpfCnpjUtils from "../../common/utils/CpfCnpjUtils"
import MoneyUtils from "../../common/utils/MoneyUtils"

export default (value, type) => {

    if (type.toUpperCase() === 'CPF_CNPJ')
        return CpfCnpjUtils.removeMask(value)

    if (type.toUpperCase() === 'MONEY')
        return MoneyUtils.removeMask(value)

    return value

}