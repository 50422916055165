import React from 'react'
import MaskedInput from 'react-text-mask'
import {Label} from "reactstrap"
import {withTranslation} from "react-i18next"
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import $filter from "../../common/utils/$filter";

class InputPercent extends React.Component {

    constructor(props) {
        super(props)

        const defaultMaskOptions = {
            prefix: '',
            suffix: '%',
            includeThousandsSeparator: true,
            thousandsSeparatorSymbol: '.',
            allowDecimal: true,
            decimalSymbol: ',',
            decimalLimit: 2,
            integerLimit: 10,
            allowNegative: false,
            allowLeadingZeroes: false,
        }

        const currencyMask = createNumberMask({
            ...defaultMaskOptions,
        })

        this.state = {
            isValid: true,
            "$error": null,

            defaultMaskOptions: defaultMaskOptions,
            currencyMask: currencyMask
        }
    }

    getValueWithoutMask = (value) => {
        return value
            .replace('R$', '').trim()
            .split('.').join("")
            .replace(',', '.')
            .trim()
    }

    setValue = (event) => {
        const newState = {value: event.target.value}
        this.setState(newState)

        if (this.props.onChange) {
            const valueWithoutMask = this.getValueWithoutMask(
                event.target.value
            )
            this.props.onChange({
                target: {value: parseFloat(valueWithoutMask), name: event.target.name}
            })
        }
    }

    onChangeValidation = (event) => {
        if (this.props.onChangeValidation) {
            const valueWithoutMask = this.getValueWithoutMask(
                event.target.value
            )
            this.props.onChangeValidation(valueWithoutMask)
        }
    }

    render() {
        return (<React.Fragment>
            {
                this.props.label !== null && this.props.label !== undefined ?

                    <Label for={this.props.id} className="form-label">
                        {`${this.props.required === true ? "*" : ""}${this.props.label}`}
                    </Label>
                    : ("")
            }

            <MaskedInput className={this.state.isValid ? "form-control" : "form-control is-invalid"}
                         mask={this.state.currencyMask}
                         placeholder="0,00%"
                         type="text"
                         id={this.props.id}
                         name={this.props.id}
                         autoComplete="off"
                         value={this.props.value !== undefined ? $filter(
                             this.props.value, 'PRECENT', true
                         ) : null}
                         onChange={this.setValue}
                         onBlur={this.onChangeValidation}
                         {...this.state.defaultMaskOptions}
            />
        </React.Fragment>)
    }
}

export default withTranslation()(InputPercent)