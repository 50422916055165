import React, { useEffect, useState } from "react";
import { Col, Row, Modal, Form, Container } from "reactstrap";

import { withTranslation } from "react-i18next";
import * as url from "../../helpers/url_helper";
import PaginationService from "./PaginationService";
import InputText from "./InputText";
import TablePagination from "./TablePagination";
import $filter from "../../common/utils/$filter";
import StringUtils from "../../common/utils/StringUtils";
import ErrorDialog from "./ErrorDialog";

function SelectCustomerModal(props) {
  const [pager, setPager] = useState(PaginationService.defaultPager());
  const [filter, setFilter] = useState(PaginationService.defaultFilter(7));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [firstSearch, setFirstSearch] = useState(false);
  const [isOpen, setIsOpen] = useState(props.isOpen);

  const afterClosed = () => {
    props.callback({ isOpen: false, entity: null, event: "CLOSE" });
  };

  const close = (selectedEntity) => {
    props.callback({
      isOpen: false,
      entity: selectedEntity,
      event: "SELECTED",
    });
  };

  const toggle = () => {
    setIsOpen(false);
  };

  const applySearch = async (currentPage, itemsPerPage) => {
    try {
      const { pagerResult, filterResult } = await PaginationService.applySearch(
        url.GET_RECEIVABLES,
        "receivables",
        filter,
        currentPage,
        itemsPerPage,
        setLoading
      );

      setPager(pagerResult);
      setFilter(filterResult);
    } catch (e) {
      if (e["status"] !== "200" && e["status"] !== "403") setError(e);
    }
  };

  useEffect(() => {
    if (!firstSearch) {
      setFirstSearch(true);
      applySearch();
    }
  });

  const handleChangeFilter = (event) => {
    filter[event.target.name] = event.target.value;
    setFilterField("filter", filter);
  };

  const setFilterField = (name, value) => {
    filter[name] = value;
    setFilter(filter);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          toggle();
        }}
        onClosed={() => {
          afterClosed();
        }}
        modalClassName="drawer right-align"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">{props.t("Select Customer")}</h5>
          <button
            type="button"
            onClick={() => {
              toggle();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col lg={9} className="mb-3">
              <InputText
                id="q"
                onChange={(event) => handleChangeFilter(event)}
                placeholder={props.t("Name")}
              />
            </Col>
            <Col lg={3} className="mb-3 text-end">
              <button
                className="btn btn-search-icon"
                onClick={() => applySearch()}
              >
                <i className="fa fa-search"></i>
              </button>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col lg={12}>
              <TablePagination
                noClass={true}
                pager={pager}
                applySearch={applySearch}
                loading={loading}
              >
                <tbody>
                  {(pager.results || []).map((entity, key) => (
                    <tr
                      className="btn-tr-clicable"
                      onClick={() => {
                        close(entity);
                      }}
                    >
                      <td className="p-0 pb-1">
                        <table>
                          <tbody>
                            <tr>
                              <td className="col-md-1 p-0">
                                <div className="avatar-sm mx-auto me-3">
                                  <span
                                    className="avatar-title rounded-circle bg-light font-size-16"
                                    style={{ color: "black" }}
                                  >
                                    <strong>
                                      {" "}
                                      {StringUtils.prefix(
                                        entity.receivingEndUser.name
                                      )}{" "}
                                    </strong>
                                  </span>
                                </div>
                              </td>
                              <td className="align-middle p-0 col-md-10">
                                <strong>
                                  {entity.receivingEndUser.name
                                    ? StringUtils.abbreviateName(
                                        entity.receivingEndUser.name,
                                        38
                                      )
                                    : $filter(
                                        entity.receivingEndUser.legalId,
                                        "CPF_CNPJ"
                                      )}
                                </strong>{" "}
                                <br />
                                <table className="table mt-1 p-0">
                                  <tbody>
                                    <tr>
                                      <td className="col-md-5 p-0">
                                        <p className="static-label">
                                          {" "}
                                          {props.t("Available limit")}
                                        </p>
                                      </td>
                                      <td className="text-end p-0">
                                        <p className="static-label">
                                          {" "}
                                          {$filter(entity.creditLimit, "MONEY")}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="col-md-5 p-0">
                                        <p className="static-label">
                                          {" "}
                                          {props.t("Installment Limit")}
                                        </p>
                                      </td>
                                      <td className="text-end p-0">
                                        <p className="static-label">
                                          {$filter(
                                            entity.installmentCreditLimit,
                                            "MONEY"
                                          )}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="col-md-5 p-0">
                                        <p className="static-label">
                                          {" "}
                                          {props.t("Balance receivable")}
                                        </p>
                                      </td>
                                      <td className="text-end p-0">
                                        <p className="static-label">
                                          {$filter(
                                            entity.balanceAvailable,
                                            "MONEY"
                                          )}
                                        </p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="align-middle text-end col-md-1">
                                <i className="mdi mdi-chevron-right font-size-18" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TablePagination>
            </Col>
          </Row>
        </div>

        {error ? (
          <ErrorDialog
            title={error["title"]}
            onClose={() => {
              setError(null);
            }}
          >
            <p>{error["detail"]} </p>
          </ErrorDialog>
        ) : (
          ""
        )}
      </Modal>
    </React.Fragment>
  );
}

export default withTranslation()(SelectCustomerModal);
