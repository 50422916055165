import React from "react"
import {withTranslation} from "react-i18next";

import {Row, Col, Alert} from "reactstrap"
import { withRouter,} from "react-router-dom"
import $filter from "../../common/utils/$filter";
import Placeholder from "../../components/Common/Placeholder";
import PaymentMethodDomain from "../../domains/PaymentMethodDomain";
import CheckoutSelectInstallments from "./CheckoutSelectInstallments";

function CheckoutTicketSeller (props) {
    
    return (
        <React.Fragment>
            <div className="p-lg-5 p-4 bg-primary h-1200 d-lg-block text-start">
                <p className="checkout-ticket-title mt-0 mb-1 mt-4">
                    {props.t("Payment of")}
                </p>
                <p className="checkout-ticket-amount mt-0 mb-2">
                    {$filter(props.entity.amount ,'MONEY')}
                </p>

                <p className="checkout-ticket-seller mt-0 mb-2">
                    <span style={{color: "#ffffff9e"}}>para&nbsp;</span>
                    <Placeholder
                        isLoading={props.firstLoad}
                        value={props.entity.seller.name}
                    />
                </p>

                <p className="checkout-ticket-installments mt-0 mb-2">
                    {
                        props.entity.paymentMethod !== 'CASH.FLOW'
                        && !props.firstLoad ?
                            <span>
                                {`${props.t("in")} ${props.entity.numInstallments}x ${props.t("of")} ${$filter(props.entity.installmentAmount ,'MONEY')}`}
                            </span>
                            : (" ")
                    }
                    {
                        props.entity.paymentMethod !== 'CASH.FLOW'
                        && props.paymentPeriodSelected && props.paymentPeriodSelected.numberInstallments > 0 ?
                            <span>
                                {`${props.t("in")} ${props.paymentPeriodSelected.numberInstallments}x ${props.t("of")} ${$filter(props.paymentPeriodSelected.installmentMainAmount ,'MONEY')}`}
                            </span>
                            : (" ")
                    }
                    {
                        props.entity.paymentMethod === 'CASH.FLOW' ?
                            <span>
                                {"pagando com "}<strong>saldo de recebíveis de cartão</strong>
                            </span>
                            : (" ")
                    }
                </p>

                {/* {
                    props.entity.paymentMethod === 'CASH.NOW'
                    && !props.firstLoad && props.entity.paymentOptions
                    && !props.entity.isSigned ?

                        <Row className="mt-4">
                            <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                                    <CheckoutSelectInstallments
                                        onChange={(option)=>{
                                            if (props.handlePaymentPeriod)
                                                props.handlePaymentPeriod(option)
                                        }}
                                        paymentOptions = {props.entity.paymentOptions}
                                    />
                            </Col>
                        </Row>
                        :
                        ("")
                } */}

                <hr/>

                <Row className="mb-2">
                    <Col xl={3} lg={6} md={6} sm={6} xs={6}>
                        <span className="checkout-ticket-info mt-0 mb-0">
                            {props.t("Legal ID Company")}
                        </span>
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={6} xs={6} className="text-end">
                        <span className="checkout-ticket-info mt-0 mb-0">
                            <Placeholder
                                isLoading={props.firstLoad}
                                value={props.entity.seller.legalId}
                            />
                        </span>
                    </Col>
                </Row>

                <Row>
                    <Col xl={3} lg={6} md={6} sm={6} xs={6} className="mb-2">
                        <span className="checkout-ticket-info mt-0 mb-0">
                            {props.t("Identifier")}
                        </span>
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={6} xs={6} className="text-end">
                        <span className="checkout-ticket-info mt-0 mb-0">
                            <Placeholder
                                isLoading={props.firstLoad}
                                value={props.entity.invoiceNumber}
                            />
                        </span>
                    </Col>
                </Row>

                <Row>
                    <Col xl={3} lg={6} md={6} sm={6} xs={6} className="mb-2">
                        <span className="checkout-ticket-info mt-0 mb-0">
                            {props.t("Date")}
                        </span>
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={6} xs={6} className="text-end">
                        <span className="checkout-ticket-info mt-0 mb-0">
                            <Placeholder
                                isLoading={props.firstLoad}
                                value={$filter(props.entity.orderDate, 'DATE')}
                            />
                        </span>
                    </Col>
                </Row>

                {/* {
                    props.entity.deadline && !props.paymentPeriodSelected ?
                        <Row>
                            <Col xl={3} lg={6} md={6} sm={6} xs={6} className="mb-2">
                        <span className="checkout-ticket-info mt-0 mb-0">
                            {props.t("Payment period")}
                        </span>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={6} xs={6} className="text-end">
                        <span className="checkout-ticket-info mt-0 mb-0">
                            <Placeholder
                                isLoading={props.firstLoad}
                                value={props.entity.deadline}
                            />
                        </span>
                            </Col>
                        </Row>
                        : ("")
                } */}
                {
                    props.entity.deadline ?
                        <Row>
                            <Col xl={3} lg={6} md={6} sm={6} xs={6} className="mb-2">
                        <span className="checkout-ticket-info mt-0 mb-0">
                            {props.t("Payment period")}
                        </span>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={6} xs={6} className="text-end">
                        <span className="checkout-ticket-info mt-0 mb-0">
                            <Placeholder
                                isLoading={props.firstLoad}
                                value={props.entity.deadline}
                            />
                        </span>
                            </Col>
                        </Row>
                        : ("")
                }

                <Row>
                    <Col xl={3} lg={6} md={6} sm={6} xs={6} className="mb-2">
                        <span className="checkout-ticket-info mt-0 mb-0">
                            {props.t("Form of payment")}
                        </span>
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={6} xs={6} className="text-end">
                        <span className="checkout-ticket-info mt-0 mb-0">
                            <Placeholder
                                isLoading={props.firstLoad}
                                value= {
                                    PaymentMethodDomain.getInstanceFromValue(
                                        props.entity.paymentMethod
                                    )['endUserLabel']}
                            />
                            <small>&nbsp;&nbsp;({
                                PaymentMethodDomain.getInstanceFromValue(
                                    props.entity.paymentMethod
                                )['name']})</small>
                        </span>
                    </Col>
                </Row>

                <hr/>
                <Row className="mb-2">
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <span className="checkout-ticket-info mt-0 mb-0">
                            {props.t("Message")}
                        </span> <br/>
                        <span className="checkout-ticket-info mt-0 mb-0">
                            {props.entity.message}
                        </span>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(CheckoutTicketSeller))

