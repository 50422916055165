import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Card, CardBody, Modal, Row, Table } from "reactstrap";
import $filter from "../../../common/utils/$filter";
import { get } from "../../../helpers/api_helper";
import * as url from "../../../helpers/url_helper";
import OrderStatusBadge from "./OrderStatusBadge";
import SerproLegalIdDetails from "../serpro/SerproLegalIdDetails";
import BootstrapTable from "react-bootstrap-table-next";
import BillingStatusBadge from "../billing/BillingStatusBadge";
import QueryString from "qs";
import Loader from "../../../components/Common/Loader";
/**
 * this is a react card with reactstrap components that shows the order details
 */
function OrderViewAdmin(props) {
  const [loading, setLoading] = React.useState(false);
  const [entity, setEntity] = React.useState({
    id: null,
    orderDate: null,
    expectedDeliveryDate: null,
    invoiceNumber: null,
    status: null,
    buyerId: null,
    sellerId: null,
    amount: null,
    mainAmount: null,
    paymentMethod: null,
    deadline: null,
    registerAgentId: null,
    financialAgentId: null,
    uriPaymentLink: null,
    message: null,
    tax: null,
    updateToken: null,
    seller: {
      legalId: null,
      name: null,
      tradingName: null,
    },
    buyer: {
      legalId: null,
      name: null,
      tradingName: null,
    },
    orderBillings: [],
    receivingEndUserDetails: undefined,
  });

  const columns = [
    {
      dataField: "id",
      text: "Boleto Id",
    },

    {
      dataField: "dueDate",
      text: "Data Vencimento",
      formatter: (cell, row) => <span id={row}>{$filter(cell, "DATE")}</span>,
    },
    {
      dataField: "amount",
      text: "Valor Boleto",
      formatter: (cell, row) => <span id={row}>{$filter(cell, "MONEY")}</span>,
    },

    {
      dataField: "billingNum",
      text: "Número Da Parcela",
    },

    {
      dataField: "statusBilling",
      text: "Status Boleto",
      formatter: (cell, row) => (
        <BillingStatusBadge key={row.id} status={cell} />
      ),
    },
  ];

  const getByIdOrder = async (id) => {
    if (!id) return;
    const response = await get(
      `${url.GET_ORDER_ADMIN_DETAILS}/${id}`,
      null,
      {},
      setLoading
    );

    return response;
  };

  const listBillingHandler = (orderId) => {
    const urlParams = {
      "pagination.currentPage": 1,
      "pagination.itemsPerPage": 10,
      orderId: orderId,
    };

    return QueryString.stringify(urlParams);
  };

  const openListBillingHandler = () => {
    const orderLibbintgListOpen = `/admin/billing?${listBillingHandler(
      entity.id
    )}`;
    window.open(orderLibbintgListOpen, "_blank");
  };

  useEffect(() => {
    if (props?.entityId) {
      getByIdOrder(props?.entityId).then((response) => {
        if (response) {
          setEntity(response);
        }
      });
    }
  }, [props?.entityId]);

  return (
    <React.Fragment>
      <Modal
        isOpen={props.isOpen}
        size="lg"
        toggle={() => {
          props.toggle(!props.isOpen);
        }}
        id={props.entityId}
        onClosed={() => {
          props.afterClosed();
        }}
        modalClassName="drawer right-align"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            <p className="card-title-desc mb-1">
              <small> {props.t("Order")}</small>
              <small>
                {" / "} {entity.id}
              </small>
            </p>
            {entity?.seller?.name}
          </h5>
          <button
            type="button"
            onClick={() => {
              props.toggle(!props.isOpen);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              <Table className="table table-striped mb-0 font-size-14">
                <tbody>
                  <tr>
                    <td className="col-md-8">
                      <p className="card-title-desc mb-2">
                        Fornecedor / Agente Financeiro
                      </p>
                      {entity?.seller?.name}
                      <br />
                      {entity?.seller?.tradingName}
                    </td>
                    <td className="col-md-4">
                      <p className="card-title-desc mb-2">
                        {props.t("CNPJ Fornecedor")}
                      </p>
                      {$filter(entity?.seller?.legalId, "CPF_CNPJ")}
                    </td>
                  </tr>
                  <tr>
                    <td className="col-md-8">
                      <p className="card-title-desc mb-2">
                        Cliente Final / Comprador / Sacador
                      </p>
                      {entity?.buyer?.name}
                      <br />
                      {entity?.buyer?.tradingName}
                    </td>
                    <td className="col-md-4">
                      <p className="card-title-desc mb-2">
                        {props.t("CNPJ Cliente Final")}
                      </p>
                      {$filter(entity?.buyer?.legalId, "CPF_CNPJ")}
                    </td>
                  </tr>
                  <tr>
                    <td className="col-md-8">
                      <p className="card-title-desc mb-2">
                        {props.t("Order Date")}
                      </p>
                      {new Date(entity?.orderDate).toLocaleDateString()}
                    </td>
                    <td className="col-md-4">
                      <p className="card-title-desc mb-2">
                        {props.t("Invoice Number")}
                      </p>
                      {entity?.invoiceNumber}
                    </td>
                  </tr>
                  <tr>
                    <td className="col-md-8">
                      <p className="card-title-desc mb-2">
                        {props.t("Status")}
                      </p>
                      <OrderStatusBadge status={entity?.status} />
                    </td>
                    <td className="col-md-4">
                      <p className="card-title-desc mb-2">Valor</p>
                      {$filter(entity?.amount, "MONEY")}
                    </td>
                  </tr>
                  <tr>
                    <td className="col-md-8">
                      <p className="card-title-desc mb-2">
                        Data de Pagamento Prevista
                      </p>
                      {entity?.expectedDeliveryDate}
                    </td>
                    <td className="col-md-4">
                      <p className="card-title-desc mb-2">Valor Principal</p>
                      {$filter(entity?.mainAmount, "MONEY")}
                    </td>
                  </tr>
                  <tr>
                    <td className="col-md-8">
                      <p className="card-title-desc mb-2">
                        {props.t("Payment Method")}
                      </p>
                      {entity?.paymentMethod}
                    </td>
                    <td className="col-md-4">
                      <p className="card-title-desc mb-2">
                        {props.t("Deadline")}
                      </p>
                      {entity?.deadline}
                    </td>
                  </tr>
                  <tr>
                    <td className="col-md-8">
                      <p className="card-title-desc mb-2">
                        {props.t("Message")}
                      </p>
                      {entity?.message}
                    </td>
                    <td className="col-md-4">
                      <p className="card-title-desc mb-2">{props.t("Tax")}</p>
                      {entity?.tax}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
          {entity?.orderBillings?.length && (
            <Card>
              <BootstrapTable
                keyField="id"
                data={entity?.orderBillings || []}
                showTotal={true}
                columns={columns}
              />
              <tr>
                <td className="col-md-8">
                  <Button
                    onClick={openListBillingHandler}
                    color="primary"
                    className="btn-rounded waves-effect waves-light"
                  >
                    Abrir listagem de Faturas
                  </Button>
                </td>
              </tr>
            </Card>
          )}
          <SerproLegalIdDetails entity={entity} />
          {loading ? <Loader loading={loading} /> : <></>}
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default withRouter(withTranslation()(OrderViewAdmin));
