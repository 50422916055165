const AcquiresDomain = {
    options: [
        {
            "legalId": "36944861000175",
            "name": "Z TECH - TECNOLOGIA E SERVICOS"
        },
        {
            "legalId": "19468242000132",
            "name": "ZOOP BRASIL"
        },
        {
            "legalId": "06540857000180",
            "name": "ZIGNET SOLUCOES DE PAGAMENTOS LTDA"
        },
        {
            "legalId": "26615279000101",
            "name": "ZEIT"
        },
        {
            "legalId": "21960826000172",
            "name": "YOP DO BRASIL DIGITAL LTDA."
        },
        {
            "legalId": "31305932000176",
            "name": "YESPAY SOLUCOES DE PAGAMENTOS LTDA"
        },
        {
            "legalId": "14338304000178",
            "name": "YAPAY PAGAMENTOS ONLINE LTDA"
        },
        {
            "legalId": "00991143000102",
            "name": "WORLDPAY"
        },
        {
            "legalId": "08718431000108",
            "name": "WIRECARD BRAZIL S.A."
        },
        {
            "legalId": "14776142000150",
            "name": "WESTWING COMERCIO VAREJISTA S.A."
        },
        {
            "legalId": "14233366000115",
            "name": "WENOV"
        },
        {
            "legalId": "30322074000105",
            "name": "VSPAY"
        },
        {
            "legalId": "31688539000109",
            "name": "VITRINE DA MODA"
        },
        {
            "legalId": "16435769000172",
            "name": "VITAL BUSINESS PROMOTORA LTDA."
        },
        {
            "legalId": "31884667000128",
            "name": "VIRTUS"
        },
        {
            "legalId": "39526970000160",
            "name": "VERSA"
        },
        {
            "legalId": "19047654000107",
            "name": "VAN DISTRIBUIDORA DE PRODUTOS LTDA."
        },
        {
            "legalId": "28533398000140",
            "name": "VALOREM MEIOS ELETRONICOS DE PAGAMENTO"
        },
        {
            "legalId": "32192325000100",
            "name": "UZZIPAY"
        },
        {
            "legalId": "30646142000191",
            "name": "UTILITY SECURITIZADORA"
        },
        {
            "legalId": "23372939000182",
            "name": "UNKLESS ACQUIRER"
        },
        {
            "legalId": "19432487000100",
            "name": "UNIVERSALPAY"
        },
        {
            "legalId": "10268349000162",
            "name": "ULTRAGATE PROCESSAMENTO DE PAGAMENTOS LTDA"
        },
        {
            "legalId": "17895646000187",
            "name": "UBER DO BRASIL TECNOLOGIA LTDA."
        },
        {
            "legalId": "28469714000162",
            "name": "TTRS SERVICOS S.A."
        },
        {
            "legalId": "17351180000159",
            "name": "TRIBANCO"
        },
        {
            "legalId": "22696923000162",
            "name": "TRADE-UP GROUP"
        },
        {
            "legalId": "10337124000110",
            "name": "TG BRASIL SERVICOS DE INTERNET LTDA"
        },
        {
            "legalId": "94505189000118",
            "name": "TEST ACQUIRER DATA DRIVEN"
        },
        {
            "legalId": "00896571000156",
            "name": "TENDENCIA INFORMACOES E SISTEMAS"
        },
        {
            "legalId": "23613543000180",
            "name": "TECPAY"
        },
        {
            "legalId": "43488049000148",
            "name": "TAL SOLUCOES EM PAGAMENTOS LTDA"
        },
        {
            "legalId": "05406237000190",
            "name": "SYSDATA TECNOLOGIA"
        },
        {
            "legalId": "14512528000154",
            "name": "SYMPLA INTERNET SOLUCOES S/A"
        },
        {
            "legalId": "16668076000120",
            "name": "SUMUP"
        },
        {
            "legalId": "22121209000146",
            "name": "STRIPE INSTITUICAO DE PAGAMENTO LTDA"
        },
        {
            "legalId": "16501555000157",
            "name": "STONE INSTITUICAO DE PAGAMENTO S.A"
        },
        {
            "legalId": "14625224000101",
            "name": "STELO S.A."
        },
        {
            "legalId": "05508946000187",
            "name": "STARPAY"
        },
        {
            "legalId": "30993254000119",
            "name": "SPEED-PAY"
        },
        {
            "legalId": "22036090000103",
            "name": "SOULPAY SOLUCOES DE PAGAMENTOS LTDA"
        },
        {
            "legalId": "60114865000100",
            "name": "SOROCRED MEIOS DE PAGAMENTOS"
        },
        {
            "legalId": "28249206000179",
            "name": "SOPAGUE"
        },
        {
            "legalId": "08402531000112",
            "name": "SMPAG"
        },
        {
            "legalId": "35480428000163",
            "name": "SKILOPAY"
        },
        {
            "legalId": "31924660000192",
            "name": "SISTEMATIZE"
        },
        {
            "legalId": "03766873000106",
            "name": "SICOOB PAGAMENTOS"
        },
        {
            "legalId": "38372267000182",
            "name": "SHPP BRASIL SERVICOS DE PAGAMENTOS LTDA"
        },
        {
            "legalId": "29759316000143",
            "name": "SERVNET INSTITUICAO DE PAGAMENTO LTDA"
        },
        {
            "legalId": "10773370000115",
            "name": "SERVELOJA TECNOLOGIA E SOLUCOES LTDA"
        },
        {
            "legalId": "20150970000180",
            "name": "SENFF SOLUCOES EM TI"
        },
        {
            "legalId": "04088208000165",
            "name": "SEM PARAR INSTITUICAO DE PAGAMENTO LTDA"
        },
        {
            "legalId": "04088208004900",
            "name": "SEM PARAR INSTITUICAO DE PAGAMENTO LTDA"
        },
        {
            "legalId": "37141939000186",
            "name": "SEMANTIX GESTAO FINANCEIRA LTDA"
        },
        {
            "legalId": "07292536000177",
            "name": "SEGUROS E SERVICOS"
        },
        {
            "legalId": "25015235000188",
            "name": "SB INVESTIMENTOS S.A."
        },
        {
            "legalId": "31037942000178",
            "name": "SAFE2PAY"
        },
        {
            "legalId": "54336880000109",
            "name": "REMOVER-DARA STORE S.A"
        },
        {
            "legalId": "01425787003898",
            "name": "REDECARD INSTITUICAO DE PAGAMENTO S.A."
        },
        {
            "legalId": "01425787000104",
            "name": "REDECARD"
        },
        {
            "legalId": "25369743000164",
            "name": "RECEBEAQUI.COM"
        },
        {
            "legalId": "26900161000125",
            "name": "RAPPI"
        },
        {
            "legalId": "01722480000167",
            "name": "QUERO-QUERO VERDECARD INSTITUICAO DE PAGAMENTO S.A."
        },
        {
            "legalId": "36382680000100",
            "name": "QUERO 2 PAY"
        },
        {
            "legalId": "23515431000196",
            "name": "PULSARPAY"
        },
        {
            "legalId": "37983316000150",
            "name": "PRONTO SERVICOS DE PAGAMENTO LTDA"
        },
        {
            "legalId": "33595865000105",
            "name": "PRONTO PAGUEI"
        },
        {
            "legalId": "21041840000172",
            "name": "PPRO BRASIL LTDA"
        },
        {
            "legalId": "37313312000165",
            "name": "POCKET"
        },
        {
            "legalId": "08879832000131",
            "name": "PNW - PAGUE NA WEB"
        },
        {
            "legalId": "18191228000171",
            "name": "PJBANK"
        },
        {
            "legalId": "17768534000165",
            "name": "'PINPAG'"
        },
        {
            "legalId": "17768068000118",
            "name": "'PINPAG'"
        },
        {
            "legalId": "17803384000183",
            "name": "'PINPAG.'"
        },
        {
            "legalId": "17079937000105",
            "name": "PINBANK BRASIL"
        },
        {
            "legalId": "22896431000200",
            "name": "PIC PAY"
        },
        {
            "legalId": "22896431000110",
            "name": "PICPAY"
        },
        {
            "legalId": "08965639000113",
            "name": "PAYU BRASIL"
        },
        {
            "legalId": "29257606000199",
            "name": "PAYSHOPX"
        },
        {
            "legalId": "20655744000151",
            "name": "PAYPI"
        },
        {
            "legalId": "10878448000166",
            "name": "PAYPAL DO BRASIL SERVICOS DE PAGAMENTOS LTDA."
        },
        {
            "legalId": "06167186000154",
            "name": "PAYGO"
        },
        {
            "legalId": "03816413000137",
            "name": "PAGUEVELOZ"
        },
        {
            "legalId": "27814492000105",
            "name": "PAGUELOGO"
        },
        {
            "legalId": "08561701000101",
            "name": "PAGSEGURO (MATRIZ)"
        },
        {
            "legalId": "08561701014323",
            "name": "PAGSEGURO (FILIAL)"
        },
        {
            "legalId": "10344530000100",
            "name": "PAGS"
        },
        {
            "legalId": "30944783000122",
            "name": "PAGPRIME"
        },
        {
            "legalId": "22939815000173",
            "name": "PAGPOS"
        },
        {
            "legalId": "37224002000174",
            "name": "PAGPLAN PAGAMENTOS"
        },
        {
            "legalId": "21262661000165",
            "name": "PAGOLIVRE"
        },
        {
            "legalId": "16725465000140",
            "name": "PAGMAX ADMINISTRADORA DE MEIOS DE PAGAMENTOS E SERVICOS LTDA"
        },
        {
            "legalId": "17819084000192",
            "name": "PAGCERTO"
        },
        {
            "legalId": "14630124000165",
            "name": "PAGBRASIL"
        },
        {
            "legalId": "18727053000174",
            "name": "PAGAR.ME PAGAMENTOS"
        },
        {
            "legalId": "25104230000121",
            "name": "PAGARE INSTITUICAO DE PAGAMENTOS S.A"
        },
        {
            "legalId": "23612423000168",
            "name": "PAG.AI TECNOLOGIA E INOVACAO S.A"
        },
        {
            "legalId": "23791922000160",
            "name": "ORUSPAY VO"
        },
        {
            "legalId": "29124395000116",
            "name": "ONNIPAY"
        },
        {
            "legalId": "02445579000121",
            "name": "ONIX SERVICOS DE VALOR AGREGADO LTDA"
        },
        {
            "legalId": "33599330000102",
            "name": "ONE PAY INTERMEDIACOES EM PAGAMENTOS LTDA"
        },
        {
            "legalId": "17204944000183",
            "name": "OLX PAY INSTITUICAO DE PAGAMENTO LTDA"
        },
        {
            "legalId": "13492000000106",
            "name": "NUVEI DO BRASIL LTDA"
        },
        {
            "legalId": "18286449000123",
            "name": "NEXXPAGO TECNOLOGIA E SERVICOS S.A."
        },
        {
            "legalId": "23736399000179",
            "name": "NETCRED"
        },
        {
            "legalId": "28748698000147",
            "name": "NEO PAYMENT INTELLIGENCE"
        },
        {
            "legalId": "35524559000103",
            "name": "NATURA PAGAMENTOS ELETRONICOS LTDA."
        },
        {
            "legalId": "03847413000102",
            "name": "MULVI  INSTITUICAO DE PAGAMENTOS  SA"
        },
        {
            "legalId": "33641877000110",
            "name": "MULTI VIS PAY LTDA."
        },
        {
            "legalId": "26614072000112",
            "name": "MULTIPLICAR INVESTIMENTOS"
        },
        {
            "legalId": "33444505000102",
            "name": "MR PAGAMENTOS"
        },
        {
            "legalId": "09083175000184",
            "name": "MOSAICO TECNOLOGIA AO CONSUMIDOR S.A."
        },
        {
            "legalId": "06308851000182",
            "name": "MOOZ SOLUCOES FINANCEIRAS LTDA"
        },
        {
            "legalId": "23055665000106",
            "name": "MONETIZZE IMPULSIONADORA DE VENDAS ON-LINE S.A."
        },
        {
            "legalId": "34320458000140",
            "name": "MOKA PAY SERVICOS FINANCEIROS LTDA"
        },
        {
            "legalId": "14055516000148",
            "name": "MOBLY COMERCIO VAREJISTA LTDA."
        },
        {
            "legalId": "33650301000110",
            "name": "ML SOLUCOES DE VAREJO S.A"
        },
        {
            "legalId": "24563598000195",
            "name": "MERCOSUL BANK"
        },
        {
            "legalId": "10573521000191",
            "name": "MERCADO PAGO INSTITUICAO DE PAGAMENTO LTDA"
        },
        {
            "legalId": "10573521000515",
            "name": "MERCADO PAGO INSTITUICAO DE PAGAMENTO LTDA"
        },
        {
            "legalId": "43214055004528",
            "name": "MARTINS COMERCIO E SERVICOS DE DISTRIBUICAO S/A"
        },
        {
            "legalId": "17948578000177",
            "name": "MAGALU PAGAMENTOS"
        },
        {
            "legalId": "10490181000135",
            "name": "MADEIRAMADEIRA COMERCIO ELETRONICO S/A"
        },
        {
            "legalId": "09614276000134",
            "name": "M4 PRODUTOS E SERVICOS LTDA."
        },
        {
            "legalId": "26794946000160",
            "name": "LUCREE SOLUCOES INTELIGENTES S.A."
        },
        {
            "legalId": "28922674000162",
            "name": "LOGICS4U"
        },
        {
            "legalId": "28505126000137",
            "name": "LOGBANK INSTITUICAO DE PAGAMENTO S.A."
        },
        {
            "legalId": "20250105000106",
            "name": "LISTO TECNOLOGIA S.A."
        },
        {
            "legalId": "32971064000126",
            "name": "LISTO INSTITUICAO DE PAGAMENTO LTDA"
        },
        {
            "legalId": "27547510000130",
            "name": "LINX PAY MEIOS DE PAGAMENTO LTDA"
        },
        {
            "legalId": "32761142000168",
            "name": "LIFT BANK"
        },
        {
            "legalId": "27439047000102",
            "name": "LIANLIAN PAY BRASIL PAGAMENTOS ELETRONICOS LTDA"
        },
        {
            "legalId": "19257959000135",
            "name": "KIIK SOLUCOES E SERVICOS DE PAGAMENTO LTDA."
        },
        {
            "legalId": "18827467000175",
            "name": "KEYPAY SOLUCOES DE PAGAMENTOS"
        },
        {
            "legalId": "29959810000151",
            "name": "JUSTA PAGAMENTOS"
        },
        {
            "legalId": "17344776000121",
            "name": "IZETTLE DO BRASIL MEIOS DE PAGAMENTO LTDA"
        },
        {
            "legalId": "15111975000164",
            "name": "IUGU"
        },
        {
            "legalId": "14380200000121",
            "name": "IFOOD.COM"
        },
        {
            "legalId": "13794399000171",
            "name": "IDEA MAKER SOLUTIONS"
        },
        {
            "legalId": "34355611000174",
            "name": "I9PAY"
        },
        {
            "legalId": "30144977000143",
            "name": "HYPERLOCAL MEIOS DE PAGAMENTOS LTDA"
        },
        {
            "legalId": "13427325000105",
            "name": "HOTMART"
        },
        {
            "legalId": "13284219000101",
            "name": "HEROSPARK TECNOLOGIA PARA EDUCACAO"
        },
        {
            "legalId": "13566137000150",
            "name": "HERA BANK PAGAMENTOS"
        },
        {
            "legalId": "27188579000114",
            "name": "HASH LAB"
        },
        {
            "legalId": "22177858000169",
            "name": "GRANITO INSTITUICAO DE PAGAMENTO S.A."
        },
        {
            "legalId": "23041219000134",
            "name": "GIRO PAGAMENTOS"
        },
        {
            "legalId": "10440482000154",
            "name": "GETNET S.A."
        },
        {
            "legalId": "09089356000118",
            "name": "GERENCIANET S.A. - INSTITUICAO DE PAGAMENTO"
        },
        {
            "legalId": "32825240000111",
            "name": "GAMA COMERCIO E NEGOCIOS"
        },
        {
            "legalId": "30765018000145",
            "name": "GALAX PAY"
        },
        {
            "legalId": "23757841000143",
            "name": "FROGPAY SOLUCAO EM PAGAMENTOS LTDA"
        },
        {
            "legalId": "27879580000195",
            "name": "FPDS"
        },
        {
            "legalId": "02732968000138",
            "name": "FORTBRASIL INSTITUICAO DE PAGAMENTO S.A"
        },
        {
            "legalId": "08508546000160",
            "name": "FLEX PAGAMENTOS"
        },
        {
            "legalId": "04870413000188",
            "name": "FLEXPAG"
        },
        {
            "legalId": "29237659000148",
            "name": "FIX PAY"
        },
        {
            "legalId": "04962772000165",
            "name": "FISERV DO BRASIL INSTITUICAO DE PAGAMENTO LTDA"
        },
        {
            "legalId": "19211359000136",
            "name": "FASTCONNECT SISTEMAS LTDA"
        },
        {
            "legalId": "26051746000118",
            "name": "FASTCASH PAGAMENTOS DIGITAIS S/A"
        },
        {
            "legalId": "11844299000187",
            "name": "FARFETCH.COM BRASIL SERVICOS LTDA"
        },
        {
            "legalId": "18126249000103",
            "name": "FACIL PAY S.A."
        },
        {
            "legalId": "03856113000181",
            "name": "F2B"
        },
        {
            "legalId": "00714671000114",
            "name": "EWALLY INSTITUICAO DE PAGAMENTO S.A"
        },
        {
            "legalId": "04556068000102",
            "name": "EVOLUSERVICES MEIOS DE PAGAMENTOS LTDA"
        },
        {
            "legalId": "28898543000197",
            "name": "EUROPAG INTERMEDIACAO DE PAGAMENTOS LTDA"
        },
        {
            "legalId": "18577728000146",
            "name": "ESFERA 5 TECNOLOGIA"
        },
        {
            "legalId": "17887874000105",
            "name": "ENTREPAY SERVICOS DE PAGAMENTO S.A."
        },
        {
            "legalId": "08958435000155",
            "name": "ENTREGUE COMERCIO E SERVICOS LTDA"
        },
        {
            "legalId": "39244100000107",
            "name": "EMPRESA DE TECNOLOGIA E SISTEMAS LTDA"
        },
        {
            "legalId": "59158642000166",
            "name": "EDENRED SOLUCOES DE PAGAMENTOS HYLA S.A."
        },
        {
            "legalId": "59158642000328",
            "name": "EDENRED SOLUCOES DE PAGAMENTOS HYLA S.A."
        },
        {
            "legalId": "25391162000129",
            "name": "EBANX PAY SOLUCOES E TECNOLOGIA EM MEIOS DE PAGAMENTO LTDA"
        },
        {
            "legalId": "24080493000185",
            "name": "EBANX PAGAMENTOS"
        },
        {
            "legalId": "21018182000106",
            "name": "EBANX INSTITUICAO DE PAGAMENTOS LTDA"
        },
        {
            "legalId": "11414839000192",
            "name": "EAGLE - GESTAO DE NEGOCIOS LTDA"
        },
        {
            "legalId": "08744817000186",
            "name": "DOCK SOLUCOES EM MEIOS DE PAGAMENTO S A"
        },
        {
            "legalId": "25021356000132",
            "name": "DLOCAL"
        },
        {
            "legalId": "07905082000162",
            "name": "DELTAPAG SOLUCOES DE PAGAMENTO"
        },
        {
            "legalId": "30612886000195",
            "name": "DELIVERY PAY"
        },
        {
            "legalId": "14317819000191",
            "name": "DBR"
        },
        {
            "legalId": "01530025000160",
            "name": "DATALINK LTDA"
        },
        {
            "legalId": "18928397000141",
            "name": "DATA LEAD INFORMACOES E TECNOLOGIA"
        },
        {
            "legalId": "11200418000169",
            "name": "DAFITI GROUP"
        },
        {
            "legalId": "27659570000144",
            "name": "CREDPAY SOLUCOES EM PAGAMENTOS LTDA"
        },
        {
            "legalId": "31633039000170",
            "name": "CREDPAG SOLUCOES PARA MEIOS DE PAGAMENTOS LTDA"
        },
        {
            "legalId": "31806121000159",
            "name": "CREDITT"
        },
        {
            "legalId": "20182807000108",
            "name": "CONTABILIZEI TECNOLOGIA LTDA"
        },
        {
            "legalId": "31531997000130",
            "name": "CONPAY INSTITUICAO DE PAGAMENTO E TECNOLOGIA  SA"
        },
        {
            "legalId": "30545324000176",
            "name": "COMPANHIA SECURITIZADORA DE CREDITOS FINANCEIROS VERT-RECARGAPAY"
        },
        {
            "legalId": "26721922000181",
            "name": "CODEPAY MEIOS DE PAGAMENTO LTDA"
        },
        {
            "legalId": "18672547000107",
            "name": "COBRATUDO"
        },
        {
            "legalId": "18189547000142",
            "name": "CLOUDWALK INSTITUICAO DE PAGAMENTO E SERVICOS LTDA"
        },
        {
            "legalId": "01027058000191",
            "name": "CIELO S.A - INSTITUICAO DE PAGAMENTO"
        },
        {
            "legalId": "17457873000120",
            "name": "CIANO - SOLUCOES FINANCEIRAS"
        },
        {
            "legalId": "26743473000172",
            "name": "CHAMA TECNOLOGIA DO BRASIL LTDA."
        },
        {
            "legalId": "26701127000121",
            "name": "CHALKNET"
        },
        {
            "legalId": "28707687000119",
            "name": "CDX"
        },
        {
            "legalId": "05442029000147",
            "name": "CASA DO CREDITO S/A - SOCIEDADE DE CREDITO AO MICROEMPREENDEDOR"
        },
        {
            "legalId": "11469083000189",
            "name": "CARTOS MEIOS DE PAGAMENTO, CONSULTORIA E PARTICIPACOES S/A"
        },
        {
            "legalId": "01984199000100",
            "name": "CARTAO BRB S/A"
        },
        {
            "legalId": "20273297000176",
            "name": "CAR10 TECNOLOGIA E INFORMACAO"
        },
        {
            "legalId": "13966572000171",
            "name": "CAPPTA"
        },
        {
            "legalId": "30507541000171",
            "name": "BRASIL CASH"
        },
        {
            "legalId": "28080769000186",
            "name": "BOLT CARD"
        },
        {
            "legalId": "31494699000117",
            "name": "BLU PAGAMENTOS S.A."
        },
        {
            "legalId": "10506341000197",
            "name": "BLU IP"
        },
        {
            "legalId": "16814330000150",
            "name": "BK INSTITUICAO DE PAGAMENTO"
        },
        {
            "legalId": "31379405000106",
            "name": "BEPOS"
        },
        {
            "legalId": "30699142000150",
            "name": "BEMFACIL DIGITAL"
        },
        {
            "legalId": "25238191000155",
            "name": "BELLUNO INSTITUICAO DE PAGAMENTO LTDA"
        },
        {
            "legalId": "92934215000106",
            "name": "BANRISUL PAGAMENTOS"
        },
        {
            "legalId": "22347623000178",
            "name": "BANQI PAGAMENTOS"
        },
        {
            "legalId": "28127603000178",
            "name": "BANESTES"
        },
        {
            "legalId": "02038232000164",
            "name": "BANCO SICOOB"
        },
        {
            "legalId": "58160789000128",
            "name": "BANCO SAFRA S A"
        },
        {
            "legalId": "68900810000138",
            "name": "BANCO RENDIMENTO S/A"
        },
        {
            "legalId": "59285411000113",
            "name": "BANCO PAN S.A."
        },
        {
            "legalId": "02038232000245",
            "name": "BANCOOB"
        },
        {
            "legalId": "36445499000198",
            "name": "BANCO DIGITAL FEEH"
        },
        {
            "legalId": "08357240000150",
            "name": "BANCO CSF S/A"
        },
        {
            "legalId": "61033106000186",
            "name": "BANCO CREFISA S.A."
        },
        {
            "legalId": "01181521000155",
            "name": "BANCO COOPERATIVO SICREDI"
        },
        {
            "legalId": "31872495000172",
            "name": "BANCO C6 S.A."
        },
        {
            "legalId": "13729930000122",
            "name": "B2C"
        },
        {
            "legalId": "17336663000184",
            "name": "AVA RIO DE JANEIRO DISTRIBUIDORA DE PRODUTOS DE LIMPEZA LTDA."
        },
        {
            "legalId": "11880018000141",
            "name": "AVA DISTRIBUIDORA DE PRODUTOS DE LIMPEZA LTDA."
        },
        {
            "legalId": "26600796000107",
            "name": "ASTEROIDE TECNOLOGIA E PAGAMENTOS"
        },
        {
            "legalId": "19540550000121",
            "name": "ASAAS GESTAO FINANCEIRA INSTITUICAO DE PAGAMENTO S.A."
        },
        {
            "legalId": "27000511000160",
            "name": "APPMAX"
        },
        {
            "legalId": "20986786000175",
            "name": "APOIA.SE"
        },
        {
            "legalId": "23145228000175",
            "name": "AMO SISTEMAS LTDA"
        },
        {
            "legalId": "23570931000120",
            "name": "AMERICAPAY"
        },
        {
            "legalId": "00776574000660",
            "name": "AMERICANAS S.A."
        },
        {
            "legalId": "15436940000103",
            "name": "AMAZON.COM.BR"
        },
        {
            "legalId": "28494032000100",
            "name": "ALPE INTERMEDIACAO DE NEGOCIOS S.A."
        },
        {
            "legalId": "24806270000152",
            "name": "AKIREDE"
        },
        {
            "legalId": "09186786000158",
            "name": "AIQFOME.COM"
        },
        {
            "legalId": "29150228000140",
            "name": "AGL ADQUIRENCIA LTDA"
        },
        {
            "legalId": "13776742000155",
            "name": "AGILLITAS SOLUCOES DE PAGAMENTOS LTDA"
        },
        {
            "legalId": "14796606000190",
            "name": "ADYEN LATIN AMERICA"
        },
        {
            "legalId": "20520298000178",
            "name": "ADIQ INSTITUICAO DE PAGAMENTO S.A."
        },
        {
            "legalId": "33171211000146",
            "name": "ACQIO ADQUIRENCIA INSTITUICAO DE PAGAMENTO S.A."
        },
        {
            "legalId": "18033552000161",
            "name": "99 TECNOLOGIA LTDA"
        },
        {
            "legalId": "35336310000166",
            "name": "99 FOOD DELIVERY TECNOLOGIA LTDA."
        },
        {
            "legalId": "35542555000140",
            "name": "UNLIMINT BRASIL SERVICOS DE INTERNET LTDA"
        }
    ],

    getNameByLegalId(legalId) {
        for (const i in AcquiresDomain.options) {
            if (AcquiresDomain.options[i].legalId === legalId) {
                return AcquiresDomain.options[i].name
            }
        }
        return null
    },
    getLabelByValue(legalId) {
        for (const i in AcquiresDomain.options) {
            if (AcquiresDomain.options[i].legalId === legalId) {
                return AcquiresDomain.options[i].name
            }
        }
        return null
    },
    getInstancesByValues(values) {
        const instances = []
        for (const i in values) {
            for (const j in AcquiresDomain.options) {
                if (AcquiresDomain.options[j].legalId === values[i]) {
                    instances.push(AcquiresDomain.options[j])
                }
            }
        }
        return instances
    }
}

export default AcquiresDomain