const TipoInfoPagamentoDomain = {

    options: [
        {label: "Troca de titularidade", value: "1"},
        {label: "Ônus - Cessão fiduciária", value: "2"},
        {label: "Ônus - Outros", value: "3"},
        {label: "Bloqueio judicial", value: "4"},
        {label: "Antecipação Pós-contratada", value: "5"},
        {label: "Liquidação", value: "6"},
        {label: "Domicílio de pagamento", value: "7"},
    ],

    getLabelByValue(value){
        for (const i in TipoInfoPagamentoDomain.options){
            if (TipoInfoPagamentoDomain.options[i].value === value){
                return this.options[i].label
            }
        }
        return null
    }
}


export default TipoInfoPagamentoDomain