import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Badge } from "reactstrap";

const BillingStatusIntegracaoBadge = ({ status, key }) => {
  const orderStatus = [
    { value: "S", label: "Sucesso" },
    { value: "P", label: "Pendente" },
    { value: "E", label: "Erro" },
  ];

  let label = "Error";
  let color = "primary";
  switch (status) {
    case "S":
      color = "success";
      label = orderStatus[0].label;
      break;
    case "P":
      color = "secondary";
      label = orderStatus[1].label;
      break;
    case "E":
      color = "dark";
      label = orderStatus[2].label;
      break;
    default:
      color = "dark";
      break;
  }

  return (
    <Badge
      className={`badge bg-${color} rounded-pill font-size-12`}
      color={color}
      pill={true}
      key={key}
    >
      {label}
    </Badge>
  );
};

export default withRouter(withTranslation()(BillingStatusIntegracaoBadge));
