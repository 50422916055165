import React, {useState} from "react"
import {withTranslation} from "react-i18next";

import {Row, Col, Alert} from "reactstrap"
import {Link, useHistory, withRouter} from "react-router-dom"

import {AvForm, AvField} from "availity-reactstrap-validation"

import Loader from "../../components/Common/Loader";
import {post} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";

const NewPassword = (props) => {

    const history = useHistory()

    const [loading, setLoading] = useState(false)
    const [newPasssword, setNewPasssword] = useState(null)
    const [confirmNewPasssword, setConfirmNewPasssword] = useState(null)

    const [lengthValid, setLengthValid] = useState(false)
    const [confirmedPwd, setConfirmedPwd] = useState(false)

    const [notDuplicatedSeqValid, setNotDuplicatedSeqValid] = useState(false)
    const [composeLettersAndNumbersValid, setComposeLettersAndNumbersValid] = useState(false)

    document.body.classList.add("login_page")

    const handleConfirm = async () => {
        try {
            const response = await post(
                url.POST_CHANGE_PASSWORD, {
                    token: props.entity.token,
                    email: props.entity.email,
                    newPasssword: newPasssword,
                    sessionId: props.entity.sessionId,
                }, null, setLoading
            )
            if (response['success']) {
                props.onSuccess(Object.assign({}, props.entity))
            } else {
                props.onFailure({
                    title: props.t("Failed to change password"),
                    status: '500',
                    detail: props.t("Start the process again")
                })
            }
        } catch (e) {
            props.onFailure(e)
        }
    }

    const validateComposeLettersAndNumbers = (pwd) => {
        let hasLetter = false
        let hasNumbers = false
        for (const lt of pwd) {
            if (isNaN(lt)) {
                hasLetter = true
            } else {
                hasNumbers = true
            }
        }
        setComposeLettersAndNumbersValid(hasLetter && hasNumbers)
    }

    const validateNotDuplicatedSeqValid = (pwd) => {
        const letters = []
        let notDuplicated = false

        if (pwd.length >= 3){
            notDuplicated = true
        }

        for (const lt of pwd) {
            letters.push(lt)

            if (pwd.length >= 3 && letters[letters.length-2] === lt){
                notDuplicated = false
            }
        }

        setNotDuplicatedSeqValid(notDuplicated)
    }

    const handlePassword = (event) => {
        setNewPasssword(event.target.value)
        setLengthValid(event.target.value.length >= 6)

        validateComposeLettersAndNumbers(event.target.value)
        validateNotDuplicatedSeqValid(event.target.value)

        setConfirmedPwd(event.target.value === confirmNewPasssword)
    }

    const handleConfirmPassword = (event) => {
        setConfirmNewPasssword(event.target.value)
        setConfirmedPwd(event.target.value === newPasssword)
    }

    const backPage = () => {
        history.push('/login')
        window.location.reload(true)
    }


    return (
        <React.Fragment>
            <div>
                <h3>{props.t("Create new password")}</h3>
            </div>

            <div className="mt-4 pt-3">

                <AvForm
                    className="form-horizontal"
                >
                    <Row className="mb-4">
                        <div>
                            <AvField
                                style={{height: 48}}
                                name="secret"
                                label={props.t("New password")}
                                type="password"
                                onChange={handlePassword}
                                errorMessage={props.t("Required field")}
                                required
                            >
                                <i className="fa fa-search" />
                            </AvField>
                        </div>
                    </Row>

                    <Row>
                        <div>
                            <AvField
                                style={{height: 48}}
                                name="newSecret"
                                label={props.t("Confirm the Password")}
                                type="password"
                                onChange={handleConfirmPassword}
                                errorMessage={props.t("Required field")}
                                required
                            />
                        </div>
                    </Row>

                    <Row className="mb-4 mt-3">
                        <div>
                            <span
                                className={!lengthValid ? "badge badge-soft-dark me-1" : "badge badge-soft-success me-1"}>
                                {props.t("minimum of 6 characters")}
                            </span>
                        </div>
                        <div>
                            <span
                                className={!composeLettersAndNumbersValid ? "badge badge-soft-dark me-1" : "badge badge-soft-success me-1"}>
                                {props.t("made up of letters and numbers")}
                            </span>
                        </div>
                        <div>
                            <span
                                className={!notDuplicatedSeqValid ? "badge badge-soft-dark me-1" : "badge badge-soft-success me-1"}>
                                {props.t("do not repeat character in sequence")}
                            </span>
                        </div>
                        <div>
                            <span
                                className={!confirmedPwd ? "badge badge-soft-dark me-1 lowecase" : "badge badge-soft-success me-1 lowecase"}>
                                {props.t("Confirm the Password")}
                            </span>
                        </div>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <div className="mt-4">
                                <button
                                    className="btn btn-primary w-100 waves-effect waves-light"
                                    type="button"
                                    onClick={handleConfirm}
                                    style={{height: 42}}
                                    disabled={!lengthValid || !confirmedPwd || !notDuplicatedSeqValid || !composeLettersAndNumbersValid}
                                >
                                    {props.t("Change Password")}
                                </button>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12} className="mt-4 text-center">
                            <Link
                                to="#!"
                                style={{height: 42}}
                                onClick={backPage}
                            >
                                {props.t("Back")}
                            </Link>
                        </Col>
                    </Row>

                </AvForm>

            </div>

            {
                loading ?
                    <Loader loading={loading}/>
                    :
                    (" ")
            }

        </React.Fragment>
    )
}


export default withRouter(withTranslation()(NewPassword))

