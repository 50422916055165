import React from 'react'

import {Col, Label, Row, UncontrolledAlert} from "reactstrap"
import {withTranslation} from "react-i18next"
import $filter from "../../../common/utils/$filter";
import InputText from "../../../components/Common/InputText";
import Select2Banks from "../../../components/Common/Select2Banks";
import Select2AccountType from "../../../components/Common/Select2AccountType";
import InputNumber from "../../../components/Common/InputNumber";

function AnticipationContractAccounts(props) {

    const setCreditorAccount = (fieldname, value) => {
        props.contract.creditorAccount[fieldname] = value
        props.setContract(props.contract)
    }

    const setContractorAccount = (fieldname, value) => {
        props.contract.contractor.bankAccount[fieldname] = value
        props.setContract(props.contract)
    }

    const handleCreditorAccountFinancial = (event) => {
        props.contract.creditorAccount['taxIdOwner'] = props.contract.financialAgent.legalId
        props.contract.creditorAccount['ownerName'] = props.contract.financialAgent.name

        props.setContract(props.contract)
    }

    const handleCreditorAccountEndUser = (event) => {
        props.contract.creditorAccount['taxIdOwner'] = props.contract.contractor.legalId
        props.contract.creditorAccount['ownerName'] = props.contract.contractor.name

        props.setContract(props.contract)
    }

    return (
        <React.Fragment>

            <Row>
                <Col lg={12}>
                    <p className="card-title-desc"  style={{color: "#0c48a6"}}>
                         Conta de <strong>{props.contract.contractor.name}</strong> para liberação do crédito
                    </p>
                </Col>
            </Row>

            <Row>
                <Col lg={7} className="mb-3">
                    <InputText readonly={true}
                               value={
                                   `${props.contract.contractor.name}  (${$filter(props.contract.contractor.legalId, 'CPF_CNPJ')})`
                               }
                               label={props.t("Legal ID bank owner")}/>
                </Col>
                <Col lg={5} className="mb-3">
                    <Select2Banks required={true}
                                  defaultValue={props.contract.contractor.bankAccount.ispb}
                                  onChange={(bank) => {
                                      setContractorAccount(
                                          'ispb', bank.ispb
                                      )
                                  }}
                                  label={props.t("ISPB/Payment company")}/>
                </Col>
            </Row>
            <Row>
                <Col lg={4} className="mb-3">
                    <Select2AccountType
                        required={true}
                        defaultValue={props.contract.contractor.bankAccount.accountType}
                        onChange={(accountType) => {
                            setContractorAccount(
                                'accountType', accountType.value
                            )
                        }}
                        label={props.t("Account type")}
                    />
                </Col>
                <Col lg={3} className="mb-3">
                    <InputNumber required={true}
                               value={props.contract.contractor.bankAccount.branch}
                                 maxLength={4}
                                 onChange={(event) => {
                                   setContractorAccount(
                                       'branch', event.target.value
                                   )
                               }}
                               label={props.t("Branch")}/>
                </Col>
                <Col lg={4} className="mb-3">
                    <InputNumber required={true}
                               value={props.contract.contractor.bankAccount.accountNumber}
                                 maxLength={20}
                               onChange={(event) => {
                                   setContractorAccount(
                                       'accountNumber', event.target.value
                                   )
                               }}
                               label={props.t("Account number")}/>
                </Col>
                <Col lg={1} className="mb-3">
                    <InputNumber
                        maxLength={1}
                        value={props.contract.contractor.bankAccount.accountDigit}
                        onChange={(event) => {
                           setContractorAccount(
                               'accountDigit', event.target.value
                           )
                       }}
                       label={props.t("Account Digit")}/>
                </Col>
            </Row>

            <Row>
                <Col lg={12} style={{marginTop: "-20px"}}>
                    <hr/>
                </Col>
            </Row>

            <Row>
                <Col lg={12}>
                    <UncontrolledAlert
                        color="info"
                        className="mb-3"
                        role="alert"
                        closeClassName="z-0"
                    >
                        <i className="mdi mdi-alert-circle-outline me-2 font-size-20"></i>
                        Conta para liquidação e provisionamento de garantias, que será utilizada como domicílio bancário no credenciador.
                    </UncontrolledAlert>
                </Col>
            </Row>

            <Row>
                <Col lg={7} >
                    <Label  className="form-label">
                        { props.t("Legal ID bank owner account")}
                    </Label>

                    <div className={
                        props.contract.creditorAccount['taxIdOwner'] === props.contract.financialAgent.legalId ?
                            "form-check form-check-active" : "form-check form-check-inactive"
                    }>
                        <input className="form-check-input"
                               type="radio"
                               name="creditorAccount"
                               id="creditorAccount1"
                               onClick={handleCreditorAccountFinancial}
                               defaultChecked />
                        <Label className="form-check-label"
                               for="creditorAccount1">
                            {props.contract.financialAgent.name}
                            &nbsp;&nbsp;
                            ({$filter(props.contract.financialAgent.legalId, 'CPF_CNPJ')})
                        </Label>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={7} className="mb-3">
                    <div className={
                        props.contract.creditorAccount['taxIdOwner'] === props.contract.contractor.legalId ?
                            "form-check form-check-active" : "form-check form-check-inactive"
                    }>
                        <input className="form-check-input"
                               type="radio"
                               name="creditorAccount"
                               onClick={handleCreditorAccountEndUser}
                               id="creditorAccount2" />
                        <Label className="form-check-label"
                               for="creditorAccount2">
                            {props.contract.contractor.name}
                            &nbsp;&nbsp;
                           ({$filter(props.contract.contractor.legalId, 'CPF_CNPJ')})
                        </Label>
                    </div>
                </Col>
            </Row>

            {/*<Row>*/}
            {/*    <Col lg={4} className="mb-3">*/}
            {/*        <InputText onChange={(value) => {*/}
            {/*                       setCreditorAccount(*/}
            {/*                           'taxIdOwner', value*/}
            {/*                       )*/}
            {/*                   }}*/}
            {/*                   value={$filter(props.contract.creditorAccount.taxIdOwner, 'CPF_CNPJ')}*/}
            {/*                   label={props.t("Legal ID bank owner")}/>*/}

            {/*    </Col>*/}
            {/*    <Col lg={4} className="mb-3">*/}
            {/*        <strong><small>{props.contract.creditorAccount.ownerName}</small></strong>*/}
            {/*    </Col>*/}
            {/*</Row>*/}

            <Row>
                <Col lg={7} className="mb-3">
                    <Select2Banks required={true}
                                  menuPlacement="top"
                                  onChange={(bank) => {
                                      setCreditorAccount(
                                          'ispb', bank.ispb
                                      )
                                  }}
                                  label={props.t("ISPB/Payment company")}/>
                </Col>
            </Row>
            <Row>
                <Col lg={4} className="mb-3">
                    <Select2AccountType
                        menuPlacement="top"
                        required={true}
                        onChange={(accountType) => {
                            setCreditorAccount(
                                'accountType', accountType.value
                            )
                        }}
                        label={props.t("Account type")}
                    />
                </Col>
                <Col lg={3} className="mb-3">
                    <InputNumber
                        maxLength={4}
                        onChange={(event) => {
                            setCreditorAccount(
                                'branch', event.target.value
                            )
                        }}
                        required={true}
                        label={props.t("Branch")}/>
                </Col>
                <Col lg={4} className="mb-3">
                    <InputNumber required={true}
                                 maxLength={20}
                               onChange={(event) => {
                                   setCreditorAccount(
                                       'accountNumber', event.target.value
                                   )
                               }}
                               label={props.t("Account number")}/>
                </Col>
                <Col lg={1} className="mb-3">
                    <InputNumber  maxLength={1}
                         onChange={(event) => {
                                   setCreditorAccount(
                                       'accountDigit', event.target.value
                                   )
                               }}
                               label={props.t("Account Digit")}/>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default withTranslation()(AnticipationContractAccounts)