class MoneyUtils {

    static removeMask(value) {
        if (value !== null && value !== undefined) {
            const newValue = (MoneyUtils.removeMaskToFormat(value) / 100)
            const money = newValue.toFixed(2)
            return money
        }
        return null;
    }

    static removeMaskToFormat(value) {
        if (value !== null && value !== undefined) {
            const newValue = parseInt(value.replace(/[\D]+/g, ''))
            return newValue
        }
        return null;
    }

    static isMoney(value) {
        if (value !== null && value !== undefined) {
            if (!isNaN(value)) {
                return true
            }
        }
        return false
    }

    static getMask(value, noprefixo) {
        const newValue = parseFloat(MoneyUtils.isMoney(value) ? value : "0.00")
        return newValue.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
    }

    static getMaskPercent(value, noprefixo) {
        const newValue = parseFloat(MoneyUtils.isMoney(value) ? value : "0.00")
        const valueConverted = newValue.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})

        const result =  `${valueConverted.replace("R$", "").trim()}%`

        if (noprefixo)
            return result.replace('%', '')
        else
            return result
    }
}

export default MoneyUtils