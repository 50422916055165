import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Card, CardBody, CardTitle, Modal, Table } from "reactstrap";
import BillingStatusBadge from "../billing/BillingStatusBadge";
import $filter from "../../../common/utils/$filter";
import { get } from "../../../helpers/api_helper";
import * as url from "../../../helpers/url_helper";
import Loader from "../../../components/Common/Loader";
import Swal from "sweetalert2";
import BillingStatusIntegracaoBadge from "./BillingStatusIntegracaoBadge";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

function BillingViewAdmin(props) {
  const [loading, setLoading] = useState(false);
  const [entity, setEntity] = useState({
    id: null,
    orderId: null,
    dueDate: null,
    amount: null,
    mainAmount: null,
    tax: null,
    billingNum: null,
    deadline: null,
    fineChargeDate: null,
    finePercent: null,
    limitDiscountDate: null,
    discountPercent: null,
    limitPaymentDate: null,
    fineValue: null,
    sellerId: null,
    buyerId: null,
    orderAmount: null,
    invoiceNumber: null,
    statusOrder: null,
    expectedDeliveryDate: null,
    sellerName: null,
    buyerName: null,
    statusBilling: null,
    provider: null,
    lastStatusUpdate: null,
    typeBilling: null,
    webhookLogs: [],
  });
  // originalRequestId: true,
  // createdAt: true,
  // paymentId: true,
  // uid: true,
  // eventType: true,
  // sourceName: true,
  const columns = [
    {
      dataField: "originalRequestId",
      text: "Webhook Id",
    },
    {
      dataField: "createdAt",
      text: "Data de Criação",
      formatter: (cell, row) => (
        <span id={row}>{new Date(cell).toLocaleString()}</span>
      ),
    },
    {
      dataField: "eventType",
      text: "Tipo Evento",
    },
  ];

  const getBillingById = async (id) => {
    if (!id) return;
    const response = await get(
      `${url.GET_BILLING_ADMIN_DETAILS}/${id}`,
      null,
      {},
      setLoading
    );

    return response;
  };

  const getPaymentsId = async (id) => {
    if (!id) return;
    const response = await get(
      `${url.GET_BILLING_ADMIN_PAYMENTS}/${id}`,
      null,
      {},
      setLoading
    );

    return response;
  };

  const openSyncnizeDataBoleto = (entity, index) => {
    const html = `<div>
                <p>Gostaria de Sinconizar os dados do Boleto do Cliente <br/>
                    <strong>${
                      entity?.buyerName || entity?.buyerId
                    }</strong><br/>
                    via ASAAS.
                </p>
            </div>`;

    Swal.fire({
      title: "Sincronizar os Dados",
      icon: "question",
      html: html,
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonText: props.t("Confirm"),
      cancelButtonText: props.t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        getPaymentsId(entity.id, index).finally(() => {
          getBillingById(entity.id).then((response) => {
            if (response) {
              setEntity(response);
            }
          });
        });
      }
    });
  };

  useEffect(() => {
    if (props?.entityId) {
      getBillingById(props?.entityId)
        .then((response) => {
          if (response) {
            setEntity(response);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [props?.entityId]);

  return (
    <React.Fragment>
      <Modal
        isOpen={props.isOpen}
        size="lg"
        toggle={() => {
          props.toggle(!props.isOpen);
        }}
        id={props.entityId}
        onClosed={() => {
          props.afterClosed();
        }}
        modalClassName="drawer right-align"
      >
        <div className="modal-header">
          {loading ? <Loader loading={loading} /> : <></>}
          <h5 className="modal-title mt-0">
            <p className="card-title-desc mb-1">
              <small> Cobrança</small>
              <small>
                {" / "} {entity?.orderId}
              </small>
              <small>{" / "} Boleto</small>
              <small>
                {" / "} {entity?.id}
              </small>
              <small>{" / "} Parcela</small>
              <small>
                {" / "} {entity?.billingNum}
              </small>
            </p>
            {entity?.seller?.name}
          </h5>
          <button
            type="button"
            onClick={() => {
              props.toggle(!props.isOpen);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              <Table className="table table-striped mb-0 font-size-14">
                <tbody>
                  <tr>
                    <td className="col-md-8">
                      <p className="card-title-desc mb-2">
                        Fornecedor / Agente Financeiro
                      </p>
                      {entity?.sellerName}
                    </td>
                    <td className="col-md-4">
                      <p className="card-title-desc mb-2">CNPJ Fornecedor</p>
                      {$filter(entity?.sellerId, "CPF_CNPJ")}
                    </td>
                  </tr>
                  <tr>
                    <td className="col-md-8">
                      <p className="card-title-desc mb-2">
                        Cliente Final / Comprador / Sacador
                      </p>
                      {entity?.buyerName}
                    </td>
                    <td className="col-md-4">
                      <p className="card-title-desc mb-2">CNPJ Cliente Final</p>
                      {$filter(entity?.buyerId, "CPF_CNPJ")}
                    </td>
                  </tr>
                  <tr>
                    <td className="col-md-8">
                      <p className="card-title-desc mb-2">
                        Valor Total da Cobrança
                      </p>
                      {$filter(entity?.orderAmount, "MONEY")}
                    </td>
                    <td className="col-md-4">
                      <p className="card-title-desc mb-2">
                        {props.t("Billing Number")}
                      </p>
                      {entity?.invoiceNumber}
                    </td>
                  </tr>
                  <tr>
                    <td className="col-md-8">
                      <p className="card-title-desc mb-2">Data do Vencimento</p>
                      {$filter(entity?.dueDate, "DATE")}
                    </td>
                    <td className="col-md-4">
                      <p className="card-title-desc mb-2">
                        Valor do Boleto / Parcela
                      </p>
                      {$filter(entity?.amount, "MONEY")}
                    </td>
                  </tr>
                  <tr>
                    <td className="col-md-8">
                      <p className="card-title-desc mb-2">Data Juros / Multa</p>
                      {$filter(entity?.fineChargeDate, "DATE")}
                    </td>
                    <td className="col-md-4">
                      <p className="card-title-desc mb-2">Juros</p>
                      {$filter(entity?.fineValue, "PRECENT")}
                    </td>
                  </tr>
                  <tr>
                    <td className="col-md-8">
                      <p className="card-title-desc mb-2">
                        {props.t("Discount Percent")}
                      </p>
                      {$filter(entity?.discountPercent, "PRECENT")}
                    </td>
                    <td className="col-md-4">
                      <p className="card-title-desc mb-2">
                        Data Limite Pagamento
                      </p>
                      {$filter(entity?.limitPaymentDate, "DATE")}
                    </td>
                  </tr>
                  <tr>
                    <td className="col-md-8">
                      <p className="card-title-desc mb-2">
                        Status Boleto / Parcela
                      </p>
                      <BillingStatusBadge status={entity?.statusBilling} />
                    </td>
                    <td className="col-md-4">
                      <p className="card-title-desc mb-2">
                        {props.t("Provider")}
                      </p>
                      {entity?.provider}
                    </td>
                  </tr>
                  <tr>
                    <td className="col-md-8">
                      <p className="card-title-desc mb-2">
                        Status Cobrança Integração
                      </p>
                      <BillingStatusIntegracaoBadge
                        status={entity?.statusIntegration}
                      />
                    </td>
                    <td className="col-md-4">
                      <p className="card-title-desc mb-2">Tipo de Cobrança</p>
                      {entity?.paymentMethod}
                    </td>
                  </tr>
                  <tr>
                    <td className="col-md-8">
                      <Button
                        color="primary"
                        onClick={() => openSyncnizeDataBoleto(entity)}
                      >
                        Sincronizar Status
                      </Button>
                    </td>
                    <td className="col-md-4">
                      <p className="card-title-desc mb-2">
                        Ultima Atualização Status
                      </p>
                      {$filter(entity?.lastStatusUpdate, "DATE")}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>

          {entity?.webhookLogs?.length ? (
            <Card>
              <CardBody>
                <CardTitle>Webhook Logs</CardTitle>
                <BootstrapTable
                  keyField="id"
                  data={entity?.webhookLogs || []}
                  showTotal={true}
                  columns={columns}
                  pagination={paginationFactory({
                    paginationSize: 5,
                  })}
                />
              </CardBody>
            </Card>
          ) : (
            <></>
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default withRouter(withTranslation()(BillingViewAdmin));
