
const color1 = 'rgb(33, 203, 201)'
const color2 = 'rgb(189, 223, 240)'
const color3 = '#55B8D7'
const color4 = '#556ee6'
const color5 = '#6a93b3'
const color6 = '#bcbbdd'

const color7 = '#2A5177'

const color8 = '#7569b3'
const color9 = '#74E0DE'
const color10 = '#50a5f1'

const color11 = '#556ee6'
const color12= '#556ee6'
const color13 = '#ffc107'
const color14 = '#f46a6a'

const ChartLocale = {

    pt : {
        name: 'pt',
        options: {
            months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            toolbar: {
                download: 'Download SVG',
                selection: 'Selection',
                selectionZoom: 'Selection Zoom',
                zoomIn: 'Zoom In',
                zoomOut: 'Zoom Out',
                pan: 'Panning',
                reset: 'Reset Zoom',
            }
        },

        colors: [color1, color2, color3, color4, color5, color6, color7, color8, color9,color10
            , color11, color12, color13, color14]
    }
}

export default ChartLocale