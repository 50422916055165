class CpfCnpjUtils {

    static removeMask(value) {
        if (value !== null && value !== undefined) {
            let newValue = value.split('.').join('')
            newValue = newValue.split('/').join('')
            newValue = newValue.split('-').join('').trim()
            return newValue
        }
        return null;
    }

    static CNPJ_PATTERN = "\\d{2}\\.\\d{3}\\.\\d{3}\\/\\d{4}-\\d{2}"
    static CPF_PATTERN = "\\d{3}\\.\\d{3}\\.\\d{3}-\\d{2}"
    static CPF_CNPJ_PATTERN = `${CpfCnpjUtils.CPF_PATTERN}|${CpfCnpjUtils.CNPJ_PATTERN}`

    static getMask(valeu) {
        let newValue = CpfCnpjUtils.removeMask(valeu)

        const TYPES = {
            CPF: "999.999.999-99",
            CNPJ: "99.999.999/9999-99",
        };

        let result = "";
        let mask = TYPES.CPF;

        if (newValue.length > 11) {
            mask = TYPES.CNPJ
        }

        let inc = 0;
        Array.from(newValue).forEach((letter, index) => {
            if (!mask[index + inc].match(/[0-9]/)) {
                result += mask[index + inc];
                inc++;
            }
            result += letter;
        });
        return result
    }

    static setMask(event) {
        if (event && event['target'] && event['target']['value'] !== null && event['target']['value'] !== undefined) {
            document.getElementById(event.target.id).value = CpfCnpjUtils.getMask(event.target.value)
        }
    }
}

export default CpfCnpjUtils