import React, { useEffect, useState } from "react";
import { Col, Label, Modal, Nav, NavItem, NavLink, Row } from "reactstrap";

import { withTranslation } from "react-i18next";
import ErrorDialog from "../../components/Common/ErrorDialog";
import * as url from "../../helpers/url_helper";
import { get } from "../../helpers/api_helper";
import LoaderMessage from "./LoaderMessage";
import $filter from "../../common/utils/$filter";
import { Scrollbars } from "react-custom-scrollbars-2";
import DateTimeUtils from "../../common/utils/DateTimeUtils";
import Select2Generic from "./Select2Generic";
import AntecipationContractCalc from "../../common/utils/AntecipationContractCalc";

function SelectEndUserReceivables(props) {
  const [loading, setLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(props.isOpen);
  const [error, setError] = useState(null);
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const [receivableUnitsAvailable, setReceivableUnitsAvailable] = useState([]);
  const [entity, setEntity] = useState(props.endUser);

  const [distType, setDisType] = useState([
    {
      label: "Por vencimento",
      value: "EMPILHAMENTO",
      detail: "Distribuição por ordem de vencimento",
      order: 10,
      type: "DIST_TYPE",
    },
    {
      label: "Até 15 dias",
      value: 15,
      detail: "Somente recebíveis com vencimento até 15 dias",
      order: 30,
      type: "DEADLINE",
    },
    {
      label: "Até 30 dias",
      value: 30,
      detail: "Somente recebíveis com vencimento até 30 dias",
      order: 20,
      type: "DEADLINE",
    },
    {
      label: "Até 60 dias",
      value: 60,
      detail: "Somente recebíveis com vencimento até 60 dias",
      order: 31,
      type: "DEADLINE",
    },
    {
      label: "Até 90 dias",
      value: 90,
      detail: "Somente recebíveis com vencimento até 90 dias",
      order: 31,
      type: "DEADLINE",
    },
    {
      label: "Até 120 dias",
      value: 120,
      detail: "Somente recebíveis com vencimento até 120 dias",
      order: 35,
      type: "DEADLINE",
    },
    {
      label: "Até 90% do valor livre",
      value: 0.9,
      detail: "Percentual máximo à onerar",
      order: 40,
      type: "PERCENT_MAX",
    },
  ]);

  const [distTypesSelected, setDistTypesSelected] = useState([distType[0]]);

  const [innerHeight] = useState(window.innerHeight);

  const toggle = () => {
    setIsOpen(false);
  };

  const afterClosed = () => {
    props.callback({ isOpen: false, entity: null, event: "CLOSE" });
  };

  const close = () => {
    const newEntity = receivableUnitsAvailable.filter((ur) => {
      return ur.selected;
    });

    props.callback({
      isOpen: false,
      entity: newEntity,
      event: "SELECTED",
      contract: props.contract,
    });
  };

  const addDistTypeExtended = (receivableUnitsAvailable) => {
    const acquires = {};
    const schemas = {};

    let acquireIdx = 0;
    let schemaIdx = 0;

    if (receivableUnitsAvailable && receivableUnitsAvailable.length > 0) {
      receivableUnitsAvailable.forEach((ur) => {
        if (!acquires[ur["legalIdAcquirer"]]) {
          acquires[ur["legalIdAcquirer"]] = {
            name: ur["nameAcquirer"],
            legalId: ur["legalIdAcquirer"],
            freeValue: ur["freeValue"],
            quantityUrs: 1,
          };
        } else {
          acquires[ur["legalIdAcquirer"]].freeValue += ur["freeValue"];
          acquires[ur["legalIdAcquirer"]].quantityUrs += 1;
        }

        if (!schemas[ur["arrangementCode"]]) {
          schemas[ur["arrangementCode"]] = {
            code: ur["arrangementCode"],
            name: ur["nameArrangement"],
            freeValue: ur["freeValue"],
            quantityUrs: 1,
          };
        } else {
          schemas[ur["arrangementCode"]].freeValue += ur["freeValue"];
          schemas[ur["arrangementCode"]].quantityUrs += 1;
        }
      });

      for (const field in acquires) {
        const ac = acquires[field];
        distType.push({
          label: ac["name"],
          value: ac["legalId"],
          hidePopover: true,
          order: 50 + acquireIdx++,
          type: "ACQUIRE",
        });
      }
      for (const field in schemas) {
        const sc = schemas[field];
        distType.push({
          label: sc["name"],
          value: sc["code"],
          hidePopover: true,
          order: 60 + schemaIdx++,
          type: "ARRANGEMENT",
        });
      }

      setDisType(Object.assign([], distType));
    }
  };

  const getReceivableUnitsAvailable = async () => {
    try {
      const apiUrl = `${url.GET_RECEIVABLES_AVAILABLE}/${entity.legalId}`;
      const response = await get(apiUrl, null, {}, setLoading);
      if (
        response &&
        response["receivableUnitsAvailable"] &&
        response["receivableUnitsAvailable"].length > 0
      ) {
        addDistTypeExtended(response["receivableUnitsAvailable"]);

        let recs = calcUrValues(response["receivableUnitsAvailable"]);
        const recsCalculed = selectUrsCore(recs, [distType[0]]);
        entity.averageTerm = recsCalculed.averageTerm;
        entity.debit = recsCalculed.debit;
        entity.totalAmountCharged = recsCalculed.totalAmountCharged;

        setReceivableUnitsAvailable(recsCalculed.receivableUnitsAvailable);
        setEntity(entity);
      }
    } catch (e) {
      setError(e);
    }
  };

  const calcUrValues = (receivableUnitsAvailable) => {
    receivableUnitsAvailable.map((ur) => {
      ur.deadline = parseInt(
        DateTimeUtils.daysBetween(
          DateTimeUtils.parse(ur.settlementDate),
          DateTimeUtils.parse(new Date())
        )
      );

      ur.amountCharged = 0;
      ur.selected = false;
    });
    return receivableUnitsAvailable;
  };

  const getSimulation = (ur, discountRate, contract, simValue) => {
    const suposedGa = AntecipationContractCalc.simSingleUr(
      contract,
      ur,
      discountRate,
      simValue
    );

    return suposedGa;
  };

  /**
   * Check the max value that can be extracted from a UR for selection
   * @param {any} ur Selectable UR
   */
  const getSelectableUrValue = (ur, dists = null) => {
    dists = dists || distTypesSelected;
    dists = [...dists].sort((a, b) => b.order > a.order);

    ur.availableFreeValue = ur.freeValue;

    let appliedAcquireFilter = false;
    let appliedSchemaFilter = false;
    let appliedDeadlineFilter = false;

    let searchedAcquire = false;
    let searchedSchema = false;
    let searcheDeadline = false;

    dists.forEach((dt) => {
      if (dt.type === "ACQUIRE") {
        appliedAcquireFilter = true;
        if (dt.value === ur.legalIdAcquirer) searchedAcquire = true;
      } else if (dt.type === "ARRANGEMENT") {
        appliedSchemaFilter = true;
        if (dt.value === ur.arrangementCode) searchedSchema = true;
      } else if (dt.type === "DEADLINE") {
        appliedDeadlineFilter = true;
        if (ur.deadline <= dt.value) searcheDeadline = true;
      } else if (dt.type === "PERCENT_MAX") {
        ur.availableFreeValue = ur.freeValue * dt.value;
      }
    });

    if (appliedAcquireFilter && !searchedAcquire) return 0;
    if (appliedSchemaFilter && !searchedSchema) return 0;
    if (appliedDeadlineFilter && !searcheDeadline) return 0;

    return ur.availableFreeValue;
  };

  const selectUrsCore = (receivableUnitsAvailable, dists = null) => {
    let debit = props.amount;
    let sumWeighted = 0;
    let sumAmountCharged = 0;

    receivableUnitsAvailable.map((ur) => {
      let amountCharged = 0;
      let discValue = 0;
      ur.selectedType = null;
      ur.amountCharged = 0;
      ur.selected = false;

      const urSelectableValue = getSelectableUrValue(ur, dists);
      if (urSelectableValue && debit > 0 && !ur.forceUnselected) {
        if (props.discountRate && props.contract) {
          ur.originalSim = getSimulation(
            ur,
            props.discountRate,
            props.contract,
            urSelectableValue
          );
          discValue = ur.originalSim.discountValue;
          // console.log(discValue);
        }
        ur.liquidValue = ur.availableFreeValue - discValue;

        if (urSelectableValue >= debit + discValue) {
          amountCharged = debit + discValue;
        } else {
          amountCharged = urSelectableValue;
        }
        ur.liquidValue = amountCharged - discValue;
        ur.amountCharged = amountCharged;
        debit -= amountCharged - discValue;
        sumWeighted = sumWeighted + ur.amountCharged * ur.deadline;
        ur.selected = true;
        ur.selectedType =
          ur.amountCharged === ur.availableFreeValue ? "Total" : "Parcial";
        sumAmountCharged = sumAmountCharged + (amountCharged - discValue);
      }
    });

    const averageTerm = Math.floor(sumWeighted / props.amount);

    return {
      receivableUnitsAvailable: receivableUnitsAvailable,
      averageTerm: averageTerm ? averageTerm : sumAmountCharged ? 1 : 0,
      debit: debit,
      totalAmountCharged: sumAmountCharged,
    };
  };

  useEffect(() => {
    if (!isFirstLoad) {
      setIsFirstLoad(true);
      getReceivableUnitsAvailable();
    }
  }, [isOpen]);

  const resetURs = (resultCaclc, index = -1) => {
    const newRecs = resultCaclc.receivableUnitsAvailable;

    entity.averageTerm = resultCaclc.averageTerm;
    entity.debit = resultCaclc.debit;
    entity.totalAmountCharged = resultCaclc.totalAmountCharged;

    setReceivableUnitsAvailable(Object.assign([], newRecs));
    setEntity(Object.assign({}, entity));
  };

  const handleSelectedUr = (index) => {
    receivableUnitsAvailable[index].selected =
      !receivableUnitsAvailable[index].selected;

    if (receivableUnitsAvailable[index].selected) {
      receivableUnitsAvailable[index].forceUnselected = false;
    } else {
      receivableUnitsAvailable[index].forceUnselected = true;
    }

    const calcs = selectUrsCore(receivableUnitsAvailable);
    resetURs(calcs, index);
  };

  const onChageDistTypes = (opts) => {
    setDistTypesSelected(opts);
    const calcs = selectUrsCore(receivableUnitsAvailable, opts);
    resetURs(calcs);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          toggle();
        }}
        onClosed={() => {
          afterClosed();
        }}
        modalClassName="drawer right-align"
        contentClassName="modal-content-scroll"
      >
        <Scrollbars style={{ height: innerHeight - 110 }}>
          <div className="modal-header">
            <Row className="grid">
              <p className="card-title-desc mb-1">Selecionar recebíveis de</p>
              <h5 className="modal-title mt-0">{props.endUser.name}</h5>
            </Row>

            <button
              type="button"
              onClick={() => {
                toggle();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <Row className="mb-4">
              <Col lg={12}>
                <Select2Generic
                  id="distType"
                  required={true}
                  isMulti={true}
                  defaultOptins={distType}
                  defaultValue={0}
                  popover={distType.map((op) => {
                    if (!op["hidePopover"]) {
                      return (
                        <React.Fragment>
                          <strong>{op.label}</strong>
                          <p className="card-title-desc mb-2">{op.detail}</p>
                        </React.Fragment>
                      );
                    }
                  })}
                  label={"Tipo de distribuição"}
                  onChange={(options) => onChageDistTypes(options)}
                />
              </Col>
            </Row>

            {receivableUnitsAvailable.map((rec, index) => {
              return (
                <React.Fragment>
                  <Row>
                    <Col lg={1}>
                      <input
                        className="form-check-input font-size-18"
                        checked={rec.selected}
                        style={{ cursor: "pointer" }}
                        onClick={(event) => {
                          handleSelectedUr(index);
                        }}
                        type="checkbox"
                      />
                    </Col>
                    <Col
                      lg={11}
                      style={{ cursor: "pointer" }}
                      onClick={(event) => {
                        handleSelectedUr(index);
                      }}
                    >
                      <Row>
                        <Col lg={6}>
                          <strong> {rec.nameAcquirer}</strong>
                        </Col>
                        <Col lg={6} className="text-end">
                          <strong>{$filter(rec.freeValue, "MONEY")}</strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={8}>
                          <p className="card-title-desc mb-0">
                            {rec.nameArrangement}
                          </p>
                        </Col>
                        <Col lg={4} className="text-end">
                          <p className="card-title-desc mb-0">
                            {$filter(rec.settlementDate, "DATE")}
                          </p>
                        </Col>
                      </Row>
                      {rec.amountCharged > 0 ? (
                        <Row className="mt-2">
                          <Col lg={props.discountRate ? 4 : 8}>
                            <span
                              className={
                                props.discountRate
                                  ? "badge rounded-pill bg-light badge-sm"
                                  : "badge rounded-pill bg-light badge-md"
                              }
                            >
                              À onerar:{" "}
                              <strong>
                                {$filter(rec.amountCharged, "MONEY")}
                              </strong>
                            </span>
                          </Col>
                          {props.discountRate ? (
                            <Col lg={4}>
                              <span className="badge rounded-pill bg-light badge-sm">
                                Líquido:{" "}
                                <strong>
                                  {$filter(rec.liquidValue, "MONEY")}
                                </strong>
                              </span>
                            </Col>
                          ) : (
                            ""
                          )}
                          <Col lg={4} className="text-end">
                            <span
                              className={
                                props.discountRate
                                  ? "badge rounded-pill bg-light badge-sm"
                                  : "badge rounded-pill bg-light badge-md"
                              }
                            >
                              {rec.selectedType}
                            </span>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                  <hr style={{ borderTop: "1px solid rgb(187 187 187)" }} />
                </React.Fragment>
              );
            })}
          </div>
        </Scrollbars>

        <div className="modal-footer fixed-bottom">
          <Row className="w-100">
            <Col lg={7} className="text-start">
              <Row>
                <Col lg={12}>
                  <span>
                    Prazo médio:{" "}
                    <strong>
                      {entity.averageTerm}{" "}
                      {entity.averageTerm === 1 ? "dia" : "dias"}
                    </strong>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <span className={entity.debit > 0 ? "text-danger" : ""}>
                    Valor selecionado:{" "}
                    <strong>
                      {$filter(entity.totalAmountCharged, "MONEY")}
                    </strong>
                  </span>
                </Col>
              </Row>
            </Col>

            <Col lg={5} className="text-end">
              <button
                className="btn btn-primary w-sm waves-effect waves-light"
                onClick={close}
                disabled={
                  entity.debit > 0 &&
                  (props.allowSelectingInsufficientValue === false ||
                    props.allowSelectingInsufficientValue === undefined)
                }
              >
                {props.t("Escolher")}
              </button>
            </Col>
          </Row>
        </div>

        {error ? (
          <ErrorDialog
            title={error["title"]}
            onClose={() => {
              setError(null);
            }}
          >
            <p>{error["detail"]} </p>
          </ErrorDialog>
        ) : (
          ""
        )}

        {loading ? (
          <LoaderMessage
            loading={loading}
            message={"Consultando recebíveis disponíveis"}
          />
        ) : (
          ""
        )}
      </Modal>
    </React.Fragment>
  );
}

export default withTranslation()(SelectEndUserReceivables);
