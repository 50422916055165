import React, {useState} from 'react'
import {Card, CardBody, Col, Modal, Row} from "reactstrap"

import {withTranslation} from "react-i18next"
import Loader from "../../components/Common/Loader";
import ErrorDialog from "../../components/Common/ErrorDialog";
import {Scrollbars} from "react-custom-scrollbars-2";
import * as url from "../../helpers/url_helper";
import {patch} from "../../helpers/api_helper";
import ParamValueEdit from "./ParamValueEdit";
import InputText from "../../components/Common/InputText";
import InputTextArea from "../../components/Common/InputTextArea";

function GlobalParamEdit(props) {

    const [entity, setEntity] = useState(props.entity)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(null)
    const [isOpen, setIsOpen] = useState(true)
    const [innerHeight] = useState(window.innerHeight)


    const toggle = () => {
        setIsOpen(false)
    }

    const afterClosed = () => {
        props.callback({isOpen: false, entity: null, event: 'CLOSE'})
    }

    const close = (newEntity) => {
        props.callback({isOpen: false, entity: newEntity, event: 'UPDATED'})
    }

    const handleChange = (event) => {
        setEntity({
            ...entity,
            keyValue: event.target.value
        })
    }

    const handleDetailChange = (event) => {
        setEntity({
            ...entity,
            detail: event.target.value
        })
    }

    const save = async () => {
        try {
            const response = await patch(
                `${url.PATCH_GLOBAL_PARAMS}/${entity.keyName}`, entity, null, setLoading
            )
            close(response)
        } catch (e) {
            if (e['status'] !== '200' && e['status'] !== '403')
                setError(e)
        }
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={isOpen}
                size="xg"
                toggle={() => {
                    toggle()
                }}
                onClosed={() => {
                    afterClosed()
                }}
                modalClassName="drawer right-align"
                contentClassName="modal-content-scroll"
            >

                <Scrollbars
                    style={{height: innerHeight - 80}}>

                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            <p className="card-title-desc mb-1">
                                <small> {props.t("Global Parameter")}</small>
                            </p>
                            <p>
                                {entity.keyName}
                            </p>
                        </h5>

                        <button
                            type="button"
                            onClick={() => {
                                toggle()
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">

                        <Row className="mt-3">

                            <Col lg={12} className="mb-3">
                                <ParamValueEdit
                                    onChange={(event) => handleChange(event)}
                                    label={"Valor"}
                                    {...entity}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">

                            <Col lg={12} className="mb-3">
                                <InputTextArea
                                    value={entity.detail}
                                    onChange={(event) => handleDetailChange(event)}
                                    label={"Detalhe"}
                                />
                            </Col>
                        </Row>
                    </div>
                </Scrollbars>

                <div className="modal-footer fixed-bottom">

                    <div className="text-end">
                        <button
                            className="btn btn-primary w-sm waves-effect waves-light"
                            onClick={save}
                        >
                            {props.t("Save")}
                        </button>
                    </div>

                </div>

                {
                    error ? (
                        <ErrorDialog
                            title={error['title']}
                            onClose={() => {
                                setError(null)
                            }}
                        >
                            <p>{error['detail']} </p>
                        </ErrorDialog>
                    ) : ("")
                }

                {
                    loading ?
                        <Loader loading={loading}/>
                        :
                        (" ")
                }

            </Modal>

        </React.Fragment>
    )
}


export default withTranslation()(GlobalParamEdit)