import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import * as url from "../../../helpers/url_helper";
import { get } from "../../../helpers/api_helper";
import SerproLegalIdDetails from "../serpro/SerproLegalIdDetails";
import CardReceivablesDetails from "../serpro/CardReceivablesDetails";

import $filter from "../../../common/utils/$filter";
import Select2Async from "../../../components/Common/Select2Async";
import { Card, CardBody, CardTitle, Col, FormGroup, Row } from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import GenerateStatusBadge from "../optin/GenerateStatusBadge";
import BillingStatusBadge from "../billing/BillingStatusBadge";

function GravameSelectContract({ entity, setEntity, ...props }) {
  const [selecteContractor, setSelectedContractor] = React.useState(null);

  const [contractor, setContractor] = React.useState({
    legalId: "",
    name: "",
    tradingName: "",
    receivingEndUserDetails: null,
    receivableDetails: null,
    optins: [],
  });

  const [loadingContractor, setLoadingContractor] = React.useState(false);

  const getContractor = async (legalId) => {
    if (!legalId) return;
    const response = await get(
      `${url.GET_PEOPLE_ADMIN_BY_ID}/${legalId}`,
      null,
      {},
      setLoadingContractor
    );

    return response;
  };

  useEffect(() => {
    if (selecteContractor) {
      getContractor(selecteContractor)
        .then((response) => {
          setContractor(response);
        })
        .finally(() => {
          setLoadingContractor(false);
        });
    }
  }, [selecteContractor]);

  const SelectClientNotFound = ({ footer }) => {
    return (
      <div className="p-3 rounded mb-3">
        <blockquote className="blockquote font-size-16 mb-0">
          <p>Selecione um Cliente</p>
          <footer className="blockquote-footer">
            {footer || "Selecione um cliente para visualizar os detalhes"}
          </footer>
        </blockquote>
      </div>
    );
  };

  return (
    <Card>
      <CardBody>
        <Row>
          <CardTitle>Selecionar Cliente</CardTitle>
        </Row>
        <Row>
          <Col sm={12}>
            <Row>
              <FormGroup>
                <Select2Async
                  url={url.GET_PAG_PEOPLE}
                  orderBy={"name"}
                  onChange={(event) => {
                    if (event?.value) {
                      entity.contractor.legalId = event?.value;
                      entity.contractor.name = event?.label;
                      setEntity(entity);
                      setSelectedContractor(event.value);
                    }
                  }}
                  id="contractorLegalId"
                  placeholder={"CNPJ Cliente Final"}
                  value={{
                    value: entity?.contractor?.legalId || "",
                    label: entity?.contractor?.name || "",
                  }}
                />
              </FormGroup>
            </Row>
          </Col>
          <Col sm={12}>
            <Row className="mt-3">
              <Col sm={6}>
                <Row>
                  {contractor.legalId ? (
                    <SerproLegalIdDetails
                      entity={{
                        receivingEndUserDetails:
                          contractor.receivingEndUserDetails,
                        receivingEndUser: {
                          legalId: contractor.legalId,
                          name: contractor.name,
                        },
                      }}
                    />
                  ) : (
                    <SelectClientNotFound />
                  )}
                </Row>
              </Col>
              <Col sm={6}>
                {contractor?.receivableDetails ? (
                  <CardReceivablesDetails
                    receivable={contractor?.receivableDetails}
                  />
                ) : (
                  <SelectClientNotFound
                    footer={
                      "Selecione um cliente pra ter acesso aos recebiveis do cliente"
                    }
                  />
                )}
              </Col>
              <Col
                sm={12}
                style={{
                  overflowX: "auto",
                }}
              >
                <CardTitle>Optin</CardTitle>
                <BootstrapTable
                  keyField="externalReference"
                  data={contractor.optins || []}
                  columns={[
                    {
                      dataField: "externalReference",
                      text: "Codigo de Referência",
                    },
                    {
                      dataField: "startDate",
                      text: props.t("Start date"),
                      formatter: (cell, row) => (
                        <span key={`${row.id}-startDate`}>
                          {$filter(cell, "DATE")}
                        </span>
                      ),
                    },
                    {
                      dataField: "endDate",
                      text: props.t("End date"),
                      formatter: (cell, row) => (
                        <span key={`${row.id}-endDate`}>
                          {$filter(cell, "DATE")}
                        </span>
                      ),
                    },
                    {
                      dataField: "status",
                      text: "Status",
                      formatter: (cell, row) => (
                        <GenerateStatusBadge status={cell} id={row.id} />
                      ),
                    },
                    {
                      dataField: "nameRegisterAgent",
                      text: "Nome Agente Registro",
                    },
                    {
                      dataField: "nameRequester",
                      text: "Nome Solicitante",
                    },
                    {
                      dataField: "nameReceivingEndUser",
                      text: "Nome Recebedor Final",
                    },
                    {
                      dataField: "nameHolder",
                      text: "Nome Titular",
                    },
                    {
                      dataField: "nameFinancialAgent",
                      text: "Nome Agente Financeiro",
                    },
                    {
                      dataField: "optoutDate",
                      text: "Data de Optout",
                    },
                  ]}
                  pagination={paginationFactory()}
                />
              </Col>
              <Col
                sm={12}
                style={{
                  overflowX: "auto",
                }}
              >
                <CardTitle>Boletos</CardTitle>
                <BootstrapTable
                  keyField="id"
                  data={contractor?.billings || []}
                  columns={[
                    {
                      dataField: "id",
                      text: "Boleto Id",
                    },

                    {
                      dataField: "dueDate",
                      text: "Data Vencimento",
                      formatter: (cell, row) => (
                        <span id={row}>{$filter(cell, "DATE")}</span>
                      ),
                    },
                    {
                      dataField: "amount",
                      text: "Valor Boleto",
                      formatter: (cell, row) => (
                        <span id={row}>{$filter(cell, "MONEY")}</span>
                      ),
                    },

                    {
                      dataField: "billingNum",
                      text: "Número Da Parcela",
                    },

                    {
                      dataField: "statusBilling",
                      text: "Status Boleto",
                      formatter: (cell, row) => (
                        <BillingStatusBadge key={row.id} status={cell} />
                      ),
                    },
                  ]}
                  pagination={paginationFactory()}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
export default withRouter(withTranslation()(GravameSelectContract));
