const YesOrNoDomain = {

    options: [
        {label: "Sim", value: true},
        {label: "Não", value: false},
    ],

    getLabelByValue(value){
        if (!value) return
        for (const i in YesOrNoDomain.options){
            if (YesOrNoDomain.options[i].value.toString() === value.toString()){
                return this.options[i].label
            }
        }
        return null
    }
}


export default YesOrNoDomain