import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Badge } from "reactstrap";

function OrderStatusBadge(props) {
  const orderStatus = [
    { value: "P", label: "Pendente" },
    { value: "A", label: "Aprovado" },
    { value: "R", label: "Rejeitado" },
    { value: "C", label: "Cancelado" },
    { value: "V", label: "Em Validação" },
  ];
  const GenerateStatus = ({ status }) => {
    let label = orderStatus[0].label;
    let color = "warning";
    switch (status) {
      case "P":
        color = "warning";
        label = orderStatus[0].label;
        break;
      case "A":
        color = "success";
        label = orderStatus[1].label;
        break;
      case "R":
        color = "warning";
        label = orderStatus[2].label;
        break;
      case "C":
        color = "danger";
        label = orderStatus[3].label;
        break;
      case "V":
        color = "info";
        label = orderStatus[4].label;
        break;
      default:
        color = "danger";
        label = orderStatus[4].label;
        break;
    }

    return {
      status: label,
      statusColor: color,
    };
  };

  const [status, setStatus] = useState({ status: "E", statusColor: "danger" });

  useEffect(() => {
    if (props.status) {
      let status = GenerateStatus({ status: props.status });
      setStatus(status);
    }
  }, [props?.status]);

  return (
    <Badge
      className={`badge bg-${status.statusColor} rounded-pill font-size-12`}
      color={status.statusColor}
      pill
    >
      {status.status}
    </Badge>
  );
}

export default withRouter(withTranslation()(OrderStatusBadge));
