import React, {useState} from 'react';
import {withTranslation} from "react-i18next";

function SelectedReceivaleUnitsActionsBar(props) {

    return (
        <React.Fragment>

            <div className="action-bar-fly">
                <div className="action-bar-fly-wrapper">
                    <div className="action-bar-fly-content">

                        <div className="row">
                            <div className="col-md-6">
                                <div>
                            <span>
                                {props.receivablesUnits.length}
                            </span>
                                    <span>
                                 Recebíveis selecionados
                            </span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="action-bar-fly-actions text-right">
                                    <button type="button" className="btn btn-primary waves-effect waves-light">
                                        Solicitar Antecipação
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default withTranslation()(SelectedReceivaleUnitsActionsBar)