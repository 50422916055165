import React, {useEffect, useState} from 'react'

import {Col, Modal, NavItem, NavLink, Row, TabContent, Table, TabPane} from "reactstrap"
import {withTranslation} from "react-i18next"
import Loader from "../../../components/Common/Loader"

import classnames from "classnames";
import AnticipationContractReceivables from "./AnticipationContractReceivables";
import AnticipationContractAccounts from "./AnticipationContractAccounts";
import AnticipationContractResume from "./AnticipationContractResume";
import EffetiveTypeDomain from "../../../domains/EffetiveTypeDomain";
import ServiceTypeDomain from "../../../domains/ServiceTypeDomain";
import UnconditionalAcceptDomain from "../../../domains/UnconditionalAcceptDomain";
import EvaluationTypeDomain from "../../../domains/EvaluationTypeDomain";
import ModalityDomain from "../../../domains/ModalityDomain";
import DateTimeUtils from "../../../common/utils/DateTimeUtils";
import AnticipationContractFinish from "./AnticipationContractFinish";
import DivisionRuleDomain from "../../../domains/DivisionRuleDomain";
import DistributionTypeDomain from "../../../domains/DistributionTypeDomain";
import AntecipationContractCalc from "../../../common/utils/AntecipationContractCalc";
import {get, getUserSession, post} from "../../../helpers/api_helper";
import * as url from "../../../helpers/url_helper";
import ErrorDialog from "../../../components/Common/ErrorDialog";
import LoaderMessage from "../../../components/Common/LoaderMessage";
import {Scrollbars} from "react-custom-scrollbars-2";
import AnticipationSelectValue from "../AnticipationSimulation/AnticipationSelectValue";
import AnticipationSelectBankAccount from "../AnticipationSimulation/AnticipationSelectBankAccount";
import AnticipationSummary from "../AnticipationSimulation/AnticipationSummary";
import Swal from "sweetalert2";

function AnticipationContract(props) {

    const [state, setState] = useState({
        loading: false,
        form: {},
    })

    const [innerHeight] = useState(window.innerHeight)

    const [tabs] = useState([
        {title: "Vencimentos"},
        // {title: "Resumo do Borderô"},
        {title: "Dados Bancários"},
        {title: "Summary"},
    ])

    const [userSession, setUserSession] = useState(getUserSession())
    const [loadingReceivableUnitsAvailable, setLoadingReceivableUnitsAvailable] = useState(true)
    const [activeTab, setActiveTab] = useState(1)
    const [passedSteps, setPassedSteps] = useState([1])
    const [finishedSuccess, setFinishedSuccess] = useState(false)
    const [statePending, setStatePending] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const [isOpen, setIsOpen] = useState(props.isOpen)
    const [contractEntity, setContractEntity] = useState({})
    const [receivableUnitsSelected, setReceivableUnitsSelected] = useState(props.receivableUnits)

    const [financialAgent, setFinancialAgent] = useState(getUserSession()['financialAgent'])
    const [registerAgent, setRegisterAgent] = useState(getUserSession()['registerAgent'])

    const [runRecalc, setRunRecalc] = useState(false)
    const [amountAvailable, setAmountAvailable ] = useState(0)

    const [contract, setContract] = useState({
            "registerAgent": registerAgent,
            "financialAgent": financialAgent,
            "creditorAccount": {
                "taxIdOwner": props.financialAgent ? props.financialAgent.legalId : financialAgent.legalId,
                "bankCode": props.financialAgent && props.financialAgent.bankAccounts && props.financialAgent.bankAccounts.length > 0
                    ? props.financialAgent.bankAccounts[0].bankCode : null,
                "accountType": props.financialAgent && props.financialAgent.bankAccounts && props.financialAgent.bankAccounts.length > 0
                    ? props.financialAgent.bankAccounts[0].accountType : null,
                "branch": props.financialAgent && props.financialAgent.bankAccounts && props.financialAgent.bankAccounts.length > 0
                    ? props.financialAgent.bankAccounts[0].branch : null,
                "ispb": props.financialAgent && props.financialAgent.bankAccounts && props.financialAgent.bankAccounts.length > 0
                    ? props.financialAgent.bankAccounts[0].ispb : null,
                "accountNumber": props.financialAgent && props.financialAgent.bankAccounts && props.financialAgent.bankAccounts.length > 0
                    ? props.financialAgent.bankAccounts[0].accountNumber : null,
                "accountDigit": props.financialAgent && props.financialAgent.bankAccounts && props.financialAgent.bankAccounts.length > 0
                    ? props.financialAgent.bankAccounts[0].accountDigit : null,
                "ownerName": props.financialAgent ? props.financialAgent.name : financialAgent.name,
            },
            "guarantees": [],
            "signatureDate": DateTimeUtils.format(new Date(), 'YYYY-MM-DD'),
            "contractor": {
                "legalId": props.receivingEndUser.legalId,
                "name": props.receivingEndUser.name,
                "bankAccount": props.receivingEndUser.bankAccounts && props.receivingEndUser.bankAccounts.length > 0 ? props.receivingEndUser.bankAccounts[0] : {
                    "bankCode": null,
                    "accountType": null,
                    "branch": null,
                    "ispb": null,
                    "accountNumber": null,
                    "accountDigit": null,
                }
            },
            "effectType": EffetiveTypeDomain.TROCA_TITULARIDADE,
            "serviceType": ServiceTypeDomain.SIMPLES,
            "unconditionalAccept": UnconditionalAcceptDomain.ACCEPT,
            "evaluationType": EvaluationTypeDomain.BASIC,
            "dueDate": null,
            "modality": ModalityDomain.CESSAO,
            "renegotiation": false,
            "debitBalance": null,
            "guaranteedOperationLimit": null,
            "valueMaintained": null,
            "additionalInfo": {
                product: "AR",
                productModality: "1",
                averageTerm: 0,
                discountRate: 2.00,
                admRate: 0.00,
                effectiveRate: 0,
                discountValue: 0,
                mainValue: 0
            }
        }
    )

    const afterClosed = () => {
        if (!finishedSuccess && props.callback)
            props.callback({isOpen: false})
    }

    const toggle = () => {
        if (!finishedSuccess)
            setIsOpen(false)
    }

    const validateSelectValues = () => {
        if (contract.guarantees && contract.guarantees.length > 0 && !contract.pendingSelectReceivables) {
            return true
        }
        setError({
            title: 'Nenhum recebível determinado',
            detail: 'Escolha os recebíveis para antecipar.',
            code: 400,
        })
        return false
    }

    const validateBankAccounts = () => {
        if (contract.creditorAccount.ispb === null || contract.creditorAccount.ispb === undefined ) {
            setError({
                title: 'Conta bancária não informada',
                detail: 'Informe a conta para mudança de titularidade no credenciador.',
                code: 400,
            })
            return false
        }
        if (contract.contractor.bankAccount.ispb === null || contract.contractor.bankAccount.ispb === undefined ) {
            setError({
                title: 'Conta bancária não informada',
                detail: 'Informe a conta para liberação do crédito.',
                code: 400,
            })
            return false
        }
        return true
    }

    const nextStep = () => {
        if (activeTab === 1 && !validateSelectValues())
            return

        else if (activeTab === 2 && !validateBankAccounts())
            return

        setActiveTab(activeTab + 1)
    }

    const saveContract = async () => {
        try {
            if (loading) return

            const response = await post(
                url.POST_CONTRACT, contract, null, setLoading
            )
            setContractEntity(response['contract'])
            setFinishedSuccess(true)
        } catch (e) {
            if (e['status'] !== '200') {
                if (e.violations && e.violations.length) {
                    if (e.violations.filter(v => v['property'] === 'accessPlan.allowContract')) {
                        openAccessPlanExcededModal();
                    } else if (e.violations.filter(v => v['property'] === 'accessPlan.isPlanOnline')) {
                        openAccessPlanActiveModal();
                    }
                }
            } else {
                setError(e)
            }
        }
    }

    const openAccessPlanExcededModal = () => {

        const html = `<div>
                <p className="card-title-desc mb-0">
                   Parece que você excedeu o seu plano de acesso. <br>    
                </p>
                <p>
                    Contate nosso setor financeiro para acessar um plano que se ajuste melhor a sua necessidade!
                </p>
                <p>
                    <a href="mailto:contato@cashtf.com">contato@cashtf.com</a>
                </p>
            </div>`

        Swal.fire({
            title: "Plano de Acesso",
            icon: 'info',
            html: html,
            confirmButtonText: 'Certo!',
            cancelButtonText: 'Cancelar...'
        })
    }

    const openAccessPlanActiveModal = () => {

        const html = `<div>
                <p className="card-title-desc mb-0">
                   Parece que seu plano de acesso esta suspenso. <br>
                </p>
                <p>
                    Contate nosso setor financeiro para acessar um plano que se ajuste melhor a sua necessidade!
                </p>
                <p>
                    <a href="mailto:contato@cashtf.com">contato@cashtf.com</a>
                </p>
            </div>`

        Swal.fire({
            title: "Plano de Acesso",
            icon: 'info',
            html: html,
            confirmButtonText: 'Certo!',
            cancelButtonText: 'Cancelar...'
        })
    }

    const backStep = () => {
        setActiveTab(activeTab - 1)
    }

    const setNewContract = (newContract, skipRecalc) => {
        setContract(
            skipRecalc ? Object.assign({}, newContract) : AntecipationContractCalc.calc(newContract)
        )
        return contract
    }

    const getReceivableUnitsAvailable = async ()=> {
        const apiUrl = `${url.GET_RECEIVABLES_AVAILABLE}/${contract.contractor.legalId}`
        const response = await get(apiUrl, null, {}, setLoadingReceivableUnitsAvailable)
        buildGuarantees(
            response['receivableUnitsAvailable'], true
        )
    }

    useEffect(() => {
        if (!statePending) {
            getReceivableUnitsAvailable()
            setStatePending(true)
        }
    })

    const buildGuarantees = (receivableUnits, isSetAmountAvailable) => {
        if (receivableUnits && receivableUnits.length > 0){
            contract.guarantees = (Object.assign([], receivableUnits) || []).map((ur, index) => {
                return {
                    "acquirers": [
                        {legalId: ur.legalIdAcquirer, name: ur.nameAcquirer}
                    ],
                    "arrangements": [
                        {code: ur.arrangementCode, label: ur.nameArrangement}
                    ],
                    "receivingEndUser": {"legalId": ur.legalIdReceivingEndUser},
                    "holder": {"legalId": props.receivingEndUser.legalId},
                    "divisionRule": DivisionRuleDomain.VALOR_DEFINIDO,
                    "typeDistribution": DistributionTypeDomain.EMPILHAMENTO,
                    "startDate": DateTimeUtils.format(ur.settlementDate),
                    "endDate": DateTimeUtils.format(ur.settlementDate),
                    "amountCharged": ur.freeValue
                }
            })

            contract.pendingSelectReceivables = false
            const newEntity = AntecipationContractCalc.calc(contract)

            if (isSetAmountAvailable)
                setAmountAvailable(newEntity.debitBalance)

            setContract(
                newEntity
            )

            return newEntity
        }
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={isOpen}
                size={!finishedSuccess ? "md" : "md"}
                toggle={() => {
                    toggle()
                }}
                onClosed={() => {
                    afterClosed()
                }}
                contentClassName="modal-content-scroll"
                modalClassName="drawer right-align">

                <Scrollbars
                    style={{ height: innerHeight-80}}>


                {
                    !finishedSuccess && activeTab !== 3 ?

                        <div className="modal-header">
                            <Row className="grid">
                                <p className="card-title-desc mb-1">
                                   Antecipar recebíveis para:
                                </p>
                                <h5 className="modal-title mt-0">
                                    {contract.contractor.name}
                                </h5>
                            </Row>

                        </div>
                 : ("") }

                    {
                        !finishedSuccess && activeTab === 3 ?

                            <div className="modal-header">
                                <Row className="grid">
                                    <h5 className="modal-title mt-0">
                                        Resumo da operação
                                    </h5>
                                </Row>

                            </div>
                            : ("") }

                <div className="modal-body">

                    {
                        finishedSuccess ? (
                            <AnticipationContractFinish
                                contract={contractEntity}
                            />
                        ) : ("")
                    }

                    {
                        !finishedSuccess ? (
                            (
                                <React.Fragment>
                                    <Row>
                                        <Col lg={12}>
                                            <div className="content clearfix ">
                                                <TabContent activeTab={activeTab}>
                                                    <TabPane tabId={1}>
                                                        {
                                                            contract.debitBalance > 0 &&
                                                            activeTab === 1 ?
                                                                <AnticipationSelectValue
                                                                    contract={contract}
                                                                    amountAvailable={amountAvailable}
                                                                    setContract={setNewContract}
                                                                    buildGuarantees={buildGuarantees}
                                                                /> : ""
                                                        }

                                                    </TabPane>

                                                    <TabPane tabId={2}>
                                                        {
                                                            activeTab === 2 ?
                                                                <AnticipationSelectBankAccount
                                                                    contract={contract}
                                                                    setContract={setNewContract}
                                                                /> : ""
                                                        }

                                                    </TabPane>

                                                    <TabPane tabId={3}>
                                                        {
                                                            activeTab === 3 ?
                                                                <AnticipationSummary
                                                                    contract={contract}
                                                                /> : ""
                                                        }

                                                    </TabPane>

                                                    <TabPane tabId={"1.1"}>
                                                        <AnticipationContractReceivables
                                                            contract={contract}
                                                            setContract={setNewContract}
                                                        />
                                                    </TabPane>
                                                    <TabPane tabId={"2.2"}>
                                                        <AnticipationContractResume
                                                            contract={contract}
                                                            setContract={setNewContract}
                                                        />
                                                    </TabPane>
                                                    <TabPane tabId={"3.0"}>
                                                        <AnticipationContractAccounts
                                                            contract={contract}
                                                            setContract={setNewContract}
                                                        />
                                                    </TabPane>
                                                </TabContent>
                                            </div>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            )
                        ) : ("")
                    }

                </div>

                </Scrollbars>

                {
                    !finishedSuccess ? (
                        <div className="modal-footer fixed-bottom">

                            <div className="text-end">
                                <button
                                    className="btn btn-light w-sm waves-effect waves-light me-2"
                                    onClick={afterClosed}
                                >
                                    {props.t("Cancel")}
                                </button>

                                <button
                                    className="btn btn-light w-sm waves-effect waves-light me-2"
                                    onClick={backStep}
                                    disabled={!(activeTab > 1)}
                                >
                                    {props.t("Back")}
                                </button>

                                {
                                    activeTab === tabs.length ? (
                                        <button
                                            className="btn btn-primary w-sm waves-effect waves-light"
                                            onClick={saveContract}
                                        >
                                            {props.t("Finish")}
                                        </button>

                                    ) : (
                                        <button
                                            className="btn btn-primary w-sm waves-effect waves-light"
                                            onClick={nextStep}
                                        >
                                            {props.t("Next")}
                                        </button>
                                    )
                                }
                            </div>
                        </div>
                    ) : ("")
                }

                {
                    error ? (
                        <ErrorDialog
                            title={error['title']}
                            code={error['code']}
                            onClose={() => {
                                setError(null)
                            }}
                        >
                            <p>{error['detail']} </p>
                        </ErrorDialog>
                    ) : ("")
                }

                {
                    loading ?
                        <Loader loading={loading}/>
                        :
                        (" ")
                }

                {
                    loadingReceivableUnitsAvailable ?
                        <LoaderMessage
                            loading={loadingReceivableUnitsAvailable}
                            message={"Consultando recebíveis disponíveis"}
                        /> : ("")
                }
            </Modal>

        </React.Fragment>
    )
}

export default withTranslation()(AnticipationContract)