import React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { Col, Container, Row } from 'reactstrap';

//import images
import accessDenied from "../../assets/images/access-denied.png"

const Error403 = props => {
    return (
        <React.Fragment>
            <div className="my-5 pt-sm-5">
                <MetaTags>
                    <title>Acesso Negado</title>
                </MetaTags>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="text-center">
                                <div>
                                    <Row className="justify-content-center">
                                        <Col sm={5}>
                                            <div className="">
                                                <img src={accessDenied} alt="" className="img-fluid mx-auto d-block" />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <h4 className="text-uppercase mt-4">Acesso Negado</h4>
                                <p className="text-muted">Você não possui permissão para acessar esta página</p>
                                <div className="mt-5">
                                    <Link className="btn btn-primary waves-effect waves-light" to="/dashboard">Voltar para Dashboard</Link>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Error403;