import React, {useState} from 'react'
import {Col, Row, Modal, Container, Table, Card, CardBody} from "reactstrap"

import {withTranslation} from "react-i18next"
import LegalIdInput from "../../components/Common/LegalIdInput"
import {post, put} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper"
import Loader from "../../components/Common/Loader";
import ErrorDialog from "../../components/Common/ErrorDialog";
import InputText from "../../components/Common/InputText";
import FormUtils from "../../components/Common/FormUtils";
import SelectOrganizationModal from "../../components/Common/SelectOrganizationModal";
import $filter from "../../common/utils/$filter";
import {getSessionUserProfile} from "../../helpers/api_helper";
import {getUserSession} from "../../helpers/api_helper";
import {Link} from "react-router-dom";
import {Scrollbars} from "react-custom-scrollbars-2";
import Swal from "sweetalert2";

class UserNew extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            isOpen: this.props.isOpen,
            innerHeight: window.innerHeight,
            editMode: props.editMode,

            entity: props.editMode === 'NEW' ? {
                legalId: null,
                userName: null,
                organizations: getSessionUserProfile() === "REGISTER_AGENT" ? [] : [
                    getUserSession()['financialAgent']
                ],
            } : props.entity,
            loading: false,
            error: null,
            form: {
                legalId: {isValid: props.editMode === 'EDIT'}
            },
            modalSelectOrganization: false,
            isRegisterAgentProfile: getSessionUserProfile() === "REGISTER_AGENT"
        }
    }

    isValid = () => {
        return FormUtils.isValid(this.state.form)
    }

    setLoading = (flag) => {
        this.setState(Object.assign(this.state, {loading: flag}))
    }

    toggle = () => {
        this.setState({isOpen: false})
    }

    afterClosed = () => {
        this.props.callback({isOpen: false, entity: null, event: 'CLOSE'})
    }

    close = (newEntity) => {
        this.props.callback({
            isOpen: false,
            entity: newEntity,
            event: this.state.editMode === 'NEW' ?  'SAVED' : 'CHANGED'
        })
    }

    notifySentMail = (newEntity) => {
        this.props.callback({
            isOpen: true,
            entity: newEntity,
            event: 'CHANGED',
            successMessage: 'Password recovery e-mail sent'
        })
    }

    setModalSelectOrganization = (flag) => {
        this.setState(Object.assign(this.state, {modalSelectOrganization: flag}))
    }

    onChangeValidation = (field, isValid) => {
        this.state.form[field] = {isValid: isValid}
        this.setState(this.state)
    }

    handleFieldEntity = (name, value) => {
        this.state.entity[name] = value
        this.setState(this.state)
    }

    setError = (error) => {
        this.state.error = error
        this.setState(this.state)
    }

    normalizedFieldValue = (value) => {
        return value !== null && value !== undefined && value.toString().length > 0 ? value.toString().trim() : null
    }

    handleSendPwdMail = async () => {
        if (this.state.loading) return
        if (!this.isValid(this.state.form)) return

        try {
            const email =  {
                email: this.state.entity.userName
            };
            await post(
                url.POST_REQUEST_NEW_PASSWORD, email, null, this.setLoading
            )
            this.notifySentMail(this.state.entity)
        } catch (e) {
            this.setError(e)
        }
    }

    save = async () => {
        if (this.state.loading) return

        if (!this.isValid(this.state.form)) return
        try {
            let response = null

            if (this.state.editMode === 'EDIT'){
                response = await put(
                    `${url.PUT_USERS}/${this.state.entity.legalId}`, this.state.entity, null, this.setLoading
                )
            }else{
                response = await post(
                    url.POST_USERS, this.state.entity, null, this.setLoading
                )
            }

            this.close(response['user'])
        } catch (e) {
            if (e['status'] !== '200' && e['status'] !== '403') {
                if (e.violations && e.violations.length) {
                    if (e.violations.filter(v => v['property'] === 'accessPlan.maxUsers')) {
                        this.openAccessPlanExcededModal();
                    } else if (e.violations.filter(v => v['property'] === 'accessPlan.isPlanOnline')) {
                        this.openAccessPlanActiveModal();
                    }
                }
            } else {

                this.setError(e)
            }
        }
    }

    openModalSelectOrganization = () => {
        this.setModalSelectOrganization(true)
    }

    removeOrganization = (entity) => {
        this.state.entity.organizations = this.state.entity.organizations.filter((org)=>{
            return org.legalId !== entity['legalId']
        })
        this.setState(this.state)
    }

    closeModalSelectOrganization = (resultModal) => {
        this.setModalSelectOrganization(false)

        if (resultModal['event'] === 'SELECTED'){

            const exists = this.state.entity.organizations.filter((org)=>{
                return org.legalId === resultModal['entity']['legalId']
            }).length > 0

            if (!exists) {
                this.state.entity.organizations.push(
                    resultModal['entity']
                )
                this.setState(this.state)
            }
        }
    }

    openAccessPlanExcededModal = () => {

        const html = `<div>
                <p className="card-title-desc mb-0">
                   Parece que você excedeu o seu plano de acesso. <br>    
                </p>
                <p>
                    Contate nosso setor financeiro para acessar um plano que se ajuste melhor a sua necessidade!
                </p>
                <p>
                    <a href="mailto:contato@cashtf.com">contato@cashtf.com</a>
                </p>
            </div>`

        Swal.fire({
            title: "Plano de Acesso",
            icon: 'info',
            html: html,
            confirmButtonText: 'Certo!',
            cancelButtonText: 'Cancelar...'
        })
    }

    openAccessPlanActiveModal = () => {

        const html = `<div>
                <p className="card-title-desc mb-0">
                   Parece que seu plano de acesso esta suspenso. <br>
                </p>
                <p>
                    Contate nosso setor financeiro para acessar um plano que se ajuste melhor a sua necessidade!
                </p>
                <p>
                    <a href="mailto:contato@cashtf.com">contato@cashtf.com</a>
                </p>
            </div>`

        Swal.fire({
            title: "Plano de Acesso",
            icon: 'info',
            html: html,
            confirmButtonText: 'Certo!',
            cancelButtonText: 'Cancelar...'
        })
    }

    render() {
        return (
            <React.Fragment>
                <Modal
                    isOpen={this.state.isOpen}
                    toggle={() => {
                        this.toggle()
                    }}

                    onClosed={() => {
                        this.afterClosed()
                    }}
                    modalClassName="drawer right-align"
                    contentClassName="modal-content-scroll"
                >

                    <Scrollbars
                        style={{ height: this.state.innerHeight-80}}>

                    <div className="modal-header">
                        {
                            this.state.editMode === 'NEW' ?
                                <h5 className="modal-title mt-0">
                                    {this.props.t("Register User")}
                                </h5>
                                :
                                <h5 className="modal-title mt-0">
                                    <p className="card-title-desc mb-1" >
                                        <small> {this.props.t("User")}</small>
                                    </p>
                                    <p className="mb-0">
                                        {this.state.entity.name}
                                    </p>
                                    <p className="mb-0">
                                        <small>{$filter(this.state.entity.legalId, 'CPF_CNPJ')}</small>
                                    </p>
                                </h5>
                        }

                        <button
                            type="button"
                            onClick={() => {
                                this.toggle()
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">

                        {
                            this.state.editMode === 'NEW' ?
                                <Row>
                                    <Col lg={12} className="mb-3">
                                        <LegalIdInput
                                            id="legalId"
                                            required={true}
                                            autoComplete="off"
                                            onChangeValidation={this.onChangeValidation}
                                            onChange={event => {
                                                this.handleFieldEntity("legalId",
                                                    this.normalizedFieldValue(event.target.value)
                                                )
                                            }}
                                            label={this.props.t("CPF")}/>
                                    </Col>
                                </Row>
                                : ("")
                        }

                        <Row>
                            <Col lg={12} className="mb-3">
                                <InputText id="userName"
                                           required={true}
                                           value={this.state.entity.userName}
                                           onChange={event => this.handleFieldEntity(
                                               "userName", this.normalizedFieldValue(event.target.value)
                                           )}
                                           label={this.props.t("Email")}/>

                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} className="mb-3">
                                {
                                    this.state.editMode !== 'NEW' ?
                                        (<Link to="#!" onClick={this.handleSendPwdMail}>
                                            {this.props.t("Send password recovery e-mail")}
                                        </Link>) : ''
                                }

                            </Col>
                        </Row>

                        <p className="card-title-desc mt-3 mb-0">{this.props.t("Access Organizations")}</p>
                        <hr/>

                        {
                            this.state.isRegisterAgentProfile ?
                                <Row>
                                    <Col lg={12} className="mb-3">
                                        <button
                                            className="btn w-100 btn-select"
                                            onClick={this.openModalSelectOrganization}
                                        >
                                            {this.props.t("Add Organization")}
                                        </button>
                                    </Col>
                                </Row> : ("")
                        }

                        {
                            this.state.entity.organizations.length > 0 ?

                                <Row>
                                    <Col lg={12} className="mb-3">
                                        <Card>
                                            <CardBody>

                                                <Table className="table table-striped mb-0">
                                                    <tbody>
                                                    {(this.state.entity.organizations || []).map((entity, key) => (
                                                        <tr key={entity['legalId']}
                                                        >
                                                            <td>
                                                                <p className="mb-0">
                                                                    {entity['name']}
                                                                </p>
                                                                <p className="mb-0">
                                                                    <small>{$filter(entity['legalId'], 'CPF_CNPJ')}</small>
                                                                </p>
                                                            </td>

                                                            {
                                                                this.state.isRegisterAgentProfile ?

                                                                    <td className="col-md-1 text-center" style={{cursor: "pointer"}} onClick={() => {
                                                                        this.removeOrganization(entity)
                                                                    }}>
                                                                        <i className="fa fa-trash"></i>
                                                                    </td> : ("")
                                                            }

                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </Table>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                : ("")
                        }
                    </div>
                    </Scrollbars>

                    <div className="modal-footer  fixed-bottom">

                        <div className="text-end">
                            <button
                                className="btn btn-primary w-sm waves-effect waves-light"
                                onClick={this.save}
                            >
                                {this.props.t("Save")}
                            </button>
                        </div>

                    </div>

                    {
                        this.state.error ? (
                            <ErrorDialog
                                title={this.state.error['title']}
                                onClose={() => {
                                    this.setError(null)
                                }}
                            >
                                <p>{this.state.error['detail']} </p>
                            </ErrorDialog>
                        ) : ("")
                    }

                    {
                        this.state.loading ?
                            <Loader loading={this.state.loading}/>
                            :
                            (" ")
                    }

                </Modal>

                {
                    this.state.modalSelectOrganization ?
                        (
                            <SelectOrganizationModal
                                isOpen={this.state.modalSelectOrganization}
                                callback={this.closeModalSelectOrganization}
                            />
                        ) : ("")
                }

            </React.Fragment>
        )
    }
}


export default withTranslation()(UserNew)