class StatusDomain {

    PENDENTE = {value: 'P', name: 'Pendente'};
    EM_ASSINATURA = {value: 'S', name: 'Pendente'};
    REJEITADO = {value: 'R', name: 'Rejeitado'};
    ERRO = {value: 'E', name: 'Erro'};
    ATIVO = {value: 'A', name: 'Ativo'};
    INATIVO = {value: 'I', name: 'Inativo'};
    LIQUIDADO = {value: 'LT', name: 'Pago Total'};
    LIQUIDADO_PARCIAL = {value: 'LP', name: 'Pago Parcial'};

    static getNameFromValue(value) {
        const obj = new StatusDomain()

        for (const fieldName in obj) {
            if (obj.hasOwnProperty(fieldName)) {
                if (value === obj[fieldName]['value']) {
                    return obj[fieldName]['name'];
                }
            }
        }
        return null;
    }

    static getClassFromValue(value) {
        if (value === 'S') return "badge rounded-pill bg-warning bg-soft text-warning badge-md"
        if (value === 'P') return "badge rounded-pill bg-warning bg-soft text-warning badge-md"
        if (value === 'E') return "badge rounded-pill bg-danger bg-soft badge-md text-danger"
        if (value === 'R') return "badge rounded-pill bg-danger bg-soft badge-md text-danger"
        if (value === 'A') return "badge rounded-pill bg-success bg-soft text-success badge-md"
        if (value === 'I') return "badge rounded-pill bg-light bg-soft text-light badge-md"
        if (value === 'LT') return "badge rounded-pill bg-success bg-soft text-success badge-md"
        if (value === 'LP') return "badge rounded-pill bg-success bg-soft text-success badge-md"

        return ""
    }
}

export default StatusDomain