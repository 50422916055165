import React, {useState} from 'react';
import {Card, CardBody, Col, Dropdown, DropdownMenu, DropdownItem, DropdownToggle} from 'reactstrap';
import SettlementsAnalyticsChart from './SettlementsAnalyticsChart';
import {withTranslation} from "react-i18next";
import $filter from "../../common/utils/$filter";

const SettlementsAnalytics = props => {
    const [menu, setMenu] = useState(false)

    return (
        <React.Fragment>
            <Col xl={12}>
                <Card className="card-height-100">
                    <CardBody>
                        <div className="float-end">
                            <Dropdown isOpen={menu}
                                      toggle={() => setMenu(!menu)}>
                                <DropdownToggle className="dropdown-toggle text-reset" tag="a">
                                    <span className="fw-semibold">Visão por:</span> <span className="text-muted">Dia<i
                                    className="mdi mdi-chevron-down ms-1"></i></span>
                                </DropdownToggle>

                                <DropdownMenu className="dropdown-menu dropdown-menu-end">
                                    <DropdownItem>Dia</DropdownItem>
                                    <DropdownItem>Mês</DropdownItem>
                                    <DropdownItem>Semana</DropdownItem>
                                    <DropdownItem>Ano</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <h4 className="card-title mb-4">Visão análitica da carteira</h4>

                        <div className="mt-1">
                            <ul className="list-inline main-chart mb-0">
                                <li className="list-inline-item chart-border-left me-0 border-0">
                                    <h3 className="text-primary"><span data-plugin="counterup">{$filter(props.view['valueAppliedEffect'], 'money')}</span><span
                                        className="text-muted d-inline-block fw-normal font-size-15 ms-3">A receber constituído</span>
                                    </h3>
                                </li>
                                <li className="list-inline-item chart-border-left me-0">
                                    <h3><span data-plugin="counterup">{props.view.contracts}</span><span
                                        className="text-muted d-inline-block fw-normal font-size-15 ms-3">Contratos</span>
                                    </h3>
                                </li>
                                <li className="list-inline-item chart-border-left me-0">
                                    <h3><span data-plugin="counterup">{props.view.qtContractors}</span><span
                                        className="text-muted d-inline-block fw-normal font-size-15 ms-3">Lojista(s)</span>
                                    </h3>
                                </li>
                                <li className="list-inline-item chart-border-left me-0">
                                    <h3 className="text-danger"><span data-plugin="counterup">{$filter(props.view.unconstitutedValue , 'money')}</span><span
                                        className="text-muted d-inline-block fw-normal font-size-15 ms-3">Saldo não constituído</span>
                                    </h3>
                                </li>
                            </ul>
                        </div>

                        <div className="mt-3">
                            <SettlementsAnalyticsChart settlements={props.settlements}/>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
}

export default withTranslation()(SettlementsAnalytics)