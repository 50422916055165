import React from 'react';
import MetaTags from 'react-meta-tags';
import {Card, CardBody, Col, Container, Label, Row, Table} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {Link, useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";

function PerformConciliation(props) {
    const history = useHistory();
    const navigateToNew = () => history.push('/perform-conciliation/new');
    const navigateToHistory = () => history.push('/perform-conciliation/history');

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Perform conciliation")}</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title={props.t("Conciliation")} breadcrumbItem={props.t("Perform conciliation")}/>

                    {/* Search */}
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">{props.t("Filters")}</h4>
                                    <Row>
                                        <Col lg={4}>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control"
                                                       id="inputBaseDate"
                                                       placeholder="."/>
                                                <Label for="inputBaseDate">{props.t("Base date")}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control"
                                                       id="inputConciliationType"
                                                       placeholder="."/>
                                                <Label
                                                    for="inputConciliationType">{props.t("Conciliation type")}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control" id="inputStatus"
                                                       placeholder="."/>
                                                <Label for="inputStatus">{props.t("Status")}</Label>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={12}>
                                            <div className="text-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-light w-sm waves-effect waves-light"

                                                >
                                                    {props.t("Clean filters")}
                                                </button>
                                                {" "}
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-light w-sm waves-effect waves-light"
                                                >
                                                    {props.t("Apply Search")}
                                                </button>
                                                {" "}
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    {/* Search results */}
                    <Row>
                        <div className="table-responsive">
                            <Table className="table table-striped table-bordered mb-0 table-hover font-size-12">
                                <thead className="table-light">
                                <tr>
                                    <th>{props.t("Base date")}</th>
                                    <th>{props.t("Status")}</th>
                                    <th>{props.t("Conciliation type")}</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>

                                {/*{"LINHA 1"}*/}
                                <tr>
                                    <td scope="row">
                                        20/09/2021
                                    </td>
                                    <td>
                                              <span className="badge bg-warning me-1 w-sm">
                                      Pendente
                                    </span>

                                    </td>
                                    <td>
                                        Contrato
                                    </td>
                                    <td>
                                        <div className="button-items text-end">

                                            <button type="button"
                                                    className="btn btn-light btn-sm waves-effect"
                                                    onClick={() => {
                                                        navigateToNew();
                                                    }}>
                                                <i className='fas fa-check-double me-1'></i>
                                                {props.t("Reconcile")}
                                            </button>
                                        </div>
                                    </td>
                                </tr>

                                {/*{"LINHA 2"}*/}
                                <tr>
                                    <td scope="row">
                                        17/09/2021
                                    </td>
                                    <td>
                                           <span className="badge bg-success me-1 w-sm">
                                      Conciliado
                                    </span>
                                    </td>
                                    <td>
                                        Contrato
                                    </td>
                                    <td>
                                        <div className="button-items text-end">
                                            <button type="button"
                                                    className="btn btn-light btn-sm waves-effect"
                                                    onClick={() => {
                                                        navigateToNew();
                                                    }}>
                                                <i className='fas fa-check-double me-1'></i>
                                                {props.t("Reconcile")}
                                            </button>
                                        </div>
                                    </td>
                                </tr>

                                {/*{"LINHA 3"}*/}
                                <tr>
                                    <td scope="row">
                                        17/09/2021
                                    </td>
                                    <td>
                                                     <span className="badge bg-success me-1 w-sm">
                                      Conciliado
                                    </span>
                                    </td>
                                    <td>
                                        Contrato
                                    </td>
                                    <td>
                                        <div className="button-items text-end">
                                            <button type="button"
                                                    className="btn btn-light btn-sm waves-effect"
                                                    onClick={() => {
                                                        navigateToNew();
                                                    }}>
                                                <i className='fas fa-check-double me-1'></i>
                                                {props.t("Reconcile")}
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
}

export default withRouter(withTranslation()(PerformConciliation))