import React, {useState} from 'react'

import {Col, Label, Row} from "reactstrap"
import {withTranslation} from "react-i18next"
import $filter from "../../../common/utils/$filter";
import InputMoney from "../../../components/Common/InputMoney";
import Select2DiscountRates from "../../../components/Common/Select2DiscountRates";
import SelectEndUserReceivables from "../../../components/Common/SelectEndUserReceivables";
import GenericModal from "../../../components/Common/GenericModal";
import AnticipationContractReceivables from "../AnticipationContract/AnticipationContractReceivables";
import Swal from "sweetalert2";

function AnticipationSelectValue(props) {

    const [openModalSelectEndUserReceivables, setOpenModalSelectEndUserReceivables] = useState(false)

    const [openModalReceivablesSelected, setOpenModalReceivablesSelected] = useState(false)

    const setReceivables = (receivableUnits) => {
        props.buildGuarantees(
            receivableUnits
        )
    }

    const openConfirmChangeValue = (sumAmountCharged, callbackConfirm) => {

        const oldAmount = $filter(props.contract.debitBalance, 'MONEY')
        const newAmount = $filter(sumAmountCharged, 'MONEY')

        const html = `<div>
                <p className="card-title-desc mb-0">
                       Valor atual <br/>  ${oldAmount}
                    </p>
                    
                    <hr style={{borderTop: "1px solid rgb(187 187 187)"}}/>
                    
                    <p className="card-title-desc mb-0">
                       Novo valor selecionado <br/>  ${newAmount}
                    </p>
            </div>`

        Swal.fire({
            title: "Deseja alterar o valor à antecipar?",
            icon: 'question',
            html: html,
            showCloseButton: false,
            showCancelButton: true,
            confirmButtonText: props.t("Confirm"),
            cancelButtonText: props.t("Cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                callbackConfirm()
            }
        })
    }

    const closeModalSelectReceivables = (resultModal) => {
        const confirmed = (newReceivableUnits) => {
            setReceivables(newReceivableUnits)
            setOpenModalSelectEndUserReceivables(false)
        }

        if (resultModal.event === "SELECTED"){
            let sumAmountCharged = 0

            const newReceivableUnits = resultModal.entity.map((ur)=>{
                ur.freeValue = ur.amountCharged
                sumAmountCharged += ur.amountCharged
                return ur
            })

            const debit = parseFloat(
                parseFloat(props.contract.debitBalance.toString()).toFixed(2)
            )
            sumAmountCharged = parseFloat(
                parseFloat(sumAmountCharged.toString()).toFixed(2)
            )

            if (sumAmountCharged !== debit){
                openConfirmChangeValue(sumAmountCharged, ()=>{confirmed(newReceivableUnits)})

            }else {
                confirmed(newReceivableUnits)
            }
        }else {
            setOpenModalSelectEndUserReceivables(false)
        }
    }

    const setDebitBalance = (value) => {
        props.contract.debitBalance = value
        props.contract.pendingSelectReceivables = true
        props.setContract(props.contract,
            true)
    }

    const setDiscountRate = (value) => {
        props.contract.additionalInfo.discountRate = value
        props.setContract(props.contract,
            props.contract.pendingSelectReceivables)
    }

    const hanleOpenSelectEndUserReceivables = () => {
        setOpenModalSelectEndUserReceivables(true)
    }

    return (
        <React.Fragment>

            <Row>
                <Col lg={12}>
                    <Label for="amount" className="form-label">
                        <h5>Qual valor deseja antecipar?</h5>

                        Saldo disponível: {$filter(props.amountAvailable, 'MONEY')}

                    </Label>

                    <InputMoney
                        id="amount"
                        value={props.contract.debitBalance}
                        onChangeValidation={(value) => {
                            setDebitBalance(value)
                        }}
                        required={true}/>
                </Col>
            </Row>

            <hr style={{borderTop: "1px solid rgb(187 187 187)"}}/>

            <Row>
                <Col lg={12}>
                    <Select2DiscountRates
                        defaultValue={3}
                        label={props.t("Taxa de deságio (a.m)")}
                        onChange={(option) => {
                            setDiscountRate(option.value)
                        }}
                    />
                </Col>
            </Row>

            <hr style={{borderTop: "1px solid rgb(187 187 187)"}}/>

            <Row>
                <Col lg={6}>
                    <p className="card-title-desc mb-0">
                        {props.t("Valor à liberar de crédito")}
                    </p>
                    <span>
                         {
                             props.contract.pendingSelectReceivables ? "--/--" :
                             $filter(props.contract.additionalInfo.mainValue, 'money')
                         }
                    </span>
                </Col>

                <Col lg={6}>
                    <p className="card-title-desc mb-0">
                        {props.t("Valor de deságio")}
                    </p>
                    <span>
                          {
                              props.contract.pendingSelectReceivables ? "--/--" :
                              $filter(props.contract.additionalInfo.discountValue, 'money')
                          }
                    </span>
                </Col>
            </Row>

            <hr style={{borderTop: "1px solid rgb(187 187 187)"}}/>

            <Row>
                <Col lg={6}>
                    <p className="card-title-desc mb-0">
                        {props.t("Taxa efetiva")}
                    </p>
                    <span>
                         {
                             props.contract.pendingSelectReceivables ? "--/--" :
                             $filter(props.contract.additionalInfo.effectiveRate, 'PRECENT')
                         }
                    </span>
                </Col>

                <Col lg={6}>
                    <p className="card-title-desc mb-0">
                        {props.t("Prazo médio")}
                    </p>
                    <span>
                        {
                            props.contract.pendingSelectReceivables ? "--/--" :
                                props.contract.additionalInfo.averageTerm
                        } dias
                    </span>
                </Col>
            </Row>

            <hr style={{borderTop: "1px solid rgb(187 187 187)"}}/>

            <Row className="mb-3">
                <Col lg={12}>
                    <button
                        className="btn w-100 btn-select"
                        onClick={hanleOpenSelectEndUserReceivables}
                    >
                        Selecionar recebiveis disponíveis
                    </button>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col lg={12}>
                    <button
                        className="btn w-100 btn-select"
                        onClick={()=>{
                            setOpenModalReceivablesSelected(true)
                        }}
                    >
                        Visualizar recebíveis selecionados
                    </button>
                </Col>
            </Row>

            {
                openModalSelectEndUserReceivables ?
                    <SelectEndUserReceivables
                        isOpen={openModalSelectEndUserReceivables}
                        allowSelectingInsufficientValue={true}
                        callback={closeModalSelectReceivables}
                        endUser={Object.assign({}, props.contract.contractor)}
                        amount={props.contract.debitBalance}
                    />
                    : ""
            }

            {
                openModalReceivablesSelected ?
                    <GenericModal
                        isOpen={openModalReceivablesSelected}
                        size="lg"
                        title={"Recebíveis selecionados"}
                        callback={() => {
                            setOpenModalReceivablesSelected(false)
                        }}
                    >
                        <React.Fragment>

                            <AnticipationContractReceivables
                                contract={props.contract}
                            />

                        </React.Fragment>
                    </GenericModal>
                    : ""
            }

        </React.Fragment>
    )
}

export default withTranslation()(AnticipationSelectValue)