import React from 'react';
import MetaTags from 'react-meta-tags';
import {Card, CardBody, Col, Container, Label, Row, Table} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {Link, useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";

function ManageSubmittedDisputes(props) {
    const history = useHistory();
    const navigateToNew = () => history.push('/disputes/new');
    const navigateToEdit = () => history.push('/disputes/edit/10');

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Manage submitted disputes")}</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title={props.t("Disputes")} breadcrumbItem={props.t("Manage submitted disputes")}/>

                    {/* Search */}
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">{props.t("Filters")}</h4>
                                    <Row>
                                        <Col lg={3}>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control"
                                                       id="inputExternalReference"
                                                       placeholder="."/>
                                                <Label
                                                    for="inputExternalReference">{props.t("External Reference")}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control"
                                                       id="inputDestinationInstitution"
                                                       placeholder="."/>
                                                <Label
                                                    for="inputDestinationInstitution">{props.t("Destination institution")}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control"
                                                       id="inputReceivingEndUser"
                                                       placeholder="."/>
                                                <Label
                                                    for="inputReceivingEndUser">{props.t("Receiving end user")}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control"
                                                       id="inputDisputeType"
                                                       placeholder="."/>
                                                <Label for="inputDisputeType">{props.t("Dispute type")}</Label>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={3}>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control"
                                                       id="inputTransactionIdentifier"
                                                       placeholder="."/>
                                                <Label
                                                    for="inputTransactionIdentifier">{props.t("Transaction/contract identifier")}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control"
                                                       id="inputReason"
                                                       placeholder="."/>
                                                <Label for="inputReason">{props.t("Reason")}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control"
                                                       id="inputDateOfDispute"
                                                       placeholder="."/>
                                                <Label for="inputDateOfDispute">{props.t("Date of dispute")}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control"
                                                       id="inputStatus"
                                                       placeholder="."/>
                                                <Label for="inputStatus">{props.t("Status")}</Label>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={12}>
                                            <div className="text-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-light w-sm waves-effect waves-light"
                                                >
                                                    {props.t("Clean filters")}
                                                </button>
                                                {" "}
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-light w-sm waves-effect waves-light"
                                                >
                                                    {props.t("Apply Search")}
                                                </button>
                                                {" "}
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-primary w-sm waves-effect waves-light"
                                                    onClick={() => {
                                                        navigateToNew();
                                                    }}
                                                >
                                                    {props.t("New")}
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Search results */}
                    <Row>
                        <div className="table-responsive">
                            <Table className="table table-striped table-bordered mb-0 table-hover font-size-12">
                                <thead className="table-light">
                                <tr>
                                    <th>{props.t("Status")}</th>
                                    <th>{props.t("Dispute type")}</th>
                                    <th>{props.t("Receiving end user")}</th>
                                    <th>{props.t("Destination institution")}</th>
                                    <th>{props.t("Date of dispute")}</th>
                                    <th>{props.t("Transaction/contract identifier")}</th>
                                    <th>{props.t("Reason")}</th>
                                </tr>
                                </thead>
                                <tbody>

                                {/*{"LINHA 1"}*/}
                                <tr onClick={() => {
                                    navigateToEdit();
                                }}>
                                    <td scope="row">
                                                         <span className="badge bg-success me-1 w-sm">
                                      Concluída
                                    </span>
                                    </td>
                                    <td>
                                        Opt-in
                                    </td>
                                    <td>
                                        JOHNNATHA ALVES DOS SANTOS <br/>
                                        009.060.220-02
                                    </td>
                                    <td>
                                        VERDE ADM CARTõES CRéDITO S.A.<br/>
                                        01.722.480/0001-67
                                    </td>
                                    <td>
                                        20/06/2021
                                    </td>
                                    <td>
                                        CTFO8426a5f235e3406894496713d81bd46f22222222
                                    </td>
                                    <td>
                                        Títular ou usuário final...
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
}

export default withRouter(withTranslation()(ManageSubmittedDisputes))