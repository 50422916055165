import React from 'react'
import { Card, CardBody, Table} from "reactstrap"

import {withTranslation} from "react-i18next"
import $filter from "../../common/utils/$filter";

const OrderViewPayments = (props) => {

    return (
        <React.Fragment>
            <Card>
                <CardBody>

                    <div className="table-responsive">
                        <Table
                            className="table table-striped table-bordered font-size-default">
                            <thead className="table-light">
                            <tr>
                                <th className="text-start">#</th>
                                <th>Data da liquidação</th>
                                <th>Valor devido</th>
                                <th>Valor liquidado</th>
                                <th>Modalidade</th>
                                <th>Origem</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                (props.entity.liquidations || []).map((liq, index)=>{
                                    return (
                                        <tr key={index}>
                                            <td className="text-start">{index+1}</td>
                                            <td>{$filter(liq.liquidationDate, 'DATE')}</td>
                                            <td>{$filter(liq.amountDue, 'MONEY')}</td>
                                            <td>{$filter(liq.amountReceived, 'MONEY')}</td>
                                            <td>
                                                {liq.typeLiquidation}
                                            </td>
                                            <td>
                                                {liq.typeLiquidation === 'LIQBOL' ? 'ASAAS': '' }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                    </div>

                </CardBody>
            </Card>

        </React.Fragment>
    )

}

export default withTranslation()(OrderViewPayments)