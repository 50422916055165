import React, {useState} from 'react'

import {Col, Row, Table} from "reactstrap"
import {withTranslation} from "react-i18next"
import InputText from "../../../components/Common/InputText"
import $filter from "../../../common/utils/$filter"
import Select2DiscountRates from "../../../components/Common/Select2DiscountRates";

function AnticipationContractResume(props) {

    const setDiscountRate = (value) => {
        props.contract.additionalInfo.discountRate = value
        props.setContract(props.contract)
    }

    const setAdmRate = (value) => {
        props.contract.additionalInfo.admRate = value
        props.setContract(props.contract)
    }

    return (
        <React.Fragment>
            <Row>
                <Col lg={8} className="mb-3">
                    <InputText
                        value={props.contract.contractor.name}
                        readOnly={true}
                        label={props.t("Estabelecimento Comercial (Contratante)")}
                    />
                </Col>
                <Col lg={4} className="mb-3">
                    <InputText
                        readOnly={true}
                        value={$filter(props.contract.contractor.legalId, 'CPF_CNPJ')}
                        label={props.t("CPF/CNPJ")}/>
                </Col>
            </Row>

            <Row>
                <Col lg={8} className="mb-3">
                    <InputText
                        value={props.contract.financialAgent.name}
                        readOnly={true}
                        label={props.t("Agente Financeiro (Credor)")}
                    />
                </Col>
                <Col lg={4} className="mb-3">
                    <InputText
                        readOnly={true}
                        value={$filter(props.contract.financialAgent.legalId, 'CPF_CNPJ')}
                        label={props.t("CPF/CNPJ")}/>
                </Col>
            </Row>

            <Row>
                <Col lg={12} className="mt-3">
                    <p className="card-title-desc">{props.t("Negociação")}</p>
                    <hr/>
                </Col>
            </Row>

            <Row>
                <Col lg={4} className="mb-3">
                    <Select2DiscountRates
                        defaultValue={3}
                        label={props.t("Taxa de deságio (a.m)")}
                        onChange={(option) => {
                            setDiscountRate(option.value)
                        }}
                    />
                </Col>
                <Col lg={4} className="mb-3">
                    <Select2DiscountRates
                        defaultOptins={[
                            {label: "0,00%", value: "0.00"},
                        ]}
                        defaultValue={0}
                        label={props.t("Taxa administrativa")}
                        onChange={(option) => {
                            setAdmRate(option.value)
                        }}
                    />
                </Col>
                <Col lg={4} className="mb-3">
                    <InputText
                        readOnly={true}
                        value={$filter(props.contract.signatureDate, 'DATE')}
                        label={props.t("Data da contratação")}/>
                </Col>
            </Row>

            <Row>
                <Col lg={12}>
                    <hr/>
                </Col>
            </Row>

            <Row>
                <Col lg={12}>

                    <Table
                        className="table table-striped table-bordered ">
                        <thead className="table-light">
                        <tr>
                            <th>Prazo médio</th>
                            <th>Valor à antecipar</th>
                            <th>Valor à liberar de crédito</th>
                            <th>Valor de deságio</th>
                            <th>Taxa efetiva</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td scope="row">
                                {props.contract.additionalInfo.averageTerm} dias
                            </td>
                            <td>
                                {$filter(props.contract.debitBalance, 'money')}
                            </td>
                            <td>
                                {$filter(props.contract.additionalInfo.mainValue, 'money')}
                            </td>
                            <td>
                                {$filter(props.contract.additionalInfo.discountValue, 'money')}
                            </td>
                            <td>
                                {$filter(props.contract.additionalInfo.effectiveRate, 'PRECENT')}
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>

        </React.Fragment>
    )
}

export default withTranslation()(AnticipationContractResume)