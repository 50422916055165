const AccountTypeDomain = {
  options: [
    { label: "Conta Corrente", value: "1", code: "CC" },
    { label: "Conta de Pagamento", value: "2", code: "PG" },
    { label: "Conta Poupança", value: "3", code: "PP" },
    { label: "Conta de Depósito", value: "4", code: "CD" },
  ],

  getLabelByValue(value) {
    if (!value) return;
    for (const i in AccountTypeDomain.options) {
      if (AccountTypeDomain.options[i].value.toString() === value.toString()) {
        return this.options[i].label;
      }
      if (AccountTypeDomain.options[i].code === value) {
        return this.options[i].label;
      }
    }
    return null;
  },
};

export default AccountTypeDomain;
