class BooleanUtils {

    static fromNative(value) {
        if ((value !== null && value !==  undefined)){
            if (value.toString().toUpperCase() === 'TRUE'){
                return 'Sim'
            }
            if (value.toString().toUpperCase() === 'FALSE'){
                return 'Não'
            }
        }
        return value
    }
}

export default BooleanUtils