import React from 'react'
import MaskedInput from 'react-text-mask'
import {Label} from "reactstrap"
import {withTranslation} from "react-i18next"
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import {number} from "prop-types";

class InputMoney extends React.Component {

    constructor(props) {
        super(props)

        const defaultMaskOptions = {
            prefix: 'R$ ',
            suffix: '',
            includeThousandsSeparator: true,
            thousandsSeparatorSymbol: '.',
            allowDecimal: true,
            decimalSymbol: ',',
            decimalLimit: 2,
            integerLimit: 10,
            allowNegative: false,
            allowLeadingZeroes: false,
        }

        const currencyMask = createNumberMask({
            ...defaultMaskOptions,
        })

        const floatVal = this.props['value'] !== undefined && this.props['value'] !== null ?
            parseFloat(this.props['value']) : null

        this.state = {
            isValid: true,
            "$error": null,
            value: InputMoney.getInicialValue(floatVal),
            defaultMaskOptions: defaultMaskOptions,
            currencyMask: currencyMask
        }
    }

    buildValue = (value) => {
        const floatVal = value !== undefined && value !== null ?
            parseFloat(value) : null

        return InputMoney.getInicialValue(floatVal)
    }

    static getInicialValue(value){
        if (value === null || value === undefined)
            return null

        let newValue = parseFloat(value.toString()).toFixed(2)
        newValue = newValue.replace(".", ",")

        return newValue
    }

    static getValueWithoutMask = (value) => {
        return value
            .replace('R$', '').trim()
            .split('.').join("")
            .replace(',', '.')
            .trim()
    }

    setValue = (event) => {
        const newState = {value: event.target.value}
        this.setState(newState)

        if (this.props.onChange) {
            const valueWithoutMask = InputMoney.getValueWithoutMask(
                event.target.value
            )
            this.props.onChange({
                target: {value: parseFloat(valueWithoutMask), name: event.target.name}
            })
        }
    }

    onChangeValidation = (event) => {
        if (this.props.onChangeValidation) {
            const valueWithoutMask = InputMoney.getValueWithoutMask(
                event.target.value
            )
            this.props.onChangeValidation(valueWithoutMask)
        }
    }

    render() {
        return (<React.Fragment>
            {
                this.props.label !== null && this.props.label !== undefined ?

                    <Label for={this.props.id} className="form-label">
                        {`${this.props.required === true ? "*" : ""}${this.props.label}`}
                    </Label>
                    : ("")
            }

            <MaskedInput className={this.state.isValid ? "form-control" : "form-control is-invalid"}
                         mask={this.state.currencyMask}
                         placeholder="R$ 0,00"
                         type="text"
                         id={this.props.id}
                         name={this.props.id}
                         autoComplete="off"
                         onChange={this.setValue}
                         value={
                             this.props['value'] !== undefined && this.props['value'] !== null ?
                             this.buildValue(this.props['value'])
                                 :
                                 null
                         }
                         onBlur={this.onChangeValidation}
                         disabled={
                             this.props.readOnly !== null && this.props.readOnly !== undefined ?
                                 this.props.readOnly : false
                         }
                         {...this.state.defaultMaskOptions}
            />
        </React.Fragment>)
    }
}

export default withTranslation()(InputMoney)