class PaymentMethodDomain {

    "CASH.NOW" = {
        value: 'CASH.NOW',
        name: 'Cash.Now',
        icon: "fas fa-award text-success",
        endUserLabel: "Boleto"
    };

    BOLETO_GARANTIDO = {
        value: 'BOLETO_GARANTIDO',
        name: 'Boleto Protegido',
        icon: "fas fa-award text-success",
        endUserLabel: "Boleto"
    };

    "CASH.RECUPERA" = {
        value: 'CASH.RECUPERA',
        name: 'Cash.Recupera',
        icon: "fas fa-award text-success",
        endUserLabel: "Boleto"
    };

    "CASH.FLOW" = {
        value: 'CASH.FLOW',
        name: 'Cash.Flow',
        icon: "fas fa-award text-success",
        endUserLabel: "Recebíveis de cartão"
    };

    static getNameFromValue(value) {
        const obj = new PaymentMethodDomain()

        for (const fieldName in obj) {
            if (obj.hasOwnProperty(fieldName)) {
                if (value === obj[fieldName]['value']) {
                    return obj[fieldName]['name'];
                }
            }
        }
        return null;
    }

    static getClassFromValue(value) {
        if (value === 'P') return "badge rounded-pill bg-warning bg-soft text-warning badge-md"
        if (value === 'A') return "badge rounded-pill bg-success bg-soft text-success badge-md"
        if (value === 'R') return "badge rounded-pill bg-danger bg-soft badge-md text-danger"
        if (value === 'C') return "badge rounded-pill bg-light bg-soft text-light badge-md"

        return ""
    }

    static getInstanceFromValue(value) {
        const instance = new PaymentMethodDomain()[value]
        if (instance) return instance

        return {
            icon: null
        }
    }
}

export default PaymentMethodDomain