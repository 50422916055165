import React, {useEffect, useState} from "react"

import {Row, Alert, Col} from "reactstrap"
import {Link, useHistory, withRouter} from "react-router-dom"
import {withTranslation} from "react-i18next";
import {AvForm, AvField} from "availity-reactstrap-validation"

import Loader from "../../components/Common/Loader";
import {post} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";

const ConfirmEmailToNewPwd = (props) => {
    const [loading, setLoading] = useState(false)
    const [showWaitingMsg, setShowWaitingMsg] = useState(false)

    const history = useHistory()

    document.body.classList.add("login_page")

    const handleValidSubmit = async (event, values) => {
        try {
            const {email} = values
             await post(
                url.POST_REQUEST_NEW_PASSWORD, {email}, null, setLoading
            )
            setShowWaitingMsg(true)
        } catch (e) {
            props.onFailure(e)
        }
    }

    const backPage = () => {
        history.push('/login')
        window.location.reload(true)
    }

    return (
        <React.Fragment>
            <div>
                <h3>{props.t("I Forget recovery")}</h3>
            </div>

            {
                showWaitingMsg ?
                    <div className="mt-4 pt-3 text-center">
                        <Alert color="info" className="text-center mb-4 mt-2">
                            {props.t("We have sent you an email with the password recovery link")}
                        </Alert>

                        <button
                            className="btn btn-secondary w-100 waves-effect waves-light"
                            type="button"
                            onClick={backPage}
                            style={{height: 42}}
                        >
                            {props.t("Back")}
                        </button>
                    </div>

                    :
                    <div className="mt-4 pt-3">
                        <AvForm  className="form-horizontal"
                                 onValidSubmit={handleValidSubmit}>
                            <Row className="mb-4">
                                <div>
                                    <AvField
                                        name="email"
                                        label="Email"
                                        className="form-control"
                                        placeholder={props.t("Enter your email here")}
                                        type="email"
                                        required
                                        errorMessage={props.t("Invalid email")}
                                        style={{height: 48}}
                                    />
                                </div>
                            </Row>

                            <Row>
                                <Col lg={12} className="mt-4 text-center">
                                    <button
                                        className="btn btn-primary w-100 waves-effect waves-light"
                                        type="submit"
                                        style={{height: 42}}
                                    >
                                        {props.t("Get recovery link")}
                                    </button>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={12} className="mt-4 text-center">
                                    <Link
                                        to="#!"
                                        onClick={backPage}
                                        style={{height: 42}}
                                    >
                                        {props.t("Back")}
                                    </Link>
                                </Col>
                            </Row>
                        </AvForm>
                    </div>
            }
            {
                loading ?
                    <Loader loading={loading}/>
                    :
                    (" ")
            }
        </React.Fragment>
    )
}


export default withRouter(withTranslation()(ConfirmEmailToNewPwd))

