import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Modal,
  Card,
  CardBody,
  Table,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap";

import { withTranslation } from "react-i18next";
import { post, put } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import Loader from "../../components/Common/Loader";
import ErrorDialog from "../../components/Common/ErrorDialog";
import InputText from "../../components/Common/InputText";
import InputPercent from "../../components/Common/InputPercent";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Select2Generic from "../../components/Common/Select2Generic";
import { Scrollbars } from "react-custom-scrollbars-2";

function SegmentNew(props) {
  const [productTabs] = useState([
    { title: "Cash.Now", id: "CASH.NOW" },
    { title: "Cash.Flow", id: "CASH.FLOW" },
    { title: "Boleto Protegido", id: "BOLETO_GARANTIDO" },
    { title: "Cash.Recupera", id: "CASH.RECUPERA" },
  ]);

  const [innerHeight] = useState(window.innerHeight);

  const [currentProductTab, setCurrentProductTab] = useState("CASH.NOW");

  const [entity, setEntity] = useState(
    props.editMode === "NEW"
      ? {
          segmentLabel: null,
          products: [],
        }
      : props.entity
  );

  const [loading, setLoading] = useState(false);
  const [currentPaymentPeriodBoletoGar, setCurrentPaymentPeriodBoletoGar] =
    useState(null);
  const [currentPaymentPeriodCashRec, setCurrentPaymentPeriodCashRec] =
    useState(null);
  const [currentPaymentPeriodCashNow, setCurrentPaymentPeriodCashNow] =
    useState(null);
  const [currentPaymentPeriodCashFlow, setCurrentPaymentPeriodCashFlow] =
    useState(null);

  const [loaded, setLoaded] = useState(false);
  const [applicationOfTheFee] = useState([
    { label: "Ao mês", value: "a.m", id: 0 },
    { label: "Por operação", value: "p.o", id: 1 },
  ]);

  const [boletoGarantido, setBoletoGarantido] = useState({
    product: "BOLETO_GARANTIDO",
    tax: 0,
    taxPeriod: null,
    active: true,
    paymentPeriod: [],
  });

  const [cashNow, setCashNow] = useState({
    product: "CASH.NOW",
    tax: 0,
    taxPeriod: null,
    active: true,
    paymentPeriod: [],
  });

  const [cashRecupera, setCashRecupera] = useState({
    product: "CASH.RECUPERA",
    tax: 0,
    taxPeriod: null,
    active: true,
    paymentPeriod: [],
  });

  const [cashFlow, setCashFlow] = useState({
    product: "CASH.FLOW",
    tax: 0,
    taxFee: 0,
    taxPeriod: null,
    active: true,
    paymentPeriod: [],
  });

  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const [editMode] = useState(props.editMode);

  const getProduct = (products, prodName) => {
    if (products && products.length > 0) {
      const prods = products.filter((pr) => {
        return pr.product === prodName;
      });
      return prods.length > 0 ? prods[0] : null;
    }
    return null;
  };

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      if (props.entity) {
        const prodBol = getProduct(props.entity.products, "BOLETO_GARANTIDO");
        setBoletoGarantido(prodBol ? prodBol : boletoGarantido);

        const prodNow = getProduct(props.entity.products, "CASH.NOW");
        setCashNow(prodNow ? prodNow : cashNow);

        const prodCashRec = getProduct(props.entity.products, "CASH.RECUPERA");
        setCashRecupera(prodCashRec ? prodCashRec : cashRecupera);

        const prodCashFlow = getProduct(props.entity.products, "CASH.FLOW");
        setCashFlow(prodCashFlow ? prodCashFlow : cashFlow);
      }
    }
  });

  const toggle = () => {
    setIsOpen(false);
  };

  const afterClosed = () => {
    props.callback({ isOpen: false, entity: null, event: "CLOSE" });
  };

  const close = (newEntity) => {
    props.callback({
      isOpen: false,
      entity: newEntity,
      event: editMode === "NEW" ? "SAVED" : "CHANGED",
    });
  };

  const handleFieldEntity = (name, value) => {
    entity[name] = value;
    setEntity(entity);
  };

  const removePaymentPeriodBoletoGar = (paymentsPeriodToDel) => {
    boletoGarantido.paymentPeriod = boletoGarantido.paymentPeriod.filter(
      (pp) => {
        return pp !== paymentsPeriodToDel;
      }
    );
    setBoletoGarantido(Object.assign({}, boletoGarantido));
  };

  const removePaymentPeriodCashNow = (paymentsPeriodToDel) => {
    cashNow.paymentPeriod = cashNow.paymentPeriod.filter((pp) => {
      return pp !== paymentsPeriodToDel;
    });
    setCashNow(Object.assign({}, cashNow));
  };
  const removePaymentPeriodCashFlow = (paymentsPeriodToDel) => {
    cashNow.paymentPeriod = cashNow.paymentPeriod.filter((pp) => {
      return pp !== paymentsPeriodToDel;
    });
    setCashNow(Object.assign({}, cashNow));
  };

  const removePaymentPeriodCashRecupera = (paymentsPeriodToDel) => {
    cashRecupera.paymentPeriod = cashRecupera.paymentPeriod.filter((pp) => {
      return pp !== paymentsPeriodToDel;
    });
    setCashRecupera(Object.assign({}, cashRecupera));
  };

  const handleAddPaymentPeriodBoletoGar = () => {
    if (
      currentPaymentPeriodBoletoGar &&
      currentPaymentPeriodBoletoGar.toString().length > 0
    ) {
      if (!boletoGarantido.paymentPeriod) boletoGarantido.paymentPeriod = [];

      const exists = boletoGarantido.paymentPeriod.filter((pp) => {
        return pp === currentPaymentPeriodBoletoGar;
      });
      if (exists.length === 0) {
        boletoGarantido.paymentPeriod.push(currentPaymentPeriodBoletoGar);
        setBoletoGarantido(Object.assign({}, boletoGarantido));
      }
      setCurrentPaymentPeriodBoletoGar("");
      document.getElementById("addPaymentPeriodBolGar").focus();
    }
  };

  const handleAddPaymentPeriodCashNow = () => {
    if (
      currentPaymentPeriodCashNow &&
      currentPaymentPeriodCashNow.toString().length > 0
    ) {
      if (!cashNow.paymentPeriod) cashNow.paymentPeriod = [];

      const exists = cashNow.paymentPeriod.filter((pp) => {
        return pp === currentPaymentPeriodCashNow;
      });
      if (exists.length === 0) {
        cashNow.paymentPeriod.push(currentPaymentPeriodCashNow);
        setCashNow(Object.assign({}, cashNow));
      }
      setCurrentPaymentPeriodCashNow("");
      document.getElementById("addPaymentPeriodCashNow").focus();
    }
  };

  const handleAddPaymentPeriodCashFlow = () => {
    if (
      currentPaymentPeriodCashFlow &&
      currentPaymentPeriodCashFlow.toString().length > 0
    ) {
      if (!cashFlow.paymentPeriod) cashFlow.paymentPeriod = [];

      const exists = cashFlow.paymentPeriod.filter((pp) => {
        return pp === currentPaymentPeriodCashFlow;
      });
      if (exists.length === 0) {
        cashFlow.paymentPeriod.push(currentPaymentPeriodCashFlow);
        setCashFlow(Object.assign({}, cashFlow));
      }
      setCurrentPaymentPeriodCashFlow("");
      document.getElementById("addPaymentPeriodCashFlow").focus();
    }
  };

  const handleAddPaymentPeriodCashRec = () => {
    if (
      currentPaymentPeriodCashRec &&
      currentPaymentPeriodCashRec.toString().length > 0
    ) {
      if (!cashRecupera.paymentPeriod) cashRecupera.paymentPeriod = [];

      const exists = cashRecupera.paymentPeriod.filter((pp) => {
        return pp === currentPaymentPeriodCashRec;
      });
      if (exists.length === 0) {
        cashRecupera.paymentPeriod.push(currentPaymentPeriodCashRec);
        setCashRecupera(Object.assign({}, cashRecupera));
      }
      setCurrentPaymentPeriodCashRec("");
      document.getElementById("addPaymentPeriodCashRec").focus();
    }
  };

  const save = async () => {
    if (loading) return;
    try {
      const request = {
        segmentLabel: entity.segmentLabel,
        segmentName: entity.segmentName,
        creditExpositionByPerformance: entity.creditExpositionByPerformance,
        products: [cashNow, boletoGarantido, cashRecupera, cashFlow],
      };
      console.log("etty", entity);
      console.log("req", request);
      let doCall = post;
      let urlApi = url.POST_SEGMENT;

      if (entity.segmentName) {
        doCall = put;
        urlApi = url.PUT_SEGMENT;
      }

      let response = await doCall(urlApi, request, null, setLoading);
      close(response["segment"]);
    } catch (e) {
      if (e["status"] !== "200" && e["status"] !== "403") setError(e);
    }
  };

  const handlePaymentPeriodValidation = (value) => {
    const parts = value.split("/");
    for (const i in parts) {
      if (isNaN(parts[i])) {
        return false;
      }
    }
    return true;
  };

  const handleTaxPeriodBolGar = (value) => {
    boletoGarantido.taxPeriod = value;
    setBoletoGarantido(Object.assign({}, boletoGarantido));
  };

  const handleTaxPeriodCashNow = (value) => {
    cashNow.taxPeriod = value;
    setCashNow(Object.assign({}, cashNow));
  };

  const handleTaxPeriodCashFlow = (value) => {
    cashFlow.taxPeriod = value;
    setCashFlow(Object.assign({}, cashFlow));
  };

  const handleTaxPeriodCashRec = (value) => {
    cashRecupera.taxPeriod = value;
    setCashRecupera(Object.assign({}, cashRecupera));
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          toggle();
        }}
        size="lg"
        onClosed={() => {
          afterClosed();
        }}
        modalClassName="drawer right-align"
        contentClassName="modal-content-scroll"
      >
        <Scrollbars style={{ height: innerHeight - 80 }}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {editMode === "NEW"
                ? props.t("Register Segment")
                : props.t("Update Segment")}
            </h5>

            <button
              type="button"
              onClick={() => {
                toggle();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <Row>
              <Col lg={6} className="mb-3">
                <InputText
                  id="segmentLabel"
                  required={true}
                  autoComplete="off"
                  value={entity.segmentLabel}
                  readOnly={editMode !== "NEW"}
                  onChange={(event) => {
                    handleFieldEntity("segmentLabel", event.target.value);
                  }}
                  label={props.t("Name")}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={8} className="mb-3">
                <div
                  className="form-check form-switch form-switch-lg mb-lg-3"
                  dir="ltr"
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked={entity.creditExpositionByPerformance}
                    onClick={(event) => {
                      entity.creditExpositionByPerformance =
                        event.target.checked;
                      console.log("creditExpositionByPerformance", entity);
                      setEntity(Object.assign({}, entity));
                    }}
                    id="creditExpositionByPerformance"
                  />
                  <Label
                    className="form-check-label"
                    for="creditExpositionByPerformance"
                  >
                    {props.t("Credit Exposition by Performance")}
                  </Label>
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col lg={12}>
                <Nav tabs className="nav-tabs-custom nav-justified">
                  {productTabs.map((tab, index) => {
                    return (
                      <NavItem key={tab.id}>
                        <NavLink
                          className={classnames({
                            active: currentProductTab === tab.id,
                          })}
                          onClick={() => {
                            setCurrentProductTab(tab.id);
                          }}
                        >
                          {tab.title}
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </Nav>
              </Col>
            </Row>

            <TabContent activeTab={currentProductTab}>
              <TabPane tabId={"CASH.NOW"}>
                <Row className="mb-3  mt-4">
                  <Col>
                    <div
                      className="form-check form-switch form-switch-lg mb-lg-3"
                      dir="ltr"
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultChecked={cashNow.active}
                        onClick={(event) => {
                          cashNow.active = event.target.checked;
                          setCashNow(Object.assign({}, cashNow));
                        }}
                        id="cashNowActive"
                      />
                      <Label className="form-check-label" for="cashNowActive">
                        {cashNow.active ? "Ativo" : "Inativo"}
                      </Label>
                    </div>
                  </Col>
                </Row>

                {cashNow.active ? (
                  <React.Fragment>
                    <Row>
                      <Col lg={6} className="mb-3">
                        <InputPercent
                          id="cashNowTax"
                          required={true}
                          autoComplete="off"
                          value={cashNow["tax"]}
                          onChange={(event) => {
                            cashNow["tax"] = event.target.value;
                            setCashNow(cashNow);
                          }}
                          label={props.t("Transaction fee")}
                        />
                      </Col>

                      <Col lg={6} className="mb-3">
                        <Select2Generic
                          id="appProfile"
                          required={true}
                          defaultOptins={applicationOfTheFee}
                          value={applicationOfTheFee.map((op, index) => {
                            if (op.value === cashNow["taxPeriod"]) {
                              return op;
                            }
                          })}
                          label={props.t("Application of the fee")}
                          onChange={(option) => {
                            handleTaxPeriodCashNow(option.value);
                          }}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6} className="mb-3">
                        <InputText
                          id="addPaymentPeriodCashNow"
                          required={false}
                          datalist={["30/60/90", "30/60/90/120"]}
                          label={props.t("Payment period")}
                          value={currentPaymentPeriodCashNow}
                          validation={handlePaymentPeriodValidation}
                          onChange={(event) => {
                            setCurrentPaymentPeriodCashNow(event.target.value);
                          }}
                        />
                      </Col>
                      <Col lg={6} style={{ marginTop: 36, cursor: "pointer" }}>
                        <Link to="#!" onClick={handleAddPaymentPeriodCashNow}>
                          {props.t("Add")}&nbsp;&nbsp;
                          <i className="fa fa-plus"></i>
                        </Link>
                      </Col>
                    </Row>

                    {cashNow &&
                    cashNow.paymentPeriod &&
                    cashNow.paymentPeriod.length > 0 ? (
                      <Row>
                        <Col lg={12} className="mb-3">
                          <Card>
                            <CardBody>
                              <Table className="table table-striped mb-0">
                                <tbody>
                                  {(cashNow.paymentPeriod || []).map(
                                    (pp, key) => (
                                      <tr key={pp}>
                                        <td>{pp}</td>
                                        <td>
                                          {pp.split("/").length} parcela(s)
                                        </td>

                                        <td
                                          className="col-md-1 text-center"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            removePaymentPeriodCashNow(pp);
                                          }}
                                        >
                                          <i className="fa fa-trash"></i>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                ) : (
                  ""
                )}
              </TabPane>

              <TabPane tabId={"CASH.FLOW"}>
                <Row className="mb-3  mt-4">
                  <Col>
                    <div
                      className="form-check form-switch form-switch-lg mb-lg-3"
                      dir="ltr"
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultChecked={cashFlow.active}
                        onClick={(event) => {
                          cashFlow.active = event.target.checked;
                          setCashFlow(Object.assign({}, cashFlow));
                        }}
                        id="cashFlowActive"
                      />
                      <Label className="form-check-label" for="cashFlowActive">
                        {cashFlow.active ? "Ativo" : "Inativo"}
                      </Label>
                    </div>
                  </Col>
                </Row>

                {cashFlow.active ? (
                  <React.Fragment>
                    <Row>
                      <Col lg={6} className="mb-3">
                        <InputPercent
                          id="cashFlowTax"
                          required={true}
                          autoComplete="off"
                          value={cashFlow["tax"]}
                          onChange={(event) => {
                            cashFlow["tax"] = event.target.value;
                            setCashFlow(cashFlow);
                          }}
                          label={props.t("Transaction fee")}
                        />
                      </Col>

                      <Col lg={6} className="mb-3">
                        <Select2Generic
                          id="cashFlowTaxPeriod"
                          required={true}
                          defaultOptins={applicationOfTheFee}
                          value={applicationOfTheFee.map((op, index) => {
                            if (op.value === cashFlow["taxPeriod"]) {
                              return op;
                            }
                          })}
                          label={props.t("Application of the fee")}
                          onChange={(option) => {
                            handleTaxPeriodCashFlow(option.value);
                          }}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6} className="mb-3">
                        <InputPercent
                          id="cashFlowCaptureAgentTax"
                          required={true}
                          autoComplete="off"
                          value={cashFlow["taxFee"]}
                          onChange={(event) => {
                            cashFlow["taxFee"] = event.target.value;
                            setCashFlow(cashFlow);
                          }}
                          label={props.t("Tax Capture Agent")}
                        />
                      </Col>
                    </Row>
                  </React.Fragment>
                ) : (
                  ""
                )}

                {/*<Row>*/}
                {/*    <Col lg={6} className="mb-3">*/}
                {/*        <InputText*/}
                {/*            id="addPaymentPeriodCashFlow"*/}
                {/*            required={false}*/}
                {/*            datalist={['30/60/90', '30/60/90/120']}*/}
                {/*            label={props.t("Payment period")}*/}
                {/*            value={currentPaymentPeriodCashFlow}*/}
                {/*            validation={handlePaymentPeriodValidation}*/}
                {/*            onChange={(event) => {*/}
                {/*                setCurrentPaymentPeriodCashFlow(*/}
                {/*                    event.target.value*/}
                {/*                )*/}
                {/*            }}/>*/}
                {/*    </Col>*/}
                {/*    <Col lg={6} style={{marginTop: 36, cursor: "pointer"}} >*/}
                {/*        <Link to="#!"*/}
                {/*              onClick={handleAddPaymentPeriodCashFlow}>*/}
                {/*            {props.t("Add")}&nbsp;&nbsp;<i className="fa fa-plus"></i>*/}
                {/*        </Link>*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                {/*{*/}
                {/*    cashFlow &&*/}
                {/*    cashFlow.paymentPeriod &&*/}
                {/*    cashFlow.paymentPeriod.length > 0 ?*/}
                {/*        <Row>*/}
                {/*            <Col lg={12} className="mb-3">*/}
                {/*                <Card>*/}
                {/*                    <CardBody>*/}
                {/*                        <Table className="table table-striped mb-0">*/}
                {/*                            <tbody>*/}
                {/*                            {(cashFlow.paymentPeriod || []).map((pp, key) => (*/}
                {/*                                <tr key={pp}*/}
                {/*                                >*/}
                {/*                                    <td>*/}
                {/*                                        {pp}*/}
                {/*                                    </td>*/}
                {/*                                    <td>*/}
                {/*                                        {pp.split('/').length} parcela(s)*/}
                {/*                                    </td>*/}

                {/*                                    <td className="col-md-1 text-center" style={{cursor: "pointer"}} onClick={() => {*/}
                {/*                                        removePaymentPeriodCashFlow( pp)*/}
                {/*                                    }}>*/}
                {/*                                        <i className="fa fa-trash"></i>*/}
                {/*                                    </td>*/}
                {/*                                </tr>*/}
                {/*                            ))}*/}
                {/*                            </tbody>*/}
                {/*                        </Table>*/}
                {/*                    </CardBody>*/}
                {/*                </Card>*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*        : ("")*/}
                {/*}*/}
              </TabPane>

              <TabPane tabId={"BOLETO_GARANTIDO"}>
                <Row className="mb-3 mt-4">
                  <Col>
                    <div
                      className="form-check form-switch form-switch-lg mb-lg-3"
                      dir="ltr"
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultChecked={boletoGarantido.active}
                        onClick={(event) => {
                          boletoGarantido.active = event.target.checked;
                          setBoletoGarantido(
                            Object.assign({}, boletoGarantido)
                          );
                        }}
                        id="boletoGarantidoActive"
                      />
                      <Label
                        className="form-check-label"
                        for="boletoGarantidoActive"
                      >
                        {boletoGarantido.active ? "Ativo" : "Inativo"}
                      </Label>
                    </div>
                  </Col>
                </Row>

                {boletoGarantido.active ? (
                  <React.Fragment>
                    <Row>
                      <Col lg={6} className="mb-3">
                        <InputPercent
                          id="boletoGarantidoTax"
                          required={true}
                          autoComplete="off"
                          value={boletoGarantido["tax"]}
                          onChange={(event) => {
                            boletoGarantido["tax"] = event.target.value;
                            setBoletoGarantido(boletoGarantido);
                          }}
                          label={props.t("Transaction fee")}
                        />
                      </Col>

                      <Col lg={6} className="mb-3">
                        <Select2Generic
                          id="appProfile"
                          required={true}
                          defaultOptins={applicationOfTheFee}
                          value={applicationOfTheFee.map((op, index) => {
                            if (op.value === boletoGarantido["taxPeriod"]) {
                              return op;
                            }
                          })}
                          label={props.t("Application of the fee")}
                          onChange={(option) => {
                            handleTaxPeriodBolGar(option.value);
                          }}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6} className="mb-3">
                        <InputText
                          id="addPaymentPeriodBolGar"
                          required={false}
                          datalist={["30/60/90", "30/60/90/120"]}
                          label={props.t("Payment period")}
                          value={currentPaymentPeriodBoletoGar}
                          validation={handlePaymentPeriodValidation}
                          onChange={(event) => {
                            setCurrentPaymentPeriodBoletoGar(
                              event.target.value
                            );
                          }}
                        />
                      </Col>
                      <Col lg={6} style={{ marginTop: 36, cursor: "pointer" }}>
                        <Link to="#!" onClick={handleAddPaymentPeriodBoletoGar}>
                          {props.t("Add")}&nbsp;&nbsp;
                          <i className="fa fa-plus"></i>
                        </Link>
                      </Col>
                    </Row>

                    {boletoGarantido &&
                    boletoGarantido.paymentPeriod &&
                    boletoGarantido.paymentPeriod.length > 0 ? (
                      <Row>
                        <Col lg={12} className="mb-3">
                          <Card>
                            <CardBody>
                              <Table className="table table-striped mb-0">
                                <tbody>
                                  {(boletoGarantido.paymentPeriod || []).map(
                                    (pp, key) => (
                                      <tr key={pp}>
                                        <td>{pp}</td>

                                        <td>
                                          {pp.split("/").length} parcela(s)
                                        </td>

                                        <td
                                          className="col-md-1 text-center"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            removePaymentPeriodBoletoGar(pp);
                                          }}
                                        >
                                          <i className="fa fa-trash"></i>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )}
                  </React.Fragment>
                ) : (
                  <></>
                )}
              </TabPane>
              <TabPane tabId={"CASH.RECUPERA"}>
                <Row className="mb-3 mt-4">
                  <Col>
                    <div
                      className="form-check form-switch form-switch-lg mb-lg-3"
                      dir="ltr"
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultChecked={cashRecupera.active}
                        onClick={(event) => {
                          cashRecupera.active = event.target.checked;
                          setCashRecupera(Object.assign({}, cashRecupera));
                        }}
                        id="cashRecuperaActive"
                      />
                      <Label
                        className="form-check-label"
                        for="cashRecuperaActive"
                      >
                        {cashRecupera.active ? "Ativo" : "Inativo"}
                      </Label>
                    </div>
                  </Col>
                </Row>

                {cashRecupera.active ? (
                  <React.Fragment>
                    <Row>
                      <Col lg={6} className="mb-3">
                        <InputPercent
                          id="cashRecuperaTax"
                          required={true}
                          autoComplete="off"
                          value={cashRecupera["tax"]}
                          onChange={(event) => {
                            cashRecupera["tax"] = event.target.value;
                            setCashRecupera(cashRecupera);
                          }}
                          label={props.t("Transaction fee")}
                        />
                      </Col>

                      <Col lg={6} className="mb-3">
                        <Select2Generic
                          id="appProfile"
                          required={true}
                          defaultOptins={applicationOfTheFee}
                          value={applicationOfTheFee.map((op, index) => {
                            if (op.value === cashRecupera["taxPeriod"]) {
                              return op;
                            }
                          })}
                          label={props.t("Application of the fee")}
                          onChange={(option) => {
                            handleTaxPeriodCashRec(option.value);
                          }}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6} className="mb-3">
                        <InputText
                          id="addPaymentPeriodCashRec"
                          required={false}
                          datalist={["30/60/90", "30/60/90/120"]}
                          label={props.t("Payment period")}
                          value={currentPaymentPeriodCashRec}
                          validation={handlePaymentPeriodValidation}
                          onChange={(event) => {
                            setCurrentPaymentPeriodCashRec(event.target.value);
                          }}
                        />
                      </Col>
                      <Col lg={6} style={{ marginTop: 36, cursor: "pointer" }}>
                        <Link to="#!" onClick={handleAddPaymentPeriodCashRec}>
                          {props.t("Add")}&nbsp;&nbsp;
                          <i className="fa fa-plus"></i>
                        </Link>
                      </Col>
                    </Row>

                    {cashRecupera &&
                    cashRecupera.paymentPeriod &&
                    cashRecupera.paymentPeriod.length > 0 ? (
                      <Row>
                        <Col lg={12} className="mb-3">
                          <Card>
                            <CardBody>
                              <Table className="table table-striped mb-0">
                                <tbody>
                                  {(cashRecupera.paymentPeriod || []).map(
                                    (pp, key) => (
                                      <tr key={pp}>
                                        <td>{pp}</td>
                                        <td>
                                          {pp.split("/").length} parcela(s)
                                        </td>

                                        <td
                                          className="col-md-1 text-center"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            removePaymentPeriodCashRecupera(pp);
                                          }}
                                        >
                                          <i className="fa fa-trash"></i>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                ) : (
                  ""
                )}
              </TabPane>
            </TabContent>
          </div>
        </Scrollbars>

        <div className="modal-footer fixed-bottom">
          <div className="text-end">
            <button
              className="btn btn-primary w-sm waves-effect waves-light"
              onClick={save}
            >
              {props.t("Save")}
            </button>
          </div>
        </div>

        {error ? (
          <ErrorDialog
            title={error["title"]}
            onClose={() => {
              setError(null);
            }}
          >
            <p>{error["detail"]} </p>
          </ErrorDialog>
        ) : (
          ""
        )}

        {loading ? <Loader loading={loading} /> : " "}
      </Modal>
    </React.Fragment>
  );
}

export default withTranslation()(SegmentNew);
