import React, {useState} from "react"
import {withTranslation} from "react-i18next"
import Select from "react-select"
import makeAnimated from "react-select/animated/dist/react-select.esm"
import {Label} from "reactstrap"
import ProductTypeDomain from "../../domains/ProductTypeDomain";

const animatedComponents = makeAnimated()

function Select2Product(props) {

    const [options, setOptions] = useState(
      ProductTypeDomain.options
    )

    function onChange(options) {
        if (props.onChange)
            props.onChange(options)
    }

    return (
        <div className="templating-select select2-container">
            {
                props.label !== null && props.label !== undefined ?
                    <Label for={props.id} className="form-label">
                        {`${props.required === true ? "*" : ""}${props.label}`}
                    </Label>
                    : ("")
            }
            <Select
                isMulti={props.isMulti !== null && props.isMulti !== undefined ? props.isMulti : false}
                classNamePrefix="select2-selection"
                closeMenuOnSelect={true}
                isSearchable={false}
                menuPlacement={props.menuPlacement}
                placeholder={
                    props.placeholder !== null && props.placeholder !== undefined ?
                        props.placeholder : ""
                }
                options={options}
                components={animatedComponents}
                onChange={(options) => {
                    onChange(options)
                }}
            />

        </div>
    )
}

export default withTranslation()(Select2Product)