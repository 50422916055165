import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";
import notificationsReducer from "./interceptor/reduxNotification";

const rootReducer = combineReducers({
  // public
  Layout,
  notifications: notificationsReducer,
});

export default rootReducer;
